import {Reducer} from 'redux'
import {unselected_attribute, VehiclesActionTypes, VehiclesState} from './types'
import {getCurrentDate} from "../../helpData/Date";
import {getVehicles_response} from "../../helpData/get_vehicles_response_example";
import {AttributesResponse, SimVehislesAttributesResponse} from "../../helpData/getVehicleAttributes";
import {AnalysisActionTypes} from "../analysis/types";
import moment from 'moment';


export const DateAttribute = {
    "name": "Date",
    "type": "date",
    "attribute_id": -1,
    "selected": true,
    "_value": {
        start_date: "2018-01-01",
        end_date: getCurrentDate().toString(),
    },
    "operator": "is"
};

export const getVehicles_query = {
    date_range: {
        start_date: DateAttribute._value.start_date,
        end_date: DateAttribute._value.end_date
    },
    attributes: []
};
// Type-safe initialState!
export const initialState: VehiclesState = {
    current_query_for_saving: getVehicles_query,
    sim_vehicle_suggest: [],
    vehicles_attributes: [],
    sim_vehicles_attributes: [],
    vehicles: {
        total: 0,
        vehicles: []
    },
    sim_vehicles: {
        total: 0,
        vehicles: []
    },
    rows_per_page: 10,
    group_by_attribute: unselected_attribute,
    vehicle_mapping: {},
    mapping_prev_sim_vehicle_suggest: {},
    vehicle_mapping_sim_vehicles_attributes: {},
    current_query_for_sim_vehicles_saving: {},
    selected_vehicles: [],
    selected_attributes: [],
    selected_date_range: {
        start_date: "LOADING",
        end_date: getCurrentDate().toString()
    },
    availableDateRange: {
        first_route: null,
        last_route: null
    },
    selected_sim_vehicles: [],
    errors: undefined,
    loading: false,
    group_to_sim_vehicle_mapping: {'N/A': []},
    incentives: {}
};
export const requiredColumns: string[] = ["telematics id", "eq id", "equipment id", "total distance", "average distance", "make", "model"];


// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<VehiclesState> = (state = initialState, action) => {
    switch (action.type) {
        case VehiclesActionTypes.FETCH_REQUEST: {
            return {...state, loading: true}
        }
        case VehiclesActionTypes.FETCH_SUCCESS: {
            if (action.payload.type === 'vehicles') {
                if (process.env.REACT_APP_NODE_ENV === 'mockdev') {
                    return {...state, vehicles_attributes: AttributesResponse, loading: false}
                } else {

                    return {...state, vehicles_attributes: action.payload.data, loading: false}
                }
            } else {
                if (process.env.REACT_APP_NODE_ENV === 'mockdev') {

                    return {...state, sim_vehicles_attributes: SimVehislesAttributesResponse, loading: false}
                } else {
                    return {...state, sim_vehicles_attributes: action.payload.data, loading: false}
                }
            }
        }
        case VehiclesActionTypes.FETCH_ERROR: {
            if (process.env.REACT_APP_NODE_ENV === 'mockdev') {
                return {...state, loading: false, vehicles_attributes: AttributesResponse}
            } else {

                return {...state, loading: false, errors: action.payload}
            }
        }

        case VehiclesActionTypes.FETCH_VEHICLES: {

            return {
                ...state, loading: true
            }
        }

        case VehiclesActionTypes.SET_CURRENT_QUERY_FOR_SIM_VEHICLES: {
            return {
                ...state, current_query_for_sim_vehicles_saving: action.payload,
            }
        }

        case VehiclesActionTypes.SET_CURRENTY_QUERY: {
            return {
                ...state, current_query_for_saving: action.payload
            }
        }

        case VehiclesActionTypes.SET_SELECTED_ATTRIBUTES: {
            return {
                ...state, selected_attributes: action.payload
            }
        }
        case VehiclesActionTypes.SET_SELECTED_DATE_RANGE: {
            return {
                ...state, selected_date_range: action.payload
            }
        }
        case VehiclesActionTypes.SET_SELECTED_SIM_VEHICLES: {
            return {
                ...state,
                sim_vehicles: {
                    vehicles: action.payload,
                    total: action.payload.lenght
                }
            }
        }
        case VehiclesActionTypes.FETCH_VEHICLES_SUCCESS: {
            if (action.payload.type === 'simvehicles') {
                if (process.env.REACT_APP_NODE_ENV === 'mockdev') {
                    return {
                        ...state,
                        sim_vehicles: getVehicles_response,
                        selected_sim_vehicles: action.payload.data.vehicles,
                        loading: false
                    }
                } else {
                    return {
                        ...state,
                        sim_vehicles: action.payload.data,
                        selected_sim_vehicles: action.payload.data.vehicles,
                        loading: false
                    }
                }
            } else {
                if (process.env.REACT_APP_NODE_ENV === 'mockdev') {
                    return {
                        ...state,
                        vehicles: getVehicles_response,
                        selected_vehicles: action.payload.data.vehicles,
                        loading: false
                    }
                } else {

                    return {
                        ...state,
                        vehicles: action.payload.data,
                        selected_vehicles: action.payload.data.vehicles,
                        loading: false
                    }
                }
            }
        }
        case VehiclesActionTypes.FETCH_VEHICLES_ERROR: {
            return {
                ...state,
                errors: action.payload,
                vehicles: initialState.vehicles,
                selected_vehicles: initialState.selected_vehicles,
                loading: false
            }
        }

        case VehiclesActionTypes.SET_ROWS_PER_PAGE: {
            return {...state, rows_per_page: action.payload}
        }

        case VehiclesActionTypes.SET_SELECTED_VEHICLE: {
            return {...state, selected_vehicles: action.payload}
        }

        // case VehiclesActionTypes.SET_SELECTED_SIM_VEHICLE: {
        //   return { ...state, selected_sim_vehicles: action.payload }
        // }

        case VehiclesActionTypes.SET_VEHICLE_MAPPING: {
            return {...state, vehicle_mapping: action.payload}
        }


        case VehiclesActionTypes.SET_MAPPING_PREV_SIM_VEHICLE_SUGGEST: {
            return {...state, mapping_prev_sim_vehicle_suggest: action.payload}
        }
        case VehiclesActionTypes.SET_SIM_VEHICLE_ATTRIBUTES: {
            return {...state, vehicle_mapping_sim_vehicles_attributes: action.payload}
        }
        case VehiclesActionTypes.SET_GROUP_BY_ATTRIBUTE: {
            return {...state, group_by_attribute: action.payload}
        }

        case VehiclesActionTypes.SET_SIM_VEHICLE_SUGGEST: {
            return {...state, sim_vehicle_suggest: action.payload}
        }
        case 'RESET_STATE': {
            return {...state, ...initialState}
        }
        case 'LOADING': {
            return {...state, loading: true}
        }

        case AnalysisActionTypes.RESTORE: {
            console.log("reducer RESTORE vehicle. ", action.payload.state.vehicle);
            return {...state, ...action.payload.state.vehicle, loading: false}
        }
        case VehiclesActionTypes.SET_GROUP_TO_SIM_VEHICLE_MAPPING: {
            return {...state, group_to_sim_vehicle_mapping: action.payload}
        }
        case VehiclesActionTypes.SET_VEHICLE_INCENTIVES: {
            return {...state, incentives: action.payload}
        }


        case VehiclesActionTypes.FETCH_AVAILABLE_DATE_RANGE_SUCCESS: {
            let newState = {...state, availableDateRange: action.payload}
            if (state.selected_date_range.start_date == "LOADING") {
                newState = {
                    ...newState,
                    selected_date_range: {
                        start_date: moment(action.payload.first_route).format("YYYY-MM-DD"),
                        end_date: moment(action.payload.last_route).format("YYYY-MM-DD")
                    }
                }
            }
            return newState
        }

        default: {
            return state
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export {reducer as vehiclesReducer}
