import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';

import { Typography } from '@material-ui/core';


import { IMG_BASE_URL } from '../../../store/car_legacy/urls';
import {getMakes, getCarProfiles, getBaseModels, addCarSearchFilter, removeCarSearchFilter, getSearch, clearSearch, setExpandedFilterPanel, setFilterModelTab} from '../../../store/car_legacy/car_actions';
//import GarageDrawer from '../GarageDrawer';
import SearchResultList from './SearchResultList';
import BodyTypesList from './BodyTypesList';
import PowertrainTypesList from './PowertrainTypesList';
import AdvancedFiltersPanel from './AdvancedFiltersPanel';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SelectedMakeBaseModels from './SelectedMakeBaseModels';
import GarageDrawer from './GarageDrawer';

const mapStateToProps = state => {
    return {
        makes: state.cars.makes,
        searchFilters: state.cars.searchFilters,
        expandedFilterPanel: state.cars.expandedFilterPanel,
        filterModelTab: state.cars.filterModelTab,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMakes: () => dispatch(getMakes()),
        getCarProfiles: () => dispatch(getCarProfiles()),
        getBaseModels: makeName => dispatch(getBaseModels(makeName)),
        addCarSearchFilter: (filterType, value, text) => dispatch(addCarSearchFilter(filterType, value, text)),
        removeCarSearchFilter: value => dispatch(removeCarSearchFilter(value)),
        getSearch: queryString => dispatch(getSearch(queryString)),
        clearSearch: () => dispatch(clearSearch()),
        setExpandedFilterPanel: (panel) => dispatch(setExpandedFilterPanel(panel)),
        setFilterModelTab: (index, make) => dispatch(setFilterModelTab(index, make))
    };
};

const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily
    },
    content: {
        maxWidth: 960,
        margin: "0 auto",
        
        fontFamily: theme.typography.fontFamily
    },
    searchField: {
        width: "100%"
    },
    searchSection: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
        marginBottom: "2rem",
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "#fff"
    },
    colTitle: {
        fontWeight: "normal"
    },

    makeList: {
        display: "flex",
        flexWrap: "wrap"
    },
    listItem: {
        width: "20%"
    },
    makeListAvatar: {
        borderRadius: 0,
        width: "20px",
        height: "20px"
    },


    baseModelsHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem"
    },

    filterSection: {
        marginBottom: "2rem"
    }
});


class AddCar extends React.Component {
    constructor(props) {
        super(props)
        this.handleMakeFilterTabChange = this.handleMakeFilterTabChange.bind(this)
    }

    componentDidMount() {
        this.props.getMakes()
        console.log("AddCar componentDidMount(). this.props are: ", this.props);
        console.log("sim_vehicles: " , this.props.sim_vehicles)
    }

    handleMakeFilterTabChange(makeName) {
        const index = (makeName ? 1 : 0)
        this.props.setFilterModelTab(index, makeName)
        if (makeName) {
            this.props.getBaseModels(makeName)
        }
    }

    handleAddChip(chip) {
        this.props.addCarSearchFilter(
            "search",
            chip,
            chip
        )
    }
    handleDeleteChip(chip, index) {
        this.props.removeCarSearchFilter(chip.value)
    }

    createQueryString(filters) {
        let queryString = ""
        let fulltextItems = []
        let bodyTypeItems = []
        let powertrainTypeItems = []
        // Iterate filter values, append to arrays to create comma seperated query values if necessary
        filters.map(filter => {
            if (filter.filterType === "model") {
                queryString += `&make=${filter.value.make}&base_model=${filter.value.base_model}`
            } else if (filter.filterType === "search") {
                fulltextItems.push(filter.value)
            } else if (filter.filterType === "body_type") {
                bodyTypeItems.push(filter.value)
            } else if (filter.filterType === "powertrain_type") {
                powertrainTypeItems.push(filter.value)
            } else if (filter.filterType === "year") {
                queryString += `&year_from=${filter.value.year_from}&year_to=${filter.value.year_to}`
            }
        })
        // Go through lists to create comma seperated values
        if (fulltextItems.length > 0) {
            let str = ""
            fulltextItems.map(item => {
                str += `,${item}`
            })
            queryString += "&q=" + str.substr(1)
        }
        if (bodyTypeItems.length > 0) {
            let str = ""
            bodyTypeItems.map(item => {
                str += `,${item}`
            })
            queryString += "&body_types=" + str.substr(1)
        }
        if (powertrainTypeItems.length > 0) {
            let str = ""
            powertrainTypeItems.map(item => {
                str += `,${item}`
            })
            queryString += "&powertrain_types=" + str.substr(1)
        }


        // Finalize Query String
        if (queryString.startsWith("&")) {
            queryString = "?" + queryString.substr(1)
        }
        return queryString
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchFilters !== this.props.searchFilters) {
            // Get the query string from the filters
            let queryString = this.createQueryString(this.props.searchFilters)
            if (queryString.length) {
                this.props.getSearch(queryString)
            } else {
                this.props.clearSearch()
            }
        }
    }

    handleFilterPanelChange = panel => (event, expanded) => {
        this.props.setExpandedFilterPanel(expanded ? panel : false);
    };

    render() {
        const { classes, expandedFilterPanel, searchFilters } = this.props;
        console.log("render: " , this.props.searchFilters)
        if (this.props.searchFilters.length > 0) {
            console.log(searchFilters[0].value)
        }
        return (
            <div>
                <div className={classes.searchSection}>
                    <div className={classes.content}>
                        <ChipInput
                            className={classes.searchField}
                            placeholder="Search for a car"
                            value={this.props.searchFilters}
                            onAdd={(chip) => this.handleAddChip(chip)}
                            newChipKeyCodes={[13]}// , 32]}
                            /*disabled={ this.props.searchFilters.length == 1 ? true : false }*/
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                            chipRenderer={({ value, text, chip, isFocused, isDisabled, handleClick, handleDelete, className }, key) => {return (
                                <Chip
                                    key={key}
                                    onClick={handleClick}
                                    onDelete={handleDelete}
                                    label={chip.text}
                                    
                                />
                            )}}
                        />
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.filterSection}>
                        {/* <ExpansionPanel expanded={expandedFilterPanel === 'filter_model'} onChange={this.handleFilterPanelChange('filter_model')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>Filter by make + model</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <SwipeableViews
                                    index={this.props.filterModelTab.index}
                                    >
                                    <div>
                                        <List dense className={classes.makeList}>
                                            {!this.props.makes.loading && this.props.makes.data ? this.props.makes.data.map(make => {
                                                return (
                                                    <ListItem button key={make.name} alignItems="flex-start" className={classes.listItem} onClick={() => this.handleMakeFilterTabChange(make.name)}>
                                                        <ListItemAvatar>
                                                            <Avatar className={classes.makeListAvatar} alt="" src={IMG_BASE_URL + make.image_url}/>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={make.name}
                                                        />
                                                    </ListItem>
                                                    
                                                )
                                            }) : null}
                                        </List>
                                    </div>
                                    <div style={{overflow: "hidden"}}>
                                        <div className={classes.baseModelsHeader}>
                                            <IconButton onClick={() => this.handleMakeFilterTabChange()} style={{marginRight: ".5rem"}}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                            <Typography variant="h5">
                                                {this.props.filterModelTab.make}
                                            </Typography>
                                        </div>
                                        <SelectedMakeBaseModels />
                                    </div>
                                </SwipeableViews>
                                
                            </ExpansionPanelDetails>
                        </ExpansionPanel> */}

                        <ExpansionPanel expanded={expandedFilterPanel === 'filter_body_type'} onChange={this.handleFilterPanelChange('filter_body_type')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>Filter by body type</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={classes.makeListGrid}>
                                    <BodyTypesList />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel expanded={expandedFilterPanel === 'filter_powertrain'} onChange={this.handleFilterPanelChange('filter_powertrain')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>Filter by powertrain</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={classes.makeListGrid}>
                                    <PowertrainTypesList />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel expanded={expandedFilterPanel === 'filter_advanced'} onChange={this.handleFilterPanelChange('filter_advanced')}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>Advanced filters</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className={classes.makeListGrid}>
                                    <AdvancedFiltersPanel />
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    

                    <SearchResultList 
                        addSimVehicle={this.props.addSimVehicle} 
                        removeSimVehicle={this.props.removeSimVehicle}
                        sim_vehicles = {this.props.sim_vehicles}
                    />

                    <GarageDrawer sim_vehicles={this.props.sim_vehicles} removeSimVehicle={this.props.removeSimVehicle} />
                </div>
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddCar))