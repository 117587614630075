import React from 'react';
import brandColors from "../../styles/colors/brandColors";
import {default as Checkbox, CheckboxProps} from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";

export const GreenCheckbox = withStyles({
    root: {
        color: brandColors.crispedge,
        '&$checked': {
            color: brandColors.crispedge,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);