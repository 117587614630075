import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {getPowertrainTypes, addCarSearchFilter} from '../../../store/car_legacy/car_actions';
import CarGridItem from './CarGridItem';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => {
    return {
        powertrainTypes: state.cars.powertrainTypes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPowertrainTypes: () => dispatch(getPowertrainTypes()),
        addCarSearchFilter: (filterType, value, text) => dispatch(addCarSearchFilter(filterType, value, text))
    };
};


const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily
    },
    carGridItem: {
        overflow: "hidden" // Hide img with rounded corners
    },
});

class PowertrainTypesList extends React.Component {

    handleItemClick(car_id) {
        
    }

    componentDidMount() {
        this.props.getPowertrainTypes()
    }

    render() {
        const {classes, t} = this.props;
        if (this.props.powertrainTypes.loading || !this.props.powertrainTypes.data) {
            return (
                <div>
                    Loading...
                </div>
            )
        } else if (this.props.powertrainTypes.data) {
            return (
                <div>
                    <Grid container className={classes.carGrid} spacing={16}>
                        {this.props.powertrainTypes.data.map(powertrainType => {
                            powertrainType = {
                                ...powertrainType,
                                display_name: t(`garage.filters.powertrainTypes.${powertrainType.name}`)
                            }
                            // TODO / Quick fix
                            // since the car_images aren't on the server and the path is therefore wrong, import them from public folder
                            let item = {
                                ...powertrainType,
                                image_url:`/car_images/${powertrainType.image_url.split("/").pop()}`
                            }
                            return (
                                <Grid item key={item.name} className={classes.carGridItem}>
                                    <CarGridItem 
                                        carGroup
                                        fullImgUrl 
                                        car={item} 
                                        onClick={() => {
                                            this.props.addCarSearchFilter(
                                                "powertrain_type",
                                                item.name,
                                                item.display_name || item.name
                                            )}
                                        }
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            )
        }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PowertrainTypesList)))