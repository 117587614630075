import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {getBodyTypes, addCarSearchFilter} from '../../../store/car_legacy/car_actions';
import CarGridItem from './CarGridItem';
import { withTranslation } from 'react-i18next';


const mapStateToProps = state => {
    return {
        bodyTypes: state.cars.bodyTypes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBodyTypes: () => dispatch(getBodyTypes()),
        addCarSearchFilter: (filterType, value, text) => dispatch(addCarSearchFilter(filterType, value, text))
    };
};


const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily
    },
    carGridItem: {
        overflow: "hidden" // Hide img with rounded corners
    },
});

class BodyTypesList extends React.Component {

    handleItemClick(car_id) {
        
    }

    componentDidMount() {
        this.props.getBodyTypes()
    }

    render() {
        const {classes, t} = this.props;
        if (this.props.bodyTypes.loading || !this.props.bodyTypes.data) {
            return (
                <div>
                    {t('loading')}...
                </div>
            )
        } else if (this.props.bodyTypes.data) {
            console.log("bodyTypes data: ", this.props.bodyTypes.data);
            return (
                <div>
                    <Grid container className={classes.carGrid} spacing={16}>
                        {this.props.bodyTypes.data.map(bodyType => {
                            bodyType = {
                                ...bodyType,
                                display_name: t(`garage.filters.bodyTypes.${bodyType.name}`)
                            }
                            // TODO / Quick fix
                            // since the car_images aren't on the server and the path is therefore wrong, import them from public folder
                            let item = {
                                ...bodyType,
                                //image_url: bodyType.name
                                image_url:`/car_images/${bodyType.image_url.split("/").pop()}`
                            }
                            return (
                                <Grid item xs={2} key={item.name} className={classes.carGridItem}>
                                    <CarGridItem 
                                        carGroup
                                        fullImgUrl 
                                        car={item} 
                                        onClick={() => {
                                            this.props.addCarSearchFilter(
                                                "body_type",
                                                item.name,
                                                item.display_name || item.name
                                            )}
                                        }
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            )
        }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BodyTypesList)))