import {lighten} from 'polished'
import {Theme} from '../../utils/styled'
import brandColors from '../colors/brandColors'

const darkTheme: Theme = {
    colors: {
        background: brandColors.gray75,
        body: brandColors.gray10,
        line: brandColors.gray30,
        disable: brandColors.gray40,
        headings: brandColors.gray5,
        black: brandColors.black,
        white: brandColors.white,
        borders: lighten(0.05, brandColors.gray75),
        tableOdd: lighten(0.025, brandColors.gray75),
        brand: brandColors.red,
        valencia: brandColors.valencia,
        inactive_brand: brandColors.kashmir_blue,
        active_link: brandColors.light_crispedge,
        regular: brandColors.crispedge,
        light_bg: brandColors.gray,
        text_red: brandColors.text_red,
        text_green: brandColors.text_green,
        header_bg: brandColors.vista_blue,
        avatar_bg: brandColors.fringy_flower,
        attrs: {
            str: '#f44336',
            agi: '#39d402',
            int: '#01a9f4'
        },
        pickled_bluewood: brandColors.pickled_bluewood,
    },
    fonts: {
        headings:
        // eslint-disable-next-line max-len
            "Roboto, 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif",
        body:
        // eslint-disable-next-line max-len
            'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif',
        monospace: "Roboto, 'IBM Plex Mono', Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace, monospace"
    },
    fontSizes: {
        h1: '2.441rem',
        h2: '1.953rem',
        h3: '1.563rem',
        h4: '1.25rem'
    },
    containerPadding: '1.5rem',
    breakpoints: {
        xs: '0px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px'
    },
    widths: {
        md: '720px',
        lg: '960px',
        xl: '1140px'
    },
    heights: {
        header: '60px'
    }
}

export default darkTheme
