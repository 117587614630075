import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CarGridItem from './CarGridItem';
import { Typography } from '@material-ui/core';
import {addCarProfile, deleteCarProfile} from '../../../store/car_legacy/car_actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => {
    return {
        searchResult: state.cars.searchResult,
        carProfiles: state.cars.carProfiles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCarProfile: (car_id, addAsCurrent) => dispatch(addCarProfile(car_id, addAsCurrent)),
        deleteCarProfile: car_id => dispatch(deleteCarProfile(car_id)),
    };
};


const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily
    },
    resultGrid: {
        opacity: 1,
        transition: "opacity 0.3s ease"
    },
    resultGridLoading: {
        opacity: .5
    }
});

class SearchResultList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            car_added: false
        };
    };

    handleCarItemClick(e, car, addAsCurrent) {
        let car_id = car.id

        this.props.addSimVehicle(e, {
            ...car,
            "sim_vehicle_id": car_id,
            "make": car.make,
            "model": car.shortname,
            "year": car.year,
            "epa_range": car.epa_range,
            "image_url": car.image_url,
            "option": car.option,
            
        })
        console.log("Add", car, this.props.addSimVehicle)

        // if (this.props.carProfiles.data != null) {
        //     const garageCarProfile = this.props.carProfiles.data.find( p => p.car.id === car_id)

        //     // Check if car is in garage already
        //     if (garageCarProfile) {
        //         // Find CarProfile ID from 
        //         this.props.deleteCarProfile(garageCarProfile.id)
        //     } else {
        //         this.setState({
        //             car_added: true
        //         })
        //         this.props.addCarProfile(car_id, addAsCurrent)
        //     } 
        // } else {
        //     this.setState({
        //         car_added: true
        //     })
        //     this.props.addCarProfile(car_id, addAsCurrent)
        // } 
    }

    render() {
        const {classes, t} = this.props;
        if (this.props.searchResult.loading || this.props.searchResult.data) {
            return (
                <div>
                    <Typography variant="h5">
                        {t('searchResults')} {this.props.searchResult.loading ? <CircularProgress size={21}/> : null}
                    </Typography>

                    <div>
                        <Grid container spacing={2} className={this.props.searchResult.loading ? [classes.resultGrid, classes.resultGridLoading].join(" ") : classes.resultGrid}>
                            {this.props.searchResult.data && this.props.searchResult.data.length > 0 ? 
                                this.props.searchResult.data.map(result => {
                                    const inGarage = this.props.sim_vehicles.some( p => p.sim_vehicle_id === result.id)
                                    return (
                                        <Grid item xs={2} key={result.id}>
                                            <CarGridItem 
                                                car={result} 
                                                onClick={(e) => this.handleCarItemClick(e, result, this.props.addAsCurrent)} 
                                                inGarage={inGarage} />
                                        </Grid>
                                    )
                                })
                                :
                                (this.props.searchResult.loading ? <Grid item>Loading...</Grid>: <Grid item>No results found</Grid>)
                            }
                        </Grid>
                    </div>
                </div>
            )
        } else {
            return null
        }
        
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchResultList)))