import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import {StyledTableCell, StylesTableBody, StylesTableHead} from '../layout/TableBaseStyles';
import {default as styled} from '../../utils/styled';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import lightTheme from '../../styles/theme/light';
import {
    deleteAnalysisById, unshareAnalysisById, fetchAnalysisResult, setAnalysisNameAction
} from "../../store/analysis/actions";
import DialogInput from '../Modals/DialogInput';
import {AnalysisResultFull} from "../../store/results/types";
import DialogModal from '../Modals/DialogModal';

const StyledButton = styled(Button)`
  
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.regular};
    }
    &.MuiButton-contained{
        background-color: transparent;
        border: 1px solid ${props => props.theme.colors.regular};
        box-shadow: none;
    }
    &.MuiButton-contained:hover{
        box-shadow: none;
    }
`;

interface IProps {
    rows: AnalysisResultFull[] | any,
    noItemText?: string
    fetchAnalysisResult: typeof fetchAnalysisResult
    deleteAnalysisById: typeof deleteAnalysisById
    unshareAnalysisById: typeof unshareAnalysisById
    setAnalysisNameAction: typeof setAnalysisNameAction
    history: any
}

interface IState {
    rows: AnalysisResultFull[] | any,
    open: boolean,
    noItemText: string
    selectedId: number,
    openDialog: boolean
}

class HomeSimpleTable extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.handleClickUnshare = this.handleClickUnshare.bind(this);
        this.onDialogOpenClicked = this.onDialogOpenClicked.bind(this);
        this.deleteAnalysisClicked = this.deleteAnalysisClicked.bind(this);
        this.openAnalysisResult = this.openAnalysisResult.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleConfirmationDialogClose = this.handleConfirmationDialogClose.bind(this);

        this.state = {
            rows: this.props.rows || [],
            open: false,
            selectedId: -1,
            noItemText: this.props.noItemText || 'No items',
            openDialog: false
        };
    }

    onDialogOpenClicked(event: any, id: number) {
        event.stopPropagation();
        this.setState({open: true, selectedId: id});
    }

    deleteAnalysisClicked(event: any, id: number){
        event.stopPropagation();

        this.setState({
            selectedId: id,
            openDialog: true
        })
    }

    handleConfirmationDialogClose() {
        const { selectedId } = this.state;

        this.setState({
            selectedId: -1,
            openDialog: false
        })

        this.props.deleteAnalysisById(selectedId)
    }

    openAnalysisResult(event: any, id: number, name: string){
        event.stopPropagation();
        this.props.fetchAnalysisResult(id);
        this.props.setAnalysisNameAction(name);
        this.props.history.push('/fleet-analysis/');
    }

    handleClickUnshare(event: any) {
        this.props.unshareAnalysisById(this.state.selectedId);
        this.handleClose();
    }

    handleClose(event?: any) {
        this.setState({open: false, selectedId: -1});
    }

    normalizeData() {
        if(this.state.selectedId === -1) return [];
        return this.props.rows.find((item: any) => item.id === this.state.selectedId).members || []
    }

    render() {
        const { openDialog } = this.state;

        return (
            <div style={{width: '100%'}}>
                <Table
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                >
                    <StylesTableHead>
                        <TableRow>
                            <StyledTableCell>Date Range</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">№ of vehicles</StyledTableCell>
                            <StyledTableCell>Members</StyledTableCell>
                            <StyledTableCell>Manage</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </StylesTableHead>
                    <StylesTableBody>
                        {this.props.rows.length ? this.props.rows.map((row: any, i: number) => {
                            let members;
                            switch (row.members.length) {
                                case 0: {
                                    members = 'Only you';
                                    break;
                                }
                                case 1: {
                                    members = '1 member';
                                    break;
                                }
                                default:
                                    members = row.members.length + ' members';
                            }

                            return (
                            <TableRow data-test-id={'AnalysisResult_'+row.name} hover key={row.id} onClick={(event: any) => this.openAnalysisResult(event, row.id, row.name)}>
                                <StyledTableCell colSpan={1}
                                                 style={{width: '200px'}}>{row.daterange.start + ' - ' + row.daterange.end}</StyledTableCell>
                                <StyledTableCell colSpan={1} style={{
                                    width: '300px',
                                    color: lightTheme.colors.regular
                                }} data-test-id={'AnalysisResultName_'+row.name}>{row.name}</StyledTableCell>
                                <StyledTableCell data-test-id={'AnalysisResultNVehicles_'+row.name}
                                                 colSpan={1} align="right">{row.number_of_vehicles}</StyledTableCell>
                                <StyledTableCell data-test-id={'AnalysisResultMembers_'+row.name}
                                                 colSpan={1} style={{width: '200px'}}>{members}</StyledTableCell>
                                <StyledTableCell colSpan={1} style={{width: '150px'}} data-test-id={'AnalysisResultManage_'+row.name}>
                                    {
                                        row.members.length > 0
                                            ? (<StyledButton
                                                data-test-id={'AnalysisResultUnshareButton_'+row.name}
                                                aria-controls="unshare"
                                                aria-haspopup="true"
                                                variant="contained"
                                                onClick={(event: any) => this.onDialogOpenClicked(event, row.id)}>
                                                Unshare
                                            </StyledButton>)
                                            : (' - ')
                                    }
                                </StyledTableCell>
                                <StyledTableCell padding="none" style={{width: '40px'}}>
                                    <IconButton aria-label="delete"
                                                data-test-id={'AnalysisResultDeleteButton_'+row.name}
                                                onClick={(event: any) => this.deleteAnalysisClicked(event, row.id)}>
                                        <ClearIcon color={'disabled'}/>
                                    </IconButton>
                                </StyledTableCell>
                            </TableRow>
                        )}) : (
                            <TableRow>
                                <StyledTableCell colSpan={6}>
                                    {this.state.noItemText}
                                </StyledTableCell>
                            </TableRow>
                        )}
                    </StylesTableBody>
                </Table>
                <DialogInput
                    alert
                    handleClose={this.handleClose}
                    isVisible={this.state.open}
                    onSubmitClicked={this.handleClickUnshare}
                    negativeBtnName="Cancel"
                    positiveBtnName="Unshare"
                    message="This analysis was shared for:"
                    messageLines={this.normalizeData()}
                    title="Unshare analysis"/>
                <DialogModal 
                    handleClose={() => this.setState({openDialog: false})}
                    error_msg={{
                        title: "Please Confirm", 
                        msg: "Are you sure you want to delete this analysis?",
                        visible: openDialog
                    }}
                    handleConfirm={this.handleConfirmationDialogClose}
                />
            </div>
        );
    }
}

export default HomeSimpleTable;
