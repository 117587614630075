import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from '../../utils/styled';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import { ApplicationState } from '../../store';
import { SortDirection } from '../../utils/enums';
import lightTheme from '../../styles/theme/light';
import Typography from '@material-ui/core/Typography';
import { fetchResults, fetchResultsDetails, resultAreNotReady } from '../../store/results/actions';
import brandColors from '../../styles/colors/brandColors';
import withStyles from '@material-ui/core/styles/withStyles';
import { FilterCardRow } from '../../components/layout/Card.tsx';
import ResultTable, { SimVehicleResult, VehicleResultView } from '../../components/Tables/ResultsTable';
import { GreenButton } from '../../components/layout/GreenButton';
import Statistic from '../../components/result_page_components/Statistic/Statistic';
import VehicleInformation from '../../components/result_page_components/VehicleInformation/VehicleInformation';
import LoadingModal from '../../components/Modals/LoadingModals';
import { TextMessage } from '../../components/result_page_components/VehicleInformation/styled';
import { setAnalysisNameAction } from '../../store/analysis/actions';
import DialogInput from '../../components/Modals/DialogInput';
import { Vehicle } from "../../helpData/get_vehicles_response_example";
import { AttributeValue } from "../../store/vehicles/types";
import { ICharging, IEvScores, IVehicle } from "../../helpData/getResults";
import { StyledGrid } from "../charging_sites/charging_sites";
import Page from "../../components/layout/Page";
import Container from "../../components/layout/Container";
import Compare from '../../components/result_page_components/Compare/Compare';
import { FusionTablesLayer } from 'react-google-maps';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface IProps {
    fetchResults: typeof fetchResults
    fetchResultsDetails: typeof fetchResultsDetails
    resultsList: IVehicle[]
    chargingSites: ICharging[]
    selectedVehicles: VehicleResultView[]
    group_to_sim_vehicle_mapping: { [group_title: string]: Vehicle[] }
    loading: boolean
    history: any
    analysisName: string
    setAnalysisNameAction: typeof setAnalysisNameAction
    resultAreNotReady: typeof resultAreNotReady
    websocket_message: boolean
    simulation_uuid: string,
    vehicle_mapping: any
}

interface IState {
    currentTabIndex: number
    locations: any,
    locations_loading: boolean,
    user: any,
    user_loading: boolean,
}

export function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, className, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            className={className}
            aria-labelledby={`simple-tab-${index}`}
            style={{ minWidth: '100%' }}
            {...other}
        >
            {children}
        </Typography>
    );
}

class ResultPage extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentTabIndex: 0,
            locations: [],
            locations_loading: true,
            user: null,
            user_loading: true
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        // if (this.props.resultsList !== prevProps.resultsList) {
        //     let websocket_message = this.props.websocket_message;

        //     if (!websocket_message) {
        //         let selectedVehicle = null;
        //         if (!this.state.selectedVehicle && this.props.selectedVehicles && this.props.selectedVehicles.length > 0) {
        //             selectedVehicle = this.props.selectedVehicles[0];
        //             this.props.fetchResultsDetails(selectedVehicle.vehicle_id);
        //         }
        //         if (this.props.selectedVehicles)
        //         this.setState({
        //             selectedVehicle: this.props.selectedVehicles.find((el: any) => {
        //                 return el.vehicle_id !== null && el.vehicle_id === this.state.selectedVehicle.vehicle_id;
        //             }),
        //             selectedVehicles: this.props.selectedVehicles,
        //         });
        //     }
        // }
        // if (this.props.websocket_message !== prevProps.websocket_message) {
        //     if (this.props.websocket_message)
        //         this.props.resultAreNotReady(this.props.websocket_message);

        //     let websocket_message = this.props.websocket_message;
        //     if (!websocket_message)
        //         this.props.fetchResults();
        // }
    }


    handleChangeTab = (event: React.ChangeEvent<{}>, tabIndex: number) =>
        this.setState({ currentTabIndex: tabIndex });


    componentDidMount() {
        const API_ENDPOINT = process.env.REACT_APP_API_URL;
        let token = window.localStorage.getItem("authenticated");
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        token && myHeaders.append('Authorization', `Token ${token}`);
        // Get locations
        fetch(`${API_ENDPOINT}/api2/locations`, {
            headers: myHeaders
        })
            .then(response => response.json())
            .then(locations_json => {
                this.setState({
                    locations: locations_json,
                    locations_loading: false
                })
            })
        // Get User / Org to retrieve org settings for TCO constants
        fetch(`${API_ENDPOINT}/api1/user`, {
            headers: myHeaders
        })
            .then(response => response.json())
            .then(json => {
                this.setState({
                    user: json[0],
                    user_loading: false
                })
            })
    }

    render() {
        let { loading, history, websocket_message, resultsList, group_to_sim_vehicle_mapping } = this.props;
        let { currentTabIndex } = this.state;

        let load = websocket_message || loading;

        //Redirect to home page if there is no analysis selected
        if (this.props.analysisName === '')
            this.props.history.push('/')


        function createData(name: any, value: any, bold: any = false) {
            return { name, value, bold };
        }
        const globalRows = [
            createData('Unit of Measure', "Imperial (miles, gallons)"),
            createData('GHG Intensity', "", true),
            createData('GHG emissions per gallon of fuel (kg of CO2)', "8.91"),
            createData('GHG emissions of electrictiy', "0"),
        ];

        const tcoRows = [
            createData('Length of Ownership (years)', "10"),
            createData('Residual value', "20%"),
            createData('Interest rate', "0%"),
            createData('Price of gas', "$3.10"),
            createData('Price of diesel', "$3.25"),
            createData('Insurance ($/vehicle-year)', "$1,000"),
        ];

        // const maintenanceCostRows = [
        //     createData('Sedan', "$0.20"),
        //     createData('SUV', "$0.20"),
        //     createData('Pick-up', "$0.20"),
        //     createData('HEV', "$0.20"),
        //     createData('EV', "$0.0655"),
        // ];


        const chargingRows = this.state.locations.map((loc: any) => {
            return ({
                ...loc,
                "pricing": "Average"
            })
        })

        let orgTCOrows: any = []
        let org_maint_entries: any = []
        if (!this.state.user_loading && this.state.user) {
            if (this.state.user.organization.settings) {
                const org_settings = this.state.user.organization.settings
                try {
                    for (let [key, entry] of Object.entries(org_settings.tco.maintenance)) {
                        org_maint_entries.push(entry)
                    }

                    orgTCOrows.push(org_settings.tco.lcfs)
                } catch (e) {
                    console.log(e)
                }
            }
        }

        return (
            <Page style={{ maxHeight: '100%' }}>
                {load && (<LoadingModal open={load} />)}

                <Container style={{ minWidth: '100%', maxHeight: '100%' }}>
                    <StyledGrid display="grid" style={{ maxHeight: '100%' }}>

                        <Paper style={{ height: 'min-content', minWidth: '100%', overflowX: 'auto', marginBottom: '10px' }}>
                            <div className={"card-container"}>
                                <ResultTabsContainer>
                                    <ResultTabs variant="fullWidth" value={currentTabIndex}
                                        onChange={this.handleChangeTab}
                                        aria-label="tabs">
                                        <Tab label="Global Settings" {...a11yProps(0)} />
                                        <Tab label="Total Cost To Own" {...a11yProps(1)} />
                                        <Tab label="Charging Sites" {...a11yProps(2)} />
                                    </ResultTabs>
                                    <TabPanel value={currentTabIndex} index={0}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                {globalRows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: row.bold ? "bold" : "inherit" }}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">{row.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TabPanel>
                                    <TabPanel value={currentTabIndex} index={1}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                {this.state.user_loading === false && tcoRows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row" style={{ fontWeight: row.bold ? "bold" : "inherit" }}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">{row.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {this.state.user_loading === false && 
                                                    orgTCOrows.map((row: any) => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row" style={{ fontWeight: row.bold ? "bold" : "inherit" }}>
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="right">{row.value}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                                {this.state.user_loading ? (
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            Loading...
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                        <>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                                                                    Maintenance cost ($/mi)
                                                                    </TableCell>
                                                                <TableCell align="right"></TableCell>
                                                            </TableRow>
                                                        {
                                                            org_maint_entries.map((row: any) => (
                                                                <TableRow key={row.name}>
                                                                    <TableCell component="th" scope="row" style={{ fontWeight: row.bold ? "bold" : "inherit", textIndent: 60 }}>
                                                                        {row.name}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.value}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        }</>
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TabPanel>
                                    <TabPanel value={currentTabIndex} index={2}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Facility</TableCell>
                                                    <TableCell component="th" scope="row">Pricing Format</TableCell>
                                                    <TableCell component="th" scope="row">Electricity Cost ($/kWh)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.locations_loading ? (
                                                    <TableRow>
                                                        <TableCell colSpan={3}>
                                                            Loading...
                                                    </TableCell>
                                                    </TableRow>
                                                ) : chargingRows.map((row: any) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.pricing}
                                                        </TableCell>
                                                        <TableCell align="right">{row.electricityPrice}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TabPanel>
                                </ResultTabsContainer>
                            </div>
                        </Paper>
                    </StyledGrid>
                </Container>
                {/*
                <DialogInput
                    key={this.props.analysisName}
                    handleClose={() => this.props.history.push('/')}
                    isVisible={this.props.analysisName === ''}
                    onSubmitClicked={this.props.setAnalysisNameAction}
                    negativeBtnName="Cancel"
                    positiveBtnName="Go next"
                    title="Create new analysis"/>
                */}
            </Page>)
    }
}

export const mapStateToProps = ({ resultsPage, chargingPage, vehicle, homePage }: ApplicationState) => {
    // @ts-ignore
    let selectedVehicles: VehicleResultView[] = [];
    if (vehicle.selected_vehicles) {
        selectedVehicles = vehicle.selected_vehicles.map((v: Vehicle) => {
            const description_attribute = v.attributes.find((a: AttributeValue) => a.name === 'Description');
            const description = description_attribute ? description_attribute.value : '';
            const attributes = v.attributes;

            if (resultsPage.resultsList && resultsPage.resultsList.length) {
                const foundVehicle = resultsPage.resultsList.find((rl: IVehicle) => rl.vehicle_id === v.vehicle_id);
                if (foundVehicle) {
                    const mapped: SimVehicleResult[] = foundVehicle.ev_scores.map((evScore: IEvScores) => {
                        // @ts-ignore

                        var svs: Vehicle | undefined;
                        Object.keys(vehicle.group_to_sim_vehicle_mapping).forEach((key) => {
                            const v: Vehicle | undefined = vehicle.group_to_sim_vehicle_mapping[key].find((vehicle: Vehicle) => evScore.sim_vehicle_id === vehicle.vehicle_id);
                            if (v) {
                                svs = v;
                            }
                        });
                        let model = 'N/A';
                        let make = 'N/A';
                        if (svs) {
                            const model_attribute: AttributeValue | undefined = svs.attributes.find((a: AttributeValue) => a.name === 'Model');
                            model = model_attribute ? model_attribute.value : 'N/A';
                            const make_attribute: AttributeValue | undefined = svs.attributes.find((a: AttributeValue) => a.name === 'Make');
                            make = make_attribute ? make_attribute.value : 'N/A';
                        }

                        return {
                            model,
                            make,
                            ev_score: evScore.ev_score,
                            sim_vehicle_id: evScore.sim_vehicle_id,
                            num_days_failed: evScore.num_days_failed,
                            num_days: evScore.num_days
                        };
                    });

                    mapped.sort(((a: SimVehicleResult, b: SimVehicleResult) => b.ev_score - a.ev_score));
                    const best = mapped[0];
                    const worst = mapped.length > 1 ? mapped[mapped.length - 1] : undefined;
                    return {
                        best,
                        worst,
                        description,
                        attributes,
                        ev_scores: mapped,
                        vehicle_id: v.vehicle_id,
                        telematics_id: v.telematics_id,
                        average_distance: v.average_distance,
                        total_distance: v.total_distance,
                        driving_days: foundVehicle.driving_days,
                        annual_distance: foundVehicle.annual_distance
                    };
                }

                return {
                    description,
                    attributes,
                    ev_scores: [],
                    vehicle_id: v.vehicle_id,
                    telematics_id: v.telematics_id,
                    average_distance: v.average_distance,
                    total_distance: v.total_distance,
                    annual_distance: null
                };
            } else {
                return {
                    description,
                    attributes,
                    ev_scores: [],
                    vehicle_id: v.vehicle_id,
                    telematics_id: v.telematics_id,
                    average_distance: v.average_distance,
                    total_distance: v.total_distance,
                    annual_distance: null
                }
            }
        });
    }

    return {
        resultsList: resultsPage.resultsList,
        chargingSites: resultsPage.chargingSites,
        selectedVehicles,
        group_to_sim_vehicle_mapping: vehicle.group_to_sim_vehicle_mapping,
        loading: resultsPage.loading,
        errors: resultsPage.errors,
        analysisName: homePage.analysisName,
        websocket_message: resultsPage.websocket_message,
        simulation_uuid: chargingPage.simulation_uuid,
        vehicle_mapping: vehicle.vehicle_mapping,
    }
};

const mapDispatchToProps = {
    fetchResults,
    fetchResultsDetails,
    setAnalysisNameAction,
    resultAreNotReady
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultPage);

export const ResultTabsContainer = styled('div')`
    display: block;
    width: 100%;
    overflow: hidden;
`;

const StyledPaper = withStyles({
    root: {
        minWidth: '300px',
        maxWidth: '360px',
    }
})(Paper);

const StyledFlexPaper = withStyles({
    root: {
        flex: 1,
        marginLeft: '1.5rem'
    }
})(Paper);

export const ResultTabs = withStyles({
    root: {
        borderBottom: `1px solid ${lightTheme.colors.borders}`,
        '& button[id^="simple-tab-"]:hover': {
            color: brandColors.kashmir_blue,
            background: '#74b29133'
        },
        '& .Mui-selected .MuiTab-wrapper': {
            color: lightTheme.colors.regular,
        },
        '& .MuiTabs-flexContainer': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr',
        },
    },
    indicator: {
        color: lightTheme.colors.regular,
        backgroundColor: lightTheme.colors.regular,
    },
})(Tabs);

export const OutOfRangeTabs = withStyles({
    root: {
        borderBottom: `1px solid ${lightTheme.colors.regular}`,
        '& button[id^="simple-tab-"]:hover': {
            color: brandColors.kashmir_blue,
            background: '#74b29133'
        },
        '& .Mui-selected .MuiTab-wrapper': {
            color: lightTheme.colors.regular,
        },
        '& .MuiTabs-flexContainer': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
        },
        '& .MuiTab-root': {
            minWidth: '124px'
        }
    },
    indicator: {
        color: lightTheme.colors.regular,
        backgroundColor: lightTheme.colors.regular,
    },
})(Tabs);
