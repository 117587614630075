import {
    GET_CAR_PROFILE_BEGIN,
    GET_CAR_PROFILE_ERROR,
    GET_CAR_PROFILE_SUCCESS,
    PATCH_CAR_PROFILE_BEGIN,
    PATCH_CAR_PROFILE_ERROR,
    PATCH_CAR_PROFILE_SUCCESS,
    GET_CAR_PROFILES_BEGIN,
    GET_CAR_PROFILES_ERROR,
    GET_CAR_PROFILES_SUCCESS,
    GET_TCO_SETTINGS_BEGIN,
    GET_TCO_SETTINGS_ERROR,
    GET_TCO_SETTINGS_SUCCESS,
    GET_CAR_RECOMMENDATIONS_BEGIN,
    GET_CAR_RECOMMENDATIONS_ERROR,
    GET_CAR_RECOMMENDATIONS_SUCCESS,
    ADD_CAR_RECOMMENDATION_BEGIN,
    ADD_CAR_RECOMMENDATION_ERROR,
    ADD_CAR_RECOMMENDATION_SUCCESS,
    GET_MAKES_BEGIN,
    GET_MAKES_SUCCESS,
    GET_MAKES_ERROR,
    GET_BASE_MODELS_BEGIN,
    GET_BASE_MODELS_SUCCESS,
    GET_BASE_MODELS_ERROR,
    ADD_CAR_SEARCH_FILTER,
    REMOVE_CAR_SEARCH_FILTER,
    GET_SEARCH_BEGIN,
    GET_SEARCH_ERROR,
    GET_SEARCH_SUCCESS,
    ADD_CAR_PROFILE_BEGIN,
    ADD_CAR_PROFILE_ERROR,
    ADD_CAR_PROFILE_SUCCESS,
    DELETE_CAR_PROFILE_BEGIN,
    DELETE_CAR_PROFILE_ERROR,
    DELETE_CAR_PROFILE_SUCCESS,
    CLEAR_SEARCH,
    SET_EXPANDED_FILTER_PANEL,
    SET_FILTER_MODEL_TAB,
    GET_BODY_TYPES_BEGIN,
    GET_BODY_TYPES_ERROR,
    GET_BODY_TYPES_SUCCESS,
    GET_POWERTRAIN_TYPES_BEGIN,
    GET_POWERTRAIN_TYPES_ERROR,
    GET_POWERTRAIN_TYPES_SUCCESS,

} from "./action-types-car";

const initialState = {
    carProfiles: {
        data: null,
        loading: false,
        error: null
    },
    currentCarProfile: {
        data: null, 
        loading: false,
        error: null
    },
    carRecommendations: {
        data: null,
        loading: false,
        error: null
    },
    makes: {
        loading: false,
        error: null,
        data: []
    },
    baseModels: {
        data: null,
        loading: false,
        error: null
    },
    bodyTypes: {
        loading: false,
        error: null,
        data: []
    },
    powertrainTypes: {
        loading: false,
        error: null,
        data: []
    },
    searchFilters: [
        {
            filterType: "year",
            text: (new Date().getFullYear() - 2) + " to " + new Date().getFullYear(),
            value: {
                year_from: (new Date().getFullYear()-2),
                year_to: new Date().getFullYear()
            }
        }
    ],
    expandedFilterPanel: null,
    filterModelTab: {
        index: 0,
        make: ""
    },
    searchResult: {
        data: null,
        error: null,
        loading: false
    },
    selectedCarProfile: {
        data: null,
        error: null,
        loading: false
    },
    tcoSettings: {
        data: null,
        error: null,
        loading: false
    }
};

function addCarProperties(car) {
    // isEV
    car.isEV = car.powertrain_type === "EV" || car.powertrain_type === "PHEV";

    // year make model string
    car.yearMakeModel = car.year + " " + car.make + " " + car.car_model;

    return car;
}

function addCarProfileProperties(carProfile) {
    var car = addCarProperties(carProfile.car);
    carProfile.car = car;

    // rating
    //TODO: remove as ratings should be included in the car model
    //let rating = Math.round((Math.random() * 4 + 1) * 10) / 10;
    //car.rating = rating;

    if(typeof carProfile.car_result.co2_emissions === "undefined"){
        carProfile.car_result.avg_monthly_fuel_cost= {"value":  "NaN", "currency": "NaN"}
        carProfile.car_result.co2_emissions=  {value:   "NaN", unit: "NaN"}
        carProfile.car_result.co2_emissions_saved_per_year= {value:   "NaN", unit: "NaN"}
        carProfile.car_result.depreciation= {value:   "NaN", currency: "NaN"}
        carProfile.car_result.finance_charge= {value:   "NaN", currency: "NaN"}
        carProfile.car_result.fuel_cost= {value:   "NaN", currency: "NaN"}
        carProfile.car_result.insurance= {value:   "NaN", currency: "NaN"}
        carProfile.car_result.maintenance= {value:   "NaN", currency: "NaN"}
        carProfile.car_result.taxes= {value:   "NaN", currency: "NaN"}
        carProfile.car_result.tires= {value:   "NaN", currency: "NaN"}
    }
    
    if(carProfile.estimated_price === null || typeof carProfile.estimated_price === 'undefined' || carProfile.estimated_price.value === null || carProfile.estimated_price.value <= 0){
        if (typeof carProfile.car.msrp_lower !== 'undefined' && carProfile.car.msrp_lower.value !== null && carProfile.car.msrp_lower.value !== -1 && typeof carProfile.car.msrp_upper !== 'undefined' && carProfile.car.msrp_upper.value !== null  && carProfile.car.msrp_upper.value !== -1){
            carProfile.estimated_price={};
            carProfile.estimated_price['value'] = (carProfile.car.msrp_lower.value + carProfile.car.msrp_lower.value)/2;
            carProfile.estimated_price['unit'] = carProfile.car.msrp_lower.unit;
        }
        else if (typeof carProfile.car.msrp_lower !== 'undefined' && carProfile.car.msrp_lower.value !== null  && carProfile.car.msrp_lower.value !== -1){
            carProfile.estimated_price={};
            carProfile.estimated_price['value'] = carProfile.car.msrp_lower.value;
            carProfile.estimated_price['unit'] = carProfile.car.msrp_lower.unit;
        }
        else if(typeof carProfile.car.msrp_upper !== 'undefined' && carProfile.car.msrp_upper.value !== null  && carProfile.car.msrp_upper.value !== -1){
            carProfile.estimated_price={};
            carProfile.estimated_price['value'] = carProfile.car.msrp_upper.value;
            carProfile.estimated_price['unit'] = carProfile.car.msrp_upper.unit;
        }
    }
    
    // annualCost
    var results = carProfile.car_result;

    // if (results.taxes < 0) { //TODO: fix values on server, EVs have negative taxes
    //     results.taxes = 0;
    // }
    carProfile.car_result.annual_ownership_cost = results.fuel_cost + results.maintenance + results.insurance + results.taxes;

    // ev viability
    var socs = carProfile.socs.filter(soc => soc.type === "end"),
        nbSuccessful = 0;
    socs.map(soc => {
        if (soc.value > 0) {
            nbSuccessful++;
        }
    });
    carProfile.car_result.trips_met_on_charge = socs.length > 0 ? Math.round(nbSuccessful / socs.length * 100) / 100 : 0;

    return carProfile;
}

function addCurrentCar(carProfiles) {
    let currentCarProfile = null;

    for (var i = 0; i < carProfiles.length; i++) {
        if (carProfiles[i].current == true) {
            currentCarProfile = carProfiles[i];
            return currentCarProfile;
        }
    }

    return currentCarProfile;
}

function addCarProfilesProperties(carProfiles) {
    carProfiles.map((carProfile, i) => {
        carProfile = addCarProfileProperties(carProfile);
        return carProfile;
    });
    return carProfiles;
}

/**
 * Will change cars rank if needed, otherwise it will just return previous carsData with new carProfile
 */
function updateCarProfilesData(carProfiles, newCarProfile){
    if (carProfiles.data !=null){
        let oldCarProfile = carProfiles.data.filter(c => c.id == newCarProfile.id)[0]
        if (oldCarProfile.rank != newCarProfile.rank){
            let addIndex = newCarProfile.rank < oldCarProfile.rank;
            for(let i = 0; i<carProfiles.data.length; i++){
                let cp = carProfiles.data[i];
                if(cp.rank < oldCarProfile.rank && cp.rank >= newCarProfile.rank && addIndex){
                    cp.rank ++;
                }
                else if(cp.rank > oldCarProfile.rank && cp.rank <= newCarProfile.rank && !addIndex){
                    cp.rank --;
                }
                if(cp.id == newCarProfile.id){
                    cp = newCarProfile
                }
                carProfiles.data[i] = cp;
            }
            return carProfiles.data;
        }
        else{
            return [...carProfiles.data.filter(c => c.id != newCarProfile.id), newCarProfile];
        }
    }
    else{
        return null
    }
}
 
const cars = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_CAR_PROFILES_BEGIN:
            return {...state, carProfiles: {data: null, loading: true, error: null}}

        case GET_CAR_PROFILES_ERROR:
            return {...state, carProfiles: {data: null, loading: false, error: action.payload}}

        case GET_CAR_PROFILES_SUCCESS:
            var carProfiles = action.payload;
            var currentCarProfile = addCurrentCar(carProfiles);
            carProfiles = addCarProfilesProperties(carProfiles);
            return {...state, currentCarProfile: {data: currentCarProfile, loading: false, error: null}, carProfiles: {data: carProfiles, loading: false, error: null}}

        case GET_CAR_PROFILE_BEGIN:
        case PATCH_CAR_PROFILE_BEGIN:
            return {...state, selectedCarProfile: {data: null, loading: true, error: null}}

        case GET_CAR_PROFILE_ERROR:
        case PATCH_CAR_PROFILE_ERROR:
            return {...state, selectedCarProfile: {data: null, loading: false, error: action.payload}}

        case GET_CAR_PROFILE_SUCCESS:
        case PATCH_CAR_PROFILE_SUCCESS:
            var carProfile = action.payload,
                carProfile = addCarProfileProperties(carProfile),
                carProfilesData = updateCarProfilesData(state.carProfiles, carProfile);
                // carProfilesData = updateCarProfilesData state.carProfiles.data ? [...state.carProfiles.data.filter(c => c.id != action.payload.id), action.payload]: null,
                // updatedCarProfiles = updateCarProfilesRanking(state.carProfiles, carProfile);
            return {...state, selectedCarProfile: {data: carProfile, loading: false, error: null}, carProfiles: {...state.carProfiles, data: carProfilesData}}
        
        case GET_TCO_SETTINGS_BEGIN:
            return {...state, tcoSettings: {data: null, loading: true, error: null}}
        case GET_TCO_SETTINGS_ERROR:
            return {...state, tcoSettings: {data: null, loading: false, error: action.payload}}
        case GET_TCO_SETTINGS_SUCCESS:
            return {...state, tcoSettings: {data: action.payload, loading: false, error: null}}

        case ADD_CAR_PROFILE_BEGIN:
            return {...state, carProfiles: {...state.carProfiles, loading: true, error: null}}
        case ADD_CAR_PROFILE_ERROR:
            return {...state, carProfiles: {...state.carProfiles, loading: false, error: action.payload}}
        case ADD_CAR_PROFILE_SUCCESS:
            return {...state, carProfiles: {...state.carProfiles, loading: false, error: null, data: [...state.carProfiles.data, action.payload]}}

        case DELETE_CAR_PROFILE_BEGIN:
            return {...state, carProfiles: {...state.carProfiles, loading: true, error: null}}
        case DELETE_CAR_PROFILE_ERROR:
            return {...state, carProfiles: {...state.carProfiles, loading: false, error: action.payload}}
        case DELETE_CAR_PROFILE_SUCCESS:
            return {...state, carProfiles: {...state.carProfiles, loading: false, error: null, data: state.carProfiles.data.filter(c => c.id != action.car_profile_id)}}


        case GET_CAR_RECOMMENDATIONS_BEGIN:
            return {...state, carRecommendations: {data: null, loading: true, error: null}}

        case GET_CAR_RECOMMENDATIONS_ERROR:
            return {...state, carRecommendations: {data: null, loading: false, error: action.payload}}

        case GET_CAR_RECOMMENDATIONS_SUCCESS:
            var carRecommendations = action.payload;
            carRecommendations = addCarProfilesProperties(carRecommendations);
            return {...state, carRecommendations: {data: carRecommendations, loading: false, error: null}}


        case ADD_CAR_RECOMMENDATION_BEGIN:
            return {...state, carProfiles: {...state.carProfiles, loading: true, error: null}, carRecommendations: {...state.carRecommendations, loading: true, error: null}}

        case ADD_CAR_RECOMMENDATION_ERROR:
            return {...state, carProfiles: {...state.carProfiles, loading: false, error: action.payload}, carRecommendations: {...state.carRecommendations, loading: false, error: action.payload}}

        case ADD_CAR_RECOMMENDATION_SUCCESS:
            var newCarProfile = action.payload;
            newCarProfile = addCarProfileProperties(newCarProfile);
            return {...state, carProfiles: {loading: false, error: null, data: [...state.carProfiles.data, newCarProfile]}, carRecommendations: {loading: false, error: null, data: state.carRecommendations.data.filter(c => c.id != action.payload.id)}}


        case GET_MAKES_BEGIN:
            return {...state, makes: {loading: true, error: null}}
        
        case GET_MAKES_SUCCESS:
            return {...state, makes: {loading: false, data: action.payload, error: null}}

        case GET_MAKES_ERROR:
            return {...state, makes: {loading: false, error: action.payload}}
        
        case GET_BASE_MODELS_BEGIN:
            return {...state, baseModels: {loading: true, error: null}}

        case GET_BASE_MODELS_SUCCESS:
            return {...state, baseModels: {loading: false, error: null, data: action.payload}}

        case GET_BASE_MODELS_ERROR:
            return {...state, baseModels: {loading: false, error: action.payload, data: null}}
        
        case GET_BODY_TYPES_BEGIN:
            return {...state, bodyTypes: {...state.bodyTypes, loading: true, error: null}}
        case GET_BODY_TYPES_ERROR:
            return {...state, bodyTypes: {...state.bodyTypes, loading: false, error: action.payload, data: null}}
        case GET_BODY_TYPES_SUCCESS:
            return {...state, bodyTypes: {...state.bodyTypes, loading: false, error: null, data: action.payload}}
        case GET_POWERTRAIN_TYPES_BEGIN:
            return {...state, powertrainTypes: {...state.powertrainTypes, loading: true, error: null}}
        case GET_POWERTRAIN_TYPES_ERROR:
            return {...state, powertrainTypes: {...state.powertrainTypes, loading: false, error: action.payload, data: null}}
        case GET_POWERTRAIN_TYPES_SUCCESS:
            return {...state, powertrainTypes: {...state.powertrainTypes, loading: false, error: null, data: action.payload}}
        
        case ADD_CAR_SEARCH_FILTER:
            // Special handling for filters that should be unique
            let filters = state.searchFilters
            if (action.payload.filterType === "year") {
                // Exclude existing filter (we'll add the new one to just have one)
                filters = filters.filter(f => f.filterType != action.payload.filterType)
            }
            return {...state, searchFilters: [...filters, action.payload]}
        case REMOVE_CAR_SEARCH_FILTER:
            return {...state, searchFilters: state.searchFilters.filter(c => c.value != action.payload.value)}
        case GET_SEARCH_BEGIN:
            // Retain only the advanced filter panel, collapse others
            let expanded = false
            if (state.expandedFilterPanel === "filter_advanced") {
                expanded = "filter_advanced"
            }
            return {...state, searchResult: {...state.searchResult, loading: true, error: null}, expandedFilterPanel: expanded, filterModelTab: {index: 0, make: ""}}
        case GET_SEARCH_ERROR:
            return {...state, searchResult: {loading: false, error: action.payload, data: null}}
        case GET_SEARCH_SUCCESS:
            return {...state, searchResult: {loading: false, error: null, data: action.payload.filter(car => car.visible)}}
        case CLEAR_SEARCH:
            return {...state, searchResult: {loading: false, error: null, data: null}}
        case SET_EXPANDED_FILTER_PANEL:
            return {...state, expandedFilterPanel: action.payload}
        case SET_FILTER_MODEL_TAB:
            return {...state, filterModelTab: {index: action.payload.index, make: action.payload.make}}
        default:
            return state;
    }
}

export default cars;