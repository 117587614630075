import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {default as styled} from "../../utils/styled";


const StyledDialogConfirmButton = styled(Button)`
    background-color: ${props => props.theme.colors.text_green};
    height: 40px;
    width: 90px;
    margin-right: 20px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.text_green};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.inactive_brand}
    }
 
`;

const StyledDialogCloseButton = styled(Button)`
    background-color: ${props => props.theme.colors.disable};
    height: 40px;
    width: 90px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.disable};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.inactive_brand}
    }
 
`;

interface IProps {
    handleClose: (event: any) => void;
    error_msg: { title: string, msg: string, visible: boolean}
    handleConfirm?: (event:any) => void;
}

interface IState { }

class DialogModal extends React.PureComponent<IProps, IState> {

    render() {
        return (
            <Dialog
                open={this.props.error_msg.visible}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.error_msg.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <span>
                            {this.props.error_msg.msg}
                      </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledDialogCloseButton onClick={this.props.handleClose} color="primary">
                        Close
                    </StyledDialogCloseButton>
                    {this.props.handleConfirm ?
                        <StyledDialogConfirmButton onClick={this.props.handleConfirm} color="primary">
                            Okay
                        </StyledDialogConfirmButton>
                    : 
                        null
                    }

                </DialogActions>
            </Dialog>
        );
    }
}


export default DialogModal;
