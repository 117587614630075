import React from 'react';
import {SortDirection} from '../../../utils/enums';
import 'pure-react-carousel/dist/react-carousel.es.css';
import SyncAltSharpIcon from '@material-ui/icons/SyncAltSharp';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import {
    TabContainer,
    ItemContainer,
    ShortInfoContainer,
    Percent,
    CarImg,
    CarNameModel,
    Status,
    SortContainer
} from './styled';

interface IResultCarTabProps {
    sortDirection: SortDirection
    selectedItem: any
    cars: any
    onSelectChange: (selectedId: number) => void
    onSortDirectionChange: () => void
}

function ResultCarTab(props: IResultCarTabProps) {

    const {selectedItem, cars, onSelectChange, onSortDirectionChange} = props;

    const renderSliders = () => cars.map((car:any) => {

        const onItemClicked = () => {
            onSelectChange(car);
        };

        const selected = selectedItem.sim_vehicle_id === car.sim_vehicle_id;

        return (
            <Slide index={car.sim_vehicle_id} key={car.sim_vehicle_id}>
                <ItemContainer selected={selected} onClick={onItemClicked}>
                    <ShortInfoContainer>
                        <Percent>{(car.ev_score * 100).toFixed(0)}%</Percent>
                        <CarImg src={car.image_url}/>
                    </ShortInfoContainer>
                    <CarNameModel>{`${car.make} ${car.model}`}</CarNameModel>
                    <Status>
                        <span>{car.num_days_failed}</span> out of range days
                    </Status>
                </ItemContainer>
            </Slide>
        )
    });

    return (
        <TabContainer>
            <SortContainer onClick={onSortDirectionChange}>
                <SyncAltSharpIcon/>
            </SortContainer>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={cars.length}
                visibleSlides={4}>
                <Slider>
                    {renderSliders()}
                </Slider>
            </CarouselProvider>
        </TabContainer>
    );
}

export default ResultCarTab;
