import {call, put, select, takeLatest} from 'redux-saga/effects'
import {AnalysisResultFull, ResultsActionTypes} from './types'
import {callApi, callApi1} from '../../utils/api'
import * as A from './actions';
import {existenceOfAnalysis} from "./actions";
import {getAnalysisListArgs} from "../analysis/sagas";
import { resolve4 } from 'dns';

export function* fetchResultsRequest(action: ReturnType<typeof A.fetchResults>) {
    let {chargingPage} = yield select();

    try {
        let hash = chargingPage.simulation_uuid;
        let vehicle_id = action.payload;
        let request_url = '/results/';
        //Dave updated this, but hash is required in order to query the chargingPage simulation data.
        if (hash && hash.length > 0) {
            request_url += (hash + '/');
        }
        request_url += 'summary/';

        const res = yield call(callApi, 'get', request_url);

        if (res.error) {
            yield put(A.fetchResultsError(res));
        } else {
            if(vehicle_id) {
                yield put(A.fetchResultsSuccess({res: res, selected_vehicle_id: vehicle_id}));

                yield put(A.fetchResultsDetails(vehicle_id));
            } else
                yield put(A.fetchResultsSuccess({res: res}));

        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.fetchResultsError(err.stack));
        } else {
            yield put(A.fetchResultsError('An unknown error occured.'));
        }
    }
}

export function* fetchTCORequest(action: ReturnType<typeof A.fetchTCO>) {
    console.log("submitting fetch request")
    try {
        let car_list = action.payload;

        const res = yield call(callApi, 'post', '/tco/', car_list);

        if (res.error) {
            console.log(res)

            yield put(A.fetchTCOError(res));
        } else {
            yield put(A.fetchTCOSuccess(res));
        }
    } catch (err) {
        console.log(err)

        if (err instanceof Error && err.stack) {
            yield put(A.fetchTCOError(err.stack));
        } else {
            yield put(A.fetchTCOError('An unknown error occured.'));
        }
    }
}

export function* fetchRoutePositionsRequest(action: ReturnType<typeof A.fetchRoutePositions>) {
    console.log("fetching route positions")
    try {
        let date = action.payload.date;
        let vehicle_id = action.payload.vehicle_id;

        console.log("Date is" + date)

        const res = yield call(callApi1, 'get', '/driving_route/driving_day_positions/' + '?date=' + date + '&vehicle_id=' + vehicle_id);

        if (res.error) {
            console.log(res)

            yield put(A.fetchRoutePositionsError(res));
        } else {
            yield put(A.fetchRoutePositionsSuccess(res));
        }
    } catch (err) {
        console.log(err)

        if (err instanceof Error && err.stack) {
            yield put(A.fetchRoutePositionsError(err.stack));
        } else {
            yield put(A.fetchRoutePositionsError('An unknown error occured.'));
        }
    }
}

export function* fetchResultsDetailsRequest(action: ReturnType<typeof A.fetchResultsDetails>) {
    let {chargingPage} = yield select();

    try {
        let hash;
        let vehicle_id = action.payload;
        hash = chargingPage.simulation_uuid;
        const res = yield call(callApi, 'get', '/results/' + hash + '/?vehicle_id=' + vehicle_id);
        if (res.error) {
            yield put(A.fetchResultsError(res))
        } else {
            yield put(A.fetchResultsDetailsSuccess(res));
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.fetchResultsError(err.stack))
        } else {
            yield put(A.fetchResultsError('An unknown error occured.'))
        }
    }
}

export function* resultsRequest(action: ReturnType<typeof A.fetchResults>) {
    let {chargingPage} = yield select();

    try {
        let hash = chargingPage.simulation_uuid;
        if (hash && hash !== '') {
            const res = yield call(callApi, 'get', '/results/' + hash + '/');
            yield put(A.fetchResultsSuccess(res));
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.fetchResultsError(err.stack))
        } else {
            yield put(A.fetchResultsError('An unknown error occured.'))
        }
    }

}

function* postAnalysisResult() {
    const state = yield select();

    // Don't send the results list to the server, due to huge traffic.
    const s = {
        ...state,
        resultsPage: {
            ...state.resultsPage,
            resultsList: null,
            loading: false
        },
    };

    const {start_date, end_date} = state.vehicle.current_query_for_saving.date_range;
    const number_of_vehicles = state.vehicle.selected_vehicles.length;
    const name = state.homePage.analysisName;
    return yield call(callApi, 'post', `/analysis_results/`,
        {name, state: s, number_of_vehicles, daterange: {start: start_date, end: end_date}}
    );
}

function* putAnalysisResult() {
    let state = yield select();

    // Don't send the results list to the server, due to huge traffic.
    const s = {
        ...state,
        resultsPage: {
            ...state.resultsPage,
            resultsList: null,
            loading: false
        }
    };

    const {start_date, end_date} = state.vehicle.current_query_for_saving.date_range;
    const number_of_vehicles = state.vehicle.selected_vehicles.length;
    const name = state.homePage.analysisName;
    const id = state.resultsPage.id;
    return yield call(callApi, 'put', `/analysis_results/${id}/`,
        {name, state: s, number_of_vehicles, daterange: {start: start_date, end: end_date}}
    );
}

function* changeOrSaveResults(action: ReturnType<typeof A.changeOrSaveResults>) {
    yield existence_of_analysis_id();

    try {
        let res;
        const state = yield select();

        if (state.resultsPage.id) {
            console.log('Change');
            res = yield putAnalysisResult();
        } else {
            console.log('SAVE');
            res = yield postAnalysisResult();
        }


        if (res.error) {
            yield put(A.saveResultsError(res.error))
        } else {
            yield put(A.saveResultsSuccess(res));
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.saveResultsError(err.stack))
        } else {
            yield put(A.saveResultsError('An unknown error occured.'))
        }
    }
}

function* existence_of_analysis_id() {

    const _getAnalysisListArgs = getAnalysisListArgs();
    const res = yield call(_getAnalysisListArgs.callApi, _getAnalysisListArgs.method, _getAnalysisListArgs.request);

    if (res && !res.error) {
        let state = yield select();

        const is_existence_of_analysis = res.filter((i: AnalysisResultFull) => {
            return i.name.localeCompare(state.homePage.analysisName, undefined, {sensitivity: 'case'}) === 0;
        });

        if (!!is_existence_of_analysis.length)
            yield put({type: 'CHANGE_ANALYSIS_ID', payload: is_existence_of_analysis[0].id});

    }
}

function* shareRequest(action: ReturnType<typeof A.shareResults>) {
    try {
        let analysisResults;
        yield existence_of_analysis_id();

        const state = yield select();
        const id = state.resultsPage.id;

        if (id)
            analysisResults = yield putAnalysisResult();
        else
            analysisResults = yield postAnalysisResult();

        const emails = action.payload;
        const analysis_results_id = analysisResults.id;
        const res = yield call(callApi, 'post', `/analysis_results/${analysis_results_id}/share/`, emails);

        if (analysisResults.error || res.error) {
            yield put(A.shareResultsError(res.error))
        } else {
            const results = {emails: emails, results: res};
            yield put(A.shareResultsSuccess(results));
        }

    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.shareResultsError(err.stack))
        } else {
            yield put(A.shareResultsError('An unknown error occured.'))
        }
    }
}


function* resultsWatcherSaga() {
    yield takeLatest(ResultsActionTypes.FETCH_RESULTS_REQUEST, fetchResultsRequest);
    yield takeLatest(ResultsActionTypes.FETCH_TCO_REQUEST, fetchTCORequest);
    yield takeLatest(ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST, fetchRoutePositionsRequest);
    yield takeLatest(ResultsActionTypes.FETCH_RESULTS_DETAILS_REQUEST, fetchResultsDetailsRequest);
    yield takeLatest(ResultsActionTypes.SHARE_REQUEST, shareRequest);
    yield takeLatest(ResultsActionTypes.EXISTENCE_OF_ANALYSIS, existenceOfAnalysis);
    yield takeLatest(ResultsActionTypes.CHANGE_OR_SAVE_RESULTS, changeOrSaveResults)
}

export default resultsWatcherSaga;
