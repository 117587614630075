export async function callApi(method: string, path: string, data?: any) {
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    let token = window.localStorage.getItem("authenticated");

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    //myHeaders.append('Accept', 'application/json, text/plain, */*');

    token && myHeaders.append('Authorization', `Token ${token}`);

    const res = await fetch(`${API_ENDPOINT}/api2${path}`, {
        method,
        headers: myHeaders,
        body: JSON.stringify(data)
    });

    if (res.status === 204) {
        return '';
    }
    if (res.status === 401) {
        window.localStorage.removeItem("authenticated");
        document.cookie = `csrftoken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        window.location.reload();
    }

    return res.json();
}

export async function callApi1(method: string, path: string, data?: any) {
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    let token = window.localStorage.getItem("authenticated");

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    //myHeaders.append('Accept', 'application/json, text/plain, */*');

    token && myHeaders.append('Authorization', `Token ${token}`);

    const res = await fetch(`${API_ENDPOINT}/api1${path}`, {
        method,
        headers: myHeaders,
        body: JSON.stringify(data)
    });

    if (res.status === 204) {
        return '';
    }
    if (res.status === 401) {
        window.localStorage.removeItem("authenticated");
        document.cookie = `csrftoken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        window.location.reload();
    }

    return res.json();
}
