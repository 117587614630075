import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button, {ButtonProps} from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

const GreenButton = withStyles({
  root: {
    backgroundColor: green[400],
    boxShadow: 'none',
    position: 'relative',
    padding: '6px 12px 6px 12px',
    alignItems: 'right',
    justifyContent: 'right',
    '&:hover': {
      backgroundColor: green[700],
    },
    '&:active':{
      backgroundColor: green[700],
    },
    '&:focus': {
      backgroundColor: green[700],
    },
  },
})((props: ButtonProps) => <Button color="default" {...props}/>);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'right',
      justifyContent: 'right',
      float: 'right',
      // '& > *': {
      //   margin: theme.spacing(1),
      // }
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      float: 'right',
      alignItems:'right',
      padding: '4px',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface IProps {
    report_downloading: boolean;
    handleButtonClick: (event: any) => void;
}

export default function DownloadReportButtons(props:IProps) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const {report_downloading, handleButtonClick, ...otherProps} = props; 
  const timer = React.useRef<number>();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

//   const handleButtonClick = () => {
//     if (!loading) {
//       setSuccess(false);
//       setLoading(true);
//       timer.current = setTimeout(() => {
//         setSuccess(true);
//         setLoading(false);
//       }, 2000);
//     }
//   };

  return (
    <div className={classes.root}>
      {/* <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          className={buttonClassname}
          onClick={handleButtonClick}
        >
          {success ? <CheckIcon /> : <SaveIcon />}
        </Fab>
        {loading && <CircularProgress size={68} className={classes.fabProgress} />}
      </div> */}
      <div className={classes.wrapper}>
        <GreenButton
          variant="contained"
          // color="primary"
          className={buttonClassname}
          disabled={report_downloading}
          onClick={handleButtonClick}
        >
            Download Report
        </GreenButton>
        {report_downloading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}
