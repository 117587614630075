import styled from '../../../utils/styled';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 15px;
`;

export const Title = styled('h1')`
    margin: 0;
    line-height: 0.92;
    font-size: 28px;
    font-weight: 300;
    color: ${props => props.theme.colors.pickled_bluewood};
    margin-bottom: 10px;
    align-self: center;
`;


export const HeaderItemContainer = styled('div')`
    display: flex;
    justify-content: center;
`;

export const HeaderTitle = styled('h4')`
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
`;

export const HeaderSubtitle = styled(HeaderTitle)`
    font-weight: 400;
    margin-left: 14px;
`;

export const BodyItemContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const Line = styled('div')`
    position: absolute;
    left: 9px;
    width: 4px;
    height: 100%;
    background-color: ${props => props.theme.colors.line};
    display: flex;
    align-items: flex-end;
`;

export const ArrowDown = styled('div')`
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${props => props.theme.colors.line};
    margin-left: -4px;
    margin-bottom: -2px;
`;

export const ItemContainer = styled('div')`
    display: flex;
    align-items: center;
`;

export const Icon = styled('img')`
    width: 20px;
`;

export const Text = styled('p')`
    font-size: 12px;
    margin: 5px;
    margin-left: 7px;
`;

export const Distance = styled('p')`
    font-size: 16px;
    margin: 3px;
    margin-left: 27px;
    display: flex;
    align-items: center;
`;
