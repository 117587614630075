import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//DM: took this sample code from material-ui.com/components/selects. 
//Customized it after


interface IReportSection {
  name: string,
  code: string
}

interface IProps {
  handleChange: (event: any) => void;
  selectedReportSectionCode: string;
  reportSectionsList: Array<IReportSection>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default function SectionSelect(props:IProps) {
  const classes = useStyles();
  const {handleChange, selectedReportSectionCode, reportSectionsList, ...otherProps} = props;
  const [sectionName, setSectionName] = React.useState('');

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSectionName(event.target.value as string);
    
  // };

  const listReportSections = () => {
    const items = [];
    for(let i = 0; i < reportSectionsList.length; i++) {
      items.push(<MenuItem key={reportSectionsList[i].code} value={reportSectionsList[i].code}>{reportSectionsList[i].name}</MenuItem>);
    }
    return items;
  };

  console.log("selectedReportSectionCode: " + selectedReportSectionCode + ", reportSectionsList: ", reportSectionsList);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">Section Name</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={selectedReportSectionCode}
          onChange={handleChange}
        >
          {listReportSections()}
        </Select>
        <FormHelperText>Select a Report section to see the list of available fields to choose</FormHelperText>
      </FormControl>
    </div>
  );
}
