import {Attribute} from "../../store/vehicles/types";
import {Vehicle} from "../../helpData/get_vehicles_response_example";

export interface VehicleGroup {
    group_title: string,
    vehicles: Vehicle[]
}


function get_attribute_value(vehicle: Vehicle, attribute_id: number): string {
    const attribute = vehicle.attributes.filter((a) => a.attribute_id === attribute_id)[0];

    // Sometimes attribute is missing.
    return attribute ? attribute.value : 'N/A';
}

export function group_vehicles_by_attribute(group_by_attribute: Attribute, vehicles?: Vehicle[]): VehicleGroup[] {
    if (typeof vehicles === 'undefined') {
        return [{group_title: '', vehicles: []}]
    }

    if (vehicles.length === 0) {
        return [{group_title: '', vehicles: []}]
    }

    const attribute_id = group_by_attribute.attribute_id;

    const mapping: Map<string, Vehicle[]> = new Map();

    vehicles.forEach((vehicle) => {
        const attribute_value = get_attribute_value(vehicle, attribute_id);
        const vehicles = mapping.get(attribute_value) || [];
        vehicles.push(vehicle);
        mapping.set(attribute_value, vehicles);
    });

    const asc = (a: string, b: string) => a > b ? 1 : -1;

    // @ts-ignore
    const grouped_vehicles: VehicleGroup[] = Array.from(mapping.keys()).sort(asc).map((key) => {
       return {
           'group_title': key,
           'vehicles': mapping.get(key)
       }
    });

    return grouped_vehicles;
}

export function uniqueVehicleArray(array: any) {
    return array.filter((item: any, index: any) => {
        return index === array.findIndex((obj: any) => {
            return JSON.stringify(obj) === JSON.stringify(item);
        });
    });
}