export const GET_CAR_PROFILES_BEGIN = "GET_CAR_PROFILES_BEGIN"
export const GET_CAR_PROFILES_ERROR = "GET_CAR_PROFILES_ERROR"
export const GET_CAR_PROFILES_SUCCESS = "GET_CAR_PROFILES_SUCCESS"

export const GET_CAR_PROFILE_BEGIN = "GET_CAR_PROFILE_BEGIN"
export const GET_CAR_PROFILE_ERROR = "GET_CAR_PROFILE_ERROR"
export const GET_CAR_PROFILE_SUCCESS = "GET_CAR_PROFILE_SUCCESS"

export const PATCH_CAR_PROFILE_BEGIN = "PATCH_CAR_PROFILE_BEGIN"
export const PATCH_CAR_PROFILE_ERROR = "PATCH_CAR_PROFILE_ERROR"
export const PATCH_CAR_PROFILE_SUCCESS = "PATCH_CAR_PROFILE_SUCCESS"

export const GET_TCO_SETTINGS_BEGIN = "GET_TCO_SETTINGS_BEGIN"
export const GET_TCO_SETTINGS_ERROR = "GET_TCO_SETTINGS_ERROR"
export const GET_TCO_SETTINGS_SUCCESS = "GET_TCO_SETTINGS_SUCCESS"

export const PATCH_TCO_SETTINGS_BEGIN = "PATCH_TCO_SETTINGS_BEGIN"
export const PATCH_TCO_SETTINGS_ERROR = "PATCH_TCO_SETTINGS_ERROR"
export const PATCH_TCO_SETTINGS_SUCCESS = "PATCH_TCO_SETTINGS_SUCCESS"

export const GET_CAR_RECOMMENDATIONS_BEGIN = "GET_CAR_RECOMMENDATIONS_BEGIN"
export const GET_CAR_RECOMMENDATIONS_ERROR = "GET_CAR_RECOMMENDATIONS_ERROR"
export const GET_CAR_RECOMMENDATIONS_SUCCESS = "GET_CAR_RECOMMENDATIONS_SUCCESS"

export const ADD_CAR_RECOMMENDATION_BEGIN = "ADD_CAR_RECOMMENDATION_BEGIN"
export const ADD_CAR_RECOMMENDATION_ERROR = "ADD_CAR_RECOMMENDATION_ERROR"
export const ADD_CAR_RECOMMENDATION_SUCCESS = "ADD_CAR_RECOMMENDATION_SUCCESS"

export const GET_MAKES_BEGIN = "GET_MAKES_BEGIN"
export const GET_MAKES_SUCCESS = "GET_MAKES_SUCCESS"
export const GET_MAKES_ERROR = "GET_MAKES_ERROR"

export const GET_BASE_MODELS_BEGIN = "GET_BASE_MODELS_BEGIN"
export const GET_BASE_MODELS_SUCCESS = "GET_BASE_MODELS_SUCCESS"
export const GET_BASE_MODELS_ERROR = "GET_BASE_MODELS_ERROR"

export const GET_BODY_TYPES_BEGIN = "GET_BODY_TYPES_BEGIN"
export const GET_BODY_TYPES_SUCCESS = "GET_BODY_TYPES_SUCCESS"
export const GET_BODY_TYPES_ERROR = "GET_BODY_TYPES_ERROR"

export const GET_POWERTRAIN_TYPES_BEGIN = "GET_POWERTRAIN_TYPES_BEGIN"
export const GET_POWERTRAIN_TYPES_SUCCESS = "GET_POWERTRAIN_TYPES_SUCCESS"
export const GET_POWERTRAIN_TYPES_ERROR = "GET_POWERTRAIN_TYPES_ERROR"

export const ADD_CAR_SEARCH_FILTER = "ADD_CAR_SEARCH_FILTER"
export const REMOVE_CAR_SEARCH_FILTER = "REMOVE_CAR_SEARCH_FILTER"

export const GET_SEARCH_BEGIN = "GET_SEARCH_BEGIN"
export const GET_SEARCH_ERROR = "GET_SEARCH_ERROR"
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS"

export const CLEAR_SEARCH = "CLEAR_SEARCH"

export const ADD_CAR_PROFILE_BEGIN = "ADD_CAR_PROFILE_BEGIN"
export const ADD_CAR_PROFILE_ERROR = "ADD_CAR_PROFILE_ERROR"
export const ADD_CAR_PROFILE_SUCCESS = "ADD_CAR_PROFILE_SUCCESS"

export const DELETE_CAR_PROFILE_BEGIN = "DELETE_CAR_PROFILE_BEGIN"
export const DELETE_CAR_PROFILE_ERROR = "DELETE_CAR_PROFILE_ERROR"
export const DELETE_CAR_PROFILE_SUCCESS = "DELETE_CAR_PROFILE_SUCCESS"

export const SET_EXPANDED_FILTER_PANEL = "SET_EXPANDED_FILTER_PANEL"
export const SET_FILTER_MODEL_TAB = "SET_FILTER_MODEL_TAB"