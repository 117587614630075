import {Store, createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
import {routerMiddleware} from 'connected-react-router'
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import {composeWithDevTools} from 'redux-devtools-extension'
// If you use react-router, don't forget to pass in your history type.
import {History} from 'history'

// Import the state interface and our combined reducers/sagas.
import {ApplicationState, createRootReducer, rootSaga} from './store'

import {initWebsocket} from './utils/websocket';

import thunk from 'redux-thunk';

const blockRequestsMiddleware = (store: { dispatch: any; getState: any; }) =>
    (next: (arg0: any) => void) =>
        (action: any) => {
          if(action.type === '@@charging/SET_SIMULATION_UUID') {
              initWebsocket(store, action.payload);
          }

          if(window.location.pathname.indexOf('/analysis_id=') === -1)
            return next(action);

          if(action.type === 'RESTORE_FETCH' || action.type === 'RESTORE') {
            return next(action);
          }
          return store.dispatch({type: 'RESTORE_FETCH'})
        };

export default function configureStore(history: History, initialState: ApplicationState): Store<ApplicationState> {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});
    // create the redux-saga middleware
    const sagaMiddleware = createSagaMiddleware();

  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(blockRequestsMiddleware, routerMiddleware(history), sagaMiddleware, thunk))
  );

  // initWebsocket(store);
  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);
  return store
}
