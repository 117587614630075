import * as React from 'react';
import Page from '../../components/layout/Page'
import {Container} from '../../components/layout/Container'
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {
    fetchAttributes,
    fetchVehicles,
    setCurrentQueryForSimVehiclesSaving,
    setGroupByAttribute,
    setGroupToSimVehiclesMapping,
    setMappingPrevSimVehicleSuggest,
    setSelectedSimVehicles,
    setSimVehicleAttributes,
    setSimVehicleSuggest,
    setVehicleMapping
} from "../../store/vehicles/actions";
import {Attribute, unselected_attribute} from "../../store/vehicles/types";
import {GetVehiclesResponse, Vehicle} from "../../helpData/get_vehicles_response_example";
import {
    QueryByAttribute,
    AttributesResponseInterface,
    getVehicles_query_Interface
} from "../../helpData/getVehicleAttributes";
import {callApi} from "../../utils/api";
import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import ClearIcon from '@material-ui/icons/Clear';
import DialogInput from '../../components/Modals/DialogInput';
import {setAnalysisNameAction} from '../../store/analysis/actions';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {BootstrapInput} from "../../components/layout/Inputs";
import {StyledTransparentButton} from "../../components/layout/GreenButton";
import ReplacementGroup from "../../components/SelectReplacement/ReplacementGroup";
import {changeVehiclesQueryAttibute} from "../../helpData/changeAttributes";
import {getVehicles_query} from "../../store/vehicles/reducer";
import {group_vehicles_by_attribute, uniqueVehicleArray, VehicleGroup} from "./group_vehicles_by_attribute";


// Separate state props + dispatch props to their own interfaces.
export interface PropsFromState {
    loading: boolean
    vehicles_attributes: Attribute[]
    sim_vehicles_attributes: Attribute[]
    errors?: string
    vehicles: GetVehiclesResponse | undefined
    sim_vehicles: Vehicle[]
    current_query_for_saving?: getVehicles_query_Interface | any
    sim_vehicle_suggest?: SimVehicleSuggest[] | undefined
    selected_vehicles?: Vehicle[] | undefined
    selected_sim_vehicles?: any
    analysisName: string
    vehicle_mapping: any
    group_by_attribute?: Attribute
    vehicle_mapping_sim_vehicles_attributes: object
    mapping_prev_sim_vehicle_suggest: object
    setAnalysisNameAction: typeof setAnalysisNameAction
    history: any,
    group_to_sim_vehicle_mapping: { [group_title: string]: Vehicle[] }
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    setSimVehicleSuggest: typeof setSimVehicleSuggest
    setVehicleMapping: typeof setVehicleMapping
    setGroupByAttribute: typeof setGroupByAttribute
    fetchRequest: typeof fetchAttributes
    fetchVehicles: typeof fetchVehicles
    setSimVehicleAttributes: typeof setSimVehicleAttributes
    setMappingPrevSimVehicleSuggest: typeof setMappingPrevSimVehicleSuggest
    setCurrentQueryForSimVehiclesSaving: typeof setCurrentQueryForSimVehiclesSaving,
    setGroupToSimVehiclesMapping: typeof setGroupToSimVehiclesMapping
    setSelectedSimVehicles: typeof setSelectedSimVehicles
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & PropsFromDispatch

interface IState {
    value: string,
    options: SimVehicleSuggest[],
    selectedSimVehicleSuggest: SimVehicleSuggest[],
    _all_sim_vehicle_suggest: SimVehicleSuggest[],
    group_by_attribute: Attribute,
    vehicle_mapping: any,
    vehicle_mapping_sim_vehicles_attributes: object,
}


class SelectReplacementPage extends React.PureComponent<AllProps, IState> {

    public waitingFor: string;

    constructor(props: AllProps) {
        super(props);

        this.onAttributesChanged = this.onAttributesChanged.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateValue = this.handleUpdateValue.bind(this);
        this.handleSelectGroupChange = this.handleSelectGroupChange.bind(this);
        this.handleUngroup = this.handleUngroup.bind(this);
        this.addAttribute = this.addAttribute.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.will_remove_vehicle = this.will_remove_vehicle.bind(this);
        this._fetchVehicles = this._fetchVehicles.bind(this);
        this.applyNewFilter = this.applyNewFilter.bind(this);

        this.props.fetchRequest('simvehicles');

        this.waitingFor = '';
        let _sim_vehicle_suggest;

        this.props.sim_vehicle_suggest && this.props.sim_vehicle_suggest.length !== 0 && (_sim_vehicle_suggest = this.props.sim_vehicle_suggest);

        this.state = {
            value: "",
            options: [],
            selectedSimVehicleSuggest: _sim_vehicle_suggest || [],
            _all_sim_vehicle_suggest: [],
            group_by_attribute: this.props.group_by_attribute ? this.props.group_by_attribute : unselected_attribute,
            vehicle_mapping: this.props.vehicle_mapping,
            vehicle_mapping_sim_vehicles_attributes: this.props.vehicle_mapping_sim_vehicles_attributes,
        };
    }

    componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<IState>, snapshot?: any): void {

    }

    handleSelectGroupChange(event: any) {
        const attribute_id = event.target.value;

        const attribute = this.props.vehicles_attributes.filter((a) => a.attribute_id === attribute_id)[0];

        if (attribute) {
            this.props.setGroupByAttribute(attribute);
            this.setState({
                group_by_attribute: attribute
            });
        }
    }

    handleUngroup(event: any) {
        this.props.setGroupByAttribute(unselected_attribute);
        this.setState({
            group_by_attribute: unselected_attribute
        });
    }

    will_remove_vehicle(group_key: string, vehicle_id: number) {
        let m = {...this.props.group_to_sim_vehicle_mapping};
        const vehicles = this.props.group_to_sim_vehicle_mapping[group_key];
        m[group_key] = vehicles.filter((v: Vehicle) => v.vehicle_id !== vehicle_id);
        this.props.setGroupToSimVehiclesMapping(m);
    }

    handleSubmit(groupkey: string, selected_sim_vehicles: Vehicle[]) {
        let m = {...this.props.group_to_sim_vehicle_mapping};

        if (!m[groupkey]) {
            m[groupkey] = []
        }

        m[groupkey] = [...m[groupkey], ...selected_sim_vehicles];

        m[groupkey] = uniqueVehicleArray(m[groupkey]);

        this.props.setGroupToSimVehiclesMapping(m);
    }

    addAttribute(vehicle_mapping_sim_vehicles_attributes: object) {
        this.props.setSimVehicleAttributes(vehicle_mapping_sim_vehicles_attributes);
    }


    handleUpdateValue(value: string, options: SimVehicleSuggest[]){
        this.setState({value: value, options: options});
    }


    handleChange(event: any, selected_value?: any) {
        let value = selected_value ? selected_value : event.currentTarget.value;
        if (!value)
            value = "";

        this.waitingFor = value;

        callApi('GET', `/sim_vehicles/suggest/?query=${value}`)
            .then((response) => {
                if (value === this.waitingFor) {
                    this.setState({
                        value: value,
                        options: response,
                        _all_sim_vehicle_suggest: response
                    });
                }

            }).catch((error) => {
                console.error(error);
                this.setState({value: value, options: []});
        });

    }

    _fetchVehicles(data: { url: string, type: "vehicles" | "simvehicles", mapping: any }) {

        this.props.fetchVehicles({url: data.url, type: data.type, mapping: data.mapping});
    }

    applyNewFilter(attribute: QueryByAttribute, vehicle_mapping_sim_vehicles_attributes: any, selected_vehicles_ids: number[], groupname: string) {
        let _getVehicles_query: any;

        selected_vehicles_ids.forEach((i: number) => {

            _getVehicles_query[i] = JSON.parse(JSON.stringify(getVehicles_query));

            if (attribute.attribute_id === -1) {
                _getVehicles_query[i].date_range = attribute.value;
            } else {
                changeVehiclesQueryAttibute(_getVehicles_query[i], attribute);
            }
        });

        const uri = JSON.stringify(_getVehicles_query[selected_vehicles_ids[0]]);
        const uri_enc = encodeURIComponent(uri);

        this._fetchVehicles({url: uri_enc, type: 'simvehicles', mapping: _getVehicles_query});
    }

    onAttributesChanged(attributes: AttributesResponseInterface[]) {
        const request = {
            date_range: {
                start_date: "2020-03-24",
                end_date: "2020-03-24"
            },
            attributes: attributes
        };

        this.props.fetchVehicles({url: encodeURIComponent(JSON.stringify(request)), type: 'simvehicles'});
    }


    render() {

        //Redirect to home page if there is no analysis selected
        if (this.props.analysisName === '')
            this.props.history.push('/')

        const grouped_vehicles = group_vehicles_by_attribute(
            this.state.group_by_attribute,
            this.props.selected_vehicles
        );

        return (
            <Page>
                <div style={{width: '90%', margin: '0 auto'}}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            margin: '10px 0',
                            alignItems: 'center',
                            justifyContent: 'start',
                        }}
                    >
                        <Typography
                            color="textPrimary"
                            variant="subtitle1"
                        >
                            <b style={{margin: "0 20px 0 0"}}>Group by:</b>
                        </Typography>

                        <FormControl style={{width: '230px'}}>
                            <Select
                                id="group-selector"
                                value={this.state.group_by_attribute.attribute_id}
                                onChange={(event: any) => this.handleSelectGroupChange(event)}
                                input={<BootstrapInput/>}
                            >
                                {this.props.vehicles_attributes.map((attr: Attribute, key: number) =>
                                    <MenuItem key={key} value={attr.attribute_id}>{attr.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {this.state.group_by_attribute.attribute_id !== -1 &&
                        (
                            <StyledTransparentButton
                                style={{margin: "0 0 0 20px"}}
                                aria-label="ungroup"
                                onClick={(event: any) => this.handleUngroup(event)}>
                                <ClearIcon color={'disabled'}/>
                                <b style={{margin: "0 0 0 10px"}}>Ungroup</b>
                            </StyledTransparentButton>
                        )}
                    </div>
                    {
                        grouped_vehicles.map((group: VehicleGroup) => {
                                return (
                                    <ReplacementGroup
                                        key={group.group_title}
                                        sim_vehicles_attributes={this.props.sim_vehicles_attributes}
                                        sim_vehicles={this.props.sim_vehicles}
                                        selectedSimVehicleSuggest={this.state.selectedSimVehicleSuggest}
                                        options={this.state.options}
                                        value={this.state.value}
                                        group_by_attribute_name={this.state.group_by_attribute.name}
                                        vehicles={group.vehicles}
                                        group_name={group.group_title}
                                        handleUpdateValue={this.handleUpdateValue}
                                        handleChange={this.handleChange}
                                        handleSubmit={this.handleSubmit}
                                        will_remove_vehicle={this.will_remove_vehicle}
                                        onAttributesChanged={this.onAttributesChanged}
                                        selected_sim_vehicles={this.props.group_to_sim_vehicle_mapping[group.group_title]}
                                        setSelectedSimVehicles={this.props.setSelectedSimVehicles}
                                    />)
                            }
                        )
                    }
                </div>
                {/*
                <DialogInput
                    key={this.props.analysisName}
                    handleClose={() => this.props.history.push('/')}
                    isVisible={this.props.analysisName === ''}
                    onSubmitClicked={this.props.setAnalysisNameAction}
                    negativeBtnName="Cancel"
                    positiveBtnName="Go next"
                    title="Create new analysis"/>
                */}
            </Page>
        )
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
export const mapStateToProps = ({vehicle, homePage}: ApplicationState) => ({
    loading: vehicle.loading,
    errors: vehicle.errors,
    vehicles_attributes: vehicle.vehicles_attributes,
    sim_vehicles_attributes: vehicle.sim_vehicles_attributes,
    vehicles: vehicle.vehicles,
    sim_vehicles: vehicle.sim_vehicles.vehicles,
    current_query_for_saving: vehicle.current_query_for_saving,
    selected_vehicles: vehicle.selected_vehicles,
    selected_sim_vehicles: vehicle.selected_sim_vehicles,
    sim_vehicle_suggest: vehicle.sim_vehicle_suggest,
    analysisName: homePage.analysisName,
    group_by_attribute: vehicle.group_by_attribute,
    vehicle_mapping: vehicle.vehicle_mapping,
    vehicle_mapping_sim_vehicles_attributes: vehicle.vehicle_mapping_sim_vehicles_attributes,
    mapping_prev_sim_vehicle_suggest: vehicle.mapping_prev_sim_vehicle_suggest,
    current_query_for_sim_vehicles_saving: vehicle.current_query_for_sim_vehicles_saving,
    group_to_sim_vehicle_mapping: vehicle.group_to_sim_vehicle_mapping
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
    setSimVehicleSuggest,
    setAnalysisNameAction,
    setVehicleMapping,
    setGroupByAttribute,
    setSimVehicleAttributes,
    fetchRequest: fetchAttributes,
    fetchVehicles,
    setMappingPrevSimVehicleSuggest,
    setCurrentQueryForSimVehiclesSaving,
    setGroupToSimVehiclesMapping,
    setSelectedSimVehicles
};

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectReplacementPage);
