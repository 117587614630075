import {call, select, put, takeLatest} from 'redux-saga/effects'
import {AnalysisActionTypes} from './types'
import { callApi, callApi1 } from '../../utils/api'
import * as A from "./actions";
import _ from 'lodash';

export interface getAnalysisListArgsI {
    callApi: typeof callApi,
    method: string,
    request: string
}

export function getAnalysisListArgs(): getAnalysisListArgsI {
    return {callApi, method: 'get', request: '/analysis_results/'}
}

function* fetchAnalysisRequest(action: ReturnType<typeof A.fetchAnalysis>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const _getAnalysisListArgs = getAnalysisListArgs();
    const res = yield call(_getAnalysisListArgs.callApi, _getAnalysisListArgs.method, _getAnalysisListArgs.request);

    if (res.error) {
      yield put(A.fetchAnalysisError(res.error))
    } else {
      yield put(A.fetchAnalysisSuccess(res))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(A.fetchAnalysisError(err.stack))
    } else {
      yield put(A.fetchAnalysisError('An unknown error occured.'))
    }
  }
}

function* createNewAnalysisAndResetOldData(action: ReturnType<typeof A.createNewAnalysisResult>){
    yield put({type: 'RESET_STATE', payload: {}});
}
function* fetchAnalysisResultRequest(action: ReturnType<typeof A.fetchAnalysisResult>) {
    try {
        let analysisId = action.payload;

        let res = yield call(callApi, 'get', `/analysis_results/${analysisId}/`);
        if (res.error) {
            yield put(A.fetchAnalysisError(res.error))
        } else {
            // @ts-ignore
            console.log("fetchAnalysisResultRequest. payload is ", res);
            yield put({type: AnalysisActionTypes.RESTORE, payload: res});
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.fetchAnalysisError(err.stack))
        } else {
            yield put(A.fetchAnalysisError('An unknown error occured.'))
        }
    }
}

function* fetchAnalysisRestoreRequest(action: ReturnType<typeof A.fetchAnalysis>) {
    try {
        const path = window.location.pathname;
        const [, analysisId] = path.split('=');
        const res = yield call(callApi, 'get', `/analysis_results/unique_link/${analysisId}/`);
        if (res.error) {
            yield put(A.fetchAnalysisError(res.error))
        } else {
            // @ts-ignore
            yield put({type: AnalysisActionTypes.RESTORE, payload: res})
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(A.fetchAnalysisError(err.stack))
        } else {
            yield put(A.fetchAnalysisError('An unknown error occured.'))
        }
    }
}

function* unshareAnalysisRequest(action: ReturnType<typeof A.unshareAnalysisById>) {
  try {
      let id = action.payload;
      let res = yield call(callApi, 'delete', `/analysis_results/${id}/share/`);

      console.log(res);

      if (!res.error) {
          const {homePage} = yield select();
          let data = _.cloneDeep(homePage.analysisList);
          data.map((item: any) => {
              if (item.id === id) {
                  item.members = [];
                  return item;
              }
              return item;
          });

          yield put(A.unshareAnalysisSuccess(data));
      } else {
          yield put(A.unshareAnalysisError(res.error))
      }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(A.unshareAnalysisError(err.stack))
    } else {
      yield put(A.unshareAnalysisError('An unknown error occured.'))
    }
  }
}

function* deleteAnalysisRequest(action: ReturnType<typeof A.deleteAnalysisById>) {
  try {
      let id = action.payload;
      let res = yield call(callApi, 'delete', `/analysis_results/${id}/`);
      console.log(res);

      if (!res.error) {
          const {homePage} = yield select();
          const data = _.cloneDeep(homePage.analysisList);
          _.remove(data, (item: any) => item.id === id);
          yield put(A.deleteAnalysisSuccess(data));
      } else {
          yield put(A.deleteAnalysisError(res.error))
      }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(A.deleteAnalysisError(err.stack))
    } else {
      yield put(A.deleteAnalysisError('An unknown error occured.'))
    }
  }
}


function* analysisWatcherSaga() {
  yield takeLatest(AnalysisActionTypes.FETCH_ANALYSIS_REQUEST, fetchAnalysisRequest);
  yield takeLatest(AnalysisActionTypes.DELETE_ANALYSIS_REQUEST, deleteAnalysisRequest);
  yield takeLatest(AnalysisActionTypes.UNSHARE_ANALYSIS_REQUEST, unshareAnalysisRequest);
  yield takeLatest(AnalysisActionTypes.FETCH_ANALYSIS_RESULT_REQUEST, fetchAnalysisResultRequest);
  yield takeLatest('RESTORE_FETCH', fetchAnalysisRestoreRequest);
  yield takeLatest(AnalysisActionTypes.RESET_STATE, createNewAnalysisAndResetOldData);
}

export default analysisWatcherSaga
