import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {addCarSearchFilter} from '../../../store/car_legacy/car_actions';
import {Slider} from 'material-ui-slider';
import { withTranslation } from 'react-i18next';


const mapStateToProps = state => {
    return {
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCarSearchFilter: (filterType, value, text) => dispatch(addCarSearchFilter(filterType, value, text))
    };
};


const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily
    },
    sliderWrapper: {
        width: "400px",
        maxWidth: "100%",
        display: "flex",
        alignItems: "center"
    },
    slider: {
        marginRight: "1rem",
        marginLeft: "1rem"
    }
});

class AdvancedFiltersPanel extends React.Component {
    constructor(props) {
        super(props)
        this.handleYearSliderChange = this.handleYearSliderChange.bind(this);
        this.handleYearSliderChangeComplete = this.handleYearSliderChangeComplete.bind(this)
        //DM get current year, minus 3, then make the default advanced filters dynamic
        var currentYear = new Date().getFullYear();
        
        //@todo make this dependent on the fleet actuals
        this.state = {
            year_from: (currentYear-2),
            year_to: (currentYear)
        }
    }

    handleYearSliderChange(value) {
        this.setState({
            year_from: value[0],
            year_to: value[1]
        })
    }
    handleYearSliderChangeComplete(value) {
        this.props.addCarSearchFilter(
            "year",
            {
                year_from: value[0],
                year_to: value[1]
            },
            `${value[0]} to ${value[1]}`
        )
    }

    render() {
        const {classes, t} = this.props;
        
        return (
            <div>
                <Typography variant="caption">
                    {t('garage.filters.advanced.slideYear')}
                </Typography>
                <div className={classes.sliderWrapper}>
                    <Typography variant="body1">
                        {this.state.year_from}
                    </Typography>
                    <Slider 
                        min={new Date().getFullYear()-2}
                        max={new Date().getFullYear()+1}
                        className={classes.slider} 
                        color="#8cdbb3" 
                        value={[this.state.year_from,this.state.year_to]} 
                        range
                        onChange={this.handleYearSliderChange}
                        onChangeComplete={this.handleYearSliderChangeComplete}
                    />
                    <Typography variant="body1">
                        {this.state.year_to}
                    </Typography>
                </div>
            </div>
        )
        
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvancedFiltersPanel)))