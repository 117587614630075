import * as React from "react";
import * as R from 'ramda';
import {connect} from "react-redux";

// material ui styles
import styled from 'styled-components';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// components
import Page from "../../components/layout/Page";
import Container from "../../components/layout/Container";
import {FlexBox} from "../../components/layout/Card.tsx";
import {GreenButton} from "../../components/layout/GreenButton";
import DetailedExpansionPanel from '../../components/ChargingSites/SimpleTable';
import LoadingModal from "../../components/Modals/LoadingModals";

// help data
import moment from 'moment'

// store
import {ApplicationState} from "../../store";
import {Attribute} from "../../store/vehicles/types";
import { useSelector } from 'react-redux';
import {GetVehiclesResponse, Vehicle} from "../../helpData/get_vehicles_response_example";
import { setVehicleIncentives } from '../../store/vehicles/actions';
import { formatNumberToQuantity } from "../../utils/helpers";
import {
    fetchAttributes,
} from "../../store/vehicles/actions";

export const StyledPage = styled(Page)(({theme}) => ({
    minHeight: '100%',
    height: 'max-content',
})) as typeof Box;

/////////////////////////////////////////////////////////////////////////////////////////////

interface IncentivesObject {
    [key: string]: {
        sim_vehicle_price: number,
        incentives: number
    }
}

export interface PropsFromState {
    errors?: string,
    loading: boolean,
    lambdaProgress: number,
    analysisName: string
    history: any,
    sim_vehicles: Vehicle[],
    group_to_sim_vehicle_mapping: { [group_title: string]: Vehicle[] },
    incentives: IncentivesObject
}

interface PropsFromDispatch {
    setVehicleIncentives: typeof setVehicleIncentives
    fetchRequest: typeof fetchAttributes
}

interface IState {
    selected_id: number,
    selected_vehicle: any,
    incentives: IncentivesObject
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & PropsFromDispatch

class IncentivesPage extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);

        const { group_to_sim_vehicle_mapping } = props;
        //const { incentives } = this.state;
        
        if (Object.keys(group_to_sim_vehicle_mapping).length === 0)
            this.props.fetchRequest('simvehicles');

        let incentives_temp = props.incentives;
        let selected_vehicle = null
        let id = -1

        for (let prop in group_to_sim_vehicle_mapping) {
            let vehicle_group = group_to_sim_vehicle_mapping[prop]

            for (let i in vehicle_group) {
                let vehicle = group_to_sim_vehicle_mapping[prop][i]

                let price = 0

                if (!incentives_temp[vehicle.telematics_id]) {

                    if (vehicle.sim_vehicle && vehicle.sim_vehicle.msrp_lower) {
                        price = vehicle.sim_vehicle.msrp_lower
                    } else {
                        let attributes = vehicle.attributes
                        for (let i in attributes) {
                            if (attributes[i].name == "MSRP") {
                                price = parseInt(attributes[i].value)
                            }
                        }
                    }


                    incentives_temp[vehicle.telematics_id] = {
                        sim_vehicle_price: price,
                        incentives: !vehicle.sim_vehicle ? 0 : vehicle.sim_vehicle.federal_incentives ? vehicle.sim_vehicle.federal_incentives : (vehicle.sim_vehicle.powertrain_type == "EV" ? 7500 : vehicle.sim_vehicle.powertrain_type == "PHEV" ? 5000 : 0)
                    }
                }
            }
        }

        selected_vehicle = this.getSelectedVehicle()

        if (selected_vehicle != null)
            id = parseInt(selected_vehicle.telematics_id)

        console.log(incentives_temp)
        console.log(group_to_sim_vehicle_mapping)
        this.state = {
            selected_id: id,
            selected_vehicle: selected_vehicle,
            incentives: incentives_temp
        };
        
        this.addDefaultSrc = this.addDefaultSrc.bind(this)
        this.handleCarClick = this.handleCarClick.bind(this)
        //this.getVehiclePrice = this.getVehiclePrice.bind(this)
    }

    componentDidUpdate(prevProps: AllProps) {

    }

    shouldComponentUpdate(nextProps: AllProps, nextState: IState) {
        if (this.state.incentives !== nextState.incentives) {
            return false
        } else {
            return true;
        }
    }

    getSelectedVehicle() {
        const { group_to_sim_vehicle_mapping } = this.props;

        for (let prop in group_to_sim_vehicle_mapping) {
            let vehicle_group = group_to_sim_vehicle_mapping[prop]
            for (let i in vehicle_group) {
                if (vehicle_group[i] && vehicle_group[i].sim_vehicle) {
                    let vehicle = group_to_sim_vehicle_mapping[prop][0]
                    return vehicle
                }
            }

        }

        return null
    }

    //If there is an error retrieving an image
    addDefaultSrc(event: any) {
        event.target.src = "https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/NoPhotoAvail.png"
    }

    handleCarClick(id: any) {
        const { group_to_sim_vehicle_mapping } = this.props;

        Object.keys(group_to_sim_vehicle_mapping).forEach((key) => {
            group_to_sim_vehicle_mapping[key].forEach((vehicle) => {
                if (vehicle.telematics_id == id) {
                    console.log("vehicle clicked")

                    this.setState({
                        selected_id: parseInt(id),
                        selected_vehicle: vehicle
                    })
                } 
            })
        })

    }
    /*
    getVehiclePrice(vehicle: any) {
        const { incentives } = this.state;
        let attributes = vehicle.attributes
        let price = 0

        for (let i in attributes) {
            if (attributes[i].name == "MSRP") {
                price = attributes[i].value
            }
        }

        return price
    }
    */

    updateVehicle(event: any, id: any, type: string) {
        const { incentives } = this.state;
        let incentives_temp = incentives;
        let value = event.target.value
        if (incentives[id]) {
            if (type == "price") {
                incentives_temp[id].sim_vehicle_price = value == "" ? 0 : parseInt(event.target.value.split(",").join(""))
            } else {
                incentives_temp[id].incentives = value == "" ? 0 : parseInt(event.target.value.split(",").join(""))
            }
        }

        console.log("incentives temp is")
        console.log(incentives_temp)
        this.props.setVehicleIncentives(incentives_temp)

        this.setState({
            incentives: incentives_temp
        })
    }

    getPowertrainIndicator(powertrain_type: string) {
		if (powertrain_type === "EV") {
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-EV.svg" title={powertrain_type} style={{width: '120px', height: '30px', objectFit: 'contain'}} />
		}
		else if (powertrain_type === "conventional") {
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-GAS.svg" title={powertrain_type} style={{width: '120px', height: '30px', objectFit: 'contain'}} />
		}
		else if (powertrain_type === "HEV") {
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-HYBR.svg" title={powertrain_type} style={{width: '120px', height: '30px', objectFit: 'contain'}} />
		}
		else if (powertrain_type === "PHEV") { // PHEV
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-PHEV.svg" title={powertrain_type} style={{width: '120px', height: '30px', objectFit: 'contain'}} />
		} else { 
			return null
		}
	}

    render() {
        const { selected_id, selected_vehicle, incentives } = this.state;
        const { loading, lambdaProgress, sim_vehicles, group_to_sim_vehicle_mapping } = this.props;
        
        //Redirect to home page if there is no analysis selected
        if (this.props.analysisName === '')
            this.props.history.push('/')

        console.log(group_to_sim_vehicle_mapping)

        return (
            <StyledPage>
                <Container style={{minWidth: '100%'}}>
                    <Paper>
                        <div className={"card-container"} style={{padding: 40}}>
                            <Typography variant="h4" style={{fontWeight: 400}}>
                                Your Incentives
                            </Typography>
                            <br />
                            <Grid container spacing={8}>
                                {selected_vehicle != null ?
                                    <Grid item xs>
                                        <Typography variant="h6" style={{fontWeight: 400}}>
                                            Replacement vehicle candidates
                                        </Typography>
                                        <List style={{marginTop: 20, height: 500, overflowY: 'auto'}}>
                                            {Object.keys(group_to_sim_vehicle_mapping).map((key) => {
                                                return (
                                                    group_to_sim_vehicle_mapping[key].map((vehicle: any, index: number) => {
                                                        if (vehicle.sim_vehicle) {
                                                            return (
                                                                <div key={vehicle.telematics_id}>
                                                                    <ListItem
                                                                        button
                                                                        alignItems="center"
                                                                        style={{paddingTop: 12, paddingBottom: 12}}
                                                                        onClick={(event) => this.handleCarClick(vehicle.telematics_id)}
                                                                        selected={selected_id == vehicle.telematics_id ? true : false}
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <img
                                                                                src={vehicle.sim_vehicle.image_url}
                                                                                style={{width: 100, height: 53, borderRadius: 10}} 
                                                                                onError={this.addDefaultSrc}
                                                                            />
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <React.Fragment>
                                                                                    <Grid container direction="row">
                                                                                        <Grid item xs>
                                                                                            <Typography variant="h6" style={{lineHeight: 1}}>
                                                                                                {vehicle.sim_vehicle.year + " " + vehicle.sim_vehicle.make + " " + vehicle.sim_vehicle.model}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            {incentives[vehicle.telematics_id] && incentives[vehicle.telematics_id].sim_vehicle_price == -1 ?
                                                                                                <ErrorOutlineIcon />
                                                                                            :
                                                                                                null
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Typography variant="h6" style={{fontWeight: 300, color: 'gray'}}>
                                                                                        {vehicle.sim_vehicle.option}
                                                                                    </Typography>
                                                                                </React.Fragment>

                                                                            }
                                                                            /*
                                                                            secondary={
                                                                                <Typography variant="body2" style={{marginTop: 10}}>
                                                                                    Most used in fleets
                                                                                </Typography>
                                                                            }
                                                                            */
                            
                                                                            style={{
                                                                                marginLeft: 20
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <Divider style={{backgroundColor: 'black'}} />
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                )
                                            })}
                                        </List>
                                    </Grid>
                                : null}
                                {selected_vehicle != null && selected_vehicle.sim_vehicle ?
                                    <Grid item xs>
                                        <img
                                            src={selected_vehicle.sim_vehicle.image_url}
                                            style={{width: 300, height: 160, objectFit: 'contain', borderRadius: 15}}
                                            onError={this.addDefaultSrc}
                                        />
                                        <Typography variant="h4" style={{marginTop: 20}}>
                                            {selected_vehicle.sim_vehicle.year + " " + selected_vehicle.sim_vehicle.make + " " + selected_vehicle.sim_vehicle.model}
                                        </Typography>
                                        {this.getPowertrainIndicator(selected_vehicle.sim_vehicle.powertrain_type)}

                                        <Typography variant="body1" style={{fontWeight: 400}}>
                                            
                                        </Typography>
                                        <Grid container spacing={4} style={{marginTop: 10, marginBottom: 10}}>
                                            <Grid item xs >
                                                <Typography variant="h6">
                                                    Purchase Price
                                                </Typography>
                                                <Typography>
                                                    Based on MSRP
                                                </Typography>
                                                
                                                <TextField
                                                    id="standard-bare"
                                                    value={formatNumberToQuantity(incentives[selected_vehicle.telematics_id].sim_vehicle_price)}
                                                    margin="normal"
                                                    type="text"
                                                    InputProps={{
                                                        style: {
                                                            fontSize: 32                             
                                                        },
                                                        startAdornment: 
                                                            <InputAdornment position="start">
                                                                <Typography variant="h4">
                                                                    $
                                                                </Typography>
                                                            </InputAdornment>
                                                        ,
                                                    }}
                                                    onChange={(event) => this.updateVehicle(event, selected_vehicle.telematics_id, "price")}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="h6" style={{marginBottom: 23}}>
                                                    Incentives
                                                </Typography>
                                                <TextField
                                                    id="standard-bare"
                                                    value={formatNumberToQuantity(incentives[selected_vehicle.telematics_id].incentives)}
                                                    margin="normal"
                                                    type="text"
                                                    InputProps={{
                                                        style: {
                                                            fontSize: 32                             
                                                        },
                                                        startAdornment: 
                                                            <InputAdornment position="start">
                                                                <Typography variant="h4">
                                                                    $
                                                                </Typography>
                                                            </InputAdornment>
                                                        ,
                                                    }}
                                                    onChange={(event) => this.updateVehicle(event, selected_vehicle.telematics_id, "incentives")}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography variant="h6">
                                            Price After Rebate
                                        </Typography>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 195,
                                            height: 59,
                                            backgroundColor: "rgba(22, 155, 213, 0.0627450980392157)",
                                            borderRadius: 19
                                        }}>
                                            <Typography variant="h4" style={{color: '#169BD5'}}>
                                                ${formatNumberToQuantity(incentives[selected_vehicle.telematics_id].sim_vehicle_price - incentives[selected_vehicle.telematics_id].incentives)}
                                            </Typography>
                                        </div>

                                    </Grid>
                                :
                                    <Grid item xs>
                                        <Typography variant="h4">
                                            It looks like this vehicle is from an older version of MyFleetBuy
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant="h6">
                                            Please remove and re-add your replacement vehicles to use this feature
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs style={{
                                    //display: 'flex',
                                    //alignItems: 'center'
                                }}>
                                    <div style={{marginTop: 100}}>
                                        <Typography variant="h6" style={{fontWeight: 400}}>
                                            Available Incentives
                                        </Typography>
                                        <Typography variant="body1" style={{fontWeight: 400}}>
                                            Select an incentive to see savings
                                        </Typography>
                                        <ul>
                                            <li style={{fontSize: 20}}>
                                                <Typography variant="h6" style={{fontWeight: 400}}>
                                                    {selected_vehicle ? 
                                                        selected_vehicle.sim_vehicle.federal_incentives ? 
                                                            "$" + new Intl.NumberFormat("en-US").format(selected_vehicle.sim_vehicle.federal_incentives)
                                                        : null 
                                                    : null} Federal tax credit 
                                                </Typography>
                                            </li>
                                            <li style={{fontSize: 20}}>
                                                <Typography variant="h6" style={{fontWeight: 400}}>
                                                    $5,000 Clean Vehicle Rebate Program
                                                </Typography>
                                            </li>
                                            <li style={{fontSize: 20}}>
                                                <Typography variant="h6" style={{fontWeight: 400}}>
                                                    $5,000 South Coast Air Quality Management District
                                                </Typography>
                                            </li>
                                        </ul>  
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </Paper>
                    
                </Container>
                {
                    loading && (<LoadingModal open={loading} progress={lambdaProgress}/>)
                }
            </StyledPage>
        );
    }
}


export const mapStateToProps = ({chargingPage, vehicle, homePage, resultsPage}: ApplicationState) => ({
    loading: vehicle.loading,
    lambdaProgress: resultsPage.lambdaProgress,
    analysisName: homePage.analysisName,
    sim_vehicles: vehicle.sim_vehicles.vehicles,
    group_to_sim_vehicle_mapping: vehicle.group_to_sim_vehicle_mapping,
    incentives: vehicle.incentives,
});

const mapDispatchToProps = {
    setVehicleIncentives
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IncentivesPage);
