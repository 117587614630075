import { Reducer } from 'redux'
import {ChargingState, ChargingActionTypes} from './types';
import {AnalysisActionTypes} from "../analysis/types";
//////////////////////////////////////////////////////////////////////

// Type-safe initialState!
export const initialState: ChargingState = {
  loading: false,
  errors: undefined,
  simulation_uuid: '',
  selectedLocations: [],
  locations: [],
  simulationResponse: undefined,
    simulationRequest: undefined
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<ChargingState> = (state = initialState, action) => {
  switch (action.type) {
    case ChargingActionTypes.FETCH_LOCALE_REQUEST: {
      return { ...state, loading: true }
    }
    case ChargingActionTypes.FETCH_LOCALE_SUCCESS: {
        return {...state, loading: false, locations: action.payload, selectedLocations: action.payload}
    }
    case ChargingActionTypes.FETCH_LOCALE_ERROR: {
        return {...state, loading: false, errors: action.payload}
    }


    case ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST: {
      return { ...state, loading: true, simulation_uuid: action.payload.simulation_uuid }
    }
    case ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST_SUCCESS: {
      return { ...state, loading: false, simulationResponse: action.payload }
      // return { ...state, loading: false, simulationResponse: getSimulationResults }
    }
    case ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST_ERROR: {
      return { ...state, loading: false, errors: action.payload }
        // return {...state, loading: false, simulationResponse: getSimulationResults}
    }

    case ChargingActionTypes.SET_SELECTED_LOCATIONS: {
      return { ...state, selectedLocations: action.payload }
    }

    case ChargingActionTypes.SET_SIMULATION_UUID: {
      return { ...state, simulation_uuid: action.payload.simulation_uuid,  simulationRequest: action.payload.body}
    }

    case ChargingActionTypes.RESULT_ARE_NOT_READY: {
        return {...state, loading: true}
    }


    case 'RESET_STATE': {
        return {...state, ...initialState}
    }

    case AnalysisActionTypes.RESTORE: {
      return {...action.payload.state.chargingPage}
    }


    default: {
      return state
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as chargingPageReducer }
