/* eslint-disable no-use-before-define */
import React from 'react';
import {connect} from "react-redux";
import {init, RootStore, thunkAction, unboxThunk} from "../../redux";
import {AttributesResponseInterface} from "../../helpData/getVehicleAttributes";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ToggleColumns from './Toogle';
import styled from "../../utils/styled";
import {GetVehiclesResponse, Vehicle} from "../../helpData/get_vehicles_response_example";
import Typography from "@material-ui/core/Typography";
import {StylesTableBody, StylesTableHead} from "../layout/TableBaseStyles";
import {GreenCheckbox} from "../layout/GreenCheckbox";


/////////////////////////////////////////////////////////////


/////////////////////////////////////////////////////////////
interface PropsComboBox {
    tableAttributes: any,
    tableData: GetVehiclesResponse,
    selected_vehicles: Vehicle[],
    rows_per_page: number,

    selectedVehicles: (selected: any) => void;
    removeAttribute: (event: React.ChangeEvent<HTMLInputElement>, attribute: AttributesResponseInterface) => void;
    changeRowsPerPage: (rowsPerPage: number) => void;
}

type Order = 'asc' | 'desc';
type CustomTableState = {
    tableAttributes: AttributesResponseInterface[],
    setAnchorEl: HTMLElement | any,
    setPage: number | string | any,
    page: number | string | any,
    order: Order,
    setOrder: Order,
    setOrderBy: number,
    orderBy: number,
    columns: Column[],
    rows: any,
    total: number,
    selected: any
}
type EnhancedTableHeadState = {
    order: Order,
    orderBy: number,
}
type EnhancedTableHeadProps = {
    order: Order,
    orderBy: number,
    numSelected: number;
    rowCount: any,
    columns: Column[],
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
        init,
        thunkAction: unboxThunk(thunkAction),
    }
);

export interface Column {
    id: number;
    label: string;
    visible: boolean;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}


function desc<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}


function getSorting<K extends keyof any>(order: Order,
                                         orderBy: K,): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const SearchResultHeader = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 16px;
`

class SortableTabTable extends React.PureComponent<PropsComboBox, CustomTableState> {
    public columns: Column[] = [];
    public rows: [] | any;
    public rowsPerPageOptions: number[] = [5, 10, 25];
    public total: number = 0;
    public emptyRows: any;
    public orderBy: number;
    public order: Order;
    public page: number;

    constructor(props: PropsComboBox) {
        super(props);

        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.addAttribute = this.addAttribute.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.createDataTable = this.createDataTable.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.isSelected = this.isSelected.bind(this);

        this.createDataTable();

        this.props.tableData ? this.total = this.props.tableData.total : this.total = 0;
        
        console.log("this total is ", this.props.tableData);

        this.state = {
            setAnchorEl: null,
            setPage: 0,
            page: 0,
            order: "asc",
            setOrder: "asc",
            orderBy: this.columns[0].id,
            setOrderBy: this.columns[0].id,
            tableAttributes: this.props.tableAttributes,
            columns: this.columns,
            rows: this.rows,
            total: this.total,
            selected: this.props.selected_vehicles || []
        };
        this.orderBy = this.state.orderBy;
        this.order = this.state.order;
        this.page = this.state.page;
    }

    componentDidUpdate(prevProps: PropsComboBox, prevState: CustomTableState, snapshot: any) {

        if (prevProps.tableData !== this.props.tableData) {
            this.createDataTable();

            let _orderBy = this.state.orderBy;

            _orderBy && this.rows.sort((a: any, b: any) => {
                return parseFloat(a[_orderBy]) - parseFloat(b[_orderBy]);
            });

            this.setState({rows: this.rows, columns: this.columns, total: this.total});
            console.log("SortableTabTable: tableData changed");
        }
        if (prevProps.selected_vehicles !== this.props.selected_vehicles) {
            this.setState({selected: this.props.selected_vehicles})
            console.log("SortableTabTable selected_vehicles changed. ", this.props.selected_vehicles);
        }
    }

    createDataTable() {
        //Define the preselected columns
        let requiredColumns = ["telematics id", "model year", "mfb-vehicle type", "department", "equipment id", "total distance", "make", "model"];

        this.props.tableAttributes.forEach((item: AttributesResponseInterface) => {
            let visible = false;
            for (let i in requiredColumns) {
                if (item.name.toLowerCase() == requiredColumns[i]) {
                    visible = true
                }
            }
            
            let column = {
                id: item.attribute_id,
                label: item.name,
                visible: visible, //item.selected,
                minWidth: 170,
                type: item.type
            };
            const isColumn = this.columns.some((one_column: Column) => one_column.id === column.id);
            !isColumn && this.columns.push(column);
        });
        this.rows = [];

        this.props.tableData && this.props.tableData.vehicles.forEach((item: Vehicle) => {
            let obj = {} as any;
            item.attributes.forEach((attr: any) => {
                const name = attr.attribute_id;
                obj[name] = attr.value;
            });
            obj['vehicle_id'] = item.vehicle_id;
            obj['-2'] = item.total_distance;
            obj['-1'] = item.average_distance;
            this.rows = [...this.rows, obj];
        });
        if(this.props.tableData && this.props.tableData.total) {
            this.total = this.props.tableData.total;
        }
        // this.emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.rows.length - this.state.page * this.state.rowsPerPage);
    }

    handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.changeRowsPerPage(parseInt(event.target.value));
        this.setState({page: 0});
    }

    handleChangePage(event: React.ChangeEvent<HTMLInputElement>, page: number) {
        this.setState({page: page});
    }

    handleRequestSort(event: React.MouseEvent<unknown>, property: number) {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({order: (isDesc ? 'asc' : 'desc')});
        this.setState({orderBy: property});
    }

    handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            let newSelected: string[] = [];
            const tableData = JSON.parse(JSON.stringify(this.props.tableData.vehicles));

            this.state.rows.forEach((n: any, index: number) => {
                const vehicle_id = tableData[index].vehicle_id;
                newSelected.push(vehicle_id);
            });

            this.setState({selected: newSelected});
            this.props.selectedVehicles(newSelected);

        } else {
            this.setState({selected: []});
            this.props.selectedVehicles([]);
        }
    }

    isSelected(name: string) {
        return this.state.selected.indexOf(name) !== -1;
    }

    handleSelectClick(event: React.MouseEvent<unknown>, name: any) {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected})
        this.props.selectedVehicles(newSelected);
    }


    addAttribute(event: any, tableAttributes: Column[], id: number) {
        let row = JSON.parse(JSON.stringify(this.state.rows));

        const _orderBy = tableAttributes.find((item: any) => item.visible === true) as any;

        this.setState({
            ...this.state,
            columns: tableAttributes,
            rows: row,
            order: this.state.order,
            orderBy: _orderBy.id,
        });

    }


    render() {
        return (
            <div>
                <SearchResultHeader>
                    <Typography color="inherit" variant="h5">
                        Search result: <b data-test-id="SearchResultCount">{(this.state.rows && this.state.rows.length >= 0) && (this.state.rows.length + ' vehicles of ' + this.state.total + ' total')}</b>
                    </Typography>
                    <ToggleColumns id_key={"ToggleColumns"} addAttribute={this.addAttribute}
                                   columns={this.state.columns}/>
                </SearchResultHeader>
                <Paper>
                    <div style={{overflowX: "scroll"}}>
                        <Table
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                columns={this.state.columns}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={this.state.rows.length}
                                numSelected={this.state.selected.length}
                                onSelectAllClick={this.handleSelectAllClick}
                            />
                            <StylesTableBody>
                                {this.state.rows.length ? (stableSort(this.state.rows, getSorting(this.state.order, this.state.orderBy))
                                        .slice(this.state.page * this.props.rows_per_page, this.state.page * this.props.rows_per_page + this.props.rows_per_page)
                                        .map((row: any, index: number) => {
                                            const tableData = JSON.parse(JSON.stringify(this.props.tableData.vehicles));

                                            if (tableData[index] && typeof tableData[index] !== 'undefined') {
                                                const vehicle_id = row.vehicle_id;
                                                const isItemSelected = this.isSelected(vehicle_id);
                                                const labelId = `enhanced-table-checkbox-${vehicle_id}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={vehicle_id}
                                                        onClick={event => this.handleSelectClick(event, vehicle_id)}
                                                        aria-checked={isItemSelected}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <GreenCheckbox
                                                                checked={isItemSelected}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                            />
                                                        </TableCell>

                                                        {this.state.columns.filter((c: Column) => c.visible).map((column: Column) => {
                                                            const mi = (column.id === -1 || column.id === -2) ? ' mi' : '';
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {value + mi}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            } else return null;
                                        }))
                                    : (<TableRow style={{height: '200px'}}>
                                        <TableCell colSpan={7} style={{
                                            paddingTop: '0px',
                                            paddingBottom: '0px',
                                            textAlign: 'center'
                                        }}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>)
                                }
                            </StylesTableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={this.rowsPerPageOptions}
                        component="div"
                        count={this.state.rows.length}
                        rowsPerPage={this.props.rows_per_page}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={(event: any, page: number) => this.handleChangePage(event, page)}
                        onChangeRowsPerPage={(event: any) => this.handleChangeRowsPerPage(event)}
                    />
                </Paper>
            </div>
        );
    }
}


class EnhancedTableHead extends React.PureComponent<EnhancedTableHeadProps, EnhancedTableHeadState> {

    constructor(props: EnhancedTableHeadProps) {
        super(props);

        this.state = {
            order: this.props.order,
            orderBy: this.props.orderBy,
        };
    }

    createSortHandler(event: React.MouseEvent<any>, property: any): void {
        this.props.onRequestSort(event, property);
    };

    componentDidUpdate(prevProps: EnhancedTableHeadProps, prevState: EnhancedTableHeadState, snapshot: any) {

        if (prevProps.order !== this.props.order) {
            this.setState({order: this.props.order});
        }
        if (prevProps.orderBy !== this.props.orderBy) {
            this.setState({orderBy: this.props.orderBy});
        }
    }

    render() {
        return (
            <StylesTableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <GreenCheckbox
                            indeterminate={this.props.numSelected > 0 && this.props.numSelected < this.props.rowCount}
                            checked={this.props.numSelected === this.props.rowCount}
                            onChange={this.props.onSelectAllClick}
                            inputProps={{'aria-label': 'select all desserts'}}
                        />
                    </TableCell>

                    {this.props.columns.map((headCell: Column) => {
                        if (headCell.visible) {
                            return (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                >
                                    <TableSortLabel
                                        data-test-id={"TableHeadSortLabel_"+headCell.label}
                                        active={this.state.orderBy === headCell.id}
                                        direction={this.state.order}
                                        onClick={(event: any) => this.createSortHandler(event, headCell.id)}
                                    >
                                        {headCell.label}
                                        {this.state.orderBy === headCell.id ? (
                                            <span style={{display: "none"}}>
                  {this.state.order === 'desc' ? ' sorted descending' : ' sorted ascending'}
                </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            )
                        } else return null;
                    })}
                </TableRow>
            </StylesTableHead>
        );
    }
}

export default storeEnhancer(SortableTabTable);