import _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import styled from '../../utils/styled';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import {ApplicationState} from '../../store';
import {SortDirection} from '../../utils/enums';
import lightTheme from '../../styles/theme/light';
import Typography from '@material-ui/core/Typography';
import {fetchResults, fetchResultsDetails, resultAreNotReady} from '../../store/results/actions';
import brandColors from '../../styles/colors/brandColors';
import withStyles from '@material-ui/core/styles/withStyles';
import {FilterCardRow} from '../../components/layout/Card.tsx';
import ResultTable, {SimVehicleResult, VehicleResultView} from '../../components/Tables/ResultsTable';
import {GreenButton} from '../../components/layout/GreenButton';
import Statistic from '../../components/result_page_components/Statistic/Statistic';
import VehicleInformation from '../../components/result_page_components/VehicleInformation/VehicleInformation';
import LoadingModal from '../../components/Modals/LoadingModals';
import {TextMessage} from '../../components/result_page_components/VehicleInformation/styled';
import {setAnalysisNameAction} from '../../store/analysis/actions';
import DialogInput from '../../components/Modals/DialogInput';
import {Vehicle} from "../../helpData/get_vehicles_response_example";
import {AttributeValue} from "../../store/vehicles/types";
import {ICharging, IEvScores, IVehicle } from "../../helpData/getResults";
import {StyledGrid} from "../charging_sites/charging_sites";
import Page from "../../components/layout/Page";
import Container from "../../components/layout/Container";
import Compare from '../../components/result_page_components/Compare/Compare';
import { FusionTablesLayer } from 'react-google-maps';

interface IProps {
    fetchResults: typeof fetchResults
    fetchResultsDetails: typeof fetchResultsDetails
    resultsList: IVehicle[]
    chargingSites: ICharging[]
    selectedVehicles: VehicleResultView[]
    group_to_sim_vehicle_mapping: {[group_title: string]: Vehicle[]}
    loading: boolean
    history: any
    analysisName: string
    setAnalysisNameAction: typeof setAnalysisNameAction
    resultAreNotReady: typeof resultAreNotReady
    websocket_message: boolean
    simulation_uuid: string,
    vehicle_mapping: any
}

interface IState {
    currentTabIndex: number
    selectedVehicle: any
    selectedVehicles: VehicleResultView[]
    selectedElectricCar: any
    sortDirection: SortDirection
}

export function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, className, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            className={className}
            aria-labelledby={`simple-tab-${index}`}
            style={{minWidth: '100%'}}
            {...other}
        >
            {children}
        </Typography>
    );
}

class ResultPage extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        let selectedVehicle = null;
        let selectedElectricCar = null;
        
        if (!_.isEmpty(props.selectedVehicles)) {
            selectedVehicle = props.selectedVehicles[0];
            if (selectedVehicle.ev_scores != null)
                selectedElectricCar = selectedVehicle.ev_scores[0];
        }

        let websocket_message = this.props.websocket_message;
        if (websocket_message && websocket_message) {
            this.props.resultAreNotReady(true);
        } else {
            selectedVehicle !== null
                ? this.props.fetchResults(selectedVehicle.vehicle_id)
                : this.props.fetchResults();
        }

        this.state = {
            currentTabIndex: 0,
            selectedVehicle,
            selectedVehicles: this.props.selectedVehicles,
            sortDirection: SortDirection.DESC,
            selectedElectricCar
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        if (this.props.resultsList !== prevProps.resultsList) {
            let websocket_message = this.props.websocket_message;

            if (!websocket_message) {
                let selectedVehicle = null;
                if (!this.state.selectedVehicle && this.props.selectedVehicles && this.props.selectedVehicles.length > 0) {
                    selectedVehicle = this.props.selectedVehicles[0];
                    this.props.fetchResultsDetails(selectedVehicle.vehicle_id);
                }
                this.setState({
                    selectedVehicle: this.props.selectedVehicles.find((el: any) => {
                        return el.vehicle_id === this.state.selectedVehicle.vehicle_id;
                    }),
                    selectedVehicles: this.props.selectedVehicles,
                });
            }
        }
        if (this.props.websocket_message !== prevProps.websocket_message) {
            if (this.props.websocket_message)
                this.props.resultAreNotReady(this.props.websocket_message);

            let websocket_message = this.props.websocket_message;
            if (!websocket_message)
                this.props.fetchResults();
        }
    }

    handleShareButtonClicked = () =>
        this.props.history.push('/fleet-analysis/share');
    handleChangeTab = (event: React.ChangeEvent<{}>, tabIndex: number) =>
        this.setState({currentTabIndex: tabIndex});
    handleChangeElectricCar = (selectedElectricCar: any) =>
        this.setState({selectedElectricCar});

    handleChangeVehicle = (vehicle: VehicleResultView) => {
        this.setState({
            selectedVehicle: vehicle,
            selectedElectricCar: vehicle.ev_scores[0]
        });

        this.props.fetchResultsDetails(vehicle.vehicle_id);
    }

    handleSortDirectionChange = () =>
        this.setState({sortDirection: this.state.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC});

    renderShareButton() {
        const {selectedVehicles, group_to_sim_vehicle_mapping} = this.props;
        if (!_.isEmpty(selectedVehicles) && !_.isEmpty(group_to_sim_vehicle_mapping)) {
            return (
                <GreenButton
                    onClick={this.handleShareButtonClicked}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained">
                    Share Result
                </GreenButton>
            );
        }
    }

    renderStatistic() {
        const {group_to_sim_vehicle_mapping, history, chargingSites} = this.props;
        const {sortDirection, selectedVehicle, selectedElectricCar} = this.state;

        console.log(selectedVehicle)
        
        if (_.isEmpty(this.props.selectedVehicles)) {
            return (
                <TextMessage>
                    Vehicles was not selected in Filter Vehicles tab.
                </TextMessage>
            )
        } else if (_.isEmpty(group_to_sim_vehicle_mapping) || !selectedVehicle.ev_scores || _.isEmpty(selectedVehicle.ev_scores)) {
            return (
                <TextMessage>
                    Replacement vehicles were not selected in <span onClick={() => {
                    history.push('/fleet-analysis/replacement')
                }}>Select replacement</span> tab
                </TextMessage>
            );
        } else if (_.isEmpty(selectedVehicle.driving_days)) {
            return (
                <TextMessage>
                    Nothing to display
                </TextMessage>
            );
        }
        return (
            <Statistic
                allDrivingDays={selectedVehicle.driving_days}
                drivingDays={selectedVehicle.driving_days}
                sortDirection={sortDirection}
                onSortDirectionChange={this.handleSortDirectionChange}
                onSelectChange={this.handleChangeElectricCar}
                selectedElectricCar={selectedElectricCar || selectedVehicle.ev_scores[0]}
                electric={selectedVehicle.ev_scores}
                charging={chargingSites}/>
        )
    }

    renderCompare() {
        const {group_to_sim_vehicle_mapping, history} = this.props;
        const {sortDirection, selectedVehicle, selectedElectricCar} = this.state;

        if (_.isEmpty(this.props.selectedVehicles)) {
            return (
                <TextMessage>
                    Vehicles was not selected in Filter Vehicles tab.
                </TextMessage>
            )
        }
        return (
            <Compare
                replacementVehicles={group_to_sim_vehicle_mapping}
                selectedVehicle={selectedVehicle}
                history={history}
            />
        )
    }

    render() {
        let {loading, history, websocket_message, resultsList, group_to_sim_vehicle_mapping} = this.props;
        let {selectedVehicle, selectedVehicles, currentTabIndex} = this.state;

        let load = websocket_message || loading;

        //Redirect to home page if there is no analysis selected
        if (this.props.analysisName === '')
            this.props.history.push('/')

        if (selectedVehicle) {
            console.log("selected vehicle is")
            console.log(selectedVehicle)
        }
        return (
            <Page style={{maxHeight: '100%'}}>
                {load && (<LoadingModal open={load}/>)}

                <Container style={{minWidth: '100%', maxHeight: '100%'}}>
                    <StyledGrid display="grid" style={{maxHeight: '100%'}}>

                        <Paper style={{maxHeight: '100%', overflowY: 'auto', marginBottom: '10px'}}>
                            <div className={"card-container"}>
                                <FilterCardRow>
                                    <Typography color="inherit" variant="h5">
                                        Result for: <b>{selectedVehicles.length} vehicles</b>
                                    </Typography>
                                </FilterCardRow>
                                <ResultTable
                                    history={history}
                                    handleChangeVehicle={this.handleChangeVehicle}
                                    selectedVehicle={selectedVehicle}
                                    items={selectedVehicles}/>
                            </div>
                        </Paper>
                        <Paper style={{height: 'min-content', minWidth: '100%', overflowX: 'auto', marginBottom: '10px'}}>
                            <div className={"card-container"}>
                                <FilterCardRow>
                                    {this.renderShareButton()}
                                </FilterCardRow>
                                <ResultTabsContainer>
                                    <ResultTabs variant="fullWidth" value={currentTabIndex}
                                                onChange={this.handleChangeTab}
                                                aria-label="tabs">
                                        <Tab label="Vehicle Information" {...a11yProps(0)} />
                                        <Tab label="EV Insights" {...a11yProps(1)} />
                                        <Tab label="Compare" {...a11yProps(2)} />
                                    </ResultTabs>
                                    <TabPanel value={currentTabIndex} index={0}>
                                        <VehicleInformation
                                            history={history}
                                            attributes={selectedVehicle ? selectedVehicle.attributes : []}
                                            selectedVehicle={selectedVehicle ? selectedVehicle : null}/>
                                    </TabPanel>
                                    <TabPanel value={currentTabIndex} index={1}>
                                        {this.renderStatistic()}
                                    </TabPanel>
                                    <TabPanel value={currentTabIndex} index={2}>
                                        {this.renderCompare()}
                                    </TabPanel>
                                </ResultTabsContainer>
                            </div>
                        </Paper>
                    </StyledGrid>
                </Container>
                {/*
                <DialogInput
                    key={this.props.analysisName}
                    handleClose={() => this.props.history.push('/')}
                    isVisible={this.props.analysisName === ''}
                    onSubmitClicked={this.props.setAnalysisNameAction}
                    negativeBtnName="Cancel"
                    positiveBtnName="Go next"
                    title="Create new analysis"/>
                */}
            </Page>)
    }
}

export const mapStateToProps = ({resultsPage, chargingPage, vehicle, homePage}: ApplicationState) => {
    // @ts-ignore
    let selectedVehicles: VehicleResultView[] = [];
    if (vehicle.selected_vehicles) {
        selectedVehicles = vehicle.selected_vehicles.map((v: Vehicle) => {
            const description_attribute = v.attributes.find((a: AttributeValue) => a.name === 'Description');
            const description = description_attribute ? description_attribute.value : '';
            const attributes = v.attributes;

            if (resultsPage.resultsList && resultsPage.resultsList.length) {
                const foundVehicle = resultsPage.resultsList.find((rl: IVehicle) => rl.vehicle_id === v.vehicle_id);
                if (foundVehicle) {
                    const mapped: SimVehicleResult[] = foundVehicle.ev_scores.map((evScore: IEvScores) => {
                        // @ts-ignore

                        var svs: Vehicle| undefined;
                        Object.keys(vehicle.group_to_sim_vehicle_mapping).forEach((key) => {
                            const v: Vehicle | undefined = vehicle.group_to_sim_vehicle_mapping[key].find((vehicle: Vehicle) => evScore.sim_vehicle_id === vehicle.vehicle_id);
                            if (v) {
                                svs = v;
                            }
                        });
                        let model = 'N/A';
                        let make = 'N/A';
                        if (svs) {
                            const model_attribute: AttributeValue | undefined = svs.attributes.find((a: AttributeValue) => a.name === 'Model');
                            model = model_attribute ? model_attribute.value : 'N/A';
                            const make_attribute: AttributeValue | undefined = svs.attributes.find((a: AttributeValue) => a.name === 'Make');
                            make = make_attribute ? make_attribute.value : 'N/A';
                        }

                        return {
                            model,
                            make,
                            ev_score: evScore.ev_score,
                            sim_vehicle_id: evScore.sim_vehicle_id,
                            num_days_failed: evScore.num_days_failed,
                            num_days: evScore.num_days
                        };
                    });

                    mapped.sort(((a: SimVehicleResult, b: SimVehicleResult) => b.ev_score - a.ev_score));
                    const best = mapped[0];
                    const worst = mapped.length > 1 ? mapped[mapped.length - 1] : undefined;
                    return {
                        best,
                        worst,
                        description,
                        attributes,
                        ev_scores: mapped,
                        vehicle_id: v.vehicle_id,
                        telematics_id: v.telematics_id,
                        average_distance: v.average_distance,
                        total_distance: v.total_distance,
                        driving_days: foundVehicle.driving_days,
                        annual_distance: foundVehicle.annual_distance
                    };
                }

                return {
                    description,
                    attributes,
                    ev_scores: [],
                    vehicle_id: v.vehicle_id,
                    telematics_id: v.telematics_id,
                    average_distance: v.average_distance,
                    total_distance: v.total_distance,
                    annual_distance: null
                };
            } else {
                return {
                    description,
                    attributes,
                    ev_scores: [],
                    vehicle_id: v.vehicle_id,
                    telematics_id: v.telematics_id,
                    average_distance: v.average_distance,
                    total_distance: v.total_distance,
                    annual_distance: null
                }
            }
        });
    }

    return {
        resultsList: resultsPage.resultsList,
        chargingSites: resultsPage.chargingSites,
        selectedVehicles,
        group_to_sim_vehicle_mapping: vehicle.group_to_sim_vehicle_mapping,
        loading: resultsPage.loading,
        errors: resultsPage.errors,
        analysisName: homePage.analysisName,
        websocket_message: resultsPage.websocket_message,
        simulation_uuid: chargingPage.simulation_uuid,
        vehicle_mapping: vehicle.vehicle_mapping,
    }
};

const mapDispatchToProps = {
    fetchResults,
    fetchResultsDetails,
    setAnalysisNameAction,
    resultAreNotReady
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultPage);

export const ResultTabsContainer = styled('div')`
    display: block;
    width: 100%;
    overflow: hidden;
`;

const StyledPaper = withStyles({
    root: {
        minWidth: '300px',
        maxWidth: '360px',
    }
})(Paper);

const StyledFlexPaper = withStyles({
    root: {
        flex: 1,
        marginLeft: '1.5rem'
    }
})(Paper);

export const ResultTabs = withStyles({
    root: {
        borderBottom: `1px solid ${lightTheme.colors.borders}`,
        '& button[id^="simple-tab-"]:hover': {
            color: brandColors.kashmir_blue,
            background: '#74b29133'
        },
        '& .Mui-selected .MuiTab-wrapper': {
            color: lightTheme.colors.regular,
        },
        '& .MuiTabs-flexContainer': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr',
        },
    },
    indicator: {
        color: lightTheme.colors.regular,
        backgroundColor: lightTheme.colors.regular,
    },
})(Tabs);

export const OutOfRangeTabs = withStyles({
    root: {
        borderBottom: `1px solid ${lightTheme.colors.regular}`,
        '& button[id^="simple-tab-"]:hover': {
            color: brandColors.kashmir_blue,
            background: '#74b29133'
        },
        '& .Mui-selected .MuiTab-wrapper': {
            color: lightTheme.colors.regular,
        },
        '& .MuiTabs-flexContainer': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
        },
        '& .MuiTab-root': {
            minWidth: '124px'
        }
    },
    indicator: {
        color: lightTheme.colors.regular,
        backgroundColor: lightTheme.colors.regular,
    },
})(Tabs);
