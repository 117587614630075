import React from "react";
import {KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MuiThemeProvider} from '@material-ui/core/styles'
import {createMuiTheme} from '@material-ui/core/styles';
import brandColors from "../../styles/colors/brandColors";
import withStyles from "@material-ui/core/styles/withStyles";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import {convertUTCDateToString} from "../../helpData/Date";

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: brandColors.crispedge,
            light: brandColors.crispedge,
            dark: brandColors.crispedge,
        },
        secondary: {
            main: '#81c784',
            light: '#81c784',
            dark: '#81c784',
        },
    },
});
const theme = {
    ...materialTheme
    // custom styles
};

interface AllProps {
    start_date: any,
    end_date: any,
    handleDateChange: (selected_type: string, selected_value: any) => void;
}

interface AllStates {
    selectedStartDate: any,
    selectedEndDate: any,
}

class InlineDatePickerDemo extends React.Component<AllProps, AllStates> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            selectedStartDate: this.props.start_date,
            selectedEndDate: this.props.end_date,
        };
    }
    componentDidUpdate(prevProps: AllProps, prevState: AllStates, snapshot: any) {
        if (prevProps.start_date !== this.props.start_date) {
            this.setState({selectedStartDate: this.props.start_date})
        }
        if (prevProps.end_date !== this.props.end_date) {
            this.setState({selectedEndDate: this.props.end_date})
        }
    }

    handleDateChange(date: any, inputAdornmentProps: string) {
        inputAdornmentProps === 'start_date' ? this.setState({selectedStartDate: date}) : this.setState({selectedEndDate: date});

        date !== null && this.props.handleDateChange(inputAdornmentProps, convertUTCDateToString(date));
    }

    render() {

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={theme}>
                    <StyledKeyboardDatePicker
                        autoOk
                        data-test-id={"start_date"}
                        id={"start_date"}
                        variant="inline"
                        inputVariant="outlined"
                        placeholder={"Start Date"}
                        format="yyyy-MM-dd"
                        strictCompareDates
                        animateYearScrolling
                        maxDate={this.state.selectedEndDate}
                        value={this.state.selectedStartDate}
                        InputAdornmentProps={{position: "start"}}
                        onChange={date => this.handleDateChange(date, 'start_date')}
                    />
                    <TrendingFlatIcon color="disabled" style={{margin: '0 5px'}}/>
                    <StyledKeyboardDatePicker
                        autoOk
                        data-test-id={"end_date"}
                        id={"end_date"}
                        variant="inline"
                        inputVariant="outlined"
                        placeholder={"End Date"}
                        format="yyyy-MM-dd"
                        strictCompareDates
                        animateYearScrolling
                        minDate={this.state.selectedStartDate}
                        value={this.state.selectedEndDate}
                        InputAdornmentProps={{position: "start"}}
                        onChange={date => this.handleDateChange(date, 'end_date')}
                    />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

const StyledKeyboardDatePicker = withStyles({
    root: {
        padding: '0',
        height: '40px',
        width: '150px',
        '& .MuiOutlinedInput-root': {
            maxHeight: '100%',
            paddingLeft: '0'
        },
        '& .MuiIconButton-root': {
            padding: '10px'
        },
        '& .MuiFormHelperText-contained': {
            margin: '0'
        }
    },
})((props: KeyboardDatePickerProps) => (
    <KeyboardDatePicker
        {...props}
    />
));

export default InlineDatePickerDemo;