// This file holds our state type, as well as any other types related to this Redux store.


// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
import {AnalysisResultFull} from "../results/types";

export enum AnalysisActionTypes {
  FETCH_ANALYSIS_RESULT_REQUEST = '@@auth/FETCH_ANALYSIS_RESULT_REQUEST',
  FETCH_ANALYSIS_REQUEST = '@@auth/FETCH_ANALYSIS_REQUEST',
  FETCH_ANALYSIS_REQUEST_SUCCESS = '@@auth/FETCH_ANALYSIS_REQUEST_SUCCESS',
  FETCH_ANALYSIS_REQUEST_ERROR = '@@auth/FETCH_ANALYSIS_REQUEST_ERROR',
  SET_ANALYSIS_NAME = '@@auth/SET_ANALYSIS_NAME',
  DELETE_ANALYSIS_REQUEST = '@@auth/DELETE_ANALYSIS_REQUEST',
  DELETE_ANALYSIS_REQUEST_ERROR = '@@auth/DELETE_ANALYSIS_REQUEST_ERROR',
  DELETE_ANALYSIS_REQUEST_SUCCESS = '@@auth/DELETE_ANALYSIS_REQUEST_SUCCESS',
  UNSHARE_ANALYSIS_REQUEST = '@@auth/UNSHARE_ANALYSIS_REQUEST',
  UNSHARE_ANALYSIS_REQUEST_SUCCESS = '@@auth/UNSHARE_ANALYSIS_REQUEST_SUCCESS',
  UNSHARE_ANALYSIS_REQUEST_ERROR = '@@auth/UNSHARE_ANALYSIS_REQUEST_ERROR',
  RESET_STATE = '@@auth/RESET_STATE',
  RESTORE = '@@auth/RESTORE',

}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface AnalysisState {
  loading: boolean;
  errors?: string;
  analysisList: AnalysisResultFull[];
  analysisName: string
}
