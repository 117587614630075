import {call, put, takeLatest} from 'redux-saga/effects'
import { ChargingActionTypes } from './types'
import * as A from './actions'
import { callApi } from '../../utils/api'
import {setSelectedLocations} from "./actions";
import * as R from '../results/actions';

function* fetchLocaleRequest(action: ReturnType<typeof A.fetchLocaleRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res = yield call(callApi, 'get', '/locations/');
    if (res.error) {
      yield put(A.fetchLocaleError(res.error))
    } else {
      yield put(A.fetchLocaleSuccess(res))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(A.fetchLocaleError(err.stack))
    } else {
      yield put(A.fetchLocaleError('An unknown error occured.'))
    }
  }
}
function* fetchPostRunSimulationRequest(action: ReturnType<typeof A.fetchPostSimulationRequest>) {
  try {
    let hash;
    // To call async functions, use redux-saga's `call()`.
    hash = action.payload.simulation_uuid;
    const res = yield call(callApi, 'post', '/results/' + hash + '/', action.payload.body);
    if (res.error) {
      yield put(A.fetchPostSimulationError(res.error))
    } else {
      const res_2 = yield put(R.fetchResults());

      if (res_2.error) {
        yield put(A.fetchPostSimulationError(res_2.error))
      } else {
          yield put(A.fetchPostSimulationSuccess(res_2))
      }
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(A.fetchPostSimulationError(err.stack))
    } else {
      yield put(A.fetchPostSimulationError('An unknown error occured.'))
    }
  }
}

function* fetchPutLocaleRequest(action: ReturnType<typeof A.fetchLocaleRequest>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res = yield call(callApi, 'put', '/locations');
    if (res.error) {
      yield put(A.fetchLocaleError(res.error))
    } else {
      yield put(A.fetchLocaleSuccess(res))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(A.fetchLocaleError(err.stack))
    } else {
      yield put(A.fetchLocaleError('An unknown error occured.'))
    }
  }
}

function handleSetSelectedLocations(action: ReturnType<typeof setSelectedLocations>) {
  setSelectedLocations(action.payload);
}

function* chargingPageWatcherSaga() {
  yield takeLatest(ChargingActionTypes.FETCH_LOCALE_REQUEST, fetchLocaleRequest);
  yield takeLatest(ChargingActionTypes.FETCH_PUT_LOCALE_REQUEST, fetchPutLocaleRequest);
  yield takeLatest(ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST, fetchPostRunSimulationRequest);
  yield takeLatest(ChargingActionTypes.SET_SELECTED_LOCATIONS, handleSetSelectedLocations);
  // yield takeLatest(ChargingActionTypes.SET_SIMULATION_UUID, handleSetSimulationUuid);

}

export default chargingPageWatcherSaga;
