import styled from '../../utils/styled'

const Page = styled('div')`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  padding: ${props => props.theme.containerPadding};
  
  &.displayNone {
    display: none;
  }
`

export default Page
