import * as React from "react";
import {init, RootStore, thunkAction, unboxThunk} from "../../redux";
import {connect} from "react-redux";

import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import Typography from "@material-ui/core/Typography";
import styled from 'styled-components';
import {CssTextField, StyledAutocomplete} from "../layout/Autocomplete";
import {FilterCardRow} from "../layout/Card.tsx";
import {Vehicle} from "../../helpData/get_vehicles_response_example";
import CustomFilter from "../FilterVehicles/CustomFilter";

import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {TransitionProps} from "@material-ui/core/transitions";
import CloseIcon from '@material-ui/icons/Close';
import SimVehiclesTable from "../Tables/SimVehiclesTable";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import brandColors from "../../styles/colors/brandColors";
import _ from "lodash";
import {uniqueVehicleArray} from "../../pages/select_replacement/group_vehicles_by_attribute";

// @ts-ignore
import AddCar from './AddCarPage/AddCarComponent'

// const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const StyleAppBar = styled(AppBar)`
    &.MuiAppBar-colorPrimary {
        background-color: ${brandColors.crispedge};
        position: relative;
    }
    > div {
        min-height: 55px;
        display: flex;
        justify-content: space-between;
     }
`;

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
        init,
        thunkAction: unboxThunk(thunkAction),
    }
);

interface IProps {
    value: string,
    suggestedSimVehicles: SimVehicleSuggest[],
    attributes: any,
    sim_vehicles: Vehicle[],
    selectedSimVehicleSuggest: SimVehicleSuggest[],
    sim_vehicles_popup: boolean,
    groupkey: string
    group_name: string,

    addSimVehicleSuggest: (sim_vehicle_id: number, selected_vehicles_ids: number[], selectedSimVehicleSuggest: any) => void;
    handleChange: (event: any, selected_value?: any) => void;
    handleUpdateValue: (value: string, options: SimVehicleSuggest[]) => void;
    handleClose: () => void;
    handleSubmit: (groupkey: string, selected_sim_vehicles: Vehicle[]) => void;

    onAttributesChanged: (attributes: []) => void;

    is_loading: boolean;
    setSelectedSimVehicles: (vehicles: Vehicle[]) => void;
}


interface IState {
    suggested_sim_vehicles: SimVehicleSuggest[],
    selected_attributes_ids: number[],
    selected_attributes_values: any
    groupkey: string
}


class DialogAddSimVehicle extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.removeVehicle = this.removeVehicle.bind(this);
        this.removeSimVehicleSuggested = this.removeSimVehicleSuggested.bind(this);
        this.handleSuggestSelection = this.handleSuggestSelection.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onRemoveAttribute = this.onRemoveAttribute.bind(this);
        this.onApplyNewFilter = this.onApplyNewFilter.bind(this);
        this.onFilterMenuChange = this.onFilterMenuChange.bind(this);

        this.state = {
            selected_attributes_ids: [1, 2, 3, 4, 5, 6, 7],
            selected_attributes_values: [],
            suggested_sim_vehicles: [],
            groupkey: this.props.groupkey
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (!_.isEqual(this.props.groupkey, prevProps.groupkey)) {

            this.setState({
                groupkey: this.props.groupkey,
            })
        }
    }

    removeVehicle(event?: any, id?: number, type?: 'filter' | 'keyword') {
        const v = this.props.sim_vehicles.filter((s: Vehicle) => !(s.vehicle_id === id));
        this.props.setSelectedSimVehicles(v);
    }

    removeSimVehicleSuggested(event?: any, id?: number, type?: 'filter' | 'keyword') {
        this.setState(prevState =>({
            suggested_sim_vehicles: prevState.suggested_sim_vehicles.filter((s: SimVehicleSuggest) => !(s.sim_vehicle_id === id))
        }));
    }

    handleSuggestSelection(event: any, value: SimVehicleSuggest) {
        if (value) {
            this.setState(prevState => {
                let a = [...prevState.suggested_sim_vehicles, value];
                let newA = uniqueVehicleArray(a);

                return ({
                    suggested_sim_vehicles: newA
                })
            });
            this.props.handleUpdateValue(this.props.value, this.props.suggestedSimVehicles);
        } else {
            this.props.handleUpdateValue("", []);
        }
    }

    handleSubmit() {
        const converted = sim_to_vehicles(this.state.suggested_sim_vehicles);
        this.props.handleSubmit(this.props.groupkey, [...this.props.sim_vehicles, ...converted]);
        this.props.handleUpdateValue("", []);
        this.props.handleClose();
    }

    onApplyNewFilter(attribute: any) {
        let attribute_values = this.state.selected_attributes_values.map((a:any) => {
            if (a.attribute_id === attribute.attribute_id) {
                return attribute;
            } else {
                return a;
            }
        });

        if (!attribute_values.find((a: any) => a.attribute_id === attribute.attribute_id)) {
            attribute_values.push(attribute);
        }

        attribute_values = attribute_values.filter((a: any) => a.value.length > 0);

        this.setState(prevState => ({
            ...prevState,
            selected_attributes_values: attribute_values
        }));

        const attributes = attribute_values.filter((a: any) => this.state.selected_attributes_ids.includes(a.attribute_id));

        this.props.onAttributesChanged(attributes);
    }

    onRemoveAttribute(event: any, attribute: any) {

        const updated_list_of_attributes: any = this.state.selected_attributes_ids.filter((a: any) => !(a===attribute.attribute_id));

        this.setState(prevState => ({
            ...prevState,
            selected_attributes_ids: updated_list_of_attributes
        }));

        const attributes = this.state.selected_attributes_values.filter((a: any) => this.state.selected_attributes_ids.includes(a.attribute_id));

        this.props.onAttributesChanged(attributes)
    }

    onFilterMenuChange(attribute: any) {

        let updated_list_of_attributes_ids: any;
        const attr_id = attribute.attribute_id;
        if (this.state.selected_attributes_ids.includes(attr_id)) {
            updated_list_of_attributes_ids = this.state.selected_attributes_ids.filter((a: any) => !(a===attr_id));
        } else {
            updated_list_of_attributes_ids = [...this.state.selected_attributes_ids, attr_id];
        }

        this.setState(prevState => ({
            ...prevState,
            selected_attributes_ids: updated_list_of_attributes_ids
        }));

    }

    render() {
        
        return (
            <Dialog
                fullScreen
                //TransitionComponent={Transition}
                open={this.props.sim_vehicles_popup}
                onClose={this.props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >

                <StyleAppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.handleClose}
                                    aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            Add sim vehicles {this.props.group_name !== '' ? ' for ' + this.props.group_name + ' group' : ''}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={this.handleSubmit}>
                            Submit
                        </Button>
                    </Toolbar>
                </StyleAppBar>
                <DialogContent>
                    {/* <Button onClick={(event: any) => this.handleSuggestSelection(event, {
                        "sim_vehicle_id": 62082,
                        "image_url": "https://myfleetbuy-storage.s3.amazonaws.com/media/car_pics/2020_Chevy_Bolt_EV.jpg",
                        "make": "Chevrolet",
                        "model": "Bolt EV",
                        "year": "2020",
                        "epa_range": "259.0"
                    })}>
                        Add test
                    </Button>

                    <Button onClick={(event: any) => this.removeSimVehicleSuggested(event, 62082, "filter")}>
                        remove test
                    </Button> */}

                    <div>
                        {React.createElement(AddCar, {
                            addSimVehicle: this.handleSuggestSelection, 
                            removeSimVehicle: this.removeSimVehicleSuggested,
                            sim_vehicles: this.state.suggested_sim_vehicles
                        })}
                    </div>
                    {/* <div>
                        <FilterCardRow key={"FilterCardRow_1"}>
                            <Typography color="inherit" variant="h6">
                                Search by custom filters:
                            </Typography>
                            {
                                this.props.attributes &&
                                this.props.attributes
                                    .filter((attribute: any) => this.state.selected_attributes_ids.includes(attribute.attribute_id))
                                    .map((attribute: any, i: number) => {
                                        return (<CustomFilter key={i}
                                                               attributes_type={"simvehicles"}
                                                               applyNewFilter={this.onApplyNewFilter}
                                                               removeAttribute={this.onRemoveAttribute}
                                                               removable={false}
                                                               attribute={attribute}/>)
                                    })
                            }
                        </FilterCardRow>
                    </div>

                    <SimVehiclesTable
                        dataTestId={"TableFilterSimVehicleSuggest"}
                        key={"TableFilterSimVehicleSuggest"}
                        data={this.props.sim_vehicles}
                        attributes={this.props.attributes}
                        type_table={"filter"}
                        handleRemove={this.removeVehicle}/>

                    <FilterCardRow>
                         <Typography color="inherit" variant="h6"> */}
                            {/* This is to prevent losing of focus when typing the first letter of the span.*/}
                            {/* <span className={"invisible-block"}>_</span>
                                Search by keyword:
                         </Typography>

                        <StyledAutocomplete
                            key={"AutocompleteSimVehicleSuggest"}
                            options={this.props.suggestedSimVehicles}
                            style={{padding: 0, margin: 0, width: '100%'}}
                            autoHighlight
                            disabled={false}
                            getOptionLabel={(o: SimVehicleSuggest) => this.props.value}
                            renderOption={(o: SimVehicleSuggest) => {
                                            return (
                                                <React.Fragment>{o ?  o.year + " " + o.make + " " + o.model : ''}</React.Fragment>
                                            )
                                        }}
                            onOpen={(event: any) => this.props.handleChange(event, this.props.value)}
                            onChange={(event: any, value: any) => this.handleSuggestSelection(event, value) }
                            renderInput={params => (
                                <CssTextField {...params} variant="outlined" style={{padding: 0, margin: 0}}
                                              placeholder={"Start typing vehicle name"}
                                              value={this.props.value}
                                              onChange={(event: any) => this.props.handleChange(event)}
                                              fullWidth/>
                            )}
                        />
                    </FilterCardRow>
                    <SimVehiclesTable
                        dataTestId={"TableKeywordSimVehicleSuggest"}
                        key={"TableKeywordSimVehicleSuggest"}
                        data={this.state.suggested_sim_vehicles}
                        type_table={"keyword"}
                        handleRemove={this.removeSimVehicleSuggested}/> */}
                </DialogContent>
            </Dialog>
        )
    }
}

export default storeEnhancer(DialogAddSimVehicle);

export function sim_to_vehicles(suggested_sim_vehicles: SimVehicleSuggest[]): Vehicle[] {
    return suggested_sim_vehicles.map((s: SimVehicleSuggest) =>
    {
        return {
            "telematics_id": s.sim_vehicle_id.toString(),
            "vehicle_id": s.sim_vehicle_id,
            "sim_vehicle": s,
            "attributes": [
                {
                    "name": "Model Year",
                    "value": s.year,
                    "attribute_id": 3
                },
                {
                    "name": "Model",
                    "value": s.model,
                    "attribute_id": 2
                },
                {
                    "name": "Make",
                    "value": s.make,
                    "attribute_id": 1
                },
                {
                    "name": "EPA Range",
                    "attribute_id": 7,
                    "value": s.epa_range || "0"
                }
            ],
            "total_distance": 0.0,
            "average_distance": 0.0
        }
    });
}