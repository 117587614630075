const uuidv1 = require('uuid/v1');

//Generate string UUID with fully-specified options
//
//
// options - (Object) Optional uuid state to apply. Properties may include:
//
// node - (Array) Node id as Array of 6 bytes (per 4.1.6). Default: Randomly generated ID. See note 1.
// clockseq - (Number between 0 - 0x3fff) RFC clock sequence. Default: An internally maintained clockseq is used.
// msecs - (Number) Time in milliseconds since unix Epoch. Default: The current time is used.
// nsecs - (Number between 0-9999) additional time, in 100-nanosecond units. Ignored if msecs is unspecified. Default: internal uuid counter is used, as per 4.2.1.2.
//
//
export function GenerateStringUUIDWithOptions() {
    // const v1options = {
    //     node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
    //     clockseq: 0x1234,
    //     msecs: new Date().getTime(),
    //     nsecs: 5678
    // };
    return uuidv1();
}