import { action } from 'typesafe-actions'
import {AuthActionTypes} from "./types";

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

export const fetchAuth = (data:  {
            username: string,
            password: string
        }) => action(AuthActionTypes.FETCH_AUTH, data);

// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const fetchAuthSuccess = (result: {token: string}) => action(AuthActionTypes.FETCH_AUTH_SUCCESS, result)
export const fetchAuthError = (message: string) => action(AuthActionTypes.FETCH_AUTH_ERROR, message)

export const redirectFromLogin = (redirect_from_login: boolean) => action(AuthActionTypes.REDIRECT_FROM_LOGIN, redirect_from_login)

