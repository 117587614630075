import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {ApplicationState} from "../../../store";

import MapView from '../Map/MapView';
import Chart from '../Chart/Chart';
import {sort} from '../../../utils/helpers';
import {StatisticContainer} from './styled';
import ResultCarTab from '../ResultCarTab/ResultCarTab';
import OutOfRangeList from '../OutOfRangeList/OutOfRangeList';
import {
    a11yProps, OutOfRangeTabs,
    TabPanel
} from "../../../pages/result_page/result_page";
import Tab from "@material-ui/core/Tab";
import moment from 'moment';
import { fetchRoutePositions } from '../../../store/results/actions';

const MILE = 0.000621;
export interface IChartData {
    battery_status: string,
    charged_for: number,
    sim_vehicle_id: number,
    start_date: string,
    _color: string,
}

export interface PropsFromState {
    route_positions: any
}

function Statistic(props: any) {
    const [selectedRoute, setSelectedRoute] = useState(props.drivingDays[0].driving_routes);
    const [selectedOneRoute, setSelectedOneRoute] = useState(null);
    const [centerCoords, setCenterCoords] = useState({
        lng: props.drivingDays[0].driving_routes[0].start_longitude,
        lat: props.drivingDays[0].driving_routes[0].start_latitude
    });
    const [selectedRange, setSelectedRange] = useState({from: '', to: ''});
    const [currentTabIndexOutOfRangeList, setCurrentTabIndexOutOfRangeList] = useState(0);
    const {drivingDays, allDrivingDays, selectedElectricCar, sortDirection, electric, onSelectChange, onSortDirectionChange} = props;
    const [startIndexChart, setStartIndexChart] = useState(0);
    const [endIndexChart, setEndIndexChart] = useState(props.allDrivingDays.length - 1);
    const [expanded, setExpanded] = React.useState<number | false>(allDrivingDays.length > 0 ? 0 : false);
    const route_positions = props.route_positions;

    useEffect(() => {
        //Get the initial route
        setSelectedRoute(props.drivingDays[0].driving_routes);

        //We meed date and vehicle id to fetch position
        let date = moment(props.drivingDays[0].driving_routes[0].start_time).format('YYYY-MM-DD');
        let vehicle_id = props.drivingDays[0].driving_routes[0].vehicle_id;

        props.fetchRoutePositions(date, vehicle_id)

        setCenterCoords({
            lng: props.drivingDays[0].driving_routes[0].start_longitude,
            lat: props.drivingDays[0].driving_routes[0].start_latitude
        });
    }, [allDrivingDays]);

    useEffect(() => {
        setInitialIndexChart();
        handleChangeTab({}, 0);
    }, [allDrivingDays]);

    useEffect(() => {
        // For Out Of Range
        if((currentTabIndexOutOfRangeList === 1) && (selectedRange.from !== '' && selectedRange.to !== '')) {
            let data = normalizeOutOfRangeList(false, selectedRange);

            if(data.length > 0) {
                setSelectedRoute(data[0].routes);
                setExpanded(0);
            }
        }
    }, [currentTabIndexOutOfRangeList, selectedRange]);

    const handleChangeRange = (range: { from: string, to: string }, index?: { startIndex: number, endIndex: number }) => {
        setSelectedRange({from: range.from, to: range.to});

        if (index) {
            setStartIndexChart(index.startIndex);
            setEndIndexChart(index.endIndex);
        }
        // Don't change tabs for now.
        // handleChangeTab({}, 1);
    };
    const setInitialIndexChart = () => {
        setStartIndexChart(0);
        setEndIndexChart(props.allDrivingDays.length - 1);
    };
    const normalizeChartData = () => {
        const normalizedData = [];
        for (let i = 0; i < drivingDays.length; i++) {
            const foundElectricCar = drivingDays[i].sim_vehicle_results.find((el: any) => el.sim_vehicle_id === selectedElectricCar.sim_vehicle_id);
            if (foundElectricCar) {
                const percent = (foundElectricCar.battery_status * 100).toFixed(1);
                const data = {
                    start_date: drivingDays[i].start_date,
                    ...foundElectricCar,
                    // @ts-ignore
                    battery_status: percent < -25 ? -25 : percent,
                };
                normalizedData.push(data);
            }
        }
        return normalizedData;
    };
    const setBarFill = (data: IChartData[]) => {
        data
            .filter((i: IChartData) => i.battery_status !== 'NaN')
            .map((i: IChartData) => {
            +i.battery_status > 0
                ? i['_color'] = "#82ca9d"
                : i['_color'] = "#D81400"
        });

        return data;
    };
    const handleChangeTab = (event: any, tabIndex: number) => {
        setCurrentTabIndexOutOfRangeList(tabIndex);
        setSelectedRoute(props.drivingDays[0].driving_routes);
        setExpanded(0);
    };


    const _onSelectOneRoute = (route: any) => {
        (route && route.length && route[0].positions.length) ? setSelectedOneRoute(route[0].positions) : setSelectedOneRoute(route);
    };

    const _onSelectChange = (route: any) => {
        
        if (route !== null && route.length) {
            setCenterCoords({
                lng: route[0].start_longitude,
                lat: route[0].start_latitude
            });

            //Fetch real route positions
            console.log("route is")
            console.log(route) 
            
            //We meed date and vehicle id
            let date = moment(route[0].start_time).format('YYYY-MM-DD');
            let vehicle_id = route[0].vehicle_id;

            props.fetchRoutePositions(date, vehicle_id)
        }
        



        //setSelectedOneRoute(null);
        //setSelectedRoute(route);
    };

    const normalizeOutOfRangeList = (allDays_range: boolean, range?: { from: string, to: string }) => {
        let normalizedData = [];

        let _selectedRange = range ? range : selectedRange;
        let days = allDays_range ? allDrivingDays : drivingDays;


        let filteredDrivingDays = days.filter((drivingDay: any) => {
            if (_selectedRange.from && _selectedRange.to) {
                // @ts-ignore
                return Date.parse(_selectedRange.from).valueOf() <= Date.parse(drivingDay.start_date).valueOf() && Date.parse(_selectedRange.to).valueOf() >= Date.parse(drivingDay.start_date).valueOf();
            }
            return true;
        });

        for (let i = 0; i < filteredDrivingDays.length; i++) {
            let data;
            let foundData;
            allDays_range ? foundData = filteredDrivingDays[i].sim_vehicle_results.find((sv: any) => sv.sim_vehicle_id === selectedElectricCar.sim_vehicle_id) : foundData = filteredDrivingDays[i].sim_vehicle_results.find((sv: any) => sv.battery_status <= 0 && sv.sim_vehicle_id === selectedElectricCar.sim_vehicle_id)


            if (foundData && !allDays_range) {
                data = {
                    distance: Math.round(filteredDrivingDays[i].distance * MILE),
                    battery: ((foundData.battery_status) * 100).toFixed(1),
                    date: filteredDrivingDays[i].start_date,
                    routes: filteredDrivingDays[i].driving_routes
                };
                normalizedData.push(data);
            }
            if (allDays_range) {
                data = {
                    distance: Math.round(filteredDrivingDays[i].distance * MILE),
                    battery: foundData ? ((foundData.battery_status) * 100).toFixed(1) : 0,
                    date: filteredDrivingDays[i].start_date,
                    routes: filteredDrivingDays[i].driving_routes
                };
                normalizedData.push(data);
            }
        }
        return normalizedData;
    };

    return (
        <>
            <ResultCarTab
                selectedItem={selectedElectricCar}
                sortDirection={sortDirection}
                cars={sort(electric, 'ev_score', sortDirection)}
                onSelectChange={onSelectChange}
                onSortDirectionChange={onSortDirectionChange}/>

            <Chart startIndexChart={startIndexChart} endIndexChart={endIndexChart} data={setBarFill(normalizeChartData())}
                   handleChangeRange={handleChangeRange}/>

            <StatisticContainer>
                <div className={'ListPanel'}>
                    <OutOfRangeTabs value={currentTabIndexOutOfRangeList}
                                    onChange={(event: any, value: number) => handleChangeTab(event, value)}
                                    aria-label="tabs">
                        <Tab label="All days" {...a11yProps(0)} />
                        <Tab label="Out of range" {...a11yProps(1)} />
                    </OutOfRangeTabs>
                    <TabPanel value={currentTabIndexOutOfRangeList} index={0} className={'TabPanelRangeList'}>
                        <OutOfRangeList
                            electricCarId={selectedElectricCar.sim_vehicle_id}
                            onSelectOneRoute={(route: any) => _onSelectOneRoute(route)}
                            onSelectChange={(route: any) => _onSelectChange(route)}
                            setExpanded={setExpanded}
                            expanded={expanded}
                            data={normalizeOutOfRangeList(true, {from: '', to: ''})}/>
                    </TabPanel>
                    <TabPanel value={currentTabIndexOutOfRangeList} index={1} className={'TabPanelRangeList'}>
                        <OutOfRangeList
                            onSelectOneRoute={(route: any) => _onSelectOneRoute(route)}
                            electricCarId={selectedElectricCar.sim_vehicle_id}
                            onSelectChange={(route: any) => _onSelectChange(route)}
                            setExpanded={setExpanded}
                            expanded={expanded}
                            data={normalizeOutOfRangeList(false)}/>
                    </TabPanel>
                </div>
                <MapView
                    selectedRoute={selectedRoute}
                    selectedOneRoute={selectedOneRoute}
                    routePositions={route_positions}
                    charging={props.charging}
                    centerCoords={centerCoords}
                    zoom={19}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDT4vVqysfFZ6K431-6cTKxF2QN7P-avN4"
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `460px`, flex: 1}}/>}
                    mapElement={<div style={{height: `100%`}}/>}/>

            </StatisticContainer>
        </>
    );
}

const mapDispatchToProps = {
    fetchRoutePositions
};

export const mapStateToProps = ({resultsPage}: ApplicationState) => ({
    loading: resultsPage.loading,
    errors: resultsPage.errors,
    route_positions: resultsPage.route_positions
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);
