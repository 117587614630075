import React, {PureComponent} from 'react';
import {
    CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer,
    BarChart, Legend, Brush, Bar, Cell
} from 'recharts';
import {_dateFormat} from "../../../helpData/Date";
import debounce from 'lodash/debounce';
import _ from "lodash";
import {IChartData} from "../Statistic/Statistic";

interface ILabelProps {
    x: number
    y: number
    payload: any
}


interface IProps {
    data: IChartData[]
    startIndexChart: number
    endIndexChart: number
    handleChangeRange: (range: { from: string, to: string }, indexes?: { startIndex: number, endIndex: number }) => void
}

interface IState {
    startIndex: number
    endIndex: number
    data: IChartData[]
}

export default class Chart extends PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            startIndex: this.props.startIndexChart || 0,
            endIndex: this.props.endIndexChart || this.props.data.length - 1 ,
            data: this.props.data
        };
        this._onMouseMove = this._onMouseMove.bind(this);
        this._onClick = this._onClick.bind(this);
        this.setIndexes = this.setIndexes.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {

        if (this.state.data !== this.props.data) {
            if (!_.isEqual(this.state.data, this.props.data)) {
                this.setState({
                    startIndex: 0,
                    endIndex: this.props.data.length - 1,
                    data: this.props.data
                });
            }
        }
    }


    setIndexes = debounce((e) => {
        if (this.state.startIndex === e.startIndex || this.state.endIndex === e.endIndex) {
            const data = this.props.data;
            const start_date = data[e.startIndex].start_date;
            const end_date = data[e.endIndex].start_date;

            this.props.handleChangeRange({from: start_date, to: end_date}, {
                startIndex: e.startIndex,
                endIndex: e.endIndex
            });
        }
    }, 700);


    _onMouseMove(e: { startIndex: number, endIndex: number }, _event: any) {
        if ((this.props.startIndexChart === e.startIndex && this.props.endIndexChart === e.endIndex) ||
            (this.props.endIndexChart === e.endIndex && this.props.startIndexChart === e.startIndex)) return false;

        this.setIndexes(e);
    }

    _onClick(e: any) {
        // let end_date = this._getNextDay(e.start_date);

        this.props.handleChangeRange({from: e.start_date, to: e.start_date});
    }

    _getNextDay(start_date: string) {
        let date = new Date(start_date);

        let d_date = date.getDate() + 1;
        let d_month = date.getMonth() + 1;
        let d_year = date.getFullYear();

        return _dateFormat(d_year, d_month, d_date);
    }

    render() {
        let dataBar = this.state.data
            .filter((e: IChartData, i: number) => (i >= this.state.startIndex && i <= this.state.endIndex));

        return (
            <div style={{width: '100%', height: '300px'}}>
                <ResponsiveContainer>
                    <BarChart
                        data={this.state.data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="start_date"/>
                        <YAxis domain={[0, 100]}/>
                        <Tooltip/>
                        <Legend verticalAlign="top" wrapperStyle={{lineHeight: '40px'}}/>
                        <Bar dataKey="battery_status" onClick={(e) => this._onClick(e)}>
                            {
                                dataBar.map((entry: IChartData, i: number) =>
                                        <Cell cursor="pointer"
                                              fill={entry['_color']}
                                              key={`cell-${i}_1`}/>
                                )
                            }
                        </Bar>
                        <Brush dataKey="start_date" height={30} stroke="#000000"
                               y={260} onChange={(e: { startIndex: number, endIndex: number }, event: any) => this._onMouseMove(e, event)}
                               startIndex={this.state.startIndex}
                               endIndex={this.state.endIndex}>
                            <BarChart>
                                <Bar dataKey="battery_status">
                                    {
                                        this.state.data
                                            .map((entry: IChartData, index: number) =>
                                                    <Cell cursor="pointer"
                                                          fill={entry._color}
                                                          key={`cell-${index}_2`}/>
                                        )
                                    }
                                </Bar>
                            </BarChart>
                        </Brush>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
