// This file holds our state type, as well as any other types related to this Redux store.

import {IVehicle} from "../../helpData/getResults";

export interface AnalysisResultFull {
    id: number
    name: string
    daterange: DateRangeInterface
    number_of_vehicles: number
    members: string[]
    state: object
}

export interface TCOResponse {
    vehicle_id: string,
    sim_vehicle_results: any
}

export interface DateRangeInterface {
  start: string
  end: string
}
// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum ResultsActionTypes {
    FETCH_RESULTS_REQUEST = '@@results/FETCH_RESULTS_REQUEST',
    FETCH_RESULTS_REQUEST_ERROR = '@@results/FETCH_RESULTS_REQUEST_ERROR',
    FETCH_RESULTS_REQUEST_SUCCESS = '@@results/FETCH_RESULTS_REQUEST_SUCCESS',

    FETCH_TCO_REQUEST = '@@results/FETCH_TCO_REQUEST',
    FETCH_TCO_REQUEST_ERROR = '@@results/FETCH_TCO_REQUEST_ERROR',
    FETCH_TCO_REQUEST_SUCCESS = '@@results/FETCH_TCO_REQUEST_SUCCESS',

    FETCH_ROUTE_POSITIONS_REQUEST = '@@results/FETCH_ROUTE_POSITIONS_REQUEST',
    FETCH_ROUTE_POSITIONS_REQUEST_ERROR = '@@results/FETCH_ROUTE_POSITIONS_REQUEST_ERROR',
    FETCH_ROUTE_POSITIONS_REQUEST_SUCCESS = '@@results/FETCH_ROUTE_POSITIONS_REQUEST_SUCCESS',

    FETCH_RESULTS_DETAILS_REQUEST = '@@results/FETCH_RESULTS_DETAILS_REQUEST',
    FETCH_RESULTS_REQUEST_DETAILS_SUCCESS = '@@results/FETCH_RESULTS_REQUEST_DETAILS_SUCCESS',

    WEBSOCKET_PROGRESS_STATUS = '@@results/WEBSOCKET_PROGRESS_STATUS',
    CLEAR_SHARE_STATUS = '@@results/CLEAR_SHARE_STATUS',
    FETCH_SHARE_RESPONSE = '@@results/FETCH_SHARE_RESPONSE',
    EXISTENCE_OF_ANALYSIS = '@@results/EXISTENCE_OF_ANALYSIS',
    CHANGE_OR_SAVE_RESULTS = '@@results/CHANGE_OR_SAVE_RESULTS',
    SAVE_REQUEST_SUCCESS = '@@results/SAVE_REQUEST_SUCCESS',
    SAVE_REQUEST_ERROR = '@@results/SAVE_REQUEST_ERROR',
    SHARE_REQUEST = '@@results/SHARE_REQUEST',
    SHARE_REQUEST_SUCCESS = '@@results/SHARE_REQUEST_SUCCESS',
    SHARE_REQUEST_ERROR = '@@results/SHARE_REQUEST_ERROR',
    RESULT_ARE_NOT_READY= '@@results/RESULT_ARE_NOT_READY',
    CHANGE_ANALYSIS_ID = '@@results/CHANGE_ANALYSIS_ID',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface ResultsState {
    loading: boolean,
    lambdaProgress: number,
    errors?: string
    shareErrors?: any
    resultsList: IVehicle[]
    chargingSites: any
    sharedEmails: string[]
    isSuccessShared: boolean
    isSuccessSaved?: boolean
    id?: number
    websocket_message: boolean,
    tco: any,
    power_profiles: any,
    emission_results: any,
    annual_results: any,
    route_positions: any
}
