import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {default as styled} from "../../utils/styled";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import PowerProfilesComponent from "../../pages/charging_sites/power_profiles_component";
import { SimulationResponse } from "../../store/charging-page/types";
import DialogReportDownloader from "../Reporting/DialogReportDownloader";

const StyledDialogConfirmButton = styled(Button)`
    background-color: ${props => props.theme.colors.text_green};
    height: 40px;
    width: 90px;
    margin-right: 20px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.text_green};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.inactive_brand}
    }
 
`;

const StyledDialogCloseButton = styled(Button)`
    background-color: ${props => props.theme.colors.disable};
    height: 40px;
    width: 90px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.disable};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.inactive_brand}
    }
 
`;

interface IProps {
    handleClose: (event: any) => void;
    visible: boolean,
    location: any,
    handleConfirm?: (event:any) => void;
    simulationResponse?: SimulationResponse | undefined,

    simulation_uuid?: any,
    analysisName?: any,
    filterQueryString?: any
}

interface IState {

}

class ChargingSitesModal extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { visible, location, simulationResponse, filterQueryString, analysisName, simulation_uuid } = this.props;

        let number_of_chargers = 0,
            total_energy_demand = 0,
            average_daily_energy = 0,
            peak_demand = 0

        let loc;
        if (simulationResponse) {
            loc = simulationResponse.find((e: any) => e.id === location.id);
        }

        if (loc) {
            number_of_chargers = loc.chargers.level_2

            if (loc.energy_results != null) {
                total_energy_demand  = parseFloat(Number(loc.energy_results.total_energy).toFixed(1))
                average_daily_energy = parseFloat(Number(loc.energy_results.avg_energy).toFixed(1))
                peak_demand = parseFloat(Number(loc.energy_results.peak_power).toFixed(0))
            }
        }


        return (
            <Dialog
                open={visible}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="paper"

            >
                <div style={{margin: 30}}>
                    <Grid container style={{justifyContent: 'flex-end'}}>
                        <Grid item>
                            <Button>
                                <img onClick={this.props.handleClose} src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/Close+icon.png" style={{height:40, width: 40, objectFit: 'contain'}} />
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h4" style={{marginBottom:20}}>
                            {location.name}
                        </Typography>
                        <Typography style={{marginBottom:20}}>
                            {location.street_address}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        {/*
                        <DialogContentText id="alert-dialog-description" style={{paddingBottom: 30}}>
                            <Typography variant="h6">
                                Energy profile according to the EV
                            </Typography>
                            <br />
                            <Typography>
                                This chart is an estimate based on the fact that each vehicle has its own EV charger and is charged simultaneously. This graph is 7,2 kW multiplied per vehicle.
                            </Typography>
                            <br />
                            <Typography>
                                This chart is based on 2020 Mitsubishi Outlander which represents the highest energy and power consumption profile based on the EV alternatives you selected for your analysis.                            
                            </Typography>
                        </DialogContentText>
                        */}

                        <DialogReportDownloader 
                            simulationUUID={simulation_uuid}
                            userAuthToken={window.localStorage.getItem("authenticated") as string}
                            analysisName={analysisName}
                            filterQueryString={filterQueryString}

                            filter_site_id={location.id}
                        />

                        <PowerProfilesComponent selected_charging_site_id={location.id} />

                        <Grid container style={{paddingTop: 30, paddingBottom: 10}}>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <Typography>
                                    TOTAL ENERGY DEMAND
                                </Typography>
                                <Typography variant="h5">
                                    {total_energy_demand} kWh
                                </Typography>
                            </Grid>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <Typography>
                                    AVG DAILY DEMAND
                                </Typography>
                                <Typography variant="h5">
                                    {average_daily_energy} kWh
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container style={{paddingTop: 10, paddingBottom: 10, borderTop: 'solid', borderWidth: 1}}>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <Typography>
                                    PEAK DEMAND
                                </Typography>
                                <Typography variant="h5">
                                    {peak_demand / 1000} kW
                                </Typography>
                            </Grid>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <Typography>
                                    EV CHARGERS NEEDED
                                </Typography>
                                <Typography variant="h5">
                                    {number_of_chargers}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {/*
                    <DialogActions>
                        <StyledDialogCloseButton onClick={this.props.handleClose} color="primary">
                            Close
                        </StyledDialogCloseButton>
                        {this.props.handleConfirm ?
                            <StyledDialogConfirmButton onClick={this.props.handleConfirm} color="primary">
                                Okay
                            </StyledDialogConfirmButton>
                        : 
                            null
                        }

                    </DialogActions>
                    */}
                </div>
            </Dialog>
        );
    }
}


export default ChargingSitesModal;
