import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {connect} from "react-redux";
import {RootStore, init, thunkAction, unboxThunk} from '../../redux';
import {default as styled} from "../../utils/styled";
import brandColors from "../../styles/colors/brandColors";
import {Column} from "./SortableTabTable";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import * as R from 'ramda';
import {requiredColumns} from "../../store/vehicles/reducer";
import rgba from "polished/lib/color/rgba";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',

        '& . MuiMenuItem-root': {
            paddingLeft: '3px',
            paddingRight: '3px'
        },
        '& li.MuiListItem-gutters': {
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
        init,
        thunkAction: unboxThunk(thunkAction),
    }
);
const StyledButton = styled(Button)`
  
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.regular};
    }
    &.MuiButton-contained{
        background-color: transparent;
        border: 1px solid ${props => props.theme.colors.regular};
        box-shadow: none;
    }
    &.MuiButton-contained:hover{
        box-shadow: none;
    }
`;
const GreenCheckbox = withStyles({
    root: {
        color: brandColors.crispedge,
        '&$checked': {
            color: brandColors.crispedge,
        },
        '&.MuiIconButton-root.Mui-disabled': {
            color: rgba(0, 0, 0, 0.26),
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const StyledDialogCloseButton = styled(Button)`
    background-color: ${props => props.theme.colors.disable};
    height: 40px;
    width: 90px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.disable};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.inactive_brand}
    }
 
`;
///////////////////////////////////////////////////////


type AppProps = {
    id_key: string,
    columns: Column[],
    addAttribute: (event: React.ChangeEvent<HTMLInputElement>, attributes: Column[], id: number) => void;
};
type FilterMenuState = {
    setAnchorEl: HTMLElement | any,
    attributes: any,
    selectedAttributeError: boolean,
}
const ToggleButtonContainer = styled('div')`
    display: flex;
 justify-content: flex-end
`;

class ToggleColumns extends React.PureComponent<AppProps, FilterMenuState> {

    constructor(props: AppProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelectedAttibute = this.handleSelectedAttibute.bind(this);
        this.handleCloseSelectedAttibuteError = this.handleCloseSelectedAttibuteError.bind(this);
        this.handleDisableAttribute = this.handleDisableAttribute.bind(this);
        this._switchToVisibleAttr = this._switchToVisibleAttr.bind(this);
        this.state = {setAnchorEl: null, attributes: null, selectedAttributeError: false};
    }

    handleClick(event: any) {
        this.setState({setAnchorEl: event.currentTarget});
    }

    handleClose() {
        this.setState({setAnchorEl: null});
    }

    handleSelectedAttibute(event: any, attr: Column) {
        const columns = R.clone(this.props.columns);
        this._switchToVisibleAttr(columns, attr);

        const unvisible_column_list = columns.filter((item: Column) => item.visible === false).map((item: Column) => item);

        if (unvisible_column_list.length < columns.length) {
            this.setState({...this.state, [attr.id]: attr.visible});
            this.props.addAttribute(event, columns, attr.id);
        } else {
            this._switchToVisibleAttr(columns, attr);
            this.setState({...this.state, [attr.id]: attr.visible, selectedAttributeError: true});
        }
    }
    _switchToVisibleAttr(columns: Column[], attr: Column){
        columns.forEach((item: Column) => {
            if (item.id === attr.id) {
                attr.visible = !attr.visible;
                item.visible = attr.visible;
            }
        });
    }

    handleCloseSelectedAttibuteError(event: any) {
        this.setState({selectedAttributeError: false});
    }

    handleDisableAttribute(label: string) {
        let _requiredColumn;
        const _availableLable = requiredColumns.filter((s: string) => s.toLowerCase() === label.toLowerCase());

        _availableLable[0] === label.toLowerCase() ? _requiredColumn = true : _requiredColumn = false;

        return _requiredColumn;
    }


    render() {
        return (
            <ToggleButtonContainer key={this.props.id_key}>
                <StyledButton
                    data-test-id={"ToggleColumnsButton"}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={(event: any) => this.handleClick(event)}>
                    Toggle Columns
                </StyledButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={this.state.setAnchorEl}
                    keepMounted
                    open={Boolean(this.state.setAnchorEl)}
                    onClose={this.handleClose}
                >
                    {this.props.columns.map(attr =>
                            <MenuItem
                                data-test-id={"ToggleColumns_"+attr.label}
                                onChange={(event: any) => this.handleSelectedAttibute(event, attr)}
                                key={this.props.id_key + '_' + attr.id} disableGutters={this.handleDisableAttribute(attr.label)}>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value={attr.id}
                                            control={<GreenCheckbox disabled={this.handleDisableAttribute(attr.label)} checked={attr.visible}/>}
                                            label={attr.label}
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </MenuItem>
                        )}
                </StyledMenu>
                {
                    this.state.selectedAttributeError === true ? (
                        <Dialog
                            open={this.state.selectedAttributeError}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Toggle Columns"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <span>The table must contain at least one column</span>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <StyledDialogCloseButton onClick={this.handleCloseSelectedAttibuteError}
                                                         color="primary">
                                    Close
                                </StyledDialogCloseButton>
                            </DialogActions>
                        </Dialog>
                    ) : null
                }
            </ToggleButtonContainer>
        );
    }
}

export default storeEnhancer(ToggleColumns);