export function getCurrentDate() {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return _dateFormat(year, month, date);
}

export function getPrevMonthDate() {
    let newDate = new Date();
    let date = newDate.getDate();

    newDate.setMonth(-1);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    month.toString();

    return _dateFormat(year, month, date);
}

export function convertUTCDateToString(UTCDate: Date) {
    let date = UTCDate.getDate();
    let month = UTCDate.getMonth() + 1;
    let year = UTCDate.getFullYear();

    return _dateFormat(year, month, date);
}

export function formatterNumberToTime(a: number) {
    const hours = Math.trunc(a / 60);
    const minutes = a % 60;

    if (hours !== 0 && minutes !== 0) return hours + " hrs " + minutes + " min ";
    if (hours !== 0) return hours + " hrs ";
    if (minutes !== 0) return minutes + " min ";

}

export function _dateFormat(year: number, month: number, date: number, separator='-') {
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

export function isValidDateRange(date: {start_date: string, end_date: string}){
    return isValidDate(date.start_date) && isValidDate(date.end_date)
        && date.start_date <= date.end_date
}
export function isValidDate(date: string){
    return date.indexOf("NaN") !== 0;
}