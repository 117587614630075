import {
    adapt_operator, QueryByAttribute, AttributesResponseInterface, getVehicles_query_Interface, QueryNumbersOperators,
    QueryTextsOperators
} from "./getVehicleAttributes";
import {Vehicle} from "./get_vehicles_response_example";
import {Attribute} from "../store/vehicles/types";

export function _createAttributesArray(vehicles_attributes: AttributesResponseInterface[], value?: any, operator?: string) {
    const cloneAttributes = JSON.parse(JSON.stringify(vehicles_attributes));
    cloneAttributes.forEach((item: AttributesResponseInterface) => {
        _changeAttributeValues(item, value, operator);
    });

    return cloneAttributes;
}

export function _changeAttributeValues(item: any, value?: any, operator?: string, selected?: boolean) {
    item.selected = selected || false;

    item['_value'] = value || "";

    if (!operator) {
        if (item.type === "string") {
            item['_operator'] = operator || QueryTextsOperators[0];
        } else {
            item['_operator'] = operator || QueryNumbersOperators[0];
        }
    } else {
        item['_operator'] = adapt_operator(operator)
    }

    return item;
}

export function changeVehiclesQueryAttibute(current_query: getVehicles_query_Interface, attribute: QueryByAttribute) {

    let is_attribute = true;
    current_query.attributes.forEach((attr: QueryByAttribute) => {
        if (attr.attribute_id === attribute.attribute_id) {
            attr.value = attribute.value;
            attr.operator = attribute.operator;

            is_attribute = false;

        } else
            is_attribute = true;
    });

    is_attribute && current_query.attributes.push(attribute);
}

export function mappingSimVehiclesAttributes(_vehicle_mapping_sim_vehicles_attributes: any, selectedVehicles: Vehicle[], changedAttribute?: AttributesResponseInterface) {
    if (changedAttribute) {
        selectedVehicles.forEach((item: Vehicle) => {
            if (!_vehicle_mapping_sim_vehicles_attributes[item.vehicle_id]) {
                _vehicle_mapping_sim_vehicles_attributes[item.vehicle_id] = [];
            }

            if (changedAttribute.selected) {
                _vehicle_mapping_sim_vehicles_attributes[item.vehicle_id].push(changedAttribute);
            } else {
                _vehicle_mapping_sim_vehicles_attributes[item.vehicle_id] = _vehicle_mapping_sim_vehicles_attributes[item.vehicle_id]
                    .filter((i: any) => i.attribute_id !== changedAttribute.attribute_id);
            }
        });
    }
    return _vehicle_mapping_sim_vehicles_attributes;
}

export function attributesForTable(attributes: Attribute[]) : Attribute[] {
    const a1 = {
        attribute_id: -2,
        selected: true,
        name: 'Total Distance',
        type: 'string'
    };
    const a2 = {
        attribute_id: -1,
        selected: true,
        name: 'Average Distance',
        type: 'string'
    };

    return [...attributes, a1, a2];
}