// This file holds our state type, as well as any other types related to this Redux store.

export interface LocationsResponse extends ApiResponse {
    id: number;
    name: string;
    l1_charger_count: number;
    l2_charger_count: number;
    dc_charger_count: number;
    geo: {
        lat: number;
        lon: number;
    };
}
export interface RunSimulationRequest {
    "date_range": {
        "startDate": string,
        "endDate": string
    },
    "selected_vehicles_ids": number[],
    "selected_simulation_vehicles_ids": number[],
    "charging_stations_ids": number[],
    "simulation_algorithm": string
}

export interface SimulationResponse extends ApiResponse {
  ev_insights: EVInsight[]
  charging_statitions_utilization: UtilizationSimulationRequest[]
}

export interface EVInsight {
  out_of_range_days: number;
  vehicle_id: string;
  sim_vehicle_id: number;
  insight: number;
  sim_vehicle_info: any;
  driving_days: any;
}

export interface UtilizationSimulationRequest {
  charging_site_id: string | number;
  utilization: number;
}

// This type is basically shorthand for `{ [key: string]: any }`. Feel free to replace `any` with
// the expected return type of your API response.
export type ApiResponse = Record<string, any>

// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum ChargingActionTypes {
    FETCH_LOCALE_ERROR = '@@charging/FETCH_ERROR',
    FETCH_LOCALE_REQUEST = '@@charging/FETCH_LOCALE_REQUEST',
    FETCH_LOCALE_SUCCESS = '@@charging/FETCH_LOCALE_SUCCESS',

    FETCH_PUT_LOCALE_REQUEST = '@@charging/FETCH_PUT_LOCALE_REQUEST',

    FETCH_POST_RUN_SIMULATION_REQUEST = '@@charging/FETCH_POST_RUN_SIMULATION_REQUEST',
    FETCH_POST_RUN_SIMULATION_REQUEST_ERROR = '@@charging/FETCH_POST_RUN_SIMULATION_REQUEST_ERROR',
    FETCH_POST_RUN_SIMULATION_REQUEST_SUCCESS = '@@charging/FETCH_POST_RUN_SIMULATION_REQUEST_SUCCESS',

    SET_SELECTED_LOCATIONS = '@@charging/SET_SELECTED_LOCATIONS',

    SET_SIMULATION_UUID = '@@charging/SET_SIMULATION_UUID',

    RESULT_ARE_NOT_READY = '@@charging/RESULT_ARE_NOT_READY',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface ChargingState {
  readonly errors?: string,
  readonly loading: boolean,
  readonly locations: LocationsResponse[],
  readonly simulation_uuid: string,
  readonly selectedLocations: LocationsResponse[],
  readonly simulationResponse?: SimulationResponse,
    readonly simulationRequest?: any
}
