import { action } from 'typesafe-actions'
import {ChargingActionTypes, LocationsResponse, RunSimulationRequest, SimulationResponse} from './types'
// import {getVehiclesInterface} from "../../helpData/getVehicles";

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

export const fetchLocaleRequest = () => action(ChargingActionTypes.FETCH_LOCALE_REQUEST);
export const fetchLocaleError = (message: string) => action(ChargingActionTypes.FETCH_LOCALE_ERROR, message);
export const fetchLocaleSuccess = (data: LocationsResponse[]) => action(ChargingActionTypes.FETCH_LOCALE_SUCCESS, data);

export const fetchPutLocaleRequest = () => action(ChargingActionTypes.FETCH_PUT_LOCALE_REQUEST);

export const fetchPostSimulationRequest = (parameters: {simulation_uuid: string, body: RunSimulationRequest}) => action(ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST, parameters)
export const fetchPostSimulationError = (message: string) => action(ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST_ERROR, message);
export const fetchPostSimulationSuccess = (data: SimulationResponse) => action(ChargingActionTypes.FETCH_POST_RUN_SIMULATION_REQUEST_SUCCESS, data);

export const setSelectedLocations = (data: LocationsResponse[]) => action(ChargingActionTypes.SET_SELECTED_LOCATIONS, data);

export const setSimulationUuid = (parameters: {simulation_uuid: string, body: RunSimulationRequest}) => action(ChargingActionTypes.SET_SIMULATION_UUID, parameters);

export const resultAreNotReady = () =>  action(ChargingActionTypes.RESULT_ARE_NOT_READY);