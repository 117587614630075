import * as React from 'react'
import {connect} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom'
import styled from '../../utils/styled'
import {Container} from "./Container";

// store
import {ApplicationState} from "../../store";


import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';


const Wrapper = styled('header')`
    padding: 0rem;
    width: 20%;
    z-index: 1;
    background-color: ${props => props.theme.colors.brand};
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.headings};
`

const HeaderInner = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
        flex-direction: column;
    }
`

const HeaderTop = styled('div')`
    padding: 20px;
`

const HeaderNav = styled('nav')`
    flex: 1 1 auto;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;

    // @media (min-width: ${props => props.theme.breakpoints.lg}) {
    //   margin: 0;
    // }
`

const HeaderNavLink = styled(NavLink)`
    overflow: hidden;
    padding: 20px;

    &.is-active,
    &:hover,
    &:focus{
        text-decoration: none;
        background-color: ${props => props.theme.colors.active_link};
        width: 100%;
    }
    &.is-active{
        border-left: 6px solid ${props => props.theme.colors.regular};
        padding: 20px 14px; 
    }

`

const HeaderNavSubLink = styled(NavLink)`
    padding: 20px;
    padding-left: 60px;

    &.is-active,
    &:hover,
    &:focus{
        text-decoration: none;
        background-color: ${props => props.theme.colors.active_link};
        width: auto;
        margin-left: 40px;
        padding-left: 20px;
    }
    &.is-active{
        border-left: 6px solid ${props => props.theme.colors.regular};
        padding: 20px 14px; 
        margin-left: 40px;
        padding-left: 20px;
    }

`

const Title = styled('h2')`
    margin: 0;
    margin-right: 20px;
    margin-top: 1px;
    font-weight: 500;
    color: ${props => props.theme.colors.regular};
`
const drawerWidth = 310;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    width: 30,
    height: 30,
    objectFit: 'contain'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#26394e'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
// const ThemeSwitcherButton = styled('button')`
//   display: inline-block;
//   padding: 0.25rem 0.5rem;
//   border: 1px solid ${props => props.theme.colors.white};
//   border-radius: 3px;
//   background-color: ${props => props.theme.colors.white};
//   color: ${props => props.theme.colors.brand};
//   font-size: 0.8rem;
//   text-transform: uppercase;
//   letter-spacing: 1px;
//   cursor: pointer;
//   transition: all 0.3s ease;
//
//   &:hover,
//   &:focus {
//     background-color: transparent;
//     color: ${props => props.theme.colors.white};
//   }
// `

// @ts-ignore

/////////////////////////////////////////////////////////////////////////////////////////////
interface RouteMenuProps {
    title: string,
    history: any
}

export interface PropsFromState {
    analysisName: string
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & RouteMenuProps

function RouteMenu(props: AllProps) {
    const { title, history, analysisName } = props;

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                }),
            }}
        >
            <HeaderInner>
            <HeaderTop style={{padding: 10}}>
                {
                    open ?
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                                <Title data-test-id={"titlename"}>{title}</Title>
                            </div>
                            <div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                <Button onClick={handleDrawerToggle} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img
                                        src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/menu-close.png"
                                        className={clsx(classes.menuButton)}
                                    />
                                </Button>
                            </div>
                        </div>

                    :
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button onClick={handleDrawerToggle}>
                                <img
                                    src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/menu-hamburger.png"
                                    className={clsx(classes.menuButton)}
                                />
                            </Button>
                        </div>
                }
            </HeaderTop>
            {//Don't show other tabs if analysis isn't selected
            analysisName === '' ?
                <HeaderNav data-test-id={"left_header_navigation"}>
                    <HeaderNavLink data-test-id={'left_header_navigation__HomeButton'} exact to={{pathname: '/', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                        <div style={{width: 120, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 70, boxSizing: 'content-box'}} className='nav-link'>
                                <img
                                    src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/home.png"
                                    className={clsx(classes.menuButton)}
                                />
                            </div>
                            <Typography variant="h6" style={{flex: 1, color: 'white'}}>
                                Home
                             </Typography>
                        </div>
                    </HeaderNavLink>
                </HeaderNav>
            :
                <HeaderNav data-test-id={"left_header_navigation"}>
                    <HeaderNavLink data-test-id={'left_header_navigation__HomeButton'} exact to={{pathname: '/', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                        <div style={{width: 120, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 70, boxSizing: 'content-box'}} className='nav-link'>
                                <img
                                    src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/home.png"
                                    className={clsx(classes.menuButton)}
                                />
                            </div>
                            <Typography variant="h6" style={{flex: 1, color: 'white'}}>
                                Home
                             </Typography>
                        </div>
                    </HeaderNavLink>

                    <HeaderNavLink                         
                        data-test-id={'left_header_navigation__FleetAnalysisButton'}
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/fleet-analysis/vehicles');
                        }}
                        strict
                        to={{pathname: '/fleet-analysis/vehicles', state: { prevPath: window.location.pathname }}}
                        isActive={(match, location) => {
                            if (match || location.pathname == '/fleet-analysis/replacement' || location.pathname == '/fleet-analysis/incentives' || location.pathname == '/fleet-analysis/run_analysis') {
                                return true
                            } else {
                                return false
                            }
                        }}
                        activeClassName="is-active"
                    > 
                        <div style={{width: 250, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 70, boxSizing: 'content-box'}} className='nav-link'>
                                <img
                                    src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/edit.png"
                                    className={clsx(classes.menuButton)}
                                />
                            </div>
                            <Typography variant="h6" style={{flex: 1, color: 'white'}}>
                                Fleet Analysis
                             </Typography>
                        </div>
                    </HeaderNavLink>

                    <HeaderNavLink
                        data-test-id={'left_header_navigation__VehicleResultsButton'}
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/fleet-analysis/result/:analysis_id');
                        }}
                        strict
                        to={{pathname: '/fleet-analysis/result/:analysis_id', state: { prevPath: window.location.pathname }}}
                        activeClassName="is-active"
                    >
                        <div style={{width: 250, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 70, boxSizing: 'content-box'}} className='nav-link'>
                                <img
                                    src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/car.png"
                                    className={clsx(classes.menuButton)}
                                />
                            </div>
                            <Typography variant="h6" style={{flex: 1, color: 'white'}}>
                                Vehicle Results
                             </Typography>
                        </div>
                    </HeaderNavLink>

                    <HeaderNavLink
                        data-test-id={'left_header_navigation__ChargingSitesButton'}
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/fleet-analysis/sites/:analysis_id');
                        }}
                        strict
                        to={{pathname: '/fleet-analysis/sites/:analysis_id', state: { prevPath: window.location.pathname }}}
                        isActive={(match, location) => {
                            if (match || location.pathname == '/fleet-analysis/power_energy/:analysis_id') {
                                return true
                            } else {
                                return false
                            }
                        }}
                        activeClassName="is-active"
                    >
                        <div style={{width: 300, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 70, boxSizing: 'content-box'}} className='nav-link'>
                                <img
                                    src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/charging.png"
                                    className={clsx(classes.menuButton)}
                                />
                            </div>
                            <Typography variant="h6" style={{flex: 1, color: 'white'}}>
                                Charging Site Results
                             </Typography>
                        </div>
                    </HeaderNavLink>

                    <HeaderNavLink
                        data-test-id={'left_header_navigation__SettingsButton'}
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/settings');
                        }}
                        strict
                        to={{pathname: '/settings', state: { prevPath: window.location.pathname }}}
                        isActive={(match, location) => {
                            if (match || location.pathname == '/settings') {
                                return true
                            } else {
                                return false
                            }
                        }}
                        activeClassName="is-active"
                    >
                        <div style={{width: 300, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 70, boxSizing: 'content-box'}} className='nav-link'>
                                <FontAwesomeIcon icon={faCog} style={{width: 30, height: 30, color: 'white'}} />
                            </div>
                            <Typography variant="h6" style={{flex: 1, color: 'white'}}>
                                Settings
                             </Typography>
                        </div>
                    </HeaderNavLink>
                </HeaderNav>
            }
            </HeaderInner>
        </Drawer>
    )
}

export const mapStateToProps = ({homePage}: ApplicationState) => ({
    analysisName: homePage.analysisName,
});

const mapDispatchToProps = {
};

// @ts-ignore
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RouteMenu));