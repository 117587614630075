// This file holds our state type, as well as any other types related to this Redux store.


// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum AuthActionTypes {
  FETCH_AUTH = '@@auth/FETCH_AUTH',
  FETCH_AUTH_SUCCESS = '@@auth/FETCH_AUTH_SUCCESS',
  FETCH_AUTH_ERROR = '@@auth/FETCH_AUTH_ERROR',
  REDIRECT_FROM_LOGIN = '@@auth/REDIRECT_FROM_LOGIN',

}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface AuthState {
  app_title: string;
  loading: boolean;
  redirect_from_login: boolean | null;
  isAuthenticated: boolean | null;
  uuid: string | null;
  errors?: string
}