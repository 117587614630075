import * as React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FilterMenu from "../../components/FilterVehicles/FilterMenu";
import {
    QueryByAttribute,
    AttributesResponseInterface, getVehicles_query_Interface,
} from "../../helpData/getVehicleAttributes";
import {GetVehiclesResponse, Vehicle} from "../../helpData/get_vehicles_response_example";
import CustomFilter from "../../components/FilterVehicles/CustomFilter";
import SortableTabTable from "../../components/Tables/SortableTabTable";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {
    fetchAttributes,
    fetchVehicles,
    setCurrentQuery,
    setSelectedAttributes,
    setSelectedVehicles,
    setSelectedDateRange, setRowsPerPage, fetchAvailableDateRange, setCurrentQueryForSimVehiclesSaving
} from "../../store/vehicles/actions";
import {Attribute} from "../../store/vehicles/types";
import {Container} from "../../components/layout/Container";
import Page from "../../components/layout/Page";
import {FilterCardRow} from "../../components/layout/Card.tsx";
import LoadingModal from "../../components/Modals/LoadingModals";
import DialogInput from '../../components/Modals/DialogInput';
import {setAnalysisNameAction} from '../../store/analysis/actions';
import {DateAttribute} from "../../store/vehicles/reducer";
import {
    _createAttributesArray, attributesForTable
} from "../../helpData/changeAttributes";
import {FilterCardTable} from "../../components/layout/TableBaseStyles";

// Separate state props + dispatch props to their own interfaces.
export interface PropsFromState {
    loading: boolean
    vehicles_attributes: Attribute[]
    vehicles: GetVehiclesResponse
    selected_vehicles: Vehicle[]
    current_query_for_saving?: getVehicles_query_Interface | any
    analysisName: string
    setAnalysisNameAction: typeof setAnalysisNameAction
    history: any
    selected_attributes: AttributeView[]
    selected_date_range: {start_date: string, end_date: string}
    rows_per_page: number
    availableDateRange: any
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    fetchAvailableDateRange: typeof fetchAvailableDateRange
    fetchAttributes: typeof fetchAttributes
    fetchVehicles: typeof fetchVehicles
    setRowsPerPage: typeof setRowsPerPage
    setSelectedVehicles: typeof setSelectedVehicles
    setSelectedAttributes: typeof setSelectedAttributes
    setSelectedDateRange: typeof setSelectedDateRange
    setCurrentQuery: typeof setCurrentQuery
}

export interface AttributeView extends Attribute {
    value: string[]
    operator: string
}

interface IState {
    attributes: AttributesResponseInterface[];
    tableAttributes: any;
    date_attribute: AttributesResponseInterface;
    tableData: GetVehiclesResponse;
    selected_vehicles_ids: number[]
}


// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & PropsFromDispatch


function attributeAdapter(a: AttributeView): AttributesResponseInterface {
    return {
        ...a,
        _operator: a.operator,
        _value: a.value
    }
}

function queryAttributeAdapter(a: AttributeView): QueryByAttribute {
    return {
        attribute_id: a.attribute_id,
        operator: a.operator,
        value: a.value
    }
}


class IndexPage extends React.Component<AllProps, IState> {

    constructor(props: AllProps) {
        super(props);

        this.addAttribute = this.addAttribute.bind(this);
        this.onFilterMenuChange = this.onFilterMenuChange.bind(this);
        this.removeAttribute = this.removeAttribute.bind(this);
        this.applyNewFilter = this.applyNewFilter.bind(this);
        this.renderData = this.renderData.bind(this);
        this.selectedVehicles = this.selectedVehicles.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this._setUpComponent = this._setUpComponent.bind(this);

        this._setUpComponent(props);
    }

    //Why didn't they use ComponentDidMount?
    // Chris: Good question jesse, i have no idea...
    _setUpComponent(props: AllProps) {
        let _selected_vehicles_ids: number[] = [];
        let _attributesArray, _tableAttributes, _vehicles;

        if (this.props.vehicles_attributes && this.props.vehicles_attributes.length) {
            _attributesArray = _createAttributesArray(this.props.vehicles_attributes);
            _tableAttributes = attributesForTable(this.props.vehicles_attributes);

        }
        console.log("_setUpComponent(*) completed vehicles_attributes");
        if (this.props.selected_vehicles) {
            console.log("this.props.selected_vehicles length: " + this.props.selected_vehicles.length);
            _selected_vehicles_ids = this.props.selected_vehicles.map((item: Vehicle) => item.vehicle_id);
        }
        
        if (this.props.vehicles && this.props.vehicles.vehicles && this.props.vehicles.vehicles.length) {
            console.log("_setUpComponent(*) vehicles: " + this.props.vehicles);
            _vehicles = JSON.parse(JSON.stringify(this.props.vehicles));
        } else {
            this.props.fetchAttributes('vehicles');
        }
        console.log("props.current_query_for_saving: ", this.props.current_query_for_saving);
        console.log("this.props.selected_date_range: ", this.props.selected_date_range);
        console.log("selected_attributes: ", this.props.selected_attributes);

        //DM:this is always true, because the initialization process sets a value for this
        //the problem is that the value does not exactly align with the selectedDate or the selectedAttributes
        // if(this.props.current_query_for_saving) {
        console.log("_setUpComponent(). from props, using current_query_for_saving. ", this.props.current_query_for_saving);
        //console.log("_setUpComponent(). from state, using current_query_for_saving. ", this.state.vehicle.current_query_for_saving);

        const current_query: getVehicles_query_Interface = {
            attributes: this.selected_attributes(),
            date_range: this.props.current_query_for_saving.date_range
        }
        console.log("_setUpComponent(*) after current_query: ", current_query);
        //setCurrentQuery(current_query);
        //console.log("_setUpComponent: setCurrentQuery with current_query: ", current_query);
        //this._fetchVehicles(current_query);
        // } else {
            //Initial fetch for vehicles
        // const current_query: getVehicles_query_Interface = {
        //     attributes: [],
        //     date_range: {
        //         start_date: "1990-01-01",
        //         end_date: "2030-01-01"
        //     }   
        // }
        // console.log("_setUpComponent(). defaulting current_query props are: ", this.props);
        //this fetchVehicles is made, then if there is something alreadt, it queries again after initialization
        
        //DM: commenting out, because this might be the extra api call that is breaking the grid table
        // this._fetchVehicles(this.props.current_query_for_saving);
        // console.log("_setUpComponent(*) _fetchVehicles(*)")

        this.props.fetchAvailableDateRange()
        console.log("_setUpComponent(*) after fetchAvailableDateRange()");

        //DM i dont think this state initialization is necessary, it reinitializes, something that is already set
        this.state = {
            attributes: _attributesArray || [],
            date_attribute: this.props.current_query_for_saving.date_range,
            tableData: _vehicles,
            tableAttributes: _tableAttributes || [],
            selected_vehicles_ids: _selected_vehicles_ids || []
        };
        console.log("_setUpComponent(*) after setting the state of the index.tsx with attributes, etc: ", this.state);
    }

    componentDidUpdate(prevProps: AllProps, prevState: IState, snapshot: any) {
        console.log("filter_vehicles/index.tsx starting componentDidUpdate()");
        if (prevProps.vehicles_attributes !== this.props.vehicles_attributes) {
            if (this.props.vehicles_attributes && this.props.vehicles_attributes.length > 0) {

                const cloneAttributes = _createAttributesArray(this.props.vehicles_attributes);

                const cloneAttributeList = attributesForTable(JSON.parse(JSON.stringify(this.props.vehicles_attributes)));

                this.setState({attributes: cloneAttributes, tableAttributes: cloneAttributeList});
            }
        }
        console.log("filter_vehicles/index.tsx componentDidUpdate(*) after vehicle_attributes")

        // Vehicles updated outside of the component.
        if (prevProps.vehicles !== this.props.vehicles) {
            if(this.props.vehicles) {
                const vehicles = JSON.parse(JSON.stringify(this.props.vehicles));
                this.setState({tableData: vehicles});
            }
            
        }
        console.log("filter_vehicles/index.tsx set vehicles state");

        // Selected vehicles are updated outside of the component.
        if (this.props.selected_vehicles !== prevProps.selected_vehicles || 
            this.props.loading !== prevProps.loading) {
            const _selected_vehicles = this.props.selected_vehicles.map((v: Vehicle) => v.vehicle_id);
            this.setState({selected_vehicles_ids: _selected_vehicles});
        }
        console.log("filter_vehicles/index.tsx set selected_vehicles");

    }

    addAttribute(event: any, attributes: AttributesResponseInterface[]) {
        this.setState({...this.state, attributes: attributes})

    }

    onFilterMenuChange(attribute: AttributesResponseInterface) {
        const attributes: AttributeView[] = this.selected_attributes().map((a: AttributeView) => {
            if (attribute.attribute_id === a.attribute_id) {
                return {
                    ...a,
                    selected: attribute.selected
                };
            } else {
                return a;
            }
        })
        console.log("onFilterMenuChange: attributes selected: ", attributes);
        this.props.setSelectedAttributes(attributes);
        //this.props.setCurrentQuery();
    }

    removeAttribute(event: any, attribute: AttributesResponseInterface) {

        
        const selected_attributes: AttributeView[] = this.selected_attributes().map((a: AttributeView) => {
            if (attribute.attribute_id === a.attribute_id) {
                return {
                    ...a,
                    selected: false
                }
            } else {
                return a
            }
        })

        const current_query: getVehicles_query_Interface = {
            attributes: selected_attributes.filter((a) => a.selected && a.value.length >0).map((a) => queryAttributeAdapter(a)),
            date_range: this.props.selected_date_range
        }

        console.log("removeAttribute: current query is: ",current_query);
        
        
        this._fetchVehicles(current_query);
        this.props.setSelectedAttributes(selected_attributes);
    }

    applyNewFilter(attribute: QueryByAttribute & AttributesResponseInterface) {

        // Date changed.
        let local_selected_date_range = this.props.selected_date_range;
        if (attribute.attribute_id === -1) {
            this.props.setSelectedDateRange(attribute.value)
            console.log("applyNewFilter. props.selected_date_range updated is: ", this.props.selected_date_range);
            console.log("applyNewFilter. updating selected_date_range with: ", attribute.value);
            local_selected_date_range = attribute.value;
        }

        const attributes: AttributeView[] = this.selected_attributes().map((a) => {
            if (a.attribute_id === attribute.attribute_id) {
                return {
                    attribute_id: a.attribute_id,
                    type: a.type,
                    operator: attribute.operator,
                    value: attribute.value,
                    selected: a.selected,
                    name: a.name
                };
            } else {
                return a;
            }
        });
        // const current_query: getVehicles_query_Interface = {
        //     attributes: attributes.filter((a) => a.selected && a.value.length >0).map((a: AttributeView) => queryAttributeAdapter(a)),
        //     date_range: this.props.selected_date_range
        // }
        const current_query: getVehicles_query_Interface = {
            attributes: attributes.filter((a) => a.selected && a.value.length >0).map((a: AttributeView) => queryAttributeAdapter(a)),
            date_range: local_selected_date_range
        }
        this.props.setCurrentQuery(current_query);
        console.log("applyNewFilter: current query is: ", current_query);
        console.log("applyNewFilter: after setCurrentQuery ", this.props.current_query_for_saving);

        this._fetchVehicles(current_query);
        this.props.setSelectedAttributes(attributes);
    }

    _fetchVehicles(current_query_for_saving: getVehicles_query_Interface) {

        const uri = JSON.stringify(current_query_for_saving);
        const uri_enc = encodeURIComponent(uri);

        this.props.fetchVehicles({url: uri_enc, type: 'vehicles'});
        console.log("inside filter_vehicles/index.tsx _fetchVehicles() with current_query_for_saving: " + uri);

    }

    selectedVehicles(selected: any) {
        let _selectedVehicles = [] as any;
        const _vehicles = JSON.parse(JSON.stringify(this.props.vehicles.vehicles));
        selected.forEach((item: number) => {
            _vehicles.forEach((vehicle: Vehicle) => {
                if (vehicle.vehicle_id === item) {
                    _selectedVehicles.push(vehicle);
                }
            });
        });

        this.props.setSelectedVehicles(_selectedVehicles);
    }

    handleChangeRowsPerPage(rowsPerPage: number){
        this.props.setRowsPerPage(rowsPerPage);
    }

    public renderData() {
        return (
            <div>
                <Paper >
                    <div className={"card-container"}>
                        <FilterCardRow>
                            <Typography variant="h5" data-test-id={"VehiclesPageTitle"}>
                                Search for vehicles using your custom filters
                            </Typography>
                        </FilterCardRow>
                        <div>
                            <FilterCardRow key={"FilterCardRow_1"}>
                                <CustomFilter dataTestId={"CustomFilter_Date"}
                                              applyNewFilter={this.applyNewFilter}
                                              removable={false}
                                              removeAttribute={this.removeAttribute}
                                              availableDateRange={this.props.availableDateRange}
                                              attribute={{
                                                  attribute_id: -1,
                                                  selected: true,
                                                  type: "date",
                                                  name: "Driving Routes Dates",
                                                  _operator: "eq",
                                                  value: this.props.selected_date_range,
                                                  _value: this.props.selected_date_range
                                              }}/>
                                {
                                    this.selected_attributes()
                                        .filter((a: AttributeView) => a.selected)
                                        .map((a: AttributeView, i: number) => {
                                            return (<CustomFilter key={i}
                                                                  dataTestId={"CustomFilter_"+i}
                                                                  suggest_enabled={true}
                                                                  applyNewFilter={this.applyNewFilter}
                                                                  removeAttribute={this.removeAttribute}
                                                                  removable={true}
                                                                  attribute={attributeAdapter(a)}/>)
                                        })
                                }
                            </FilterCardRow>

                            {this.state.attributes && this.state.attributes.length > 0 ? (
                                <FilterCardRow>

                                    <FilterMenu
                                        dataTestId={"AddFilterButton"}
                                        id_key={"FilterMenu"}
                                        attributes={this.selected_attributes()}
                                        onChange={this.onFilterMenuChange}
                                    >
                                        Add filter
                                    </FilterMenu>
                                </FilterCardRow>
                            ) : null}
                        </div>
                    </div>
                </Paper>
                <Paper style={{marginTop: "20px"}}>
                    <div className={"card-container"}>
                        {this.state.tableAttributes.length > 0 ? (
                            <FilterCardTable key={"FilterCardRow_2"}>
                                <SortableTabTable
                                    tableData={this.state.tableData}
                                    tableAttributes={this.state.tableAttributes as any}
                                    removeAttribute={this.removeAttribute}
                                    selected_vehicles={this.state.selected_vehicles_ids as any}
                                    selectedVehicles={this.selectedVehicles}
                                    rows_per_page={this.props.rows_per_page}
                                    changeRowsPerPage={this.handleChangeRowsPerPage}/>
                            </FilterCardTable>
                        ) : <FilterCardRow>
                            <Typography color="inherit" variant="h5">
                                Search result:
                            </Typography>
                        </FilterCardRow>}
                    </div>
                </Paper>
            </div>
        )
    }

    private selected_attributes() : AttributeView[] {
        let selected_attributes = this.props.selected_attributes;
        if (!selected_attributes || selected_attributes.length === 0) {
            selected_attributes = this.props.vehicles_attributes.map((a: Attribute) => {
                return {
                    ...a,
                    value: [],
                    operator: "eq",
                    selected: false
                }
            })
        }
        return selected_attributes;
    }

    render() {
        const {loading, analysisName, setAnalysisNameAction, history} = this.props;

        //If there is no analysis selected then redirect to the home page
        if (this.props.analysisName === '')
            this.props.history.push('/')


        return (
            <Page>
                <Container style={{minWidth: '90%'}}>
                    <div>
                        {
                            loading ? (<LoadingModal open={loading}/>) : this.renderData()
                        }
                    </div>
                </Container>
                {/*(!loading && analysisName === '') &&(<DialogInput
                    key={analysisName}
                    handleClose={() => history.push('/')}
                    isVisible={ analysisName === '' }
                    onSubmitClicked={setAnalysisNameAction}
                    negativeBtnName="Cancel"
                    positiveBtnName="Go next"
                    title="Create new analysis"/>)
                */}
            </Page>
        );
    }
}


// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
export const mapStateToProps = ({vehicle, homePage}: ApplicationState) => ({
    loading: vehicle.loading,
    vehicles_attributes: vehicle.vehicles_attributes,
    vehicles: vehicle.vehicles,
    current_query_for_saving: vehicle.current_query_for_saving,
    selected_vehicles: vehicle.selected_vehicles,
    analysisName: homePage.analysisName,
    selected_attributes: vehicle.selected_attributes,
    selected_date_range: vehicle.selected_date_range,
    availableDateRange: vehicle.availableDateRange,
    rows_per_page: vehicle.rows_per_page
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
    fetchAvailableDateRange,
    fetchAttributes,
    fetchVehicles,
    setRowsPerPage,
    setSelectedVehicles,
    setSelectedAttributes,
    setSelectedDateRange,
    setCurrentQuery,
    setAnalysisNameAction
};

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexPage);
