import {call, put, takeLatest} from 'redux-saga/effects'
import {AuthActionTypes} from './types'
import { callApi } from '../../utils/api'
import {fetchAuth, fetchAuthError, fetchAuthSuccess, redirectFromLogin} from "./actions";

function* handleFetch(action: ReturnType<typeof fetchAuth>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res = yield call(callApi, 'post', '/api-token-auth/', action.payload);

    if (res['non_field_errors']) {
      yield put(fetchAuthError(res['non_field_errors'][0]))
    } else {
      yield put(fetchAuthSuccess(res))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(fetchAuthError(err.stack))
    } else {
      yield put(fetchAuthError('An unknown error occured.'))
    }
  }
}
function handlRedirectFromLogin(action: ReturnType<typeof redirectFromLogin>) {
  redirectFromLogin(action.payload);
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
// function* watchFetchAuthRequest() {
//   yield takeEvery( handleFetch)
// }
// function* watchRedirectFromLogin(){
//   yield takeEvery(AuthActionTypes.REDIRECT_FROM_LOGIN, handlRedirectFromLogin)
// }
// We can also use `fork()` here to split our saga into multiple watchers.
// function* authSaga() {
//   yield all([fork(watchFetchAuthRequest), fork(watchRedirectFromLogin)])
// }

function* authWatcherSaga() {
  yield takeLatest(AuthActionTypes.FETCH_AUTH, handleFetch);
  yield takeLatest(AuthActionTypes.REDIRECT_FROM_LOGIN, handlRedirectFromLogin);
}

export default authWatcherSaga
