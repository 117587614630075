import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {default as styled} from "../../utils/styled";
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {useState} from 'react';
import lightTheme from "../../styles/theme/light";

const StyledDialogOkButton = styled(Button)`
    background-color: ${props => props.theme.colors.regular};
    height: 40px;
    width: 100px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.regular};
     }
    & .MuiButton-label {
        text-transform: none;
        font-size: 18px;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.line}
    }
`;

const StyledDialogCloseButton = styled(Button)`
    background-color: ${props => props.theme.colors.disable};
    height: 40px;
    width: 90px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.disable};
     }
    & .MuiButton-label {
        font-size: 18px;
        font-weight: 500;
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-root:hover {
       background-color: ${props => props.theme.colors.line}
    }
 
`;

const CustomTextField = withStyles({
    root: {
        marginTop: 0,
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(TextField);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: '10px',
            color: lightTheme.colors.pickled_bluewood
        }
    }),
);

const CustomDialogTitle = withStyles({
    root: {
        paddingBottom: 0,
        '& .MuiTypography-root': {
            fontSize: 28,
            fontFamily: 'Roboto',
            fontWeight: 300
        },
    },
})(DialogTitle);

interface IProps {
    handleClose: (event: any) => void;
    onSubmitClicked: (event: any) => void;
    isVisible: boolean
    title?: string
    message?: string
    positiveBtnName?: string
    negativeBtnName?: string
    messageLines?: Array<string>
    alert?: boolean
}

function DialogInput(props: IProps) {
    const {alert, isVisible, handleClose, title, message, messageLines, positiveBtnName, negativeBtnName, onSubmitClicked} = props;

    const [name, setName] = useState('');
    const [isNameValid, setNameValid] = useState(true);
    const classes = useStyles();

    const onTextChange = (e: any) => {
        setName(e.target.value);
        setNameValid(e.target.value >= 0);
    };

    // @ts-ignore
    const renderMessageLines = () => messageLines.map((item: string) =>
        <div className={classes.root} key={item}>
            <span data-test-id={"DialogMessageLines"}>{item}</span>
        </div>
    );

    return (
        <Dialog
            style={{zIndex: 9999}}
            open={isVisible}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle data-test-id={"DialogTitle"} id="alert-dialog-title">{title || 'Confirm'}</CustomDialogTitle>
            <DialogContent style={{width: 400}}>
                {message && <span>{message}</span>}
                {messageLines && renderMessageLines()}
                {!alert && <CustomTextField
                    data-test-id={"DialogInput"}
                    autoFocus={true}
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={name}
                    onChange={onTextChange}
                    onKeyDown={(e) => e.keyCode == 13 ? onSubmitClicked(name) : null}
                    placeholder={"Please enter the name for new analysis"}
                />}
            </DialogContent>
            <DialogActions>
                {positiveBtnName &&
                    <StyledDialogOkButton
                        data-test-id={"DialogOkButton"}
                        disabled={!alert && isNameValid}
                        onClick={() => onSubmitClicked(name)}
                        variant="contained"
                        color="primary"
                        autoFocus>
                        {positiveBtnName}
                    </StyledDialogOkButton>
                }
                {negativeBtnName &&
                    <StyledDialogCloseButton data-test-id={"DialogCloseButton"} onClick={handleClose}>
                        {negativeBtnName}
                    </StyledDialogCloseButton>
                }
            </DialogActions>
        </Dialog>
    );
}


export default DialogInput;
