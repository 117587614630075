import * as React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

//Material ui
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core';
import { Divider, CardActions, IconButton } from '@material-ui/core';

//Store
import {ApplicationState} from "../../../store";
import PropTypes from 'prop-types';

//Assets
import HybridIcon from '../../../assets/images/powertrain-type-HYBR.svg';
import PHEVIcon from '../../../assets/images/powertrain-type-PHEV.svg';
import EVIcon from '../../../assets/images/powertrain-type-EV.svg';
import GasIcon from '../../../assets/images/powertrain-type-GAS.svg';
import { IMG_BASE_URL } from '../../../static/constants/urls'

//Chart
import { ResponsiveContainer, BarChart, Bar, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { tooltipFormatNumberToCurrency, formatMonthsToYearsOrMonths, formatNumberToCurrency, formatNumberToQuantity, formatDistance} from '../../../utils/helpers';
import { getPowertrainTypeDisplayString} from '../../../utils/helpers';
import { FlatButton } from 'material-ui';
import { StyledButton } from '../../layout/GreenButton';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
		link: {
			color: 'black'
		},
		card: {
			//height: 720,
			width: '100%',
			position: 'relative',
			borderRadius: '16px',
			boxShadow: "0 1px 5px 0 rgba(0,0,0,0.2), 0 2px 5px 0 rgba(0,0,0,0.14), 0 3px 5px -2px rgba(0,0,0,0.12)",
		},
		currentCard: {
			backgroundColor: '#fff4d9'
		},
		cardAbsHeader: {
			position: 'absolute',
			top: 0,
			'z-index': 100,
			width: '100%',
			height: '3rem',
			/*filter: 'drop-shadow(1px 2px 2px rgba(0,0,0,0.3))'*/
		},
		cardAbsHeaderLeft: {
			position: 'absolute',
			left: 0,
			height: '100%',
			padding: '12px'
		},
		cardAbsHeaderRight: {
			position: 'absolute',
			right: 0,
			height: '100%',
			padding: '12px'
		},
		media: {
			height: "140px",
			width: "260px",
			borderRadius: "15px",
			backgroundSize: "contain",
			//display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: "3.2rem" //Because the header position is absolute
		},
		carDetailSection: {
			//marginTop: "2rem", //Because the header position is absolute
			"& div:last-child": {
				paddingBottom: 0,
			}
		},
		yearMakeLabelContainer: {
			display: 'flex',
		},
		yearMakeLabel: {
			flex: 1,
		},
		currentLabel: {
			marginTop: '-2px',
		},
		ratingFeature: {
			marginBottom: '1rem',
		},
		carMainResultsLine: {
			padding: '4px 0',
		},
		carMainResultsValue: {
			fontWeight: 500,
			marginRight: '8px'
		},
		carDetailResultsValue: {
			fontSize: '1rem',
			fontWeight: 500,
			marginRight: '4px'
		},
		rankSpan: {
			padding: '0 8px',
			height: '24px',
			marginLeft: '16px',
			color: '#ffffff',
			backgroundColor: '#9db',
			textAlign: 'center',
			borderRadius: '12px',
			lineHeight: '24px',
			fontWeight: 500
		},
		cardActions: {
			padding: 0,
			height: '3rem',
			borderTop: '1px solid rgba(0, 0, 0, 0.08)',
			width: '100%',
		},
		actionButton: {
			height: '100%',
			textTransform: 'uppercase',
			margin: 0,
			width: '100%',
		},
		multipleActionsDiv: {
			width: '100%',
			height: '100%',
			margin: 0,
			"& button, & a": {
				width: '50%',
				"&:first-child": {
					borderRight: '1px solid rgba(0, 0, 0, 0.08)',
					borderRadius: 0,
				},
			},
		},
		expansionPanel: {
			marginTop: 0
		},
		resultsSectionTitle: {
			fontWeight: 500,
			textTransform: 'uppercase'
		},
		resultsDiv: {
			width: '100%',
			marginTop: '0.5rem 0',
		},
		resultsTableRow: {
			height: '1.6rem',
		},
		resultsTableCell: {
			fontSize: '0.9rem',
			border: 'none',
			padding: 0
		},
		resultsValueTableCell: {
			fontWeight: 500,
			width: '90px',
		},
		resultsLabelTableCell: {
			fontWeight: 300,
			"&:last-child": {
				paddingRight: 0
			}
		},
		chartContainer: {
			/*
			'& .recharts-tooltip-wrapper': {
				zIndex: '1000'
			}
			*/
		},
		noEstimatedPriceText: {
			textAlign: 'center',
			width: '100%',
			marginTop: '0.75rem'
		},
		tcoLengthCaption: {
			marginBottom: '1rem',
		},
		carPictureContainer: {
			position: 'relative'
		},
		notEVLabel: {
			color: 'rgba(0, 0, 0, 0.56)',
			lineHeight: '6rem',
		}
	})
);

const carDetailSectionHeight = '175px';
const optionsSectionHeight = '6rem';
const tcoSectionHeight = '13.8rem';
const evSectionHeight = '10.5rem';

interface IProps {
	car_profile: any,
	tco: any,
	emissions: number,
	history: any
}

function CompareCarCard(props: IProps){

    const classes = useStyles();
	const { car_profile, tco, emissions, history, ...otherProps } = props;

	//console.log("tco is ", tco)

	const car = car_profile,
		ownershipCost = tco,
		ownershipLengthString = formatMonthsToYearsOrMonths(tco ? tco.nb_months : 0)

	//console.log("ownership cost")
	//console.log(ownershipCost)
	
	let taxesType,
		tcoData,
		financingCost,
		financingString;

	let year, make, model, powertrain_type, msrp, epa_range;

	for (let i in car.attributes) {
		if (car.attributes[i].name == "Year")
			year = car.attributes[i].value
		if (car.attributes[i].name == "Make")
			make = car.attributes[i].value
		if (car.attributes[i].name == "Model")
			model = car.attributes[i].value
		if (car.attributes[i].name == "Powertrain")
			powertrain_type = car.attributes[i].value
		if (car.attributes[i].name == "MSRP")
			msrp = car.attributes[i].value
		if (car.attributes[i].name == "EPA Range")
			epa_range = car.attributes[i].value
	}

	if (car.sim_vehicle && car.sim_vehicle.powertrain_type)
		powertrain_type = car.sim_vehicle.powertrain_type

	const getPowertrainIndicator = (powertrain_type: string) => {
		if (powertrain_type === "EV") {
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-EV.svg" title={getPowertrainTypeDisplayString(powertrain_type)} className={classes.cardAbsHeaderLeft} style={{ }} />
		}
		else if (powertrain_type === "conventional") {
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-GAS.svg" title={getPowertrainTypeDisplayString(powertrain_type)} className={classes.cardAbsHeaderLeft} style={{ }} />
		}
		else if (powertrain_type === "HEV") {
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-HYBR.svg" title={getPowertrainTypeDisplayString(powertrain_type)} className={classes.cardAbsHeaderLeft} style={{ }} />
		}
		else if (powertrain_type === "PHEV") { // PHEV
			return <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/powertrain-type-PHEV.svg" title={getPowertrainTypeDisplayString(powertrain_type)} className={classes.cardAbsHeaderLeft} style={{ }} />
		} else { 
			return null
		}
	}

	const getNoEstimatedPricePrompt = () => {

		return (
			/*
			<Typography variant="body2" className={classes.noEstimatedPriceText}>
				<a href={`garage/single_vehicle/${car_profile.id}/?edit_price=true`} className={classes.link}>
					Set an estimated price for this car
				</a>

			</Typography>
			*/
			<StyledButton onClick={() => props.history.push('/fleet-analysis/incentives')} className={classes.noEstimatedPriceText}>
				Set a price for this vehicle
			</StyledButton>
		)

	}

	if (!car_profile.simulating) {
		//const annualCost = result.fuel_cost + result.maintenance + result.insurance + result.taxes;

		switch (ownershipCost.financing_method) {
			case 'buy':
			default:
				financingCost = typeof ownershipCost.car_purchase === 'undefined'? null : ownershipCost.car_purchase;
				financingString = "Purchase";
				break;

			case 'loan':
				financingCost = ownershipCost.loan + ownershipCost.down_payment;
				financingString = "Loan";
				break;

			case 'lease':
				financingCost = ownershipCost.lease + ownershipCost.down_payment;
				financingString = "Lease";
				break;

			case 'lease-buy':
				financingCost = ownershipCost.lease + ownershipCost.down_payment + ownershipCost.lease_buyout_price;
				financingString = "Lease & Buyout";
				break;
		}

		if (car_profile && ownershipCost) {
			if (ownershipCost.taxes >= 0) {
				taxesType = "Taxes";
				tcoData = [{
					name: "Total Cost of Ownership",
					Financing: financingCost,
					Fuel: ownershipCost.fuel_cons,
					Insurance: ownershipCost.insurance,
					Maintenance: ownershipCost.maintenance,
					Taxes: ownershipCost.taxes
				}];
			}
			else {
				taxesType = "Taxes";
				tcoData = [{
					name: "Total Cost of Ownership",
					Financing: financingCost,
					Fuel: ownershipCost.fuel_cons,
					Insurance: ownershipCost.insurance,
					Maintenance: ownershipCost.maintenance,
					Incentives: ownershipCost.taxes
				}];
			}
		}
	}

    //If there is an error retrieving an image
    const addDefaultSrc = (event: any) => {
        event.target.src = "https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/NoPhotoAvail.png"
	}
	
	return (
		<Card className={`${classes.card}`}>
			<div className={classes.cardAbsHeader}>
				{powertrain_type ?
					getPowertrainIndicator(powertrain_type)
				:
					<Typography className={[classes.resultsSectionTitle, classes.cardAbsHeaderLeft].join(' ')}>
						Unknown Powertrain Type
					</Typography>
				}
			</div>
			<div style={{ 
				display: "flex",
				width: "100%",
				alignItems: "center",
				justifyContent: "center"
			}}>
				<img
					className={classes.media}
					src={car.sim_vehicle ? car.sim_vehicle.image_url : "https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/NoPhotoAvail.png"}
					title={car.yearMakeModel}
					onError={(event) => addDefaultSrc(event)}
				>
				</img>
			</div>


			<CardContent className={classes.carDetailSection} style={{height: carDetailSectionHeight}}>
				<div className={classes.yearMakeLabelContainer}>
					<Typography variant="body2" className={classes.yearMakeLabel}>
						{year} {make}
					</Typography>
				</div>
				<Typography variant="h6">
					{model}
				</Typography>
					{ownershipCost.car_purchase != -1 ?
						<List>
							<ListItem className={classes.carMainResultsLine}>
								<Typography variant="body1">
									<span className={classes.carMainResultsValue}>{formatNumberToCurrency(ownershipCost.car_purchase)}</span>
									<span >Estimated Price</span>
								</Typography>
							</ListItem>
							<ListItem className={classes.carMainResultsLine}>
								<Typography variant="body1">
									<span className={classes.carMainResultsValue}>{formatNumberToCurrency(ownershipCost.new_tco)}</span>
									<span >Cost to own over {ownershipLengthString}</span>
								</Typography>
							</ListItem>
						</List>
					:
						getNoEstimatedPricePrompt()
					}
			</CardContent>

			<Divider light/>

			<CardContent style={{height: optionsSectionHeight}}>
				{
				//style={{height: optionsSectionHeight}}>
				}
				<Typography className={classes.resultsSectionTitle}>Options</Typography>
				<Typography variant="body2">
					{car.sim_vehicle ? car.sim_vehicle.option : "Unknown"}
				</Typography>
				{/*
				<Typography variant="h6">{getOptionsDisplayString(car.option)}</Typography>
				*/}
			</CardContent>

			<Divider light/>

			<CardContent style={{height: tcoSectionHeight}}>
				{ ownershipCost.car_purchase != -1 ?
					<div>
						<Typography className={classes.resultsSectionTitle}>{formatNumberToCurrency(ownershipCost.new_tco) + " " }Total Cost to Own</Typography>

						<Typography variant='caption' className={classes.tcoLengthCaption}>
							Over {ownershipLengthString}
						</Typography>
						{/* <ResponsiveContainer className={`${classes.resultsDiv} ${classes.chartContainer}`} width='100%' height={40} >
							<BarChart
								layout="vertical"
								data={tcoData}
								margin={{ top: -8 }}>
								{
								//<Tooltip cursor={false} formatter={tooltipFormatNumberToCurrency} />
								}
								<XAxis hide type="number" domain={[0, 'dataMax']} />
								<YAxis hide type="category" dataKey="name" />
								<Bar dataKey="Financing" stackId="ownershipCost" fill="#77bbee" />
								<Bar dataKey="Fuel" stackId="ownershipCost" fill="#8884d8" />
								<Bar dataKey="Insurance" stackId="ownershipCost" fill="#82ca9d" />
								<Bar dataKey="Maintenance" stackId="ownershipCost" fill="#efcd8a" />
							</BarChart>
						</ResponsiveContainer> */}
						<Table className={`${classes.resultsDiv}`}>
							<TableBody>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{formatNumberToCurrency(ownershipCost.loss_in_value)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Loss in value</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{ownershipCost.fuel_cons == 0 ? "$0" : formatNumberToCurrency(ownershipCost.fuel_cons)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Fuel</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{formatNumberToCurrency(ownershipCost.insurance)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Insurance</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{formatNumberToCurrency(ownershipCost.maintenance)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Maintenance</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>-${powertrain_type === "conventional" ? 0 : Number(128 * emissions * tco.nb_months / 12 / 1000).toFixed(2)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>LCFS Credits</TableCell>
								</TableRow>
								{/*
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{formatNumberToCurrency(ownershipCost.taxes)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>{taxesType}</TableCell>
								</TableRow>
								*/}
							</TableBody>
						</Table>
					</div>
				:
					<div>
						<Typography className={classes.resultsSectionTitle}>
							TCO Not Available
						</Typography>

						<Typography variant="body2" className={classes.noEstimatedPriceText}>
							An estimated price has not been set
							<br />
							for this car
						</Typography>

						<div style={{marginBottom: '1rem'}}>

						</div>

						<Table className={`${classes.resultsDiv}`}>
							<TableBody>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>-</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Loss in value</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>-</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Fuel</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>-</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Insurance</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>-</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Maintenance</TableCell>
								</TableRow>
								<TableRow className={classes.resultsTableRow}>
									<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>${powertrain_type === "" ? 0 : Number(128 * emissions * tco.nb_months / 12 / 1000).toFixed(2)}</TableCell>
									<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>LCFS Credits</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</div>
				}
			</CardContent>

			<Divider light/>

			
			<CardContent>
				<Typography className={classes.resultsSectionTitle}>Details</Typography>
					<Table className={`${classes.resultsDiv}`}>
						<TableBody>
							<TableRow className={classes.resultsTableRow}>
								<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{epa_range} miles</TableCell>
								<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>Electric Range</TableCell>
							</TableRow>
							<TableRow className={classes.resultsTableRow}>
								<TableCell className={`${classes.resultsValueTableCell} ${classes.resultsTableCell}`}>{powertrain_type === "EV" ? 0 : Number(emissions * tco.nb_months / 12 / 1000).toFixed(1)} tonnes</TableCell>
								<TableCell className={`${classes.resultsLabelTableCell} ${classes.resultsTableCell}`}>CO₂ Emissions / {tco.nb_months / 12} yrs
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
			</CardContent>
		</Card>
	)
}

export default CompareCarCard;