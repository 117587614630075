import React from 'react';

import {createStyles, makeStyles, Theme, withStyles, WithStyles} from '@material-ui/core';

import {LocationsResponse, SimulationResponse} from '../../store/charging-page/types';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {connect} from "react-redux";
import {init, RootStore, thunkAction, unboxThunk} from "../../redux";

const styles = (theme: Theme) => createStyles({
    table: {
        backgroundColor: 'white !important',
    },
	tableRow: {
        backgroundColor: 'white !important',
        "&:hover": {
            backgroundColor: "lightgray !important"
        }
    },
    tableRowSelected: {
        backgroundColor: "rgba(140, 219, 179, 0.27) !important"
    }
});

//////////////////////////////////////////////////////////////////

interface Props extends WithStyles<typeof styles> {
    locations: LocationsResponse[]
    selectedIDs: number[]
    simulationResponse: SimulationResponse
    setLocationsData: any
    selectEvent: (selectedIDs: number[]) => void
    onChangeLocation: (locatioon: any, lat: number, lon: number) => void
}

interface IState {
    selectedLocation: number
}

class DetailedExpansionPanel extends React.PureComponent<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedLocation: -1
        }

        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
        this.handleChangeOneLocation = this.handleChangeOneLocation.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.isSelected = this.isSelected.bind(this);
    }


    handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const newSelected = this.props.locations.map(n => n.id);
            this.props.selectEvent(newSelected);
            return;
        }
        this.props.selectEvent([]);
    }

    handleChangeOneLocation(location: any, name: string) {
        const locations = JSON.parse(JSON.stringify(this.props.locations));

        locations.forEach((loc: any) => {
            if (loc.id === location.id) {
                loc[name] = location[name];
            }
        });
        this.props.setLocationsData(locations);
    }

    handleSelectClick(event: React.MouseEvent<unknown>, id: number) {
        const selectedIndex = this.props.selectedIDs.indexOf(id);
        let newSelected: number[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.props.selectedIDs, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.props.selectedIDs.slice(1));
        } else if (selectedIndex === this.props.selectedIDs.length - 1) {
            newSelected = newSelected.concat(this.props.selectedIDs.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.props.selectedIDs.slice(0, selectedIndex),
                this.props.selectedIDs.slice(selectedIndex + 1),
            );
        }

        this.props.selectEvent(newSelected);
    }

    isSelected(id: number) {
        return this.props.selectedIDs.indexOf(id) !== -1;
    }

    locationSelected(location: any, index: number) {
        this.setState({
            selectedLocation: location.id
        })

        this.props.onChangeLocation(location, location.geo.lat, location.geo.lon)
    }

    render() {
        console.log(this.props.locations)
        const { selectedLocation } = this.state
        const { classes } = this.props;

        return (
            <div className={classes.table} style={{maxHeight: 'calc(100% - 110px)', overflowY: 'auto'}}>

                <Table aria-label="a table" stickyHeader style={{maxHeight: '100%'}}>
                    <TableHead style={{backgroundColor: 'white !important'}}>
                        <TableRow style={{marginLeft: 32, backgroundColor: 'white !important'}}>
                            <TableCell >Your Fleet Vehicles</TableCell>
                            <TableCell >Total Chargers{/* <br/>(L1, L2, DC)*/}</TableCell>
                            <TableCell >Chargers Needed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.table} >
                        {
                            this.props.locations.map((location: LocationsResponse, index: number) => {

                                let loc;
                                if (this.props.simulationResponse) {
                                    loc = this.props.simulationResponse.find((e: any) => e.id === location.id);
                                }

                                return (
                                    <TableRow key={location.name}
                                              onClick={(event) => this.locationSelected(location, index)}
                                              hover={true}
                                              selected={location.id == selectedLocation}
                                              className={location.id == selectedLocation ? classes.tableRowSelected : classes.tableRow}
                                    >
                                        <TableCell component="th" scope="row" >
                                            {location.name}
                                        </TableCell>
                                        <TableCell align="center">{location.num_total_chargers}{/*<br/>({location.l1_charger_count}, {location.l2_charger_count}, {location.dc_charger_count})*/}</TableCell>
                                        <TableCell align="center">{loc ? loc.chargers.level_2 : ''}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            
            </div>
        )
    }
}

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
        init,
        thunkAction: unboxThunk(thunkAction),
    }
);

export default storeEnhancer((withStyles(styles)(DetailedExpansionPanel)));
