import {call, put, takeLatest} from 'redux-saga/effects'
import { VehiclesActionTypes } from './types'
import {
    fetchError, fetchAttributes, fetchSuccess, fetchVehicles, fetchVehiclesError,
    fetchVehiclesSuccess, setCurrentQueryForSimVehiclesSaving, setMappingPrevSimVehicleSuggest, setSelectedVehicles,
    setSimVehicleAttributes,
    setSimVehicleSuggest, setGroupByAttribute,
    setVehicleMapping, fetchAvailableDateRange, fetchAvailableDateRangeError, fetchAvailableDateRangeSuccess
} from './actions'
import { callApi, callApi1 } from '../../utils/api'

function* handleFetch(action: ReturnType<typeof fetchAttributes>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res = yield call(callApi, 'get', '/vehicles/attributes?type=' + action.payload);

    if (res.error) {
      yield put(fetchError(action.payload, res.error))
    } else {
      yield put(fetchSuccess(action.payload, res))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(fetchError(action.payload, err.stack))
    } else {
      yield put(fetchError(action.payload, 'An unknown error occured.'))
    }
  }
}
function* handleVehicles(action: ReturnType<typeof fetchVehicles>) {
  try {
    let callApiUrl: string;
    console.log("fetchVehicles payload url: : ", action.payload.url);
    console.log("fetchVehicles payload: ", action.payload);
    if(action.payload.type === "simvehicles")
      callApiUrl = '/vehicles/?type='+action.payload.type+'&query=' + action.payload.url;
    else
      callApiUrl = '/vehicles/?query=' + action.payload.url;

    console.log("call url: ", callApiUrl);
    const res = yield call(callApi, 'get', callApiUrl);

   if (res.error) {
      yield put(fetchVehiclesError({message: res.error, type: action.payload.type}))
    } else {
      yield put(fetchVehiclesSuccess({data: res, type: action.payload.type}))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(fetchVehiclesError({message: err.stack, type: action.payload.type}))
    } else {
      yield put(fetchVehiclesError({message: 'An unknown error occured.', type: action.payload.type}))
    }
  }
}

// function* handleSuggestAttributes(action: ReturnType<typeof fetchSuggestAttributes>) {
//   try {
//     const res = yield call(callApi, 'get', `vehicles/attributes/suggest/${action.payload.attribute_id}/?query=${action.payload.query}/`)
//
//    if (res.error) {
//       yield put(fetchError(res.error))
//     } else {
//       yield put(fetchSuccess(res))
//     }
//   } catch (err) {
//     if (err instanceof Error && err.stack) {
//       yield put(fetchError(err.stack))
//     } else {
//       yield put(fetchError('An unknown error occured.'))
//     }
//   }
// }

function handleSetSimVehicleSuggest(action: ReturnType<typeof setSimVehicleSuggest>) {
  setSimVehicleSuggest(action.payload);
}

function handleSetSelectedVehicles(action: ReturnType<typeof setSelectedVehicles>) {
  setSelectedVehicles(action.payload);
}
function handleSetVehicleMapping(action: ReturnType<typeof setVehicleMapping>) {
  setVehicleMapping(action.payload);
}
function handleSetSortSelectedByGroup(action: ReturnType<typeof setGroupByAttribute>){
  setGroupByAttribute(action.payload);
}
function handleSetSimVehicleAttributes(action: ReturnType<typeof setSimVehicleAttributes>){
  setSimVehicleAttributes(action.payload);
}
function handleSetMappingPrevSimVehicleSuggest(action: ReturnType<typeof setMappingPrevSimVehicleSuggest>){
  setMappingPrevSimVehicleSuggest(action.payload);
}
function handleSetCurrentQueryForSimVehiclesSaving(action: ReturnType<typeof setCurrentQueryForSimVehiclesSaving>){
  setCurrentQueryForSimVehiclesSaving(action.payload);
}

function* handleFetchAvailableDateRange(action: ReturnType<typeof fetchAvailableDateRange>) {
  try {
    let res = yield call(callApi1, 'get', `/driving_route/driving_routes_meta/`);
    if (!res.error) {
      console.log(res)
      yield put(fetchAvailableDateRangeSuccess(res))
    } else {
      yield put(fetchAvailableDateRangeError(res.error))
    }
  } catch(err) {
    if (err instanceof Error && err.stack) {
      yield put(fetchAvailableDateRangeError(err.stack))
    } else {
      yield put(fetchAvailableDateRangeError('An unknown error occured.'))
    }
  }
}



// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.

function* vehiclesWatcherSaga() {
  yield takeLatest(VehiclesActionTypes.FETCH_REQUEST, handleFetch);
  yield takeLatest(VehiclesActionTypes.FETCH_VEHICLES, handleVehicles);
  yield takeLatest(VehiclesActionTypes.SET_SIM_VEHICLE_SUGGEST, handleSetSimVehicleSuggest);
  yield takeLatest(VehiclesActionTypes.SET_SELECTED_VEHICLE, handleSetSelectedVehicles);
  yield takeLatest(VehiclesActionTypes.SET_VEHICLE_MAPPING, handleSetVehicleMapping);
  yield takeLatest(VehiclesActionTypes.SET_MAPPING_PREV_SIM_VEHICLE_SUGGEST, handleSetMappingPrevSimVehicleSuggest);
  yield takeLatest(VehiclesActionTypes.SET_GROUP_BY_ATTRIBUTE, handleSetSortSelectedByGroup);
  yield takeLatest(VehiclesActionTypes.SET_SIM_VEHICLE_ATTRIBUTES, handleSetSimVehicleAttributes);
  yield takeLatest(VehiclesActionTypes.SET_CURRENT_QUERY_FOR_SIM_VEHICLES, handleSetCurrentQueryForSimVehiclesSaving);
  yield takeLatest(VehiclesActionTypes.FETCH_AVAILABLE_DATE_RANGE, handleFetchAvailableDateRange);
}

export default vehiclesWatcherSaga;
