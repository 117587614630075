import ChargeMarker from './ChargeMarker';
import React, {useState, useEffect, ReactElement} from 'react';
import iconRed from '../../../assets/images/Car_icon_red.png';
import iconGreen from '../../../assets/images/Car_icon_green.png';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {GoogleMap, Marker, withGoogleMap, withScriptjs, Polyline} from 'react-google-maps';
import m4 from '../../../assets/images/m4.png';

interface IProps {
    selectedRoute: IDrivingRoutes[] | null,
    selectedOneRoute?: any,
    routePositions?: any,
    centerCoords: {
        lng: number;
        lat: number;
    } | undefined,
    charging: any,
    selectedLocationID?: number,
    zoom?: number,
    googleMapURL: string,
    loadingElement?: ReactElement<any, string>,
    containerElement?: ReactElement<any, string>,
    mapElement?: ReactElement<any, string>,
    showChargingSitesModal?: (event: any) => void
}
export const initialLocation = {
    lng: 34.0522,
    lat: 118.2437,
};

export interface IDrivingRoutes {
    "id": string,
    "distance": number,
    "end_longitude": number,
    "end_time": string,
    "end_latitude": number,
    "start_time": string,
    "start_longitude": number,
    "start_latitude": number,
    "end_charging": null,
    "results": { }
}


const MapView = withScriptjs(withGoogleMap((props: IProps) => {
    let centerCoords = initialLocation;
    
    if(props.centerCoords && !!Object.keys(props.centerCoords).length){
        centerCoords.lat = props.centerCoords.lat;
        centerCoords.lng = props.centerCoords.lng;
    }
    const [currentLocation, setCurrentLocation] = useState(centerCoords);
    const [locationSelected, setLocationSelected] = useState(false);

    useEffect(() => {
        if(props.selectedRoute) {
            setCurrentLocation({
                lat: props.selectedRoute[0].start_latitude,
                lng: props.selectedRoute[0].start_longitude
            })
            setLocationSelected(true)
        }
    }, [props.selectedRoute]);

    const renderChargesMarkers = () => props.charging.map((charge: any) => (
            <ChargeMarker showChargingSitesModal={props.showChargingSitesModal} key={charge.id} click={props.selectedLocationID == charge.id ? true : false} chargeSite={charge} lat={charge.latitude} lng={charge.longitude}/>
        )
    );

    const renderRouteMarkers = () => {
        if(props.selectedRoute) {
            return props.selectedRoute.map((route: IDrivingRoutes) => {
                const start = { lat: route.start_latitude, lng: route.start_longitude };
                const end = { lat: route.end_latitude, lng: route.end_longitude };
                const style = {size: {width: 33, height: 20}, anchor: {x: 15, y: 50}, scaledSize: {width: 33, height: 20}};
                return (
                    <React.Fragment key={route.id}>
                        <Marker icon={{ url: iconGreen, size: style.size, scaledSize: style.scaledSize}} position={start}/>
                        <Marker icon={{ url: iconRed, size: style.size, scaledSize: style.scaledSize}} position={end}/>
                    </React.Fragment>
                )
            })
        }
    };

    const renderRoutePositions = () => {
        if(props.routePositions && props.routePositions.length > 0) {
            let path = []
            for (let i in props.routePositions) {
                path.push({
                    lat: props.routePositions[i].latitude,
                    lng: props.routePositions[i].longitude
                });
            }

            return (
                <React.Fragment>
                    <Polyline
                        path={path}
                        options={{
                            strokeColor: "#324A61",
                            strokeOpacity: 0.75,
                            strokeWeight: 2,
                        }}
                    />
                </React.Fragment>
            )
        }
    };

    const renderOutRoute = () => {
        if(props.selectedOneRoute) {

            return props.selectedOneRoute.map((route: any, n: number) => {

                let onePath;

                if(props.selectedOneRoute.length > 1) {

                    onePath = props.selectedOneRoute.slice(0, 2);

                    onePath = onePath.map((route: any, i: number) => {
                        return {lat: route.latitude, lng: route.longitude};
                    });
                } else {
                    const start = { lat: route.start_latitude, lng: route.start_longitude };
                    const end = { lat: route.end_latitude, lng: route.end_longitude };

                    onePath = [start, end];
                }

                return (
                    <React.Fragment key={route.id}>
                        <Polyline
                            path={onePath}
                            options={{
                                strokeColor: "#50cb83",
                                strokeOpacity: 1,
                                strokeWeight: 5,
                                icons: [
                                    {
                                        offset: "0",
                                        repeat: "10px"
                                    }
                                ]
                            }}
                        />
                    </React.Fragment>
                )
            });
        }
    };

    return (
        <GoogleMap
            key={currentLocation.lat}
            defaultZoom={props.zoom || 8}
            defaultCenter={currentLocation}>
            <MarkerClusterer key={'MarkerClustererForChargingSite'}
                averageCenter
                enableRetinaIcons
                gridSize={60}
                maxZoom={16}>
                {renderChargesMarkers()}
            </MarkerClusterer>
            <MarkerClusterer
                averageCenter
                enableRetinaIcons
                gridSize={30}
                styles={[
                    {
                      url: m4,
                      height: 53,
                      lineHeight: 53,
                      width: 53,
                    }
                  ]}>
            {renderRouteMarkers()}
            {renderRoutePositions()}
            {//renderOutRoute()
            }
            </MarkerClusterer>
        </GoogleMap>
    )
}));

export default MapView;
