import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IMG_BASE_URL } from '../../../store/car_legacy/urls';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import IconButton from '@material-ui/core/IconButton';
import CarListItem from './CarListItem';
import Backdrop from '@material-ui/core/Backdrop';

import { withTranslation } from 'react-i18next';

const mapStateToProps = state => {
    return {
        // carProfiles: state.cars.carProfiles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // deleteCarProfile: (car_id) => dispatch(deleteCarProfile(car_id))
    };
};

const styles = theme => ({
    GarageDrawerContainer: {
        position: "fixed",
        bottom: "0px",
        right: "60px",
        zIndex: 100,
        minWidth: 400,
        fontFamily: theme.typography.fontFamily,
        transform: "translateY(100%)"
    },
    GarageDrawerContent: {
        boxShadow: "0 1px 5px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12)",
        transform: "translateY(0)",
        transition: "transform 400ms cubic-bezier(0.4,0,0.2,1)",
    },
    isVisible: {
        transform: "translateY(-48px)",
    },
    isExpanded: {
        transform: "translateY(-100%)"
    },
    header: {
        display: "flex",
        height: "48px",
        alignItems: "center",
        background: "#222",
        color: "#fff"
    },
    headerText: {
        paddingLeft: 28,
        flex: 1
    },
    showMoreButton: {
        color: "#fff"
    },
    garageDrawerBody: {
        background: "#fff",
        padding: "1rem"
    },
    backdrop: {
        transition: "opacity 400ms cubic-bezier(0.4,0,0.2,1)",
        zIndex: -1
    },
    backdropVisible: {
        zIndex: 90,
        opacity: "1 !important"
    }
})

class GarageDrawer extends React.Component {
    state = { expanded: false };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { classes, t } = this.props;
        
        let drawerClasses = classes.GarageDrawerContent + ' ';
        if (this.props.sim_vehicles && this.props.sim_vehicles.length) {
            drawerClasses += classes.isVisible + ' ';
        }
        drawerClasses += (this.state.expanded ? classes.isExpanded : '');

        let backdropClasses = classes.backdrop + ' ' + (this.state.expanded ? classes.backdropVisible : '')
        return (

            <div>
                <Backdrop className={backdropClasses} open={this.state.expanded}/>
                <div className={classes.GarageDrawerContainer}>
                    <div className={drawerClasses}>
                        <div className={classes.header}>
                            <div className={classes.headerText}>
                                {this.props.sim_vehicles ? this.props.sim_vehicles.length: 0} {"selected"}
                            </div>
                            <IconButton onClick={this.handleExpandClick} aria-label="Show" className={classes.showMoreButton}>
                                <UnfoldMoreIcon fontSize="small" />
                            </IconButton>
                        </div>
                        <div className={classes.garageDrawerBody}>
                            <div>
                                {this.props.sim_vehicles && this.props.sim_vehicles.map(car => {
                                    return (
                                        <CarListItem key={car.sim_vehicle_id} car_profile={car} secondaryAction={this.props.removeSimVehicle}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GarageDrawer)));