import * as React from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import {StyledTableCell, StylesTableBody, StylesTableHead} from "../layout/TableBaseStyles";
import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import {Vehicle} from "../../helpData/get_vehicles_response_example";
import {AttributesResponseInterface} from "../../helpData/getVehicleAttributes";
import {AttributeValue} from "../../store/vehicles/types";


interface IProps {
    data: any,
    dataTestId: string,
    type_table: "filter" | "keyword",
    attributes?: AttributesResponseInterface[],
    noItemText?: string,

    handleRemove: (event: any, vehicle_id: number, type_table: "filter" | "keyword") => void;
}

interface IState {
    noItemText: string,
}

class SimVehiclesTable extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            noItemText: this.props.noItemText || 'No items',
        };
    }


    render() {
        console.log("row is")
        console.log(this.props.data)

        return (
            <Table
                key={"TableSimVehicleSuggest"}
                aria-labelledby="tableTitle"
                size={'small'}
                aria-label="enhanced table"
            >
                {this.props.type_table === "filter" && this.props.attributes && this.props.attributes.length && (
                    <StylesTableHead>
                        <TableRow key={'SimVehicleSuggest_RowHead_' + this.props.dataTestId}>
                            {/*
                            <StyledTableCell padding="none" style={{width: "60px"}}/>
                            {this.props.attributes.map((attribute: AttributesResponseInterface, i: number) => (
                                <StyledTableCell
                                    key={"TableSimVehicleSuggest_Head_Cell_" + i}
                                >{attribute.name}</StyledTableCell>
                            ))}
                            */}
                            <StyledTableCell padding="none" style={{width: "60px"}}/>
                            {/*<StyledTableCell>Image</StyledTableCell>*/}
                            <StyledTableCell>Model Year</StyledTableCell>
                            <StyledTableCell>Make</StyledTableCell>
                            <StyledTableCell>Model</StyledTableCell>
                            <StyledTableCell>EPA range</StyledTableCell>
                            <StyledTableCell>Powertrain Type</StyledTableCell>
                        </TableRow>
                    </StylesTableHead>
                )}

                {this.props.type_table !== "filter" && (
                    <StylesTableHead>
                        <TableRow key={'SimVehicleSuggest_RowHead_' + this.props.dataTestId}>
                            <StyledTableCell padding="none" style={{width: "60px"}}/>
                            {/*<StyledTableCell>Image</StyledTableCell>*/}
                            <StyledTableCell>Model Year</StyledTableCell>
                            <StyledTableCell>Make</StyledTableCell>
                            <StyledTableCell>Model</StyledTableCell>
                            <StyledTableCell>EPA range</StyledTableCell>
                            <StyledTableCell>Powertrain Type</StyledTableCell>
                        </TableRow>
                    </StylesTableHead>)
                }


                <StylesTableBody style={{fontWeight: 300}}>

                    {this.props.type_table === 'keyword' && typeof this.props.data !== 'undefined' && this.props.data.map((row: SimVehicleSuggest, i: number) => {
                        return (
                            <TableRow key={'SimVehicleSuggest_Row_' + this.props.dataTestId + '_' + i}>
                                <StyledTableCell padding="none"
                                                style={{width: '30px', paddingLeft: '12px'}}>
                                    <IconButton aria-label="delete"
                                                onClick={(event: any) => this.props.handleRemove(event, row.sim_vehicle_id, this.props.type_table)}>
                                        <ClearIcon color={'disabled'}/>
                                    </IconButton>
                                </StyledTableCell>
                                {/*<StyledTableCell colSpan={1}>*/}
                                {/*<Avatar variant="square" src={row.image_url}>*/}
                                {/*</Avatar>*/}
                                {/*</StyledTableCell>*/}
                                <StyledTableCell colSpan={1}>{row.year}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{row.make}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{row.model}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{row.epa_range}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{row.powertrain_type}</StyledTableCell>
                            </TableRow>
                        )
                    })}

                    {this.props.type_table === 'filter' && typeof this.props.data !== 'undefined' && this.props.data.map((sim_vehicle: Vehicle, i: number) => {
                        if (sim_vehicle.sim_vehicle) {
                            return (
                            <TableRow key={'SimVehicleSuggest_Row_' + this.props.dataTestId + '_' + i}>
                                <StyledTableCell padding="none"
                                                style={{width: '30px', paddingLeft: '12px'}}>
                                    <IconButton aria-label="delete"
                                                onClick={(event: any) => this.props.handleRemove(event, sim_vehicle.vehicle_id, this.props.type_table)}>
                                        <ClearIcon color={'disabled'}/>
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>{sim_vehicle.sim_vehicle.year}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{sim_vehicle.sim_vehicle.make}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{sim_vehicle.sim_vehicle.model}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{sim_vehicle.sim_vehicle.epa_range}</StyledTableCell>
                                <StyledTableCell colSpan={1}>{sim_vehicle.sim_vehicle.powertrain_type}</StyledTableCell>
                            </TableRow>
                            )
                        } else {
                            return (
                            <TableRow key={'SimVehicleSuggest_Row_' + this.props.dataTestId + '_' + i}>
                                <StyledTableCell padding="none"
                                                    style={{width: '30px', paddingLeft: '12px'}}>
                                    <IconButton aria-label="delete"
                                                onClick={(event: any) => this.props.handleRemove(event, sim_vehicle.vehicle_id, this.props.type_table)}>
                                        <ClearIcon color={'disabled'}/>
                                    </IconButton>
                                </StyledTableCell>
                                {
                                    typeof this.props.attributes !== 'undefined' && this.props.attributes.map((a: AttributesResponseInterface) => {
                                        return sim_vehicle.attributes
                                            .filter((a2) => a.attribute_id === a2.attribute_id)
                                            .map((a2: AttributeValue) => {
                                                return (<StyledTableCell
                                                    key={"SimVehicleSuggest_Head_Cell_" + this.props.dataTestId + '_' + i}
                                                    colSpan={1}>{a2.value}</StyledTableCell>)
                                        })
                                    })
                                }
                            </TableRow>
                            )
                        }
                    })}

                </StylesTableBody>
            </Table>
        )
    }
}

export default SimVehiclesTable;
