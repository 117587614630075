import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Switch,
  } from 'react-router-dom';
  import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core';

import styled from '../../utils/styled'
import Container from './Container'
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from '@material-ui/icons/Person';
import Menu, {MenuProps} from "@material-ui/core/Menu";
import {NavLink, withRouter} from 'react-router-dom';
import DialogInput from '../Modals/DialogInput';
import {ApplicationState} from "../../store";
import Button from '@material-ui/core/Button';
import DialogCustomReportModal from "../Modals/DialogCustomReportModal";
import DialogReportDownloader from "../Reporting/DialogReportDownloader";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Grid, CircularProgress } from '@material-ui/core';

//Store
import {setAnalysisNameAction} from '../../store/analysis/actions';
import { truncate } from 'lodash';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
		stepIcon: {
            "&$active": {
                color: '#26394e'
            },
            "&$completed": {
                color: '#26394e'
            }
        },
        active: {},
        completed: {},
        stepLabel: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            },
        }
	})
);


const Wrapper = styled('header')`
  padding: 11px ${props => props.theme.containerPadding };
  background-color: ${props => props.theme.colors.header_bg};
  color: ${props => props.theme.colors.brand};
  font-family: ${props => props.theme.fonts.headings};
`;

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MenuContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HeaderNavLink = styled(NavLink)`
    font-family: Roboto;
    margin-left: 12px;
    margin-right: 12px;
    &.is-active,
    &:hover,
    &:focus{
    text-decoration: none;
    }
    &.is-active{
        font-size: 18px;
        font-weight: 500;
    }
  
`;

const AvatarButton = styled(IconButton)`
  &.MuiIconButton-root {
  display: inline-block;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.avatar_bg};
  color: ${props => props.theme.colors.pickled_bluewood};
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 8px;
  margin: 0 20px;
  }
  & .MuiIconButton-label {
    font-size: 16px;
  }
`;

const UserIcon = styled(PersonIcon)`
 &.MuiSvgIcon-root {
    color: ${props => props.theme.colors.pickled_bluewood};
    width: 100%;
    height: 100%;
}`;

const TransparentButton = styled('button')`
    background-color: transparent;
    border-color: ${props => props.theme.colors.pickled_bluewood};
    color: ${props => props.theme.colors.pickled_bluewood};
    margin: 0 20px;
    border-width: 1px;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1.38;
    font-weight: 500;
    cursor: pointer;
    &:active, :hover {    
        background-color: ${props => props.theme.colors.active_link};
    }
    &:disabled {
        background-color: ${props => props.theme.colors.active_link};
        cursor: progress;
    }
`

const ButtonsWrapper = styled('p')`
    margin: 0;
`;

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        padding: '0 10px',
        display: 'flex',
        maxHeight: '100%',
        width: '150px',
        '& ul.MuiMenu-list.MuiList-padding': {
            width: '100%',
            '& .MuiListItemIcon-root': {
                color: '#000000',
            }
        }
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

interface HeaderTopProps {
    user: string
    state: ApplicationState
}

interface PropsFromState {
    errors?: string,
    loading: boolean,
}

interface PropsFromDispatch {
    setAnalysisNameAction: typeof setAnalysisNameAction
}

type AllProps = HeaderTopProps & PropsFromState & PropsFromDispatch



function HeaderTop(props: AllProps) {
    // @ts-ignore
    const {history, setAnalysisName, changeOrSaveResults, createNewAnalysis, state, vehicles} = props;

    const [activeStep, setActiveStep] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [isDialogCustomReportVisible, setDialogCustomReportVisible] = useState(true);

    const classes = useStyles();



    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogOut = () => {
        window.localStorage.removeItem("authenticated");
        document.cookie = 'csrftoken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        history.push('/')
        window.location.reload();
    };

    const renderCustomizeReport = () => {
        console.log("renderCustomizeReport")
    };

    useEffect(() => {
        history.listen((location: any, action: any) => {
            //Reset active step if coming back to vehicles page from left route menu
            if (history.location.pathname.startsWith('/fleet-analysis/vehicles')) {
                setActiveStep(0)
            }
    
            if(action === 'PUSH' && location.pathname !== '/') {
                const state = location.state;
                if(state && state.prevPath.lastIndexOf('/analysis_id') !== -1) {
                    const result = state.prevPath.substr(state.prevPath.lastIndexOf('/analysis_id'), state.prevPath.length);
                    history.replace(location.pathname + result);
                }
            }
        });
    }, [history]);

    const onNextStep = () => {
        let newStep = activeStep + 1;
        
        if (vehicles && vehicles.vehicles) {
            if (vehicles.vehicles.length > 300) {
                alert("You have more than 300 vehicles in your analysis. This exceeds your current limit of simultaneous simulations. Please add more filters to reduce the number of vehicles to below 100 and try again.\nContact us if you need assistance.")
            } else {
                if (newStep == 4) {
                    history.push('/fleet-analysis/result/:analysis_id')
                    setActiveStep(0)
                } else {
                    history.push(stepLink[newStep])
                    setActiveStep(newStep)
                }
            }
        } else {
            alert("Vehicles have not finished loading. Please wait and try again.")
        }
        
    };

    const onLastStep = () => {
        let newStep = activeStep - 1;
        history.push(stepLink[newStep])
        setActiveStep(newStep)
    };

    const steps = ["Filter Vehicles", "Select Replacement", "Vehicle Price & Incentives", "Run Analysis"];

    const stepLink = ['/fleet-analysis/vehicles', '/fleet-analysis/replacement', '/fleet-analysis/incentives', '/fleet-analysis/run_analysis'];

    const renderMenu = () => {

        if(history.location.pathname !== '/') {
            console.log(history.location.pathname)
            if(history.location.pathname.startsWith('/settings')) {
                return (
                    <MenuContainer>
                        <HeaderNavLink to={{pathname: '/settings', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                            Settings
                        </HeaderNavLink>
                    </MenuContainer>
                );
            } else if(history.location.pathname.startsWith('/fleet-analysis/result')) {
                return (
                    <MenuContainer>
                        <HeaderNavLink to={{pathname: '/fleet-analysis/result/:analysis_id', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                            Results
                        </HeaderNavLink>
                    </MenuContainer>
                );
            } else if (history.location.pathname == '/fleet-analysis/sites/:analysis_id') {
                return (
                    <MenuContainer>

                        <HeaderNavLink to={{pathname: '/fleet-analysis/sites/:analysis_id', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                            Charging Sites
                        </HeaderNavLink>
                        
                    </MenuContainer>
                );
            } else {
                return (
                    /*
                    <MenuContainer>
                        <HeaderNavLink to={{pathname: '/fleet-analysis/vehicles', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                            Filter Vehicles
                        </HeaderNavLink>
                        {'>'}
                        <HeaderNavLink to={{pathname: '/fleet-analysis/replacement', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                            Select Replacement
                        </HeaderNavLink>
                        {'>'}
                        <HeaderNavLink to={{pathname: '/fleet-analysis/incentives', state: { prevPath: window.location.pathname }}} activeClassName="is-active">
                            Incentives
                        </HeaderNavLink>
                        {'>'}
                        <HeaderNavLink
                            to={{pathname: '/fleet-analysis/run_analysis', state: { prevPath: window.location.pathname }}}
                            activeClassName="is-active"
                        >
                            Run Analysis
                        </HeaderNavLink>
                    </MenuContainer>
            */
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item style={{width: 'auto'}} container justify="flex-end">
                                <Button variant="outlined" disabled={activeStep == 0} onClick={onLastStep}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs >
                                <Stepper 
                                    style={{backgroundColor: 'transparent', padding: 0}}
                                    activeStep={activeStep
                                    }
                                >

                                    {steps.map((step: string, index: number) => {
                                        return (
                                            <Step key={index}>
                                                <StepLabel
                                                    StepIconProps={{
                                                        classes: { 
                                                            root: classes.stepIcon,
                                                            active: classes.active,
                                                            completed: classes.completed
                                                        }
                                                    }}
                                                    
                                                >
                                                    <Typography className={classes.stepLabel} variant="body2">
                                                        {step}
                                                    </Typography>
                                                </StepLabel>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </Grid>
                            <Grid item style={{width: 'auto'}} container justify="flex-start">
                                <Button variant="outlined" onClick={onNextStep}>
                                    {activeStep != 3 ? "Next" : "Finish"}
                                </Button>
                            </Grid>
                        </Grid>
                );
            }
        }
        return <div/>
    };

    const onDialogCustomReportCloseClicked = () => setDialogCustomReportVisible(false);

    const onSaveAnalysisClicked = () => {
        changeOrSaveResults(state);
    };
    const onCreateAnalysisClicked = () => setDialogVisible(true);
    const onDialogCloseClicked = () => setDialogVisible(false);

    const renderButton = () => {
        const data = {
            handler: () => {},
            name: ''
        };
        if(history.location.pathname === '/') {
            //data.name = 'Create Analysis';
            //data.handler = onCreateAnalysisClicked;
            return null
        } else {
            data.name = 'Save Analysis';
            data.handler = onSaveAnalysisClicked;
        }
        
        return (
            <Button
                variant="outlined"
                disabled={state.resultsPage.loading}
                onClick={data.handler}
                style={{marginLeft: 10}}
            > 
                {data.name}
            </Button>
        );
    };

    const onSubmitClicked = (name: string) => {
        setDialogVisible(false);
        history.push('/fleet-analysis/vehicles');
        createNewAnalysis();
        setAnalysisName(name);
        // saveResults(state);
    };

    const handleClickCustomizeExport = (event: any) => {
         console.log("handleClickCustomizeExport clicked");
        //  setDialogCustomReportVisible(true);
         const hash: string = state.chargingPage.simulation_uuid;
         const token: any = window.localStorage.getItem("authenticated");
         const analysisId = state.resultsPage.id;
         const filterQuery = {date_range: state.vehicle.selected_date_range,
            attributes: state.vehicle.selected_attributes.filter((a) => a.selected && a.value.length > 0)};
        
         // need to display the dialog modal for the custom report
         console.log("hash: " + hash + ", token: " + token + ", analysisId: " + analysisId
            + ", filterQuery: " + filterQuery);

        
    };

    const getExportResultURL = () => {
        const hash: string = state.chargingPage.simulation_uuid;
        const token: any = window.localStorage.getItem("authenticated");
        return process.env.REACT_APP_API_URL
            + "/api2/report?compute_hash="
            + hash
            + "&token=" + token
            + "&analysis_id=" + state.resultsPage.id
            + "&analysis_name=" + state.homePage.analysisName
            + "&filter_query=" + JSON.stringify({
                date_range: state.vehicle.selected_date_range,
                attributes: state.vehicle.selected_attributes.filter((a) => a.selected && a.value.length > 0)
            })
    };
    let filterQueryString = JSON.stringify({
        date_range: state.vehicle.selected_date_range,
        attributes: state.vehicle.selected_attributes.filter((a) => a.selected && a.value.length > 0)
    });

    return (
        <Wrapper>
            <HeaderInner 
                style={
                    history.location.pathname.startsWith('/fleet-analysis/sites') ||
                    history.location.pathname.startsWith('/fleet-analysis/result') || 
                    history.location.pathname.startsWith('/fleet-analysis/vehicles') || 
                    history.location.pathname.startsWith('/fleet-analysis/replacement') || 
                    history.location.pathname.startsWith('/fleet-analysis/incentives') || 
                    history.location.pathname.startsWith('/fleet-analysis/run_analysis') ? {minWidth: '100%'} :
                    history.location.pathname !== '/' ? {minWidth: '90%'} :
                    {}
                }
            >
                <Grid container spacing={4} alignItems="center">
                    {
                        history.location.pathname.startsWith('/fleet-analysis/vehicles') ||
                        history.location.pathname.startsWith('/fleet-analysis/replacement') || 
                        history.location.pathname.startsWith('/fleet-analysis/incentives') || 
                        history.location.pathname.startsWith('/fleet-analysis/run_analysis') ?
                            null
                        :
                            null
                    }
                    {
                        history.location.pathname.startsWith('/fleet-analysis/vehicles') ||
                        history.location.pathname.startsWith('/fleet-analysis/replacement') || 
                        history.location.pathname.startsWith('/fleet-analysis/incentives') || 
                        history.location.pathname.startsWith('/fleet-analysis/run_analysis') ?
                            <Grid item xs container justify="center">
                                {renderMenu()}
                            </Grid>
                        :
                            <Grid item xs>
                                {renderMenu()}
                            </Grid>
                    }
                    <Grid item style={{width: 'auto'}} container justify="flex-end">
                        <ButtonsWrapper>
                            {(history.location.pathname === '/fleet-analysis/result' && state.chargingPage.simulation_uuid ) ? 
                                <Button target="_blank" href={getExportResultURL()} variant="outlined">Export</Button> 
                            : null}
                            {(history.location.pathname.startsWith('/fleet-analysis/result') && state.chargingPage.simulation_uuid ) ? 
                                //<Button target="_blank" onClick={handleClickCustomizeExport} href="" variant="outlined">Customize Export</Button>
                                <DialogReportDownloader 
                                    simulationUUID={state.chargingPage.simulation_uuid}
                                    userAuthToken={window.localStorage.getItem("authenticated") as string}
                                    analysisName={state.homePage.analysisName}
                                    filterQueryString={filterQueryString}
                                    />
                                : null}
                            {renderButton()}
                            <AvatarButton data-test-id={"AvatarButton"} aria-label="user settings" onClick={handleClick}>
                                <UserIcon/>
                            </AvatarButton>

                            {/*<AvatarButton aria-label="user settings" onClick={handleClick}>*/}
                                {/*{user}*/}
                            {/*</AvatarButton>*/}

                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem data-test-id={"LogoutButton"} onClick={handleLogOut}>Log Out</MenuItem>
                            </StyledMenu>
                        </ButtonsWrapper>
                    </Grid>
                </Grid>

            </HeaderInner>
            <DialogInput
                key={+isDialogVisible}
                handleClose={onDialogCloseClicked}
                isVisible={isDialogVisible}
                onSubmitClicked={onSubmitClicked}
                negativeBtnName="Cancel"
                positiveBtnName="Go next"
                title="Create new analysis"/>
        </Wrapper>
    )
}

export const mapStateToProps = ({vehicle, homePage}: ApplicationState) => ({
    loading: homePage.loading,
    errors: homePage.errors,
    vehicles: vehicle.vehicles,
});

const mapDispatchToProps = {
    setAnalysisNameAction
};

// @ts-ignore
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderTop));
