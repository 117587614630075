import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import iconRed from '../../../assets/images/Car_icon_red.png';
import iconGreen from '../../../assets/images/Car_icon_green.png';
import iconBlue from '../../../assets/images/Car_icon_blue.png';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {
    Container,
    HeaderItemContainer,
    HeaderTitle,
    HeaderSubtitle,
    BodyItemContainer,
    Line,
    ItemContainer,
    Icon,
    Text,
    Distance,
    ArrowDown
} from './styled';
import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import {formatterNumberToTime} from "../../../helpData/Date";

export const OutOfRangeButton = withStyles({
    root: {
        color: 'inherit',
        textTransform: 'none',
        display: 'block',
        padding: 0,
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        borderRadius: 0,
        letterSpacing: 'inherit',
        width: 'inherit',
        minWidth: 'inherit',

        '& .MuiIconButton-label': {
            display: 'block',
        },
        '&:hover, &.active': {
            background: '#74b29133'
        },

    }
})(IconButton);

interface IOutOfRangeListProps {
    data: any,
    onSelectChange: (route: any) => void
    onSelectOneRoute: (route: any) => void
    electricCarId: number
    expanded: number | false
    setExpanded: (panel: number | false) => void
}

const MILE = 0.000621;

function OutOfRangeList(props: IOutOfRangeListProps) {
    const {data, onSelectChange, onSelectOneRoute, electricCarId, expanded, setExpanded} = props;

    // const [expanded, setExpanded] = React.useState<number | false>(data.length > 0 ? 0 : false);

    const handleChange = (panel: number, routes: any, stop?: boolean) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        stop && event.stopPropagation();

        setExpanded(newExpanded ? panel : false);
        onSelectChange(newExpanded ? routes : null);
        onSelectOneRoute(null);
    };

    const handleClick = (event: any, panel: number, routes: any) => {
        let route;

        event.stopPropagation();

        if(!routes.length) {
            route = [];
            route.push(routes);

            routes = route;
        }

        setExpanded(panel);
        onSelectOneRoute(routes);
    };

    const renderPanels = () => data.map((item: any, item_index: number) => {
        const renderRouteItems = () => item.routes.map((route: any, index: number) => {
            return (
                <OutOfRangeButton key={index} onClick={(event: any) => handleClick(event, item_index, route)}>
                    <ItemContainer>
                        <Icon src={iconGreen} alt='icon-start'/>
                        {/*<Text>{route.start_latitude} {route.start_longitude}</Text>*/}
                        <Text>{route.start_location_name}</Text>
                    </ItemContainer>
                    <ItemContainer>
                        <Icon src={iconRed} alt='icon-end'/>
                        {/*<Text>{route.end_latitude} {route.end_longitude}</Text>*/}
                        <Text>{route.end_location_name}</Text>
                    </ItemContainer>
                    <Distance>
                        {Math.round(route.distance * MILE)}mi
                        <ArrowRightAltIcon style={{ fontSize: 28, opacity: .4 }}/>
                        {-(route.results[electricCarId].soc_cons * 100).toFixed(1)}% battery usage
                    </Distance>
                    { route.end_charging && route.end_charging.active === true &&
                    (<ItemContainer>
                        <Icon src={iconBlue} alt='icon-start'/>
                        <Distance style={{marginLeft: '8px'}}>Charged for {formatterNumberToTime(Math.round(route.end_charging.results[electricCarId].charge_duration / 60))} (Parked for {formatterNumberToTime(Math.round(route.end_charging.dwell_time / 60))})</Distance>
                     </ItemContainer>)
                    }
                </OutOfRangeButton>
            )
        });
        return (
            <ExpansionPanel square key={item.date} expanded={expanded === item_index} TransitionProps={{ unmountOnExit: true }}
                            onChange={handleChange(item_index, item.routes)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon fontSize='large'/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <HeaderItemContainer>
                        <HeaderTitle>{item.date}</HeaderTitle>
                        <HeaderSubtitle>{item.distance}mi<ArrowRightAltIcon style={{ fontSize: 28, opacity: .4 }}/>{item.battery}% battery</HeaderSubtitle>
                    </HeaderItemContainer>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <BodyItemContainer>
                        <Line><ArrowDown/></Line>
                        {renderRouteItems()}
                    </BodyItemContainer>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    });

    return (
        <Container>
            {/*<Title>Out of range days:</Title>*/}
            {(data && data.length > 0) ?renderPanels():<Text>No driving days for selected out of range</Text>}
        </Container>
    );
}

export default OutOfRangeList;
