import {Reducer} from 'redux';
import {ResultsActionTypes, ResultsState} from './types';
import {AnalysisActionTypes} from "../analysis/types";
import { toColorString } from 'polished';

export const initialState: ResultsState = {
    loading: false,
    lambdaProgress: 0,
    resultsList: [],
    chargingSites: [],
    isSuccessShared: false,
    sharedEmails: [],
    shareErrors: undefined,
    id: undefined,
    websocket_message: false,
    tco: [],
    power_profiles: {},
    emission_results: undefined,
    annual_results: undefined,
    route_positions: []
};

const reducer: Reducer<ResultsState> = (state = initialState, action) => {
    switch (action.type) {
        case ResultsActionTypes.FETCH_RESULTS_REQUEST: {
            return {...state, loading: true}
        }
        case ResultsActionTypes.FETCH_RESULTS_REQUEST_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        case ResultsActionTypes.FETCH_RESULTS_REQUEST_SUCCESS: {
            let newState = {
                ...state,
                resultsList: action.payload.res.vehicle_results,
                chargingSites: action.payload.res.charging_sites,
                lambdaProgress: 0,
                websocket_message: false,
                power_profiles: action.payload.res.power_profiles,
                emission_results: action.payload.res.emission_results,
                annual_results: action.payload.res.annual_results
            };

            if(!action.payload.selected_vehicle_id){
                newState = {...newState, loading: false}
            }
            return newState;
        }

        case ResultsActionTypes.FETCH_TCO_REQUEST: {
            return {...state, loading: true}
        }
        case ResultsActionTypes.FETCH_TCO_REQUEST_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        case ResultsActionTypes.FETCH_TCO_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                tco: action.payload
            }
        }

        case ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST: {
            return {...state, loading: true}
        }
        case ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        case ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                route_positions: action.payload
            }
        }


        case ResultsActionTypes.FETCH_RESULTS_DETAILS_REQUEST: {
            return {...state, loading: true}
        }
        case ResultsActionTypes.FETCH_RESULTS_REQUEST_DETAILS_SUCCESS: {
            return {
                ...state,
                resultsList: state.resultsList.map((result: any, index: number) => {
                    if (state.resultsList[index].vehicle_id === action.payload.vehicle_results[0].vehicle_id) {
                        return action.payload.vehicle_results[0]
                    } else {
                        return result
                    }
                }),
                loading: false,
            }
        }

        case ResultsActionTypes.WEBSOCKET_PROGRESS_STATUS: {
            return {...state, loading: true, lambdaProgress: action.payload}
        }


        case ResultsActionTypes.CHANGE_OR_SAVE_RESULTS: {
             return {...state, loading: true}
        }

        case ResultsActionTypes.SAVE_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                isSuccessSaved: true,
                id: action.payload.id,
            }
        }
        case ResultsActionTypes.SAVE_REQUEST_ERROR: {
            return {...state,
                loading: false,
                shareErrors: action.payload,
                isSuccessSaved: false,
            }
        }

        case ResultsActionTypes.SHARE_REQUEST: {
            return {...state, loading: true}
        }
        case ResultsActionTypes.SHARE_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                isSuccessShared: true,
                sharedEmails: action.payload.emails,
                shareErrors: undefined,
                ...action.payload.results
            }
        }
        case ResultsActionTypes.SHARE_REQUEST_ERROR: {
            return {...state,
                loading: false,
                shareErrors: action.payload,
                isSuccessShared: false,
            }
        }
        case ResultsActionTypes.CLEAR_SHARE_STATUS: {
            return {...state, isSuccessShared: false}
        }

        case ResultsActionTypes.RESULT_ARE_NOT_READY: {
            return {...state, loading: action.payload}
        }

        case 'CHANGE_ANALYSIS_ID': {
            return {...state, id: action.payload}
        }

        case 'RESULT_ARE_NOT_READY': {
            return {...state, loading: true, websocket_message: true}
        }
        case 'RESULT_ARE_READY': {
            return {...state, loading: false, websocket_message: false}
        }
        case AnalysisActionTypes.RESTORE: {
            return {...action.payload.state.resultsPage, id: action.payload.id, sharedEmails: action.payload.members, loading: false}
        }
        case 'RESET_STATE': {
            return {...state, ...initialState}
        }
    }
    return state;
};

export {reducer as resultsReducer};
