import * as React from "react";
import _ from "lodash";
import {init, RootStore, thunkAction, unboxThunk} from "../../redux";
import {connect} from "react-redux";

import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import styled from 'styled-components';
import Box from "@material-ui/core/Box";
import SimpleTable from "../../components/Tables/SimpleTable";
import {FilterCardRow} from "../layout/Card.tsx";
import {Vehicle} from "../../helpData/get_vehicles_response_example";
import {AttributesResponseInterface} from "../../helpData/getVehicleAttributes";
import {_createAttributesArray} from "../../helpData/changeAttributes";
import {StyledButton} from "../layout/GreenButton";
import AddIcon from '@material-ui/icons/Add';
import SimVehiclesTable from "../Tables/SimVehiclesTable";
import DialogAddSimVehicle from "./DialogAddSimVehicle";

const StyledGrid = styled(Box)(({theme}) => ({
    gridGap: '2vw',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
    height: '100vh',
    marginBottom: '20px',
    '&.height-inherit': {
        height: 'inherit',
    }
})) as typeof Box;

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
        init,
        thunkAction: unboxThunk(thunkAction),
    }
);

interface IProps {
    value: string,
    options: SimVehicleSuggest[],
    selectedSimVehicleSuggest: SimVehicleSuggest[],
    vehicles: Vehicle[],
    sim_vehicles: Vehicle[],
    group_by_attribute_name: string,
    group_name: string,
    sim_vehicles_attributes: AttributesResponseInterface[],
    handleChange: (event: any, selected_value?: any) => void;
    handleUpdateValue: (value: string, options: SimVehicleSuggest[]) => void;
    handleSubmit: (groupkey: string, selected_sim_vehicles: Vehicle[]) => void;
    onAttributesChanged: (attributes: []) => void;

    selected_sim_vehicles: Vehicle[];
    setSelectedSimVehicles: (vehicles: Vehicle[]) => void;
    will_remove_vehicle: (group_key: string, vehicle_id: number) => void;
}

interface IState {
    attributes: AttributesResponseInterface[],
    sim_vehicles_popup: boolean
}


class ReplacementGroup extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.handleClickAddSimVehicle = this.handleClickAddSimVehicle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.will_remove_vehicle = this.will_remove_vehicle.bind(this);
        this.addSimVehicleSuggest = this.addSimVehicleSuggest.bind(this);

        let _attributesArray = _createAttributesArray(this.props.sim_vehicles_attributes);

        this.state = {
            attributes: _attributesArray,
            sim_vehicles_popup: false
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (!_.isEqual(this.props.sim_vehicles_attributes, prevProps.sim_vehicles_attributes)) {
            if (this.props.sim_vehicles_attributes && this.props.sim_vehicles_attributes.length) {
                let _attributesArray = _createAttributesArray(this.props.sim_vehicles_attributes);
                this.setState({
                    attributes: _attributesArray
                });
            }
        }
    }

    handleClickAddSimVehicle() {
        this.setState({sim_vehicles_popup: true});
    }

    handleClose() {
        this.setState({
            sim_vehicles_popup: false
        });
        this.props.handleUpdateValue("", []);
    }

    will_remove_vehicle(event: any, vehicle_id: number, type_table: "filter" | "keyword") {
        this.props.will_remove_vehicle(this.props.group_name, vehicle_id);
    }

    addSimVehicleSuggest(sim_vehicle_id: number, vehicles_ids_array: number[], selectedSimVehicleSuggest: any){

    }

    render() {

        const short_sim_vehicle_attributes = [
            {
                "name": "Year",
                "type": "number",
                "attribute_id": 3,
                selected: true
            },
            {
                "name": "Make",
                "type": "string",
                "attribute_id": 1,
                selected: true
            },
            {
                "name": "Model",
                "type": "string",
                "attribute_id": 2,
                selected: true
            },
            {
                "name": "EPA Range",
                "type": "number",
                "attribute_id": 7,
                selected: true
            }
        ];

        return (
            <StyledGrid key={this.props.group_name} display="grid" className={this.props.group_by_attribute_name !== '' ? 'height-inherit' : ''}>
                <Paper>
                    <div className={"card-container"}>
                        <FilterCardRow>
                            <Typography color="inherit" variant="h5">
                                Selected: <b>{this.props.vehicles && this.props.vehicles.length} vehicles</b>
                                {this.props.group_by_attribute_name !== '' && ' (' + this.props.group_name + ')'}
                            </Typography>
                        </FilterCardRow>

                        <SimpleTable
                            rows={this.props.vehicles}
                            noItemText={"Vehicles was not selected in Filter Vehicles tab"}/>

                    </div>
                </Paper>
                <Paper>
                    <div className={"card-container"}>
                        <FilterCardRow>
                            <Typography color="inherit" variant="h5">
                                Replacement vehicles
                            </Typography>
                        </FilterCardRow>

                        <FilterCardRow>
                            <StyledButton style={{padding: '6px 16px 6px 10px'}}
                                          aria-controls="customized-menu"
                                          aria-haspopup="true"
                                          variant="contained"
                                          disabled={!this.props.vehicles.length}
                                          onClick={(event: any) => this.handleClickAddSimVehicle()}>
                                <AddIcon/>Add sim vehicle
                            </StyledButton>
                        </FilterCardRow>

                        <DialogAddSimVehicle
                            is_loading={false}
                            groupkey={this.props.group_name}
                            sim_vehicles_popup={this.state.sim_vehicles_popup}
                            sim_vehicles={this.props.sim_vehicles}
                            group_name={this.props.group_name}
                            value={this.props.value}
                            suggestedSimVehicles={this.props.options}
                            attributes={this.state.attributes}
                            addSimVehicleSuggest={this.addSimVehicleSuggest}
                            handleChange={this.props.handleChange}
                            handleUpdateValue={this.props.handleUpdateValue}
                            selectedSimVehicleSuggest={this.props.selectedSimVehicleSuggest}
                            handleClose={this.handleClose}
                            handleSubmit={this.props.handleSubmit}
                            onAttributesChanged={this.props.onAttributesChanged}
                            setSelectedSimVehicles={this.props.setSelectedSimVehicles}
                        />

                    <SimVehiclesTable
                        dataTestId={"TableSimVehicleSuggest"}
                        key={"TableSimVehicleSuggest"}
                        type_table={"filter"}
                        data={this.props.selected_sim_vehicles}
                        attributes={short_sim_vehicle_attributes}
                        handleRemove={this.will_remove_vehicle}/>
                </div>
            </Paper>

            </StyledGrid>
        )
    }

}

export default storeEnhancer(ReplacementGroup);