import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IMG_BASE_URL } from '../../../store/car_legacy/urls';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    item: {
        display: "flex",
        alignItems: "center",
        marginBottom: ".5rem",
    },
    carImgWrapper: {
        marginRight: "1rem",
        width: "100px"
    },
    carImg: {
        width: "100%",
        
    },
    carNameHeading: {
        fontSize: "1.2rem",
        fontWeight: "500",
        marginBottom: ".5rem"
    },
    carOptionsHeading: {
        fontSize: ".8rem"
    },
    carItemBody: {
        flex: 1
    }
});

class CarListItem extends React.Component {
    state = { expanded: false };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { classes, car_profile } = this.props;
        const car = car_profile;
        console.log(car)

        return (

            <div className={classes.item}>
                <div className={classes.carImgWrapper}>
                    <img src={car.image_url} className={classes.carImg}/>
                </div>
                <div className={classes.carItemBody}>
                    <Typography variant="h4" className={classes.carNameHeading}>
                        {car.year} {car.make} {car.model}
                    </Typography>
                    <Typography variant="body2" className={classes.carOptionsHeading}>
                        {car.option}
                    </Typography>
                </div>
                {this.props.secondaryAction ? 
                    <div>
                        <IconButton aria-label="Delete" onClick={(e) => this.props.secondaryAction(e, car.sim_vehicle_id, "filter")}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                : null}
            </div>
        )
    }
}


export default withStyles(styles)(CarListItem);