import * as React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Global} from '@emotion/core'
import {connect} from "react-redux";
import {Redirect} from "react-router";

//Components
import Root from './components/layout/Root'
import RouteMenu from './components/layout/RouteMenu'
import HeaderTop from "./components/layout/HeaderTop";

//Styles
import normalize from './styles/normalize'
import globals from './styles/globals'

//Pages
import ResultPage from "./pages/result_page/result_page";
import SelectReplacementPage from "./pages/select_replacement/select_replacement";
import IncentivesPage from "./pages/incentives/incentives";
import ShareResult from "./pages/result_page/share_result";
import RunAnalysis from "./pages/run_analysis/run_analysis";
import HomePage from "./pages/home/home";
import LoginPage from "./pages/login/login";
import ChargingSitesPage from "./pages/charging_sites/charging_sites";
import PowerEnergyPage from "./pages/power_energy/power_energy";
import NotFoundPage from "./pages/notfound";
import IndexPage from './pages/filter_vehicles/index'
import SettingsPage from './pages/settings/settings_page'

//Store
import {fetchAuth} from "./store/auth/actions";
import {ApplicationState} from "./store";
import {createNewAnalysisResult, setAnalysisNameAction} from './store/analysis/actions';
import {changeOrSaveResults} from "./store/results/actions";
import DialogCustomReportModal from "./components/Modals/DialogCustomReportModal";

// If your app is big + you have routes with a lot of components, you should consider
// code-splitting your routes! If you bundle stuff up with Webpack, I recommend `react-loadable`.
//
// $ yarn add react-loadable
// $ yarn add --dev @types/react-loadable
//
// The given `pages/` directory provides an example of a directory structure that's easily
// code-splittable.
interface AllProps {
    app_title?: string,
    loading: boolean,
    errors?: string | null,
    uuid: string | null,
    isAuthenticated: boolean,
    redirect_from_login?: boolean | null;
    fetchAuth: typeof fetchAuth,
    setAnalysisName: typeof setAnalysisNameAction
    createNewAnalysis: typeof createNewAnalysisResult
    changeOrSaveResults: typeof changeOrSaveResults
    state: ApplicationState
}

class Routes extends React.Component<AllProps> {

    render() {
        return (
            <BrowserRouter basename="app">
                <Global styles={normalize}/>
                <Global styles={globals}/>
                {!this.props.isAuthenticated ? (<Root><Redirect to='/login' /><Route exact={true} path="/login" component={LoginPage}/></Root>) :
                    (<Root>
                            <RouteMenu title={"MyFleetBuy"}/>
                            <div style={{flex: 1, flexGrow: 1, height: '100%'}}>
                                <HeaderTop user="CK" state={this.props.state}
                                           setAnalysisName={this.props.setAnalysisName}
                                           changeOrSaveResults={this.props.changeOrSaveResults}
                                           createNewAnalysis={this.props.createNewAnalysis}/>
                                <div className={"container"}>
                                    <Switch>
                                        { this.props.redirect_from_login === true ? (<Redirect from='/login' to='/'/>) : null}
                                        <Route exact={true} path="/" component={HomePage}/>

                                        <Route path="/fleet-analysis/vehicles/:analysis_id?" component={IndexPage}/>
                                        <Route path="/fleet-analysis/replacement/:analysis_id?" component={SelectReplacementPage}/>
                                        <Route path="/fleet-analysis/incentives/:analysis_id?" component={IncentivesPage}/>
                                        <Route path="/fleet-analysis/run_analysis/:analysis_id?" component={RunAnalysis}/>

                                        <Route path="/fleet-analysis/sites/:analysis_id?" component={ChargingSitesPage}/>
                                        {/*<Route path="/fleet-analysis/power_energy/:analysis_id?" component={PowerEnergyPage}/>*/}

                                        <Route path="/fleet-analysis/result/:analysis_id?" component={ResultPage}/>
                                        <Route path="/fleet-analysis/share" component={ShareResult}/>
                                        <Route path="/fleet-analysis/dialogcustomreport" component={DialogCustomReportModal}/>
                                        <Route path="/settings" component={SettingsPage}/>
                                        <Route component={NotFoundPage}/>
                                    </Switch>
                                </div>
                            </div>
                        </Root>
                    )}
            </BrowserRouter>
        )
    };
}

const mapStateToProps = (state: ApplicationState) => {
    let localStorageToken = window.localStorage.getItem("authenticated");

    if(state.auth.uuid !== null || localStorageToken !== null) {
        state.auth.isAuthenticated = true;

        state.auth.redirect_from_login = window.location.pathname === '/app/login' || state.router.location.pathname === '/app/login';

    } else {
        state.auth.isAuthenticated = false;
        state.auth.redirect_from_login = false;
    }

    return ({
        app_title: state.auth.app_title,
        loading: state.auth.loading,
        errors: state.auth.errors,
        uuid: state.auth.uuid,
        isAuthenticated: state.auth.isAuthenticated,
        redirect_from_login: state.auth.redirect_from_login,
        state: state
    });
}

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
    fetchAuth: fetchAuth,
    setAnalysisName: setAnalysisNameAction,
    createNewAnalysis: createNewAnalysisResult,
    changeOrSaveResults: changeOrSaveResults
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
