export const HOST = process.env.REACT_APP_HOST
export const HOST_PROTOCOL = process.env.REACT_APP_HOST_PROTOCOL

export const BASE_URL = HOST ? `${HOST_PROTOCOL}://${HOST}` : process.env.REACT_APP_API_URL
export const LOGIN_URL = `${BASE_URL}/accounts/login/`
export const LOGOUT_URL = `${BASE_URL}/accounts/logout/`

export const API_URL = `${BASE_URL}/api1/`

export const API_USER_SUFFIX = 'user/'
export const API_USER_PROFILE_SUFFIX = 'profile/'
export const API_CAR_SUFFIX = 'car/'
export const API_CAR_MAKES_SUFFIX = `${API_CAR_SUFFIX}makes/`
export const API_CAR_BASE_MODELS_SUFFIX = `${API_CAR_SUFFIX}base_models/`
export const API_CAR_BODY_TYPES_SUFFIX = `${API_CAR_SUFFIX}body_types/`
export const API_CAR_POWERTRAIN_TYPES_SUFFIX = `${API_CAR_SUFFIX}powertrain_types/`
export const API_CAR_PROFILE_SUFFIX = 'car_profile/'
export const API_TCO_SUFFIX = 'ownership_cost_settings/'
export const API_CAR_RECOMMENDATIONS_SUFFIX = `${API_CAR_PROFILE_SUFFIX}recommendations/`
export const API_TRIP_SUFFIX = 'trip/'
export const API_TRIPS_RESULTS_SUFFIX = `${API_TRIP_SUFFIX}?include_results=1&amp;limit=0`
export const API_LOCATION_SUFFIX = 'location/'
export const API_RESULT_SUFFIX = `result/`
export const API_ITINERARY_SUFFIX =  'itinerary/'

export const API_ADVISOR_SUFFIX = 'advisor/'
export const API_ADVISORS_INVITATIONS_SUFFIX = `${API_ADVISOR_SUFFIX}invitation/`

// S3 Media base url
export const IMG_BASE_URL = "https://mgc-storage.s3.amazonaws.com"