import styled, {CreateStyled} from '@emotion/styled'

export interface Theme {
    colors: {
        background: string
        body: string
        line: string
        disable: string
        headings: string
        borders: string
        tableOdd: string
        brand: string,
        inactive_brand: string,
        active_link: string
        regular: string
        valencia: string
        black: string
        white: string
        light_bg: string
        text_red: string
        text_green: string
        header_bg: string
        avatar_bg: string
        attrs: {
            str: string
            agi: string
            int: string
        },
        pickled_bluewood: string
    }
    fonts: {
        body: string
        headings: string
        monospace: string
    }
    fontSizes: {
        h1: string
        h2: string
        h3: string
        h4: string
    }
    containerPadding: string
    breakpoints: {
        xs: string
        sm: string
        md: string
        lg: string
        xl: string
    }
    widths: {
        md: string
        lg: string
        xl: string
    }
    heights: {
        header: string
    }
}

export default styled as CreateStyled<Theme>
