import React from 'react';
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { withTranslation } from 'react-i18next';

import { getBodyTypeImage, getOptionsDisplayString } from '../../../store/car_legacy/strings';


const styles = theme => ({
    
    
    carImgWrapper: {
        position: "relative"
    },
    carGridItem: {
        overflow: "hidden" // Hide img with rounded corners
    },
    carItemContent: {
        padding: theme.spacing.unit,
    },
    inGarageOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgba(0,0,0,0.6)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
    },
    optionText: {
        lineHeight: "1",
        fontSize: ".75rem"
    },
    titleText: {
        lineHeight: "1",
        marginBottom: ".5rem",
        fontWeight: "bold"
    }
})

class CarGridItem extends React.Component {

    //If there is an error retrieving an image
    addDefaultSrc(event){
        event.target.src = "https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/NoPhotoAvail.png"
    }

    render() {
        const {classes, car, t} = this.props;
        //console.log("CarGridItem car: ", car);
        return (
            <ButtonBase
                focusRipple
                onClick={this.props.onClick}
            >
                <Paper className={classes.carGridItem}>
                    <div className={classes.carImgWrapper}>
                        <img 
                            //Check if it's a body type otherwise return server url
                            src={car.image_url} 
                            className={classes.carImg}
                            width={'100%'}
                            height={80}
                            onError={this.addDefaultSrc}
                        />
                        {this.props.inGarage?
                            <Fade in={true}>
                                <div className={classes.inGarageOverlay}>
                                    <Grow in={true}>
                                        <Typography variant="h5" style={{color: '#fff'}}>
                                            Selected
                                        </Typography>
                                    </Grow>
                                </div>
                            </Fade>
                        : null}
                    </div>
                    <div className={classes.carItemContent}>
                        {this.props.carGroup ? 
                            <Typography variant="caption">
                                <span>{car.display_name || car.name}</span>
                            </Typography>
                            :
                            <div>
                                <Typography variant="caption" className={classes.titleText}>
                                    <span>{car.year} {car.make} {car.shortname}</span>
                                </Typography>
                                {/*DM 07102021 - let's do an override, and just print out the car option here, instead of translating it.*/}
                                <Typography variant="body1" className={classes.optionText}>
                                    {
                                        /*
                                        * DM 07102021 - let's do an override, and just print out the car option here, instead of translating it.
                                        <span>{getOptionsDisplayString(car.option ? car.option : null)}</span>
                                        */
                                    }
                                    <span>{car.option}</span>
                                </Typography>
                            </div>
                            
                        }
                    </div>
                </Paper>
            </ButtonBase>
        )
    }
}

export default withTranslation()(withStyles(styles)(CarGridItem))