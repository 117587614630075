import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogCustomReportModal from "../Modals/DialogCustomReportModal";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import DownloadReportButtons from './DownloadReportButtons';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface IProps {
  simulationUUID:string;
  userAuthToken:string;
  analysisName:string;
  //DM: being lazy here. accepting as string to avoid having to define an interface
  filterQueryString:string;

  filter_site_id?: any;
}

export default function DialogReportDownloader(props:IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [shouldDownloadReport, setShouldDownloadReport] = React.useState(false);
  const [reportDownloading, setReportDownloading] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const {simulationUUID, userAuthToken, analysisName, filterQueryString, filter_site_id, ...otherProps} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaxWidth(event.target.value as DialogProps['maxWidth']);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  const downloadReport = () => {
    setShouldDownloadReport(true)
  };

  console.log(`DialogReportDownloader.props: simulationUUID: ${simulationUUID}, userAuthToken: ${userAuthToken}, analysisName: ${analysisName}`);


  return (
    <React.Fragment>
      {/*This button shows up in the top nav.*/}
      {/* DM: 02/22/2021 commenting out temporarily*/}
      <Button variant="outlined" onClick={handleClickOpen}>
        Export Report
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth="lg"
        open={open}
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">Select the Sections and Fields to customize your Report for download</DialogTitle>
        <DialogContent dividers={true}>
          {/* <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}>Select the report sections and the fields to customize the report for download
          </DialogContentText> */}
          <DialogCustomReportModal 
            computeHash={simulationUUID}
            token={userAuthToken}
            analysisName={analysisName}
            filterQueryString={filterQueryString}
            shouldDownloadReport={shouldDownloadReport}
            setShouldDownloadReport={setShouldDownloadReport}
            filter_site_id={filter_site_id}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <DownloadReportButtons
              report_downloading={reportDownloading}
              handleButtonClick={downloadReport}
              />
          </DialogActions>
        {/* <DialogTitle id="max-width-dialog-title">Optional sizes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">maxWidth</InputLabel>
              <Select
                autoFocus
                value={maxWidth}
                onChange={handleMaxWidthChange}
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value={false as any}>false</MenuItem>
                <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Switch checked={fullWidth} onChange={handleFullWidthChange} />}
              label="Full width"
            />
          </form>
        </DialogContent> */}
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
