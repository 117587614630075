import * as React from "react";
import {TableCellProps} from "@material-ui/core/TableCell";
import {TableHeadProps} from "@material-ui/core/TableHead";
import {TableBodyProps} from "@material-ui/core/TableBody";
import { TableCell, TableHead, TableBody } from '@material-ui/core';
import {default as Typography, TypographyProps} from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import styled from '../../utils/styled';

export const FilterCardTable = styled('div')`
    display: block;
    width: 100%;
    overflow: hidden;
`;

export const StyledTableCell = withStyles({
})((props: TableCellProps) => (
    <TableCell
        {...props}
    />
));
export const StylesTableHead = withStyles({
    root: {
        '& .MuiTableCell-head': {
            fontWeight: 600,
            lineHeight: '1.2rem',
            fontSize: '0.76rem'
        }
    },
})((props: TableHeadProps) => (
    <TableHead
        {...props}
    />
));
export const StylesTableBody = withStyles({
    root: {
        fontWeight: 200
    }
})((props: TableBodyProps) => (
    <TableBody
        {...props}
    />
));

export const ExpansionPanelSummaryHeader = withStyles({
    root: {
        display: 'flex',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '0.76rem'
    }
})((props: TypographyProps) => (
    <Typography
        {...props}
    />
));
export const CustomExpansionPanelSummary = withStyles({
    root: {
        padding: '0',
        maxWidth: '500px',
        paddingRight: '8px',
        borderBottom: '1.5px solid #E5E5E5',
    }
})(MuiExpansionPanelSummary);
export const Box = withStyles({
    root: {
        width: '100%',
        boxShadow: 'none',
    }
})(MuiExpansionPanel);

export const CustomExpansionPanelDetails = withStyles({
    root: {
        padding: '0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    }
})(MuiExpansionPanelDetails);

export const CustomExpansionPanelDetailsTextField = withStyles({
    root: {
        width: '40px',
        margin: '0 10px 0 5px',
        '& > .MuiInputBase-root': {
                width: '33px',
                height: '30px',
                margin: '0 5px',
            '& > .MuiOutlinedInput-input': {
                textAlign: 'center',
                padding: '0 0 0 5px',
            }
        }
    }
})((props: TextFieldProps) => (
    <TextField
        {...props}
    />
));

export const TableElement = styled('td')`
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(-225deg);
    border-bottom: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${props => props.theme.colors.regular}44;
    margin-top: 20px;
    right: -3px;
`;
