import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import {connect} from "react-redux";

// store
import {ApplicationState} from "../../../store";
import { fetchTCO } from "../../../store/results/actions";

//Componenets
import {
    a11yProps, OutOfRangeTabs,
    TabPanel
} from "../../../pages/result_page/result_page";
import CompareCarCard from './CompareCarCard';

//Styles
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, CircularProgress, Button, TextField, InputAdornment } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import './chart.css';

//Types
import { carProfiles } from './carProfiles';
import { TCOResponse } from '../../../store/results/types';
import {Attribute} from "../../../store/vehicles/types";
import CostBarChart from './CostBarChart';
import { GreenButton } from '../../layout/GreenButton';

const MILE = 0.000621;
const cardWidth = 300;
const nbCards = 4;

interface IncentivesObject {
    [key: string]: {
        annual_fuel_cost?: any,
        annual_elec_cost?: any,
        sim_vehicle_price: number,
        incentives: number
    }
}

const mapStateToProps = ({resultsPage, vehicle}: ApplicationState) => ({
    loading: resultsPage.loading,
    tco: resultsPage.tco,
    emission_results: resultsPage.emission_results,
    annual_results: resultsPage.annual_results,
    incentives: vehicle.incentives,
    group_by_attribute: vehicle.group_by_attribute,
});

// You can access these via `this.props`.
const mapDispatchToProps = {
    fetchTCO
};

interface IProps {
    replacementVehicles: any,
    selectedVehicle: any
}

interface PropsFromState {
    errors?: string,
    loading: boolean,
    tco: TCOResponse[] | any
    emission_results: any,
    annual_results: any
    incentives: IncentivesObject,
    history: any,
    group_by_attribute: Attribute
}

interface PropsFromDispatch {
    fetchTCO: typeof fetchTCO
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = IProps & PropsFromState & PropsFromDispatch

function Compare(props: AllProps) {
    const parentRef = useRef(null);
    const [lcfs, setLCFS] = useState("0");

    const {
        replacementVehicles, 
        selectedVehicle, 
        fetchTCO, 
        tco, 
        loading, 
        emission_results, 
        annual_results, 
        incentives, 
        history, 
        group_by_attribute
    } = props;

    let vehicles: any = []

    //Filter replacement vehicle to only get the one associated with the given group
    let group = "N/A"
    for (let i in selectedVehicle.attributes) {
		if (selectedVehicle.attributes[i].name == group_by_attribute.name)
			group = selectedVehicle.attributes[i].value
    }
    
    let vehicle_group = replacementVehicles[group]

    for (let i in vehicle_group) {
        let vehicle = vehicle_group[i]

        vehicles.push(vehicle)
    }

    vehicles.sort((a: any, b: any) => {
        let powertrainA = a.sim_vehicle.powertrain_type
        let powertrainB = b.sim_vehicle.powertrain_type

        if (powertrainA < powertrainB)
            return -1;

        if (powertrainA > powertrainB)
            return 1;
      
        // names must be equal
        return 0;
    })

    console.log("VEHICLES ARE", vehicles)

    useEffect(() => {
        if (selectedVehicle.annual_distance) {
            let sims_ids = []
            let tco_settings: IncentivesObject = {}

            for (let i in vehicles) {
                
                let id = vehicles[i].vehicle_id
                sims_ids.push(id)
                
                let sim_vehicle_id_str = String(id)
                let fleet_vehicle_id_str = String(selectedVehicle.vehicle_id)
                let ar_fuel = annual_results[fleet_vehicle_id_str].fuel_cost[id]
                let ar_elec = annual_results[fleet_vehicle_id_str].elec_cost[id]
                console.log(annual_results, selectedVehicle.vehicle_id, id, annual_results[fleet_vehicle_id_str], ar_fuel, ar_elec, vehicles)
                tco_settings[id.toString()] = {
                    annual_fuel_cost: ar_fuel ? ar_fuel : -1, //dollars
                    annual_elec_cost: ar_elec ? ar_elec : -1,
                    ...incentives[id.toString()]
                }
            }

            let params = {
                vehicle_id: selectedVehicle.vehicle_id,
                annual_distance: selectedVehicle.annual_distance, //meters
                sim_vehicle_ids: sims_ids,
                sim_vehicle_tco_setting: tco_settings
            }

            let params_list = []
            params_list.push(params)
            console.log("******** >>> ", annual_results, params_list, selectedVehicle, "TEST")
            fetchTCO(params_list)
        }

    }, [selectedVehicle.annual_distance]);

    if (tco != null && loading != true && tco.length > 0) {
        //console.log(tco[0].sim_vehicle_results[0])
        //console.log(vehicles[0])
    }

    return (
        <div className={"chart-parent"} ref={parentRef}>
            {/* <div style={{margin: 20}}>
                <Typography variant="h5" style={{marginBottom: 10}}>
                    Enter Low Carbon Fuel Standard (LCFS) Dollar Amount
                </Typography>
                <TextField 
                    style={{width: 200}}
                    variant="outlined"
                    type="number"
                    value={lcfs}
                    onChange={(event) => setLCFS(event.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                />
            </div> */}

                
            {tco != null && loading != true && tco.length > 0 && (
                <CostBarChart vehicles={vehicles} vehicle_results={tco[0].sim_vehicle_results} parentRef={parentRef} />
            )}
            <Accordion style={{marginTop: 20}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <GreenButton variant="contained">
                        Show details
                    </GreenButton>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} direction="row" wrap="nowrap" style={{
                        //minWidth: (4 + cardWidth) * carProfiles.length,
                        width: '100%',
                        margin: 0,
                        //marginTop: 5,
                        overflowX: 'auto',
                        //transform: 'rotateX(180deg)'
                    }}
                    >
                        {tco != null && loading != true && tco.length > 0 && (
                            vehicles.map((vehicle: any, index: number) => {
                                //console.log("sim vehicle rersults are", tco[0].sim_vehicle_results)
                                //console.log("vehicle", vehicle)
                                //console.log("emissions", annual_results && annual_results.emissions ? annual_results.emissions : null)
                                return (
                                    <Grid item key={index} style={{
                                        minWidth: cardWidth,
                                        height: '100%',
                                        verticalAlign: 'top',
                                        //marginBottom: 5,
                                        //transform: 'rotateX(180deg)'
                                    }}
                                    >
                                        <CompareCarCard 
                                            //cardWidth={cardWidth}
                                            car_profile={vehicle}
                                            tco={tco[0].sim_vehicle_results.filter((ownershipCost: any) => ownershipCost.sim_vehicle_id === vehicle.vehicle_id)[0]}
                                            emissions={annual_results && annual_results[selectedVehicle.vehicle_id] && annual_results[selectedVehicle.vehicle_id].emissions[vehicle.telematics_id] ? annual_results[selectedVehicle.vehicle_id].emissions[vehicle.telematics_id] : -1}
                                            history={history}
                                        />
                                    </Grid>
                                )
                            })
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Compare);
