import React from 'react';
import {InformationItem, Title, Description, TextContainer, TableContainer, TextMessage} from './styled';
import _ from 'lodash';

function VehicleInformation(props: any) {
    const {attributes, history, selectedVehicle} = props;

    const renderAttributes = () => 
        attributes.map((attribute: any, index: number) => (
            <InformationItem key={index}>
                <TextContainer flex={2}>
                    <Title>{attribute.name}</Title>
                </TextContainer>
                <TextContainer flex={5} border>
                    <Description>{attribute.value}</Description>
                </TextContainer>
            </InformationItem>
        ));

    const renderTextMessage = () =>
        <TextMessage>
            Vehicles was not selected in <span onClick={() => {history.push('/fleet-analysis/vehicles')}}>Filter Vehicles</span> tab
        </TextMessage>;

    return (
        <TableContainer>
            {_.isEmpty(attributes) ?
                renderTextMessage()
            : 
                renderAttributes()
            }
            {selectedVehicle ?
                <InformationItem>
                    <TextContainer flex={2}>
                        <Title>Annual Distance</Title>
                    </TextContainer>
                    <TextContainer flex={5} border>
                        <Description>
                            {//Divide by 1600 to get miles from meters
                            selectedVehicle.annual_distance ? (selectedVehicle.annual_distance / 1600).toLocaleString(undefined, {maximumFractionDigits:0}) + " miles" : null
                            }
                        </Description>
                    </TextContainer>
                </InformationItem>
            :
                null
            }
        </TableContainer>
    );
}

export default VehicleInformation;
