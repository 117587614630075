import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Chart from "react-google-charts";
 
interface IProps {
    vehicles: any[],
    vehicle_results: any[],
    parentRef: any
}

function CostBarChart(props: IProps) {
    const { vehicles, vehicle_results } = props

    const [fakeControls, setFakeControls] = useState([]);
    let width = window.innerWidth * .6
    let height = 20 + (100 * vehicles.length)

    useEffect(() => {
    // change reference to trigger chart rerender
    setFakeControls([]);
    }, [width, height]);

    const getCarData = () => {
        let data = [
            ['Car', 'Cost', { role: "style" }]
        ];

        for (var i = 0; i < vehicles.length; i++) {
            var car = vehicles[i];
            var colors = ["#6ed5fa", "#00c4ff", "#00b1ff", "#00a7ff", "#0092ff", "#007aff", "#005eff", "#0036ff"];

            //initialize temporary car data array and assign car model name as first entry
            let carData = [];

            let year, make, model;

            for (let i in car.attributes) {
                if (car.attributes[i].name == "Year")
                    year = car.attributes[i].value
                if (car.attributes[i].name == "Make")
                    make = car.attributes[i].value
                if (car.attributes[i].name == "Model")
                    model = car.attributes[i].value
            }
            carData.push(`${make} ${model}`);

            //loop through results to assign trip cost
            carData.push(vehicle_results[i].new_tco);

            //assign color to the bar in the chart
            if (i >= 7) {
                carData.push(colors[i - 7]);
            } else {
                carData.push(colors[i]);
            };

            data.push(carData);
        }

        return data;
    }

    return (
                <Chart
                    chartType="BarChart"
                    width={width}
                    height={height}
                    loader={<div>Loading Chart</div>}
                    style={{margin: 20}}
                    options={{
                        title: 'Total Cost of Ownership Over 10 Years',
                        hAxis: {
                            title: "Ownership Cost",
                            titleTextStyle: { fontSize: 16, margin: '10px !important' },
                            minValue: 0,
                            maxValue: 100000
                        },
                        vAxis: {
                            title: "Vehicle",
                            titleTextStyle: { fontSize: 16, margin: '10px !important' }
                        },
                        width: width,
                        height: height,
                        bar: { 
                            groupWidth: '90%'
                        },
                        //bars: 'horizontal',
                        legend: { 
                            position: 'none'
                        },
                    }}
                    data={getCarData()}
                />
    );
}

export default CostBarChart