import styled from '../../../utils/styled';

export const TabContainer = styled('div')`
    height: 150px;
    width: 100%;
    background-color: ${props => props.theme.colors.light_bg};
    display: flex;
    overflow: hidden;
`;

interface IItemProps {
    selected: boolean | undefined
}
export const ItemContainer = styled('div')<IItemProps>`
    ${props => props.selected && 'background-color: white;'}
    width: 100%;
    height: 100%;    
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

export const ShortInfoContainer = styled('div')`
    display: flex;
    padding-top: 15px; 
    justify-content: center;
    align-items: center;
`;

export const Percent = styled('h2')`
    font-size: 28px;
    margin: 0;
    color: ${props => props.theme.colors.pickled_bluewood};
`;

interface IImageProps {
    src: string | undefined
}
export const CarImg = styled('div')<IImageProps>`
    width: 65px;
    height: 70px;
    border-radius: 3px;
    background-image: url('${props => props.src}');
    background-position: center;
    background-size: cover;
    margin-left: 5px;
`;

export const CarNameModel = styled('h3')`
    margin: 0;
    font-size: 16px;
    color: ${props => props.theme.colors.black};
    font-weight: normal;
    text-align: center;
    margin-top: 7px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    text-overflow: ellipsis;
`;

export const Status = styled('p')`
    margin: 0;
    font-size: 12px;
    color: ${props => props.theme.colors.black};
    text-align: center;
    & > span {
        font-size: 16px;
        font-weight: 600;
        color: ${props => props.theme.colors.valencia};
    }
`;

interface ISortProps {
    onClick: () => void
}
export const SortContainer = styled('div')<ISortProps>`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    background-color: ${props => props.theme.colors.light_bg};
    & > svg {
        transform: rotate(90deg);
        opacity: .4;
    }
`;
