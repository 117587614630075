import * as React from 'react';
import Page from '../../components/layout/Page'
import {fetchAuth} from "../../store/auth/actions";
import {ApplicationState} from "../../store";
import {connect} from "react-redux";
import {History} from "history";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import {CssTextField, StyledLoginButton, StyledTitle} from "../../components/layout/LoginStyle";
import {Container} from "../../components/layout/Container";


// Separate state props + dispatch props to their own interfaces.
export interface PropsFromState {
    app_title: string;
    loading: boolean;
    uuid: string | null;
    isAuthenticated: boolean | null;
    errors?: string
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    history: History
    fetchAuth: typeof fetchAuth
}

interface IState {
    displayErrors: {
        username: boolean,
        password: boolean
    },
    username: string,
    password: string
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & PropsFromDispatch;

class LoginPage extends React.Component<AllProps, IState> {

    constructor(props: AllProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            displayErrors: {
                username: false,
                password: false
            },
            username: '',
            password: ''
        };
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.uuid !== this.props.uuid) {

            if (nextProps.uuid && nextProps.isAuthenticated === true) {
                this.props.history.push(`/vehicles`)
            }
        }
    }

    handleSubmit(event: any) {
        event.preventDefault();

        let displayErrors = JSON.parse(JSON.stringify(this.state.displayErrors));

        if (!event.target.checkValidity()) {
            for (let prop in displayErrors) {
                // skip loop if the property is from prototype
                if (!displayErrors.hasOwnProperty(prop)) continue;

                if (event.target[prop] === 'username' || 'password') {
                    displayErrors[prop] = !event.target[prop].checkValidity();
                }
            }
            this.setState({...this.state, displayErrors: displayErrors});
            // form is invalid! so we do nothing
            return false;
        } else {
            let data = {} as any;

            for (let prop in displayErrors) {
                if (!displayErrors.hasOwnProperty(prop)) continue;

                if (event.target[prop] === 'username' || 'password') {
                    let value = event.target[prop].value.toString().trim();
                    data[prop] = value;
                }
            }

            this.props.fetchAuth(data);

            this.setState({displayErrors: {username: false, password: false}});
        }
    }

    render() {
        return (
            <Page style={{backgroundColor: "white"}} id={"login-page"}>
                <Container>

                    <Grid container justify={"center"} alignItems={"center"} style={{height: '100vh'}} component="main">
                        <Grid item xs={12} sm={7} md={4}>
                            <StyledTitle data-test-id={'LoginPageTitle'} style={{textAlign: "center"}}>
                                {this.props.app_title}
                            </StyledTitle>
                            <form onSubmit={(event: any) => this.handleSubmit(event)} noValidate>
                                <CssTextField
                                    data-test-id={'loginpage_username'}
                                    id="username"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="username"
                                    label="User name"
                                    type="text"
                                    autoComplete="text"
                                    autoFocus
                                    error={this.state.displayErrors.username}
                                />
                                <CssTextField
                                    data-test-id={'loginpage_password'}
                                    id="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    error={this.state.displayErrors.password}
                                />
                                { this.props.errors && (<FormHelperText error style={{fontSize:'13px'}} id="component-error-text">{this.props.errors}</FormHelperText>)}

                                {/*<FormControlLabel*/}
                                {/*control={<Checkbox value="remember" color="primary"/>}*/}
                                {/*label="Remember me"*/}
                                {/*/>*/}
                                <StyledLoginButton
                                    data-test-id={'loginpage_submit_button'}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    Log In
                                </StyledLoginButton>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        )
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({auth}: ApplicationState) => ({
    app_title: auth.app_title,
    loading: auth.loading,
    errors: auth.errors,
    uuid: auth.uuid,
    isAuthenticated: auth.isAuthenticated,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
    fetchAuth: fetchAuth
};

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage)