import React from 'react';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox, {CheckboxProps} from '@material-ui/core/Checkbox';
import { render } from 'react-dom';
import { green } from '@material-ui/core/colors';




interface IReportField {
    label: string,
    is_checked: boolean
}

//DM: copied and edited from material-ui.com/components/checkboxes/
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(0),
    },
    checkbox: {
        height: 10,
        color: green[400],
      },
  }),
);

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
interface IReportField {
    name: string,
    label: string,
    is_checked: boolean
  }

interface IProps {
    handleFieldChecked: (event: any) => void;
    reportSectionFields: Array<any>;
}

export default function CheckboxesGroup(props:IProps) {
    const classes = useStyles();
    const {reportSectionFields, handleFieldChecked, ...otherProps} = props;
    const report_fields = reportSectionFields;

    const [state, setState] = React.useState({
        all_none: false
    });


    const listReportFields = () => {
        console.log("listReportFields() started. report_fields.length: " + report_fields.length);
        const items = [];
        for(let i = 0; i < report_fields.length; i++){
            items.push(<FormControlLabel
                key={report_fields[i].name}
                control={<GreenCheckbox checked={report_fields[i].is_checked}
                onChange={handleFieldChecked}
                color="primary"
                className={classes.checkbox}
                name={report_fields[i].name}/>}
                label={report_fields[i].label}/>);

        }
        return items;
    };
    console.log("reportSectionFields: ", reportSectionFields);

    return (
        <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
            {
                //DM: this label isn't needed because the selector above it should be
                //showing the label
                //<FormLabel component="legend">Assign responsibility</FormLabel>
            }
            <FormGroup>
                {listReportFields()}
            </FormGroup>
            <FormHelperText>Select fields to be included in Report</FormHelperText>
        </FormControl>
        </div>
    );
}
