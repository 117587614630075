import {Reducer} from "redux";
import {AnalysisActionTypes, AnalysisState} from "./types";

// Type-safe initialState!
export const initialState: AnalysisState = {
    loading: false,
    analysisList: [],
    analysisName: ''
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AnalysisState> = (state = initialState, action) => {
    switch (action.type) {
        case AnalysisActionTypes.FETCH_ANALYSIS_REQUEST: {
            return {...state, loading: true}
        }
        case AnalysisActionTypes.FETCH_ANALYSIS_RESULT_REQUEST: {
            return {...state, loading: true}
        }
        case AnalysisActionTypes.FETCH_ANALYSIS_REQUEST_SUCCESS: {
            return {...state, loading: false, analysisList: action.payload}
        }
        case AnalysisActionTypes.FETCH_ANALYSIS_REQUEST_ERROR: {
            return {...state, loading: false, errors: action.payload}
        }
        case AnalysisActionTypes.SET_ANALYSIS_NAME: {
            return {...state, analysisName: action.payload}
        }
        case AnalysisActionTypes.DELETE_ANALYSIS_REQUEST: {
            return {...state, loading: true}
        }
        case AnalysisActionTypes.DELETE_ANALYSIS_REQUEST_SUCCESS: {
            return {...state, loading: false, analysisList: action.payload}
        }
        case AnalysisActionTypes.UNSHARE_ANALYSIS_REQUEST_SUCCESS: {
            return {...state, loading: false, analysisList: action.payload}
        }

        case AnalysisActionTypes.RESET_STATE: {
            return {...state, ...initialState}
        }

        case AnalysisActionTypes.RESTORE: {
            //@TODO ask Jesse if this should be loading: true, so that it has time to finish
            console.log("inside store/analysis/reducer.ts for RESTORE: ", action.payload);
            return {...state, analysisName: action.payload.name, loading: false}
        }

    }
    return state;
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export {reducer as analysisReducer}
