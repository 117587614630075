export interface AttributesResponseInterface {
    name: string,
    type: string,
    attribute_id: number,
    selected: boolean,
    value?: any,
    _default?: boolean,
    _value?: any,
    _operator?: string
}

export interface getVehicles_query_Interface {
    date_range: {
        start_date: string,
        end_date: string
    },
    attributes: QueryByAttribute[];
}

export interface QueryByAttribute {
    attribute_id: number,
    operator: string,
    value: string | number | {}
}


export interface DataAttribute {
    "name": string,
    "type": string,
    "attribute_id": number,
    "selected": boolean,
    "value": any,
    "operation": string
};

export const AttributesResponse = [{"name":"Equipment ID","type":"string","attribute_id":1,"selected":true},{"name":"VIN","type":"string","attribute_id":2,"selected":true},{"name":"Make","type":"string","attribute_id":3,"selected":true},{"name":"Model","type":"string","attribute_id":4,"selected":true},{"name":"Model Year","type":"number","attribute_id":5,"selected":true},{"name":"Purchase Year","type":"string","attribute_id":6,"selected":true},{"name":"Department","type":"string","attribute_id":7,"selected":true},{"name":"MFB-Vehicle Type","type":"string","attribute_id":8,"selected":true}];
export const SimVehislesAttributesResponse = [{"name":"VIN","type":"string","attribute_id":2,"selected":true},{"name":"Make","type":"string","attribute_id":3,"selected":true},{"name":"Model","type":"string","attribute_id":4,"selected":true},{"name":"Model Year","type":"number","attribute_id":5,"selected":true},{"name":"Department","type":"string","attribute_id":7,"selected":true},{"name":"MFB-Vehicle Type","type":"string","attribute_id":8,"selected":true}];

export const Attributes = [{"name":"Equipment ID","type":"string","attribute_id":22,"selected":true},{"name":"VIN","type":"string","attribute_id":23,"selected":true},{"name":"Make","type":"string","attribute_id":24,"selected":true},{"name":"Model","type":"string","attribute_id":25,"selected":true},{"name":"Model Year","type":"number","attribute_id":26,"selected":true},{"name":"Purchase Year","type":"string","attribute_id":27,"selected":true},{"name":"Department","type":"string","attribute_id":28,"selected":true},{"name":"MFB-Vehicle Type","type":"string","attribute_id":29,"selected":true}];

// R2.8 Available query operators:
// "is", "is not" for text fields
// "is", "is not", "greater than", "lower than", "greater than or equal", "lower than or equal" for numbers, dates
export const QueryNumbersOperators = [
    "eq", "neq", "gt", "lt", "gte", "lte"
];
export const QueryTextsOperators = [
    "eq", "neq"
];


export const esOpMapping: {[key: string]: string} = {
    "eq": "is",
    "neq": "is not",
    "gt": "greater than",
    "lt": "lower than",
    "gte": "greater than or equal",
    "lte": "lower than or equal"
};


export const elasticsearchConditionCompare = {
    eq: "is",
    neq: "is not",
    gt: "greater than",
    lt: "lower than",
    gte: "greater than or equal",
    lte: "lower than or equal"
};

export function adapt_operator(value: string): string{
    let new_operator: string = "";

    for (const key of keys(elasticsearchConditionCompare)) {
        if(elasticsearchConditionCompare[key] === value)
            new_operator = key;

        if(key === value)
            new_operator = elasticsearchConditionCompare[key];
    }

    return new_operator;
}

export function keys<O extends object>(obj: O): Array<keyof O> {
    return Object.keys(obj) as Array<keyof O>;
}