import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {addCarSearchFilter} from '../../../store/car_legacy/car_actions';
import CarGridItem from './CarGridItem';

const mapStateToProps = state => {
    return {
        baseModels: state.cars.baseModels
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCarSearchFilter: (filterType, value, text) => dispatch(addCarSearchFilter(filterType, value, text))
    };
};

const styles = theme => ({
    carGrid: {
        maxHeight: 580,
        overflowY: "auto",
        overflowX: "visible",
        marginBottom: ".5rem"
    },
    
    carImg: {
        width: "100%"
    },
    carGridItem: {
        overflow: "hidden" // Hide img with rounded corners
    },
    carItemContent: {
        padding: theme.spacing.unit,
    }
})

class SelectedMakeBaseModels extends React.Component {


    render() {
        const { classes } = this.props;
        
        if (this.props.baseModels.loading) {
            return (
                <div>
                    Loading
                </div>
            )
        } else {
            console.log(this.props.baseModels)
            return (
                
                <Grid container className={classes.carGrid} spacing={16}>
                    {this.props.baseModels.data && this.props.baseModels.data.map(baseModel => {
                        return (
                            <Grid item xs={2} key={baseModel.name} className={classes.carGridItem}>
                                <CarGridItem carGroup
                                    car={baseModel} 
                                    onClick={() => {
                                        this.props.addCarSearchFilter(
                                            "model",
                                            {
                                                make: baseModel.make,
                                                base_model: baseModel.name
                                            },
                                            baseModel.make + ' ' + baseModel.name
                                        )}
                                    }
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            )
        }
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectedMakeBaseModels));