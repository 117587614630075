import brandColors from "../../styles/colors/brandColors";
import styled from "../../utils/styled";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const StyledTitle = styled('h2')`
  margin: 0;
  font-weight: 500;
  color: ${props => props.theme.colors.regular};
`
export const StyledLoginButton = styled(Button)`
    background-color: ${props => props.theme.colors.regular};
    height: 40px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.regular};
      margin-top: 20px;
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.active_link}
    }
 
`;
export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: brandColors.crispedge,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: brandColors.crispedge,
    },
  },
})(TextField);