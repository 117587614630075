import { action } from 'typesafe-actions'
import {Attribute, VehiclesActionTypes, Incentives} from './types'
import {GetVehiclesResponse, Vehicle} from "../../helpData/get_vehicles_response_example";
import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import {AttributeView} from "../../pages/filter_vehicles";

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

//vehicles/attributes/
export const fetchAttributes = (type: string = 'vehicles' || 'simvehicles') => action(VehiclesActionTypes.FETCH_REQUEST, type);
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const fetchSuccess = (type: string = 'vehicles' || 'simvehicles', data: Attribute[]) => action(VehiclesActionTypes.FETCH_SUCCESS, {type, data});
export const fetchError = (type: string = 'vehicles' || 'simvehicles', message: string) => action(VehiclesActionTypes.FETCH_ERROR, {type, message});


///vehicles/?query=
export const fetchVehicles = (data: {url: string, type: 'vehicles' | 'simvehicles', mapping?: any}) => action(VehiclesActionTypes.FETCH_VEHICLES, data);
export const fetchVehiclesSuccess = (data: {data: GetVehiclesResponse, type: 'vehicles' | 'simvehicles'}) => action(VehiclesActionTypes.FETCH_VEHICLES_SUCCESS, data);
export const fetchVehiclesError = (data: { message: string, type: 'vehicles' | 'simvehicles'}) => action(VehiclesActionTypes.FETCH_VEHICLES_ERROR, data);

export const setRowsPerPage = (rows_per_page: number) => action(VehiclesActionTypes.SET_ROWS_PER_PAGE, rows_per_page);

export const setSimVehicleSuggest = (data: SimVehicleSuggest[]) => action(VehiclesActionTypes.SET_SIM_VEHICLE_SUGGEST, data);
export const setVehicleMapping = (data: {}) => action(VehiclesActionTypes.SET_VEHICLE_MAPPING, data);
export const setMappingPrevSimVehicleSuggest = (data: {}) => action(VehiclesActionTypes.SET_MAPPING_PREV_SIM_VEHICLE_SUGGEST, data);
export const setGroupByAttribute = (attribute: Attribute) => action(VehiclesActionTypes.SET_GROUP_BY_ATTRIBUTE, attribute);
export const setSelectedVehicles = (data: Vehicle) => action(VehiclesActionTypes.SET_SELECTED_VEHICLE, data);
export const setCurrentQuery = (data: any) => action(VehiclesActionTypes.SET_CURRENTY_QUERY, data);
export const setSelectedAttributes = (attributes: AttributeView[]) => action(VehiclesActionTypes.SET_SELECTED_ATTRIBUTES, attributes);
export const setSelectedDateRange = (date_range: { start_date: string, end_date: string }) => action(VehiclesActionTypes.SET_SELECTED_DATE_RANGE, date_range);
export const setSimVehicleAttributes = (data: {}) => action(VehiclesActionTypes.SET_SIM_VEHICLE_ATTRIBUTES, data);
export const setCurrentQueryForSimVehiclesSaving = (data: {}) => action(VehiclesActionTypes.SET_CURRENT_QUERY_FOR_SIM_VEHICLES, data);
export const setGroupToSimVehiclesMapping = (mapping: {[group_title: string]: Vehicle[]}) => action(VehiclesActionTypes.SET_GROUP_TO_SIM_VEHICLE_MAPPING, mapping);
export const setSelectedSimVehicles = (vehicles: Vehicle[]) => action(VehiclesActionTypes.SET_SELECTED_SIM_VEHICLES, vehicles);
export const setVehicleIncentives = (incentives: Incentives) => action(VehiclesActionTypes.SET_VEHICLE_INCENTIVES, incentives);


export const fetchAvailableDateRange = () => action(VehiclesActionTypes.FETCH_AVAILABLE_DATE_RANGE);
export const fetchAvailableDateRangeSuccess = (data: any) => action(VehiclesActionTypes.FETCH_AVAILABLE_DATE_RANGE_SUCCESS, data);
export const fetchAvailableDateRangeError = (error: string) => action(VehiclesActionTypes.FETCH_AVAILABLE_DATE_RANGE_ERROR, error);
