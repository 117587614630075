import {Reducer} from "redux";
import {AuthActionTypes, AuthState} from "./types";
import {AnalysisActionTypes} from "../analysis/types";
export const pjson = require('../../../package.json');

// Type-safe initialState!
export const initialState: AuthState = {
  app_title: pjson.title,
  loading: false,
  uuid: null,
  isAuthenticated: null,
  redirect_from_login: null
}
// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
      case AuthActionTypes.FETCH_AUTH: {
          return {...state, loading: true, selected: action.payload}
      }
      case AuthActionTypes.FETCH_AUTH_ERROR: {
          return {...state, loading: false, uuid: null, isAuthenticated: false, errors: action.payload}
      }

      case AuthActionTypes.FETCH_AUTH_SUCCESS: {
          action.payload !== null && window.localStorage.setItem("authenticated", action.payload.token);
          return {...state, loading: false, uuid: action.payload.token, isAuthenticated: true}
      }

      case AuthActionTypes.REDIRECT_FROM_LOGIN: {
          return {...state, redirect_from_login: action.payload}
      }

      case 'RESET_AUTH_STATE': {
          return {...state, ...initialState}
      }

      case AnalysisActionTypes.RESTORE: {
          return {...action.payload.state.auth}
      }
  }
  return state;
}

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as authReducer }
