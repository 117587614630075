import * as React from 'react';
import Page from '../../components/layout/Page'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';


import {Container} from "../../components/layout/Container";
import {FilterCardRow} from "../../components/layout/Card.tsx";
import HomeSimpleTable from "../../components/home_page_components/HomeSimpleTable";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {AnalysisResultFull} from "../../store/results/types";
import {
    fetchAnalysis, deleteAnalysisById, unshareAnalysisById,
    fetchAnalysisResult, setAnalysisNameAction, createNewAnalysisResult
} from "../../store/analysis/actions";
import LoadingModal from "../../components/Modals/LoadingModals";
import { StyledTransparentButton, StyledButton, GreenButton } from '../../components/layout/GreenButton';
import DialogInput from '../../components/Modals/DialogInput';


export interface PropsFromState {
    errors?: string,
    loading: boolean,
    analysisList: AnalysisResultFull[],
    history: any
}
// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    fetchAnalysis: typeof fetchAnalysis
    fetchAnalysisResult: typeof fetchAnalysisResult
    deleteAnalysisById: typeof deleteAnalysisById
    unshareAnalysisById: typeof unshareAnalysisById
    setAnalysisNameAction: typeof setAnalysisNameAction
    createNewAnalysis: typeof createNewAnalysisResult
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & PropsFromDispatch

interface IState {
    analysisList?: AnalysisResultFull[]
    isDialogVisible: boolean
}

class HomePage extends React.PureComponent<AllProps, IState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isDialogVisible: false
        }

        this.props.fetchAnalysis();
        this.onSubmitClicked = this.onSubmitClicked.bind(this)
        this.onDialogCloseClicked = this.onDialogCloseClicked.bind(this)
        this.openDialog = this.openDialog.bind(this)
    }

    onDialogCloseClicked() {
        this.setState({
            isDialogVisible: false
        })
    } 

    openDialog() {
        this.setState({
            isDialogVisible: true
        })
    } 

    onSubmitClicked(name: string) {
        this.setState({
            isDialogVisible: false
        })
        this.props.history.push('/fleet-analysis/vehicles');
        this.props.createNewAnalysis();
        this.props.setAnalysisNameAction(name);
        // saveResults(state);
    };

    render() {
        const { isDialogVisible } = this.state

        return (
            <Page>
                <Container>
                    {/*<Typography color="inherit" variant="h5" style={{margin: "23px 13px"}}>*/}
                        {/*Welcome!*/}
                    {/*</Typography>*/}
                    <Paper>
                        <div className={"card-container"}>
                            <Grid container >
                                <Grid item xs style={{margin: '20px'}}>
                                    <Typography color="inherit" variant="h5">
                                        Recent Analysis
                                    </Typography>
                                </Grid>
                                <Grid item xs style={{margin: '20px'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    
                                        <GreenButton
                                            data-test-id={"CreateOrSaveAnalysisButton"}
                                            //disabled={state.resultsPage.loading}
                                            onClick={this.openDialog}
                                            variant="contained"
                                            //className={"transparen-button"}
                                        >
                                            Create Analysis
                                        </GreenButton>
                                    </div>
                                </Grid>
                            </Grid>




                            <HomeSimpleTable key={"AnalysisListOnHomePage"}
                                            rows={this.props.analysisList.sort((a: AnalysisResultFull, b: AnalysisResultFull) => b.id - a.id)}
                                             history={this.props.history}
                                             unshareAnalysisById={this.props.unshareAnalysisById}
                                             deleteAnalysisById={this.props.deleteAnalysisById}
                                             fetchAnalysisResult={this.props.fetchAnalysisResult}
                                             setAnalysisNameAction={this.props.setAnalysisNameAction}
                                             noItemText={"Analysis was not created"}/>

                             {
                                this.props.loading && (<LoadingModal open={this.props.loading}/>)
                            }
                        </div>
                    </Paper>
                </Container>
                <DialogInput
                    key={+isDialogVisible}
                    handleClose={this.onDialogCloseClicked}
                    isVisible={isDialogVisible}
                    onSubmitClicked={this.onSubmitClicked}
                    negativeBtnName="Cancel"
                    positiveBtnName="Go next"
                    title="Create new analysis"
                />
            </Page>
        )
    }
}
// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
export const mapStateToProps = ({homePage}: ApplicationState) => ({
    analysisList: homePage.analysisList,
    loading: homePage.loading,
    errors: homePage.errors,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
    fetchAnalysis,
    deleteAnalysisById,
    unshareAnalysisById,
    fetchAnalysisResult,
    setAnalysisNameAction,
    createNewAnalysis: createNewAnalysisResult
};

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);
