import rgba from "polished/lib/color/rgba";

const brandColors = {
    black: '#000000',
    gray75: '#2e2e2c',
    gray60: '#666666',
    gray50: '#7f7f7f',
    gray40: '#999999',
    gray30: '#b2b2b2',
    gray20: '#cccccc',
    gray10: '#e5e5e5',
    gray5: '#ebebea',
    gray: '#f5f5f5',
    solitude: '#e5e7eb',
    white: '#fefefe',
    yellow: '#f3df49',
    red: '#eb5558',
    valencia: '#eb5558',
    text_red: '#DB3445',
    green: '#9ee79a',
    regular_green: '#008d00',
    light_green: '#00be00',
    text_green: '#66BB6A',
    madison: '#26394e',
    fringy_flower: '#aedec5',
    vista_blue: '#8bd1ac',
    pickled_bluewood: '#324a61',
    crispedge: '#52b76e',
    kashmir_blue: '#5c667d',
    light_crispedge: rgba(155, 172, 186, 0.46)
};

export default brandColors
