import * as React from 'react';
import Page from '../../components/layout/Page'
import styled from '../../utils/styled'
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {GreenButton, RoundGreenButton} from "../../components/layout/GreenButton";
import Typography from '@material-ui/core/Typography';
import FormHelperText from "@material-ui/core/FormHelperText";
import {Container} from "../../components/layout/Container";
import {withStyles} from '@material-ui/core';
import {clearShareStatusAction, shareResults} from '../../store/results/actions';
import {connect} from 'react-redux';
import {ApplicationState} from '../../store';
import ChipInput from "material-ui-chip-input";
import {EMAIL_REGEXP, validateEmail} from "../../utils/helpers";
import DialogInput from "../../components/Modals/DialogInput";
import {setAnalysisNameAction} from "../../store/analysis/actions";
import LoadingModal from "../../components/Modals/LoadingModals";

interface IProps {
    history: any
    isSuccessShared: boolean
    sharedEmails: string[]
    loading: boolean
    shareErrors: string | null
    clearShareStatusAction: typeof clearShareStatusAction
    shareResults: typeof shareResults
    analysisName: string
    setAnalysisNameAction: typeof setAnalysisNameAction
}

interface IState {
    emails: string[]
    isEmailValid: boolean
    isFieldTouched: boolean

}

class ShareResult extends React.PureComponent<IProps, IState> {


    constructor(props: IProps) {
        super(props);

        this.state = {
            emails: this.props.sharedEmails || [],
            isEmailValid: true,
            isFieldTouched: false,
        };
    }

    componentWillUnmount(): void {
        this.props.clearShareStatusAction();
    }

    shareResultClick = () => {
        this.props.shareResults(this.state.emails);
    };

    onGoHomeClicked = () =>
        this.props.history.push('/');

    onGoBackClicked = () =>
        this.props.history.push('/fleet-analysis/result');

    handleChange = (emails: string[]) => {
        this.setState({isFieldTouched: true});
    }

    handleUpdateInput = (event: any) => {
        let email = event.target.value.trim();
        const last = email.toString().slice(-1);

        if (email !== '') {
            if (last === ',') {
                let email_without_comma = email.slice(0, -1);

                validateEmail(email_without_comma) && this.handleAdd(email_without_comma);
            }

            this.setState({isFieldTouched: true});
        } else {
            this.state.emails.length >= 1 && this.setState({isEmailValid: true, isFieldTouched: true});
        }
    }

    onBeforeAdd = (email: string) => {
        let _email = email.trim();
        if (_email !== '') {
            if (!validateEmail(_email)) {
                this.setState({
                    isEmailValid: EMAIL_REGEXP.test(_email), isFieldTouched: true
                });
            } else this.setState({isEmailValid: EMAIL_REGEXP.test(_email), isFieldTouched: true});
            return validateEmail(_email);
        }
    }

    handleAdd = (email: string) => {
        let _email = email.trim();

        const emails = this.state.emails.map((i: string) => i);
        emails.push(_email);

        this.setState({
            emails: emails,
            isEmailValid: EMAIL_REGEXP.test(_email), isFieldTouched: true
        })
    }

    handleDelete = (deletedChip: string) => {
        this.setState({
            emails: this.state.emails.filter((c) => c !== deletedChip),
            isEmailValid: true,
            isFieldTouched: true
        })
    }

    renderContent() {
        const {isEmailValid, isFieldTouched, emails} = this.state;
        const {isSuccessShared, loading, shareErrors} = this.props;
        if (isSuccessShared) {
            return (
                <MessageContainer>
                    <Title>Shared Successfully</Title>
                    <GreenButton
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={this.onGoHomeClicked}>
                        Go Home
                    </GreenButton>
                </MessageContainer>
            );
        }
        return (
            <>
                <FilterCardRow>
                    <RoundGreenButton
                        onClick={this.onGoBackClicked}
                        aria-label="share result"
                        style={{marginRight: '10px'}}>
                        <ArrowBackIcon/>
                    </RoundGreenButton>
                    <Typography color="inherit" variant="h5">
                        Share result
                    </Typography>
                </FilterCardRow>

                <FilterCardRow>
                    <div>
                        <div id={"emailSharing"}>
                            <CustomTextField
                                {...this.props}
                                id="email"
                                required
                                fullWidth
                                variant="outlined"
                                value={emails}
                                onBeforeAdd={(chip) => this.onBeforeAdd(chip)}
                                onChange={(chips) => this.handleChange(chips)}
                                onUpdateInput={(chips) => this.handleUpdateInput(chips)}
                                onAdd={(chip) => this.handleAdd(chip)}
                                onDelete={(deletedChip) => this.handleDelete(deletedChip)}
                                newChipKeyCodes={[188]}
                                placeholder={"Please enter an email address"}
                                helperText={!isEmailValid && "Incorrect email address"}
                                error={!isEmailValid}
                            />
                            <div>
                                <FormHelperText
                                    style={{fontSize: '13px'}}>{"The accounts will get read-only access to the analysis result"}</FormHelperText>
                                {shareErrors &&
                                <FormHelperText
                                    style={{fontSize: '13px', color: 'red'}}>{shareErrors}</FormHelperText>
                                }
                            </div>
                        </div>
                    </div>
                    <GreenButton
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        disabled={!isFieldTouched || !isEmailValid || loading || emails.length === 0}
                        style={{height: '40px', marginLeft: '10px'}}
                        onClick={this.shareResultClick}
                    >Share
                    </GreenButton>
                </FilterCardRow>
                <DialogInput
                    key={this.props.analysisName}
                    handleClose={() => this.props.history.push('/')}
                    isVisible={this.props.analysisName === ''}
                    onSubmitClicked={this.props.setAnalysisNameAction}
                    negativeBtnName="Cancel"
                    positiveBtnName="Go next"
                    title="Create new analysis"/>
            </>
        );
    }

    render() {
        const {loading} = this.props;
        return (
            <Page>
                <Container>
                    <Paper>
                        <div className={"card-container"}>
                            {loading ? (<LoadingModal open={loading}/>) : this.renderContent()}
                        </div>
                    </Paper>

                </Container>
            </Page>
        )
    }
}

export const mapStateToProps = ({resultsPage, homePage}: ApplicationState) => ({
    isSuccessShared: resultsPage.isSuccessShared,
    loading: resultsPage.loading,
    shareErrors: resultsPage.shareErrors,
    sharedEmails: resultsPage.sharedEmails,
    analysisName: homePage.analysisName
});
const mapDispatchToProps = {
    clearShareStatusAction,
    shareResults,
    setAnalysisNameAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareResult);

const FilterCardRow = styled('div')`
    display: flex;
    width: 50%;
    margin-top: 20px;
    padding: 0 16px;
`;

const Title = styled('h1')`
    color: ${props => props.theme.colors.pickled_bluewood};
    font-size: 18px;
    font-weight: 500;
`;

const MessageContainer = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
`;

const CustomTextField = withStyles({
    root: {
        marginTop: 0,
        '& .MuiChip-root': {
            height: '30px'
        }
    },
})(ChipInput);
