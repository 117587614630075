import {createEndpoint} from './websocket_auth';
import mqtt from 'mqtt';
import AWS, {AWSError} from 'aws-sdk';
import {fetchPostSimulationRequest} from "../store/charging-page/actions";
import {fetchResults, websocketProgressStatus} from "../store/results/actions";

export function initWebsocket(store: { dispatch: any; getState: any; }, payload: any) {
    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:39867bfc-99e7-4a5d-8b60-0c71e2779e0c',
    });
    let mqtt_client: mqtt.MqttClient | null = null;
    // @ts-ignore
    AWS.config.credentials.get((err: AWSError) => {
        // @ts-ignore
        const accessKeyId = AWS.config.credentials.accessKeyId;
        // @ts-ignore
        const secretAccessKey = AWS.config.credentials.secretAccessKey;
        // @ts-ignore
        const sessionToken = AWS.config.credentials.sessionToken;

        const endpoint = createEndpoint(
            'us-west-2',
            'a9rf6gnul6p0j-ats.iot.us-west-2.amazonaws.com',
            accessKeyId,
            secretAccessKey,
            // @ts-ignore
            sessionToken
        );
        console.log("Connecting to websocket:", endpoint);

        mqtt_client = mqtt.connect(endpoint);


        // let state = store.getState();
        let simulation_uuid = payload.simulation_uuid;
        let runSimulationRequest = payload.body;


        mqtt_client.on('connect', () => {
            if(simulation_uuid !== ''){
                console.log(`compute_status/`+simulation_uuid);
                 // @ts-ignore
                mqtt_client.subscribe(`compute_status/`+simulation_uuid, (err2 => console.log(err2)));

                store.dispatch(fetchPostSimulationRequest({simulation_uuid: simulation_uuid, body: runSimulationRequest}));
                store.dispatch({type: 'RESULT_ARE_NOT_READY'});
            }
        });

        mqtt_client.on('message', (topic: any, message: any) => {
            let msg = JSON.parse(message.toString());

            console.log('received', msg);
            
            if (msg.status === "COMPUTE_DONE") {
                store.dispatch(fetchResults());
                //store.dispatch({type: 'RESULT_ARE_READY'});

                // @ts-ignore
                mqtt_client.end();
            } else if (msg.status === "COMPUTE_PROGRESS") {
                
                let progress = Math.round((msg.es_hits / msg.num_lambdas)*100);
                console.log("Progress", progress);
                store.dispatch(websocketProgressStatus(progress))
            }
        });
    });
}

