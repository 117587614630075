import {all, fork} from 'redux-saga/effects';
import authWatcherSaga from './auth/sagas';
import vehiclesWatcherSaga from './vehicles/sagas';
import chargingPageWatcherSaga from './charging-page/sagas';
import analysisWatcherSaga from './analysis/sagas';
import resultsWatcherSaga from './results/sagas';

///////////////////////////////////////////////////////////////////////


export default function* mainSaga() {
	yield all([
		fork(authWatcherSaga),
		fork(vehiclesWatcherSaga),
		fork(chargingPageWatcherSaga),
		fork(analysisWatcherSaga),
		fork(resultsWatcherSaga),
	]);
	// yield fork(router, history, routesSagas, options);
};
