import * as React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import useTheme from "@material-ui/core/styles/useTheme";
import LinearProgress from "@material-ui/core/LinearProgress";
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import brandColors from "../../styles/colors/brandColors";
import rgba from "polished/lib/color/rgba";

interface IProps {
    open: boolean,
    progress?: number,
    message?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
                zIndex: 0,
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: rgba(225, 231, 235, 1)
            },
            '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: rgba(82, 183, 110, 1)
            },
        },
        palette: {
            primary: {
                main: brandColors.crispedge,
                light: brandColors.crispedge,
                dark: brandColors.crispedge,
            },
            secondary: {
                main: '#81c784',
                light: '#81c784',
                dark: '#81c784',
            },
        },
    }),
);

const useDialogClasses = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiDialog-paper': {
                width: '300px',
                height: '150px'
            },
            '& .MuiDialogTitle-root': {
                justifyContent: 'center',
                display: 'flex'
            },
            '& .MuiDialogContent-root': {
                padding: '24px'
            },
            paperWidthSm: {
                width: '200px',
            },
            paperWidthXs: {
                width: '100px',
            },
        },
    })
);

const LoadingModal: React.SFC<IProps> = ({open, progress}) => {
    const classes = useStyles();
    const dialogClasses = useDialogClasses();
    let showProgressBar = progress && (progress >= 0 && progress <= 100);
    return (
        <Dialog
            className={dialogClasses.root}
            open={open}
            aria-labelledby="responsive-dialog-title"
            onBackdropClick={() => open = false}
        >
            <DialogTitle id="responsive-dialog-title">{"Please wait."}</DialogTitle>
            <DialogContent>
                <div className={classes.root}>
                    {/*<LinearProgress variant="determinate" value={completed} color={"primary"}/>*/}
                    {(showProgressBar) ? 
                    <React.Fragment>
                        <LinearProgress variant="buffer" value={progress} valueBuffer={progress}/>
                        <div style={{textAlign: "center", color: "#424242"}}>
                            Running Analysis: {progress}%
                        </div>
                    </React.Fragment>
                    
                    :
                    <LinearProgress/>
                    }
                    
                </div>
            </DialogContent>
        </Dialog>

    );
}

export default LoadingModal