import styled from "../../utils/styled";

export const FilterCardRow = styled('div')`
	display: block;
	width: 100%;
	margin: 10px 0;
	padding: 0 16px;
`;

export const FlexBox = styled('div')`
	display: flex;
	width: 100%;
	height: max-content;
	flex-direction: column;
	margin: 10px 0;
`;