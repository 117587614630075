import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';

import Plot from 'react-plotly.js';
import moment from 'moment'


const useStyles = makeStyles({

});

export default function PowerProfilesComponent(props) {
    const classes = useStyles();
    const { selected_charging_site_id } = props
    const power_profiles = useSelector(state => state.resultsPage.power_profiles)

    if (power_profiles && power_profiles.hasOwnProperty(selected_charging_site_id)) {
        return (
            <div style={{minWidth: '100%'}}>
                {/* <LineChart
                    width={1000}
                    height={300}
                    data={power_profiles[selected_charging_site_id]}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="cumulative" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart> */}

                <Plot
                    style={{minWidth: '100%'}}
                    data={[
                        {
                            x: power_profiles[selected_charging_site_id].map(p => new Date(p.timestamp * 1000)),
                            y: power_profiles[selected_charging_site_id].map(p => p.cumulative/ 1000),
                            type: 'scatter',
                            mode: 'lines',
                            marker: { color: 'red' },
                        },
                    ]}
                    config={{
                        responsive: true,
                        displayModeBar: true
                    }}
                    layout={{
                        title: 'Power Profile',
                        xaxis: {
                            title: "Date/Time",
                            standoff: 10
                        },
                        yaxis: {
                            title: "Power Demand (kW)",
                            standoff: 10
                        },
                        //autosize: true,
                        //automargin: true
                    }}
                />
            </div>
        );
    } else {
        return null
    }
}