import {default as styled} from "../../utils/styled";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import lightTheme from "../../styles/theme/light";

export const GreenButton = styled(Button)`
    background-color: ${props => props.theme.colors.regular};
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.regular};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.line}
    }
    &:disabled {
        background-color: ${props => props.theme.colors.active_link};
        cursor: progress;
    }
 
`;
export const RoundGreenButton = withStyles({
    root: {
        backgroundColor: lightTheme.colors.regular,
        borderRadius: '50%',
        color: 'white',
        width: '30px',
        height: '30px',
        minWidth: '30px',
        minHeight: '30px',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
})(Button);

export const StyledTransparentButton = styled(Button)`
    background-color: transparent;
    height: 40px;
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.disable};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.active_link}
    }
 
`;

export const StyledButton = styled(Button)`
    background-color: ${props => props.theme.colors.regular};
    height: 40px;
    &.MuiButtonBase-root{
      background-color: ${props => props.theme.colors.regular};
     }
    & .MuiButton-label{
        text-transform: none;
        color: ${props => props.theme.colors.white};
    }
    &.MuiButton-contained:hover {
       background-color: ${props => props.theme.colors.line}
    }
 
`;