import React, { useState } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import iconBlue from '../../../assets/images/Car_icon_blue.png';
import { Subtitle, Title } from './styled';
import { Button } from '@material-ui/core';
import { GreenButton } from '../../layout/GreenButton';
import Typography from "@material-ui/core/Typography";
import { default as styled } from "../../../utils/styled";
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface ICarMarkerProps {
    lat: number
    lng: number
    chargeSite: any,
    click?: boolean,
    showChargingSitesModal?: (event: any) => void
}

function ChargeMarker(props: ICarMarkerProps) {
    const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

    const { chargeSite, lat, lng, click } = props;

    const [isVisible, setVisible] = useState(false);
    const [isClicked, setClicked] = useState(click);

    const onMouseOver = () => setVisible(true);
    const onMouseExit = () => setVisible(false);
    const onClick = () => setClicked(true)
    const onCloseClick = (event: any) => setClicked(false)

    const [utilityRatesModalOpen, setUtilityRatesModalVisible] = React.useState(false);
    function createData(name: any, value: any, bold: any = false) {
        return { name, value, bold };
    }
    const rows = [
        createData('Rate Schedule', "32 - Small Nonresidential Standard Service"),
        createData('Basic Charge', "Single Phase Service = $20"),
        createData('Transmission and related services charge', "0.206 ȼ per kWh"),
        createData('Distribution Charge', "", true),
        createData('First 5,000 kWh', "4.222 ȼ per kWh"),
        createData('Over 5,000 kWh', "1.477 ȼ per kWh"),
        createData('Energy Charge Options', "", true),
        createData('On-Peak Period', "10.378 ȼ per kWh"),
        createData('Mid-Peak Period', "5.842 ȼ per kWh"),
        createData('Off-Peak Period', "3.462 ȼ per kWh")
    ];

    return (
        <Marker
            icon={{
                url:
                    chargeSite.chargers.level_2 == 0 ?
                        "https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/Building+Marker.png"
                        :
                        "https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/Building+EV+Marker.png"
                ,
                //iconSize: new window.google.maps.Size(32, 32),
                scaledSize: { height: 50, width: 50 }
            }}
            position={{ lat, lng }}
            onClick={onClick}
        >
            {isClicked ?
                <InfoBox visible={true} options={{ closeBoxURL: ``, enableEventPropagation: true }}>
                    <div style={{ margin: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: 'white', borderRadius: 10 }}>
                        <div style={{ borderRadius: 10, boxShadow: '0px 0px 8px 0px rgba(64,167,116,1)' }}>
                            <div style={{ margin: 15 }}>
                                <Grid container justify='flex-end'>
                                    <Button onClick={onCloseClick} style={{ width: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                        <img src="https://myfleetbuy-frontend-assets.s3.ca-central-1.amazonaws.com/Close+icon.png" style={{ height: 10, width: 10, objectFit: 'contain' }} />
                                    </Button>
                                </Grid>
                                <Typography style={{ fontSize: 16 }}>
                                    {chargeSite.name}
                                </Typography>
                                <div style={{ marginTop: 10, marginBottom: 10 }}>
                                    {/* <Typography style={{ fontSize: 12 }}>
                                        Current chargers: {chargeSite.num_chargers}
                                    </Typography> */}
                                    <Typography style={{ fontSize: 12 }}>
                                        Chargers needed: {chargeSite.chargers.level_2}
                                    </Typography>
                                </div>
                                <Button variant="outlined" color="primary" disabled={chargeSite.chargers.level_2 == 0} onClick={props.showChargingSitesModal} style={{ width: 150 }}>
                                    Power Model
                                </Button>
                                <Button variant="outlined" color="primary" onClick={() => setUtilityRatesModalVisible(true)} style={{ width: 150 }}>
                                    Utility Rates
                                </Button>


                                <Dialog
                                    maxWidth={"md"}
                                    open={utilityRatesModalOpen}
                                    onClose={() => setUtilityRatesModalVisible(false)}
                                    aria-labelledby="max-width-dialog-title"
                                >
                                    <DialogTitle id="max-width-dialog-title">{chargeSite.name}</DialogTitle>
                                    <DialogContent>
                                        <Table  aria-label="simple table">
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row" style={{fontWeight: row.bold ? "bold" : "inherit"}}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">{row.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setUtilityRatesModalVisible(false)} color="primary">
                                            Close
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </InfoBox>

                : null}
        </Marker>
    );
}

export default ChargeMarker;
