import * as React from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import {StyledTableCell, StylesTableBody, StylesTableHead} from "../layout/TableBaseStyles";

interface IProps {
	rows: [] | any,
	noItemText?: string
}

interface IState {
	noItemText: string
}

class SimpleTable extends React.PureComponent<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			noItemText: this.props.noItemText || 'No items'
		};
	}

	getRowAttribute(attributes: any, type: string) {
		for (let i in attributes) {
			if (attributes[i].name == type)
				return attributes[i].value
		}

		return null
	}

	render() {
		console.log(this.props.rows)

		return (
			<Table
				aria-labelledby="tableTitle"
				size={'medium'}
				aria-label="enhanced table"
			>
				<StylesTableHead>
					<TableRow key={'SimpleTableRowHeader'}>
						<StyledTableCell>Eq id</StyledTableCell>
						<StyledTableCell>Make</StyledTableCell>
						<StyledTableCell>Model</StyledTableCell>
						<StyledTableCell>Model Year</StyledTableCell>
						<StyledTableCell>Department</StyledTableCell>
						<StyledTableCell>Vehicle Type</StyledTableCell>
						<StyledTableCell>Total dist.</StyledTableCell>
					</TableRow>
				</StylesTableHead>
				<StylesTableBody>
					{this.props.rows.length ? this.props.rows.map((row: any) => (
						<TableRow key={row.vehicle_id || row.telematics_id}>
							<StyledTableCell colSpan={1} style={{width: '90px'}}>{row.telematics_id}</StyledTableCell>
							<StyledTableCell colSpan={1} >{this.getRowAttribute(row.attributes, "Make")}</StyledTableCell>
							<StyledTableCell colSpan={1} >{this.getRowAttribute(row.attributes, "Model")}</StyledTableCell>
							<StyledTableCell colSpan={1} >{this.getRowAttribute(row.attributes, "Model Year")}</StyledTableCell>
							<StyledTableCell colSpan={1} >{this.getRowAttribute(row.attributes, "Department")}</StyledTableCell>
							<StyledTableCell colSpan={1} >{this.getRowAttribute(row.attributes, "MFB-Vehicle Type")}</StyledTableCell>
							<StyledTableCell colSpan={1}>{row.total_distance.toFixed(1) + ' mi'}</StyledTableCell>
						</TableRow>
					)) : (
						<TableRow key={'SimpleTableRowNoItemText'}>
							<StyledTableCell colSpan={8}>
								{this.state.noItemText}
							</StyledTableCell>
						</TableRow>
					)}
				</StylesTableBody>
			</Table>
		);
	}
}
export default SimpleTable;
