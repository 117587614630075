import * as React from 'react'
import styled from '../../utils/styled'
import {Redirect} from 'react-router-dom';

import Page from '../../components/layout/Page'
import {Container} from "../../components/layout/Container";


function NotFoundPage(props: any) {

    if(props.location.pathname.indexOf('fleet-analysis') !== -1) {
        return <Redirect to={'/fleet-analysis/vehicles'}/>
    }

    return (
        <Page>
            <Container>
                <InnerContainerNotFound>
                    <Wrapper>
                        <h2>Not Found</h2>
                    </Wrapper>
                </InnerContainerNotFound>
            </Container>
        </Page>
    )
}

const Wrapper = styled('div')`
    position: relative;
`;
const InnerContainerNotFound = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export default NotFoundPage
