import sha256 from 'crypto-js/sha256';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import hex from 'crypto-js/enc-hex';
import moment from 'moment'

function SigV4Utils() {
}

SigV4Utils.sign = function (key: string, msg: string) {
    const hash = hmacSHA256(msg, key);
    return hash.toString(hex);
};

SigV4Utils.sha256 = function (msg: string) {
    const hash = sha256(msg);
    return hash.toString(hex);
};

SigV4Utils.getSignatureKey = function (key: string, dateStamp: string, regionName: string, serviceName: string) {
    const kDate = hmacSHA256(dateStamp, 'AWS4' + key);
    const kRegion = hmacSHA256(regionName, kDate);
    const kService = hmacSHA256(serviceName, kRegion);
    return hmacSHA256('aws4_request', kService);
};


export function createEndpoint(
    regionName: string,
    awsIotEndpoint: string,
    aKey: string,
    sKey: string,
    session_token: string
) {
    const time = moment.utc();
    const dateStamp = time.format('YYYYMMDD');
    const amzdate = dateStamp + 'T' + time.format('HHmmss') + 'Z';
    const service = 'iotdevicegateway';
    const region = regionName;
    const secretKey = sKey;
    const accessKey = aKey;
    const algorithm = 'AWS4-HMAC-SHA256';
    const method = 'GET';
    const canonicalUri = '/mqtt';
    const host = awsIotEndpoint;
    const credentialScope = dateStamp + '/' + region + '/' + service + '/aws4_request';
    let canonicalQuerystring = 'X-Amz-Algorithm=AWS4-HMAC-SHA256';
    canonicalQuerystring += '&X-Amz-Credential=' + encodeURIComponent(accessKey + '/' + credentialScope);
    canonicalQuerystring += '&X-Amz-Date=' + amzdate;
    canonicalQuerystring += '&X-Amz-SignedHeaders=host';
    const canonicalHeaders = 'host:' + host + '\n';
    const payloadHash = SigV4Utils.sha256('');
    const canonicalRequest = method + '\n' + canonicalUri + '\n' + canonicalQuerystring + '\n' + canonicalHeaders + '\nhost\n' + payloadHash;
    const stringToSign = algorithm + '\n' + amzdate + '\n' + credentialScope + '\n' + SigV4Utils.sha256(canonicalRequest);
    const signingKey = SigV4Utils.getSignatureKey(secretKey, dateStamp, region, service);
    // @ts-ignore
    const signature = SigV4Utils.sign(signingKey, stringToSign);
    canonicalQuerystring += '&X-Amz-Signature=' + signature;
    canonicalQuerystring += '&X-Amz-Security-Token=' + encodeURIComponent(session_token);
    return 'wss://' + host + canonicalUri + '?' + canonicalQuerystring;
}
