/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { init, RootStore, thunkAction, unboxThunk } from "../../redux";
import { default as styled } from "../../utils/styled";
import CloseIcon from '@material-ui/icons/Close';
import { default as Menu, MenuProps } from "@material-ui/core/Menu";
import {
    QueryByAttribute,
    AttributesResponseInterface, keys, QueryNumbersOperators,
    QueryTextsOperators, esOpMapping
} from "../../helpData/getVehicleAttributes";
import Chip from "@material-ui/core/Chip";
import 'isomorphic-fetch';
import { CssTextField, StyledAutocomplete } from "../layout/Autocomplete";
import { GreenButton } from "../layout/GreenButton";
import InlineDatePickerDemo from "../layout/CustomDatePicker";
import { MenuItem, Select } from "@material-ui/core";
import { isValidDateRange } from "../../helpData/Date";
import moment from 'moment';


/////////////////////////////////////////////////////////////
const CustomFilterBlock = styled('div')`
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
`;


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        padding: '0 10px',
        display: 'flex',
        maxHeight: '100%',
        // width: '27vw'
    },
    list: {
        width: '100%'
    }
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const StyledMenuRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  margin: 15px 0;
`;


const StyledChip = styled(Chip)`
    background-color: ${props => props.theme.colors.inactive_brand};
    &.MuiChip-root{
      background-color: ${props => props.theme.colors.inactive_brand};
     }
    &.MuiChip-root, .MuiChip-deleteIcon{
        text-transform: none;
        color: ${props => props.theme.colors.white};
        font-size: 12px;
    }
    &.MuiChip-root {
        border-radius: 7px;
        height: 40px;
    }
    &.MuiChip-clickable:hover, .MuiChip-clickable:focus {
       background-color: ${props => props.theme.colors.brand}
    }
    
`;


/////////////////////////////////////////////////////////////

interface PropsComboBox {
    attribute: AttributesResponseInterface,
    attributes_type?: 'simvehicles' | 'vehicles',
    removable: boolean,
    removeAttribute: (event: React.ChangeEvent<HTMLInputElement>, attribute: AttributesResponseInterface) => void;
    applyNewFilter: (attribute: QueryByAttribute & AttributesResponseInterface) => void;
    suggest_enabled?: boolean;
    dataTestId?: string;
    availableDateRange?: any;
}

interface CustomState {
    setAnchorEl: HTMLElement | any,
    value: any,
    attribute_id: number,
    loading: boolean,
    options: string[],
    operators: string[],
    QueryOperator: string,
}

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
    init,
    thunkAction: unboxThunk(thunkAction),
}
);

class CustomFilter extends React.PureComponent<PropsComboBox, CustomState> {
    _changed: boolean;

    constructor(props: PropsComboBox) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRemoveAttribute = this.handleRemoveAttribute.bind(this);
        this.handleApplyClick = this.handleApplyClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangeQueryOperator = this.handleChangeQueryOperator.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this._getQueryOperatorsForChips = this._getQueryOperatorsForChips.bind(this);
        this._getTypeofOperators = this._getTypeofOperators.bind(this);
        this._setOperators = this._setOperators.bind(this);
        this.isValidValue = this.isValidValue.bind(this);
        this.handleEnter = this.handleEnter.bind(this);

        this._changed = false;

        if (this.props.attribute) {

            this.state = {
                setAnchorEl: null,
                value: this.props.attribute._value,
                attribute_id: this.props.attribute.attribute_id,
                loading: false,
                options: [],
                QueryOperator: this.props.attribute._operator || this._setOperators()[0],
                operators: this._setOperators(),
            };
        }
    }

    componentDidUpdate(prevProps: PropsComboBox, prevState: CustomState, snapshot: any) {
        if (prevProps.attribute !== this.props.attribute) {

            this.setState({
                setAnchorEl: null,
                value: this.props.attribute._value,
                attribute_id: this.props.attribute.attribute_id,
                loading: false,
                options: [],
                QueryOperator: this.props.attribute._operator || this._setOperators()[0],
                operators: this._setOperators(),
            });
        }

        /**
         * There are cases of different uses of Enter:
         * simply entering Enter into the TextField and
         * pressing Enter at the moment of selecting an item from the AutoComplete list.
         * TextField is a child component of AutoComplete and it is not possible
         * to differentiate keystroke events (individually).
         **/

        if (prevState.value !== this.state.value) {
            this._changed = true;
        }
        if (prevState.value === this.state.value) {
            this._changed = false;
        }
    }

    isValidValue() {
        if (this.props.attribute.attribute_id !== -1) {
            return Array.isArray(this.state.value);
        } else {
            return isValidDateRange(this.state.value);
        }
    }

    _setOperators() {
        return (this.props.attribute.type === "string")
            ? QueryTextsOperators
            : QueryNumbersOperators;
    }

    _getQueryOperatorsForChips(_queryOperator?: string) {
        const operator = _queryOperator ? _queryOperator : this.state.QueryOperator;

        return QueryTextsOperators.some((s: string) => s === operator);
    }

    _getTypeofOperators() {
        if (this.props.attribute.type === "string") {
            return QueryTextsOperators;
        } else {
            return QueryNumbersOperators;
        }
    }

    handleClick(event: any) {
        this.setState({ setAnchorEl: event.currentTarget });
    }

    handleClose() {
        this.setState({ setAnchorEl: null });
    }

    handleRemoveAttribute(event: any, attr: any) {
        event.preventDefault();

        attr.selected = !attr.selected;

        this.props.removeAttribute(event, this.props.attribute);
    }

    handleApplyClick() {
        let value = typeof this.state.value === 'string' ? [this.state.value] : this.state.value;

        const queryAttribute = {
            attribute_id: this.props.attribute.attribute_id,
            operator: this.state.QueryOperator,
            value: value,
            name: this.props.attribute.name,
            selected: this.props.attribute.selected,
            _value: value,
            _operator: this.state.QueryOperator,
            type: this.props.attribute.type
        };
        console.log("handleAppkyClick: queryAttribute: ", queryAttribute);
        this.props.applyNewFilter(queryAttribute);
    }

    handleDateChange(selected_type: string, selected_value: string) {
        const get_state_value = JSON.parse(JSON.stringify(this.state.value));

        for (const key of keys(get_state_value)) {
            if (key === selected_type) get_state_value[key] = selected_value;
        }

        this.setState({ value: get_state_value });
    }


    handleDelete(selected_value: string) {
        let value = this.state.value;
        value = value.filter((s: string) => s !== selected_value);

        this.setState({ value: value });
    }

    handleChange(event: any, selected_value?: string[]) {
        let value: string;

        if (this._getQueryOperatorsForChips()) {
            if (selected_value) {
                selected_value = selected_value.filter((s: string) => {
                    this.state.value.forEach((i: string) => {
                        return s !== i
                    })
                })
            }
            value = selected_value && selected_value.length ? selected_value : event.currentTarget.value;
        } else {
            value = selected_value ? selected_value : event.currentTarget.value;
        }

        if (!value)
            value = "";

        const API_ENDPOINT = process.env.REACT_APP_API_URL;
        let token = window.localStorage.getItem("authenticated");

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json, text/plain, */*');

        token && true && myHeaders.append('Authorization', `Token ${token}`);

        let url: string;
        if (this.props.attributes_type === 'simvehicles') {
            url = `${API_ENDPOINT}/api2/vehicles/attributes/suggest/${this.props.attribute.attribute_id}/?type=simvehicles&query=${value}`;
        } else {
            url = `${API_ENDPOINT}/api2/vehicles/attributes/suggest/${this.props.attribute.attribute_id}/?query=${value}`;
        }

        fetch(url, {
            method: 'GET',
            headers: myHeaders,
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                this.setState({ options: [] });
                throw new Error('Something went wrong on api server!');
            }
        }).then((response) => {
            this.setState({ options: response });
        }).catch((error) => {
            this.setState({ options: [] });
        });

    }

    handleSelect(event: any, selected_value: string[] | string) {
        let array_value;

        if (Array.isArray(selected_value)) {
            array_value = selected_value.filter((s: string) => typeof s !== 'undefined');
        } else {
            if (typeof selected_value !== 'undefined')
                array_value = [selected_value];
        }

        this.setState({ value: array_value });

    }
    handleEnter(event: any) {
        if (event.charCode === 13 || event.key === 'Enter') {
            if (this.state.value.length && this._changed === true) {
                this.handleApplyClick();
                event.preventDefault();
            }
        }

    }

    handleChangeQueryOperator(event: any, selected_value?: any) {
        let operator = selected_value ? selected_value : event.currentTarget.value;
        const value = this.state.value[0];
        !this._getQueryOperatorsForChips(operator) ? this.setState({ QueryOperator: operator, value: [value] }) : this.setState({ QueryOperator: operator });
    }

    render() {
        let valid = this.isValidValue();
        let MainChip;
        let CurrentStyledAutocomplete;
        if (this.state.attribute_id === this.props.attribute.attribute_id) {
            if (this._getQueryOperatorsForChips()) {
                CurrentStyledAutocomplete = <StyledAutocomplete
                    multiple
                    options={this.state.options}
                    getOptionLabel={(option: string) => option}
                    value={this.state.value}
                    style={{ padding: 0, margin: 0 }}
                    onChange={(event: any, value: any) => this.handleSelect(event, value)}
                    onOpen={(event: any) => this.handleChange(event, this.state.value)}
                    filterSelectedOptions
                    autoHighlight
                    autoSelect
                    renderTags={(value: string[]) => {
                        return value.map((option: string, index: number) => (
                            <Chip
                                key={index}
                                variant="outlined"
                                label={option}
                                onDelete={(event: any) => this.handleDelete(option)}
                            />
                        ))
                    }
                    }
                    renderInput={params => (
                        <CssTextField
                            {...params}
                            data-test-id={this.props.dataTestId + '_Autocomplete'}
                            variant="outlined"
                            placeholder={this.props.attribute.name}
                            onChange={this.props.suggest_enabled ? (event: any) => this.handleChange(event) : undefined}
                            style={{ padding: 0, margin: 0 }}
                            autoFocus={true}
                            onKeyDown={(event: any) => this.handleEnter(event)}
                            fullWidth
                        />
                    )}
                />

            } else {
                CurrentStyledAutocomplete = <StyledAutocomplete
                    data-test-id={this.props.dataTestId + '_Autocomplete'}
                    options={this.state.options}
                    getOptionLabel={(option: string) => option}
                    value={this.state.value[0]}
                    style={{ padding: 0, margin: 0 }}
                    autoHighlight
                    autoSelect
                    onChange={(event: any, value: any) => this.handleSelect(event, value)}
                    onOpen={(event: any) => this.handleChange(event, this.state.value[0])}
                    renderInput={params => (
                        <CssTextField {...params} variant="outlined" style={{ padding: 0, margin: 0 }}
                            placeholder={this.props.attribute.name} autoFocus={true}
                            onKeyDown={(event: any) => this.handleEnter(event)}
                            onChange={(event: any) => this.handleChange(event)} fullWidth />
                    )}
                />
            }
        }

        const label =
            this.props.attribute.name + ' ' + esOpMapping[this.state.QueryOperator] + ' ' +
            (this.props.attribute.type !== "date" ? this.state.value
                : this.state.value.start_date + ' to ' + this.state.value.end_date);

        if (this.props.removable) {
            MainChip = <StyledChip
                data-test-id={this.props.dataTestId}
                onClick={this.handleClick}
                label={label}
                deleteIcon={<CloseIcon />}
                onDelete={(event: any) => this.handleRemoveAttribute(event, this.props.attribute)} />
        } else {
            MainChip = <StyledChip
                data-test-id={this.props.dataTestId}
                onClick={this.handleClick}
                label={label} />
        }

        return (
            <CustomFilterBlock>

                {MainChip}

                <StyledMenu
                    id={(this.props.attribute.type !== "date") ? 'AttributeFilter' : 'DataRangeFilter'}
                    anchorEl={this.state.setAnchorEl}
                    keepMounted
                    open={Boolean(this.state.setAnchorEl)}
                    onClose={this.handleClose}
                >

                    <StyledMenuRow>
                        <h5 style={{ margin: 'auto 10px auto 0px' }}>
                            {/* This is to prevent losing of focus when typing the first letter of the span.*/}
                            <span className={"invisible-block"}>_</span>
                            {this.props.attribute.name}
                        </h5>
                        <Select
                            style={{
                                padding: 0,
                                margin: 0,
                                height: 'fit-content',
                                width: '100%',
                                maxHeight: 'fit-content',
                            }}
                            disabled={this.props.attribute.type === "date"}
                            value={this.state.QueryOperator}
                            onChange={(event: any, value: any) => this.handleChangeQueryOperator(event, event.target.value)}
                        >
                            {this.state.operators.map((op) => {
                                return (<MenuItem key={op} value={op}>{esOpMapping[op]}</MenuItem>)
                            })}

                        </Select>
                    </StyledMenuRow>

                    <StyledMenuRow>
                        {this.props.attribute.type !== "date"
                            ? CurrentStyledAutocomplete
                            : <InlineDatePickerDemo handleDateChange={this.handleDateChange}
                                start_date={this.state.value.start_date}
                                end_date={this.state.value.end_date} />}
                    </StyledMenuRow>
                    {
                        this.props.attribute.type === "date" ? (
                            <StyledMenuRow style={{display: "block"}}>
                                <div>
                                    <MenuItem onClick={() => { 
                                        this.setState({ value: { 
                                            start_date: moment(this.props.availableDateRange.last_route).subtract(3, "months").format("YYYY-MM-DD") , 
                                            end_date: moment(this.props.availableDateRange.last_route).format("YYYY-MM-DD") 
                                        } }) 
                                    }}>
                                        3 months from end date
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem onClick={() => { 
                                        this.setState({ value: { 
                                            start_date: moment(this.props.availableDateRange.last_route).subtract(1, "months").format("YYYY-MM-DD") , 
                                            end_date: moment(this.props.availableDateRange.last_route).format("YYYY-MM-DD") 
                                        } }) 
                                    }}>
                                        1 month from end date
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem onClick={() => { 
                                        this.setState({ value: { 
                                            start_date: moment(this.props.availableDateRange.first_route).format("YYYY-MM-DD") , 
                                            end_date: moment(this.props.availableDateRange.last_route).format("YYYY-MM-DD") 
                                        } }) 
                                    }}>
                                        All available data
                                    </MenuItem>
                                </div>
                            </StyledMenuRow>
                        ): null
                    }

                    <StyledMenuRow>
                        <GreenButton
                            disabled={!valid}
                            data-test-id={"CustomFilter_ApplyButton_" + this.props.dataTestId}
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            onClick={(event: any) => this.handleApplyClick()}
                        >Apply
                        </GreenButton>
                    </StyledMenuRow>

                </StyledMenu>
            </CustomFilterBlock>
        );
    }
}

export default storeEnhancer(CustomFilter);