import { action } from 'typesafe-actions'
import {AnalysisActionTypes} from "./types";
import {AnalysisResultFull} from "../results/types";

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

export const deleteAnalysisById = (id: number) => action(AnalysisActionTypes.DELETE_ANALYSIS_REQUEST, id);
export const deleteAnalysisSuccess = (data: any) => action(AnalysisActionTypes.DELETE_ANALYSIS_REQUEST_SUCCESS, data);
export const deleteAnalysisError = (message: string) => action(AnalysisActionTypes.DELETE_ANALYSIS_REQUEST_ERROR, message);

export const unshareAnalysisById = (id: number) => action(AnalysisActionTypes.UNSHARE_ANALYSIS_REQUEST, id);
export const unshareAnalysisSuccess = (data: any) => action(AnalysisActionTypes.UNSHARE_ANALYSIS_REQUEST_SUCCESS, data);
export const unshareAnalysisError = (message: string) => action(AnalysisActionTypes.UNSHARE_ANALYSIS_REQUEST_ERROR, message);

export const fetchAnalysisResult = (id: number) => action(AnalysisActionTypes.FETCH_ANALYSIS_RESULT_REQUEST, id);
export const createNewAnalysisResult = () => action(AnalysisActionTypes.RESET_STATE);

export const fetchAnalysis = () => action(AnalysisActionTypes.FETCH_ANALYSIS_REQUEST);
export const fetchAnalysisSuccess = (data: AnalysisResultFull[]) => action(AnalysisActionTypes.FETCH_ANALYSIS_REQUEST_SUCCESS, data);
export const fetchAnalysisError = (message: string) => action(AnalysisActionTypes.FETCH_ANALYSIS_REQUEST_ERROR, message);
export const setAnalysisNameAction = (name: string) => action(AnalysisActionTypes.SET_ANALYSIS_NAME, name);
