import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
//types
import {AuthState} from "./auth/types";
import { LayoutState } from './layout'
import {VehiclesState} from "./vehicles/types";
import {ChargingState} from './charging-page/types';
import {AnalysisState} from "./analysis/types";
import {ResultsState} from "./results/types";
// reducers
import {authReducer} from './auth/reducer';
import { layoutReducer } from './layout/reducer';
import {vehiclesReducer} from './vehicles/reducer';
import {analysisReducer} from './analysis/reducer';
import {resultsReducer} from './results/reducer';
import carsLegacyReducer from './car_legacy/car'
import { chargingPageReducer } from './charging-page/reducer';
// sagas
import mainSaga from "./saga";
////////////////////////////////////////////////////

// The top-level state object
export interface ApplicationState {
  auth: AuthState
  layout: LayoutState
  router: RouterState
  homePage: AnalysisState
  vehicle: VehiclesState
  chargingPage: ChargingState
  resultsPage: ResultsState
  
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = (history: History) => {
    return combineReducers({
        auth: authReducer,
        layout: layoutReducer,
        vehicle: vehiclesReducer,
        homePage: analysisReducer,
        chargingPage: chargingPageReducer,
        resultsPage: resultsReducer,
        cars: (carsLegacyReducer as any),
        router: connectRouter(history)
    });
};

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([fork(mainSaga)])
}
