import { css } from '@emotion/core'
import brandColors from "./colors/brandColors";

const globals = css`
  body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: Roboto;
  }

    ::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    ::-webkit-scrollbar-button {
      width: 3px;
      height: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #8bd1ac;
      border: 4px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #52b76e;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #52b76e;
    }
    ::-webkit-scrollbar-track {
      background: #c9cbcf;
      border: 4px none #ffffff;
      border-radius: 76px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #9fa0a1;
    }
    ::-webkit-scrollbar-track:active {
      background: #9fa0a1;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    position: relative;
    vertical-align: middle;
  }

  .carousel {
    flex: 1;
  }

  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    font-size: 85%;
    border-collapse: collapse;
  }
  .main-container{
    width: calc(100% - 70px);
    height: 100vh;
    overflow: hidden;
  }
  .green-button{
        height: 32px;
        border-radius: 9px;
        padding: 0 10px;
        font-size: 15px;
  }
  .chart-parent {
    display: 'block';
  }
  .card-container {
        flex-direction: column;
        align-items: start;
        display: flex;
        position: relative;
        padding: 10px;
        min-height: 64px;
        padding-left: 0;
        padding-right: 0;
        maxHeight: 100%;
   }
   .container {
        //Subtract the height of the header
        height: calc(100vh - 62px);
        overflow-y: auto;
        // padding-bottom: 3rem;
   }
   .tableRow {

      "&:selected": {
        background-color: "yellow !important";
      }
    }
   .invisible-block{
        color: white;
        display: block;
        height: 1px;
   }
   .transparen-button{
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
    }
    .MuiFormControl-root{
        width: 100%;
    }
    label.MuiFormControlLabel-root{
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    ul.MuiList-padding{
        padding-left: 6px;
        padding-right: 6px;
        }
    li.MuiListItem-gutters{
        padding-left: 3px;
        padding-right: 3px;
    }
    .MuiTypography-root.MuiTypography-h5{
       color: ${brandColors.madison}
    }
   .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${brandColors.crispedge};
        box-shadow: none;
    }
    .subSubRowPanelDetails{
        grid-template-columns: 30px 2.8fr 1.6fr 1.6fr .8fr;
    }
    .rowForExpansionPanel {
        flex: 1;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
    }
    .MuiTypography-root.columnsForExpansionPanel, .MuiTypography-body1.columnsForExpansionPanel{
        flex: 1;
        color: #000;
        display: flex;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        justify-content: flex-start;
     }
     .MuiTypography-body1.subColumnForExpansionPanel, .subColumnForExpansionPanel{
        width: 100%;
        display: flex;
        margin: 10px 0;
        align-items: center;
        justify-content: flex-start;
     }
     .subColumnForExpansionPanel > .MuiTypography-body1 {
        color: #000;
        font-size: 14px;
        line-height: 16px;
     }

}
    .ListPanel{
        width: 55%;
        height: 400px;
    }
    .TabPanelRangeList{ 
        overflow-y: auto;
        height: 100%;
    }
    .chart-style-select {
        user-select: none;
    }
    tr.MuiTableRow-root.MuiTableRow-hover {
        cursor: pointer;
    }
    div#emailSharing [class*="WAMuiChipInput-chipContainer"]{
        min-height: 42px;
        max-width: 370px;
    }
    div#emailSharing [class*="WAMuiChipInput-chip-"] {
        margin: 4px 8px 4px 0px;
    }
    div#emailSharing [class*="WAMuiChipInput-outlined-"] input{
        padding: 0;
    }
    
    div#emailSharing [class*="WAMuiChipInput-inputRoot-"][class*="WAMuiChipInput-outlined-"]{
        padding-top: 0;
    }
     div#emailSharing [class*="WAMuiChipInput-helperText-"]{
        margin-bottom: 0;
    }
    // #DataRangeFilter .MuiFormControl-root.MuiTextField-root{ 
    //     width: 100%;
    // }
    // MuiPaper-root MuiMenu-paper
   
`

export default globals
