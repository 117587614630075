import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'

import Main from './main'
import * as serviceWorker from './serviceWorker'
import configureStore from './configureStore'

import 'typeface-ibm-plex-sans'

// We use hash history because this example is going to be hosted statically.
// Normally you would use browser history.
const _project_pack = require('../package.json');
const history = createBrowserHistory()

const initialState = window.INITIAL_REDUX_STATE
const store = configureStore(history, initialState)

window.console.info(
`%c ${_project_pack.title} v${_project_pack.version}`,
'color: rgba(82,183,110,1); font-size: 20px'
);

ReactDOM.render(
    <Main store={store} history={history} />,
    document.getElementById('root') || document.createElement('div'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
