// This file holds our state type, as well as any other types related to this Redux store.

// Response object for GET /vehicles
import {GetVehiclesResponse, Vehicle} from "../../helpData/get_vehicles_response_example";
import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import {getVehicles_query_Interface} from "../../helpData/getVehicleAttributes";
import {AttributeView} from "../../pages/filter_vehicles";

export interface Attribute extends ApiResponse {
    name: string
    type: string
    attribute_id: number
    selected: boolean
}

export const unselected_attribute = {
    attribute_id: -1,
    name: '',
    selected: false,
    type: 'string'
};

export interface AttributeValue{
    attribute_id: number
    name: string
    value: string
}

export interface Incentives {
    [key: string]: {
        sim_vehicle_price: number,
        incentives: number
    }
}

// This type is basically shorthand for `{ [key: string]: any }`. Feel free to replace `any` with
// the expected return type of your API response.
export type ApiResponse = Record<string, any>

// Use `enum`s for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum VehiclesActionTypes {
  FETCH_REQUEST = '@@vehicles/FETCH_REQUEST',
  FETCH_SUCCESS = '@@vehicles/FETCH_SUCCESS',
  FETCH_ERROR = '@@vehicles/FETCH_ERROR',
  FETCH_VEHICLES = '@@vehicles/FETCH_VEHICLES',
  FETCH_VEHICLES_SUCCESS = '@@vehicles/FETCH_VEHICLES_SUCCESS',
  FETCH_VEHICLES_ERROR = '@@vehicles/FETCH_VEHICLES_ERROR',
  SET_ROWS_PER_PAGE =  '@@vehicles/SET_ROWS_PER_PAGE',
  FETCH_SUGGEST_ATTRIBUTES = '@@vehicles/FETCH_SUGGEST_ATTRIBUTES',
  SET_SIM_VEHICLE_SUGGEST = '@@vehicles/SET_SIM_VEHICLE_SUGGEST',
  SET_SELECTED_VEHICLE = '@@vehicles/SET_SELECTED_VEHICLE',
  SET_CURRENTY_QUERY = '@@vehicles/SET_CURRENTY_QUERY',
  SET_SELECTED_ATTRIBUTES = '@@vehicles/SET_SELECTED_ATTRIBUTES',
  SET_SELECTED_DATE_RANGE = '@@vehicles/SET_SELECTED_DATE_RANGE',
  SET_VEHICLE_MAPPING = '@@vehicles/SET_VEHICLE_MAPPING',
  SET_MAPPING_PREV_SIM_VEHICLE_SUGGEST = '@@vehicles/SET_MAPPING_PREV_SIM_VEHICLE_SUGGEST',
  SET_SIM_VEHICLE_ATTRIBUTES = '@@vehicles/SET_SIM_VEHICLE_ATTRIBUTES',
  SET_GROUP_BY_ATTRIBUTE = '@@vehicles/SET_SORT_SELECTED_BY_GROUP',
  SET_CURRENT_QUERY_FOR_SIM_VEHICLES =  '@@vehicles/SET_CURRENT_QUERY_FOR_SIM_VEHICLES',
  SET_GROUP_TO_SIM_VEHICLE_MAPPING =  '@@vehicles/SET_GROUP_TO_SIM_VEHICLE_MAPPING',
  SET_SELECTED_SIM_VEHICLES =  '@@vehicles/SET_SELECTED_SIM_VEHICLES',
  SET_VEHICLE_INCENTIVES =  '@@vehicles/SET_VEHICLE_INCENTIVES',


  FETCH_AVAILABLE_DATE_RANGE = '@@auth/FETCH_AVAILABLE_DATE_RANGE',
  FETCH_AVAILABLE_DATE_RANGE_SUCCESS = '@@auth/FETCH_AVAILABLE_DATE_RANGE_SUCCESS',
  FETCH_AVAILABLE_DATE_RANGE_ERROR = '@@auth/FETCH_AVAILABLE_DATE_RANGE_ERROR'

}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface VehiclesState {
    readonly loading: boolean,
    readonly vehicles_attributes: Attribute[],
    readonly sim_vehicles_attributes: Attribute[],
    readonly vehicles: GetVehiclesResponse,
    readonly rows_per_page: number,
    readonly selected_vehicles: Vehicle[],
    readonly selected_attributes: AttributeView[],
    readonly selected_date_range: {start_date: string, end_date: string},
    readonly errors?: string,
    readonly current_query_for_saving?: getVehicles_query_Interface | any,
    readonly current_query_for_sim_vehicles_saving: any
    readonly mapping_prev_sim_vehicle_suggest: object,
    readonly sim_vehicle_suggest?: SimVehicleSuggest[],
    readonly vehicle_mapping_sim_vehicles_attributes: object,
    readonly vehicle_mapping: any,
    readonly group_by_attribute: Attribute,
    readonly selected_sim_vehicles: Vehicle[],
    readonly sim_vehicles: GetVehiclesResponse,
    readonly group_to_sim_vehicle_mapping: {[group_title: string]: Vehicle[]},
    incentives: Incentives,

    availableDateRange: any
}
