import * as React from 'react'

//Material-ui
import Slide from "@material-ui/core/Slide";
import {TransitionProps} from "@material-ui/core/transitions";
import Paper from "@material-ui/core/Paper";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Page from '../../components/layout/Page';
import {RootStore} from "../../redux";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { Grid, createStyles } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Checkbox from "@material-ui/core/Checkbox";

import DownloadReportButtons from "../Reporting/DownloadReportButtons";
import brandColors from "../../styles/colors/brandColors";
import styled from 'styled-components';

import {connect} from "react-redux";

import CustomizedTreeView from "../Reporting/CustomizedTreeView";
import SectionSelect from "../Reporting/SectionSelect";
import FieldCheckbox from "../Reporting/FieldCheckbox";
import { ContentSort } from 'material-ui/svg-icons';
import LoadingModal from './LoadingModals';
import DialogModal from './DialogModal';

//Store
import {ApplicationState} from "../../store";


// const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });


//TODO: how can this style be made to work
const reportSelector = styled(Select)`
    width: '300px';
    padding: '4px';
`;

interface IReportField {
    name: string,
    label: string,
    is_checked: boolean
}

interface IReportSection {
    name: string,
    code: string
}

interface IProps {
    computeHash: string;
    token: string;
    analysisName: string;
    filterQueryString: string;
    shouldDownloadReport: boolean;
    setShouldDownloadReport: any;

    filter_site_id?: any
    //custom_report_popup: false,
    // handleClose: (event: any) => void;
    // handleDownloadSubmit: (event: any) => void;
    // handleReportSelectorChanged: (event: any) => void;
    // handleReportAttributeSelected: (event: any) => void;
}

export interface PropsFromState {
    loading: boolean,
}

interface PropsFromDispatch {

}

type AllProps = PropsFromState & PropsFromDispatch & IProps


interface IState {

    
    custom_report_popup: boolean,
    selectedReportSectionCode: string,
    report_downloading: boolean,
    //report_sections_list: Array<any>,
    //report_fields: Object,
    sectionsList: Array<IReportSection>,
    fleetReportFieldsList: Array<IReportField>,
    yourFleetEstsFieldsList: Array<IReportField>,
    replaceIceEstsFieldsList: Array<IReportField>,
    evReplaceOptsFieldsList: Array<IReportField>
    report_error: boolean
}

class DialogCustomReportModal extends React.PureComponent<AllProps, IState>  {

    constructor(props:AllProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleDownloadSubmit = this.handleDownloadSubmit.bind(this);
        this.handleReportSelectorChanged = this.handleReportSelectorChanged.bind(this);
        this.handleReportAttributeSelected = this.handleReportAttributeSelected.bind(this);
        const {computeHash, token, analysisName, filterQueryString, ...otherProps} = props;


        this.state = {
            custom_report_popup: false,
            selectedReportSectionCode: "fleet_report",
            report_downloading: false,
            report_error: false,
            sectionsList: [
                {
                    name: "Fleet Report",
                    code: "fleet_report"
                },
                {
                    name: "Your Fleet Estimates",
                    code: "your_fleet_estimates"
                },
                {
                    name: "Replacement ICE Estimates",
                    code: "replacement_ice_estimates"
                },
                {
                    name: "EV Replacement Options",
                    code: "ev_replacement_options"
                }
            ],
            fleetReportFieldsList: [
                {
                    name: "all_none",
                    label: "Select (all/none)",
                    is_checked: true
                },
                {
                    name: "2_1",
                    label: "Equipment ID",
                    is_checked: true
                },
                {
                    name: "2_2",
                    label: "Exclusion",
                    is_checked: true
                },
                {
                    name: "2_3",
                    label: "To Be Replaced?",
                    is_checked: true
                },
                {
                    name: "2_4",
                    label: "Proceed with Electrification?",
                    is_checked: true
                },
                {
                    name: "2_5",
                    label: "MFB-Vehicle Type",
                    is_checked: true
                },
                {
                    name: "2_6",
                    label: "Eq. EV Available (based on MFB)?",
                    is_checked: true
                },
                {
                    name: "2_7",
                    label: "Model Year",
                    is_checked: true
                },
                {
                    name: "2_8",
                    label: "Make",
                    is_checked: true
                },
                {
                    name: "2_9",
                    label: "Model",
                    is_checked: true
                },
                {
                    name: "2_10",
                    label: "Department",
                    is_checked: true
                },
                {
                    name: "2_11",
                    label: "Domicile Facility",
                    is_checked: true
                },
                {
                    name: "2_12",
                    label: "Powertrain",
                    is_checked: true
                },
                {
                    name: "2_13",
                    label: "Detailed Fuel Type",
                    is_checked: true
                },
                {
                    name: "2_14",
                    label: "VIN",
                    is_checked: true
                },
                {
                    name: "2_15",
                    label: "Out of Service/Auction/Loaners/Short Term Loaners",
                    is_checked: true
                },
                {
                    name: "2_16",
                    label: "Purchase Year",
                    is_checked: true
                },
                {
                    name: "2_17",
                    label: "Purchase Pice",
                    is_checked: true
                },
                {
                    name: "2_18",
                    label: "Aux. Eq. Cost",
                    is_checked: true
                },
                {
                    name: "2_19",
                    label: "Total Price",
                    is_checked: true
                },
                {
                    name: "2_20",
                    label: "Current Life-to-Date (LTD) Cost",
                    is_checked: true
                },
                {
                    name: "2_21",
                    label: "LTD Cost to Purchase Price",
                    is_checked: true
                },
                {
                    name: "2_22",
                    label: "Type of Vehicle/Equipment",
                    is_checked: true
                },
                {
                    name: "2_23",
                    label: "Department_Code",
                    is_checked: true
                },
                {
                    name: "2_24",
                    label: "Domicile Location",
                    is_checked: true
                },
                {
                    name: "2_25",
                    label: "Expected Service Life",
                    is_checked: true
                },
                {
                    name: "2_26",
                    label: "Life Cycle Utilization",
                    is_checked: true
                },
                {
                    name: "2_27",
                    label: "Meter Type",
                    is_checked: true
                },
                {
                    name: "2_28",
                    label: "Class_Code",
                    is_checked: true
                },
                {
                    name: "2_29",
                    label: "Class",
                    is_checked: true
                },
                {
                    name: "2_30",
                    label: "General_Class(FHWA)",
                    is_checked: true
                },
                {
                    name: "2_31",
                    label: "MFB-Vehicle Type (details)",
                    is_checked: true
                },
                {
                    name: "2_32",
                    label: "MFB-Class",
                    is_checked: true
                },
                {
                    name: "2_33",
                    label: "Pool Vehicle",
                    is_checked: true
                },
                {
                    name: "2_34",
                    label: "In_Network_Fleet?",
                    is_checked: true
                },
                {
                    name: "2_35",
                    label: "Auxiliary Equipment Description",
                    is_checked: true
                },
                {
                    name: "2_36",
                    label: "Parasitic Load (kWh/day)",
                    is_checked: true
                },
                {
                    name: "2_37",
                    label: "Parasitic Load Equivalent Mileage",
                    is_checked: true
                },
                {
                    name: "2_38",
                    label: "Replacement ICE Vehicle for TCO Comparison",
                    is_checked: true
                },
                {
                    name: "2_39",
                    label: "MSRP of Replacement ICE Vehicle for TCO Comparison",
                    is_checked: true
                },
                {
                    name: "2_40",
                    label: "Data Source",
                    is_checked: true
                },
                {
                    name: "2_41",
                    label: "Distance (miles) (4150)",
                    is_checked: true
                },
                {
                    name: "2_42",
                    label: "Ending Meter Date (4150)",
                    is_checked: true
                },
                {
                    name: "2_43",
                    label: "Beginning Date (4150)",
                    is_checked: true
                },
                {
                    name: "2_44",
                    label: "Fuel Qty (gallons) (4150)",
                    is_checked: true
                },
                {
                    name: "2_45",
                    label: "2019/20 ICE replacement Annualized Fuel Qty (Gallons)",
                    is_checked: true
                }
            ],
            yourFleetEstsFieldsList: [
                {
                    name: "all_none",
                    label: "Select (all/none)",
                    is_checked: true
                },
                {
                    name: "3_1",
                    label: "Annualized Distance travelled (mi)",
                    is_checked: true
                },
                {
                    name: "3_2",
                    label: "Avg. Distance per Travel Day (mi)",
                    is_checked: true
                },
                {
                    name: "3_3",
                    label: "Standard Deviation per Travel Day (mi)",
                    is_checked: true
                },
                /**
                 * DM: removing for clubhouse 3269 ticket
                {
                    name: "3_4",
                    label: "Estimated Tailpipe Annual GHG Emissions (kg of CO2)",
                    is_checked: true
                },
                */
                {
                    name: "3_5",
                    label: "Avg. Dwell Time per Week (hrs)",
                    is_checked: true
                },
                {
                    name: "3_6",
                    label: "Standard Deviation for Avg. Dwell Time per Week (hrs)",
                    is_checked: true
                }
            ],
            replaceIceEstsFieldsList:[
                {
                    name: "all_none",
                    label: "Select (all/none)",
                    is_checked: true
                },
                {
                    name: "4_1",
                    label: "2019/20 ICE Equivalent",
                    is_checked: true
                },
                {
                    name: "4_2",
                    label: "2019/20 ICE Equivalent MSRP (USD)",
                    is_checked: true
                },
                // {
                //     name: "4_3",
                //     label: "Total Costs (MSRP + Existing Aux. Equip. Cost) (USD)",
                //     is_checked: true
                // },
                {
                    name: "4_4",
                    label: "Annual Maintenance",
                    is_checked: true
                },
                {
                    name: "4_5",
                    label: "Estimated annual fuel consumption (gal)",
                    is_checked: true
                },
                {
                    name: "4_6",
                    label: "Annual cost of fuel",
                    is_checked: true
                },
                {
                    name: "4_7",
                    label: "Estimated Tailpipe Annual GHG Emissions (kg of CO2)",
                    is_checked: true
                }
            ],
            evReplaceOptsFieldsList:[
                {
                    name: "all_none",
                    label: "Select (all/none)",
                    is_checked: true
                },
                {
                    name: "5_1",
                    label: "Vehicle Name",
                    is_checked: true
                },
                {
                    name: "5_2",
                    label: "Percent in Electric Range Days",
                    is_checked: true
                },
                {
                    name: "5_3",
                    label: "Battery size (kWh)",
                    is_checked: true
                },
                {
                    name: "5_4",
                    label: "Electric range (mi)",
                    is_checked: true
                },
                {
                    name: "5_5",
                    label: "MSRP (USD)",
                    is_checked: true
                },
                {
                    name: "5_6",
                    label: "Est. Annual Maintenance (USD)",
                    is_checked: true
                },
                // {
                //     name: "5_7",
                //     label: "Fuel efficiency (MPG rating)",
                //     is_checked: true
                // },
                {
                    name: "5_8",
                    label: "Annual kWh consumption (kWh)",
                    is_checked: true
                },
                {
                    name: "5_9",
                    label: "Annual Cost of Electricity (USD)",
                    is_checked: true
                },
                {
                    name: "5_10",
                    label: "Annual gas consumption (gal)",
                    is_checked: true
                },
                {
                    name: "5_11",
                    label: "Est. Annual Fuel Cost (USD)",
                    is_checked: true
                },
                {
                    name: "5_12",
                    label: "Total Annual Energy Cost (USD)",
                    is_checked: true
                },
                {
                    name: "5_13",
                    label: "Annual Tailpipe GHG emissions (kg of CO2)",
                    is_checked: true
                }
            ]
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.shouldDownloadReport !== this.props.shouldDownloadReport && this.props.shouldDownloadReport) {
          this.handleDownloadSubmit(null)
          this.props.setShouldDownloadReport(false)
        }
    }

    handleClose() {
        console.log("handleClose called");
    };

    handleReportSelectorChanged = (event: any) => {
        console.log("handleReportSelectorChanged(). Event.target: ", event.target);
        this.setState(prevState => ({
            ...prevState,
            selectedReportSectionCode: event.target.value
        }));
    };


    listSelectedReportSectionFields() {
        console.log("selectedReportSectionCode is: " + this.state.selectedReportSectionCode);

        return this.matchReportSectionFields(this.state.selectedReportSectionCode);
    };

    matchReportSectionFields(inSectionCode:string) {
        if ("fleet_report" === inSectionCode) {
            return this.state.fleetReportFieldsList;
        } else if("your_fleet_estimates" === inSectionCode) {
            return this.state.yourFleetEstsFieldsList;
        } else if("replacement_ice_estimates" === inSectionCode) {
            return this.state.replaceIceEstsFieldsList;
        } else if("ev_replacement_options" === inSectionCode) {
            return this.state.evReplaceOptsFieldsList;
        } else {
            return [];
        }
    };

    handleReportAttributeSelected = (event: any) => {
        console.log("handleReportAttributeSelected(). Event.target.name: " + event.target.name + ", Event.target.value: ", event.target);
        //now need to process this event data, to an update in the list of fields
        let selectedSectionFields = this.matchReportSectionFields(this.state.selectedReportSectionCode);
        let mySectionFields: Array<IReportField> = [];

        console.log("selectedSectionFields length: " + selectedSectionFields.length);

        for(let i = 0; i < selectedSectionFields.length; i++) {
            let myFieldIsChecked = false;

            if("all_none" === event.target.name) {
                //assuming that the first entry in the array is always the select all/none
                //and that an event with all_none target name is opposite of whatever the
                //current state is
                myFieldIsChecked = !selectedSectionFields[0].is_checked;
            } else {
                myFieldIsChecked = selectedSectionFields[i].is_checked;
                if (selectedSectionFields[i].name === event.target.name) {
                    console.log("found matching field " + selectedSectionFields[i].name
                        + ", for event sent: setting checked value: " + event.target.value);
    
                    //this should really be a toggle, doesn't have to match a value sent from the target
                    myFieldIsChecked = !selectedSectionFields[i].is_checked;
                }
            }
            let myField: IReportField = {
                name: selectedSectionFields[i].name,
                label: selectedSectionFields[i].label,
                is_checked: myFieldIsChecked
            }
            mySectionFields.push(myField);
        }
        console.log("mySectionFields length: " + mySectionFields.length);

        if ("fleet_report" === this.state.selectedReportSectionCode) {
            console.log("setState for fleet_report");

            this.setState(prevState => ({
                ...prevState,
                fleetReportFieldsList: mySectionFields
            }));
        } else if("your_fleet_estimates" === this.state.selectedReportSectionCode) {   
            console.log("setState for your_fleet_estimates");

            this.setState(prevState => ({
                ...prevState,
                yourFleetEstsFieldsList: mySectionFields
            }));
        } else if("replacement_ice_estimates" === this.state.selectedReportSectionCode) {
            console.log("setState for replacement_ice_estimates");

            this.setState(prevState => ({
                ...prevState,
                replaceIceEstsFieldsList: mySectionFields
            }));
        } else if("ev_replacement_options" === this.state.selectedReportSectionCode) {
            console.log("setState for ev_replacement_options");

            this.setState(prevState => ({
                ...prevState,
                evReplaceOptsFieldsList: mySectionFields
            }));
        }
        
    };

    buildSectionFieldsList = (pSectionReportFields: Array<IReportField>) => {
        let sectionFieldNamesList:Array<string> = [];

        for(let i = 0; i < pSectionReportFields.length; i++) {
            if ((pSectionReportFields[i].name != "all_none") && (pSectionReportFields[i].is_checked)) {
                sectionFieldNamesList.push(pSectionReportFields[i].label);
            }
        }
        return sectionFieldNamesList;
    };

    buildSelectFieldsJson() {
        return {
            "fleet_report_tab": {
                "fleet_report": this.buildSectionFieldsList(this.state.fleetReportFieldsList),
                "your_fleet_estimates": this.buildSectionFieldsList(this.state.yourFleetEstsFieldsList),
                "replacement_ice_estimates": this.buildSectionFieldsList(this.state.replaceIceEstsFieldsList),
                "ev_replacement_options": this.buildSectionFieldsList(this.state.evReplaceOptsFieldsList)
            },
            "ev_charging_results": {

            }
        };
    };

    handleDownloadSubmit = (event:any) => {
        console.log("handleDownloadSubmit(*). event: ", event);
        this.setState(prevState => ({
            ...prevState,
            report_downloading: true
        }));
        //this needs to parse out the report fields and create a json to POST to the api and cause a download
        // let selectedFleetReportFieldsName:string[] = this.buildSectionFieldsList(this.state.fleetReportFieldsList);
        // console.log("selectedFleetReportFieldsName: ", selectedFleetReportFieldsName);
        console.log("building the download post payload");
        console.log("computeHash: " + this.props.computeHash);
        console.log("token: " + this.props.token);
        console.log("analysisName: " + this.props.analysisName);
        console.log("filterQueryString: " + this.props.filterQueryString);

        // let download_post_payload = {
        //     "compute_hash":"bdbf5a10-b58a-11ea-be2a-296dded3bde2",
        //     "token": "1004f7d72a06c66639da714e99cb19d74d6dfb50",
        //     "analysis_name": "Teresa's test",
        //     "vehicle_id": null,
        //     "filter_query": {"date_range":{"start_date":"2018-01-01","end_date":"2020-06-23"},"attributes":[{"attribute_id":190,"type":"string","operator":"eq","value":["yes"],"selected":true,"name":"Proceed with Electrification?"},{"attribute_id":196,"type":"string","operator":"eq","value":["building maintenance"],"selected":true,"name":"Department"}]},
        //     "select_fields": this.buildSelectFieldsJson()
        // };
        // let downloadFilename:string = "Teresa's test".replace(/[^a-z0-9]/gi, '-');


        let download_post_payload = {
            "compute_hash":this.props.computeHash,
            "token": this.props.token,
            "analysis_name": this.props.analysisName,
            "vehicle_id": null,
            "filter_query": JSON.parse(this.props.filterQueryString),
            "select_fields": this.buildSelectFieldsJson(),

            "filter_site_id": this.props.filter_site_id ? this.props.filter_site_id : null
        }
        let downloadFilename:string = this.props.analysisName.replace(/[^a-z0-9]/gi, '-');
        //afterwards the page should display a message that the download is ready
        console.log("download_post_payload: ", download_post_payload);
        const apiURL = process.env.REACT_APP_API_URL + "/api2/custom_report";
        
        let response = fetch(apiURL, {
             method: "POST",
             body: JSON.stringify(download_post_payload)
         }).then(response => {
            if(response.ok) {
               return response.blob();
            } else {
                console.log("Error downloading report");
            }
            })
         .then((reportBlob) => {
             let reportURL = URL.createObjectURL(reportBlob);
             let reportElem = document.createElement('a');
             reportElem.href = reportURL;
             reportElem.download = downloadFilename+"-myfleetbuy-report.xlsx";
             document.body.append(reportElem);
             reportElem.click();
             reportElem.remove();
             
            //console.log("response: ", response);
             
            this.setState(prevState => ({
                ...prevState,
                report_downloading: false
            }));
            //console.log(myBlob);
         }).catch((error) => {
             console.log("error from api call: ", error)
             this.setState(prevState => ({
                ...prevState,
                report_downloading: false,
                report_error: true
            }));
         });
    };

    handleSubmitForm(event: any) {
        console.log("handleSubmitForm(*) called. event: ", event);
        event.preventDefault();
    }
    render() {
        const { report_downloading, report_error } = this.state;
        console.log("starting render of DialogCustomReportModal");
        /**
         * Can StyledGrid be used, just like ReplacementGroup.tsx
         */

        return (
            <div>
                {report_downloading && (<LoadingModal open={report_downloading} />)}
                {report_error && (<DialogModal handleClose={() => this.setState({report_error: false})} error_msg={{title: "Something went wrong", msg: "There was an error downloading your report. Please try again later", visible: true}} />)}

                <form onSubmit={this.handleSubmitForm}>
                <FormControl>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={4}>
                    <Grid item xs>
                        <SectionSelect 
                            selectedReportSectionCode = {this.state.selectedReportSectionCode}
                            reportSectionsList={this.state.sectionsList}
                            handleChange={this.handleReportSelectorChanged}
                        />
                        <FieldCheckbox 
                            reportSectionFields={this.listSelectedReportSectionFields()}
                            handleFieldChecked ={this.handleReportAttributeSelected}
                        />
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="flex-start">
                                {/*
                            <Grid item>
                                <DownloadReportButtons
                                    report_downloading={this.state.report_downloading}
                                    handleButtonClick={this.handleDownloadSubmit}/>
                            </Grid>
                                */}
                            <Grid item xs>
                                <h2>Selected Report Fields</h2>
                            </Grid>
                            <Grid item xs style={{minWidth: '100%'}}>
                                <CustomizedTreeView 
                                    fleetReportFieldsList={this.state.fleetReportFieldsList}
                                    yourFleetEstsFieldsList={this.state.yourFleetEstsFieldsList}
                                    replaceIceEstsFieldsList={this.state.replaceIceEstsFieldsList}
                                    evReplaceOptsFieldsList={this.state.evReplaceOptsFieldsList}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </FormControl>
                </form>
            </div>
        // <Dialog
        //     fullScreen
        //     TransitionComponent={Transition}
        //     open={this.props.custom_report_popup}
        //     onClose={this.props.handleClose}
        //     aria-labelledby="responsive-dialog-title"
        // >
        //     <StyleAppBar>
        //         <Toolbar>
        //             <IconButton edge="start" color="inherit" onClick={this.props.handleClose}
        //                 aria-label="close">
        //                 <CloseIcon/>
        //             </IconButton>
        //             <Typography variant="h6">
        //                 Select fields for export
        //             </Typography>
        //         </Toolbar>
        //     </StyleAppBar>
        //     <DialogContent>
        //         <div>Body of Form For Selecting Form Fields</div>
        //     </DialogContent>
        // </Dialog>
        );
    }
}

export const mapStateToProps = ({resultsPage}: ApplicationState) => ({
    loading: resultsPage.loading,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogCustomReportModal);