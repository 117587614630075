import { action } from 'typesafe-actions'
import {AnalysisResultFull, ResultsActionTypes, TCOResponse} from "./types";


// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions

export const fetchResults = (selected_vehicle_id?: number) => action(ResultsActionTypes.FETCH_RESULTS_REQUEST, selected_vehicle_id);
export const fetchResultsSuccess = (data: { res: AnalysisResultFull[], selected_vehicle_id?: number}) => action(ResultsActionTypes.FETCH_RESULTS_REQUEST_SUCCESS, data);
export const fetchResultsError = (message: string) => action(ResultsActionTypes.FETCH_RESULTS_REQUEST_ERROR, message);

export const fetchTCO = (car_list?: any) => action(ResultsActionTypes.FETCH_TCO_REQUEST, car_list);
export const fetchTCOSuccess = (data: TCOResponse[]) => action(ResultsActionTypes.FETCH_TCO_REQUEST_SUCCESS, data);
export const fetchTCOError = (message: string) => action(ResultsActionTypes.FETCH_TCO_REQUEST_ERROR, message);

export const fetchRoutePositions = (date?: string, vehicle_id?: number) => action(ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST, {"date": date, "vehicle_id": vehicle_id});
export const fetchRoutePositionsSuccess = (data: []) => action(ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST_SUCCESS, data);
export const fetchRoutePositionsError = (message: string) => action(ResultsActionTypes.FETCH_ROUTE_POSITIONS_REQUEST_ERROR, message);

export const websocketProgressStatus = (lambdaProgress: number) => action(ResultsActionTypes.WEBSOCKET_PROGRESS_STATUS, lambdaProgress);
export const fetchResultsDetails = (vehicle_id: number) => action(ResultsActionTypes.FETCH_RESULTS_DETAILS_REQUEST, vehicle_id);
export const fetchResultsDetailsSuccess = (data: AnalysisResultFull[]) => action(ResultsActionTypes.FETCH_RESULTS_REQUEST_DETAILS_SUCCESS, data);

export const clearShareStatusAction = () => action(ResultsActionTypes.CLEAR_SHARE_STATUS);

export const existenceOfAnalysis = () => action(ResultsActionTypes.EXISTENCE_OF_ANALYSIS);
export const changeOrSaveResults = () => action(ResultsActionTypes.CHANGE_OR_SAVE_RESULTS);

export const saveResultsSuccess = (data: AnalysisResultFull) => action(ResultsActionTypes.SAVE_REQUEST_SUCCESS, data);
export const saveResultsError = (message: string) => action(ResultsActionTypes.SAVE_REQUEST_ERROR, message);

export const shareResults = (email: string[]) => action(ResultsActionTypes.SHARE_REQUEST, email);
export const shareResultsSuccess = (data: {emails: string[], results: any}) => action(ResultsActionTypes.SHARE_REQUEST_SUCCESS, data);
export const shareResultsError = (message: string) => action(ResultsActionTypes.SHARE_REQUEST_ERROR, message);

export const resultAreNotReady = (loading: boolean) =>  action(ResultsActionTypes.RESULT_ARE_NOT_READY, loading);


