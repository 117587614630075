import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const CssTextField = withStyles({
    root: {
        'MuiAutocomplete-root': {
            height: '40px',
        },
        '& .MuiAutocomplete-inputRoot': {
            display: 'flex',
        },
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            padding: 0,
            width: 'initial'
        }
    },
})(TextField);

export const StyledAutocomplete = withStyles({
    root: {
        height: 'fit-content',
        width: '100%',
        maxHeight: 'fit-content',
        },

})(Autocomplete);