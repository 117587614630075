import {
    GET_CAR_PROFILE_BEGIN,
    GET_CAR_PROFILE_ERROR,
    GET_CAR_PROFILE_SUCCESS,
    PATCH_CAR_PROFILE_BEGIN,
    PATCH_CAR_PROFILE_ERROR,
    PATCH_CAR_PROFILE_SUCCESS,
    GET_TCO_SETTINGS_BEGIN,
    GET_TCO_SETTINGS_ERROR,
    GET_TCO_SETTINGS_SUCCESS,
    PATCH_TCO_SETTINGS_BEGIN,
    PATCH_TCO_SETTINGS_ERROR,
    PATCH_TCO_SETTINGS_SUCCESS,
    GET_CAR_PROFILES_BEGIN,
    GET_CAR_PROFILES_ERROR,
    GET_CAR_PROFILES_SUCCESS,
    GET_CAR_RECOMMENDATIONS_BEGIN,
    GET_CAR_RECOMMENDATIONS_ERROR,
    GET_CAR_RECOMMENDATIONS_SUCCESS,
    ADD_CAR_RECOMMENDATION_BEGIN,
    ADD_CAR_RECOMMENDATION_ERROR,
    ADD_CAR_RECOMMENDATION_SUCCESS,
    GET_MAKES_BEGIN,
    GET_MAKES_SUCCESS,
    GET_MAKES_ERROR,
    GET_BASE_MODELS_BEGIN,
    GET_BASE_MODELS_SUCCESS,
    GET_BASE_MODELS_ERROR,
    ADD_CAR_SEARCH_FILTER,
    REMOVE_CAR_SEARCH_FILTER,
    GET_SEARCH_BEGIN,
    GET_SEARCH_ERROR,
    GET_SEARCH_SUCCESS,
    ADD_CAR_PROFILE_BEGIN,
    ADD_CAR_PROFILE_ERROR,
    ADD_CAR_PROFILE_SUCCESS,
    DELETE_CAR_PROFILE_BEGIN,
    DELETE_CAR_PROFILE_ERROR,
    DELETE_CAR_PROFILE_SUCCESS,
    CLEAR_SEARCH,
    SET_EXPANDED_FILTER_PANEL,
    SET_FILTER_MODEL_TAB,
    GET_BODY_TYPES_BEGIN,
    GET_BODY_TYPES_ERROR,
    GET_BODY_TYPES_SUCCESS,
    GET_POWERTRAIN_TYPES_BEGIN,
    GET_POWERTRAIN_TYPES_ERROR,
    GET_POWERTRAIN_TYPES_SUCCESS,

} from "./action-types-car";
import { API_URL, API_CAR_PROFILE_SUFFIX, API_CAR_RECOMMENDATIONS_SUFFIX, API_CAR_MAKES_SUFFIX, API_CAR_BASE_MODELS_SUFFIX, API_CAR_SUFFIX, API_TCO_SUFFIX, API_CAR_BODY_TYPES_SUFFIX, API_CAR_POWERTRAIN_TYPES_SUFFIX, API_ADVISOR_SUFFIX } from "./urls";

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        console.log(response)
        throw Error(response.statusText);
    }
    return response;
}

function getAPIURL(endpointSuffix) {
    return API_URL + endpointSuffix;
}

export const getCarProfilesBegin = () => ({ type: GET_CAR_PROFILES_BEGIN });
export const getCarProfilesSuccess = (result) => ({ type: GET_CAR_PROFILES_SUCCESS, payload: result });
export const getCarProfilesError = error => ({ type: GET_CAR_PROFILES_ERROR, payload: error });

export function getCarProfiles() {
    return dispatch => {
        dispatch(getCarProfilesBegin());
        return fetch(getAPIURL(API_CAR_PROFILE_SUFFIX), {
            method: "GET",
            credentials: 'include',
            headers: {
                "Authorization": `Token ${window.localStorage.getItem("authenticated")}`
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getCarProfilesSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getCarProfilesError(error.message)) });
    };
}

export const getCarProfileBegin = () => ({ type: GET_CAR_PROFILE_BEGIN });
export const getCarProfileSuccess = (result) => ({ type: GET_CAR_PROFILE_SUCCESS, payload: result });
export const getCarProfileError = error => ({ type: GET_CAR_PROFILE_ERROR, payload: error });

export function getCarProfile(car_profile_id) {
    return dispatch => {
        dispatch(getCarProfileBegin());
        return fetch(getAPIURL(API_CAR_PROFILE_SUFFIX) + `${car_profile_id}/`, {
            method: "GET",
            credentials: 'include'
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getCarProfileSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getCarProfileError(error.message)) });
    };
}

export const addCarProfileBegin = () => ({ type: ADD_CAR_PROFILE_BEGIN });
export const addCarProfileSuccess = (result) => ({ type: ADD_CAR_PROFILE_SUCCESS, payload: result });
export const addCarProfileError = error => ({ type: ADD_CAR_PROFILE_ERROR, payload: error });

export function addCarProfile(car_id, addAsCurrent=false) {
    return dispatch => {
        dispatch(addCarProfileBegin());
        let params = new URLSearchParams(`car_id=${car_id}`)
        if (addAsCurrent === true) {
            params.append("current", 1)
        }
        return fetch(getAPIURL(API_CAR_PROFILE_SUFFIX), {
            method: "POST",
            body: params,
            credentials: 'include',
            headers: {
                'X-CSRFToken': window.csrftoken
            },
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(addCarProfileSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(addCarProfileError(error.message)) });
    };
}

export const deleteCarProfileBegin = () => ({ type: DELETE_CAR_PROFILE_BEGIN });
export const deleteCarProfileSuccess = (result, car_profile_id) => ({ type: DELETE_CAR_PROFILE_SUCCESS, payload: result, car_profile_id: car_profile_id });
export const deleteCarProfileError = error => ({ type: DELETE_CAR_PROFILE_ERROR, payload: error });

export function deleteCarProfile(car_profile_id) {
    return dispatch => {
        dispatch(deleteCarProfileBegin());
        return fetch(getAPIURL(API_CAR_PROFILE_SUFFIX) + `${car_profile_id}/`, {
            method: "DELETE",
            credentials: 'include',
            headers: {
                'X-CSRFToken': window.csrftoken
            },
        }).then(handleErrors)
            .then(res => {
                dispatch(deleteCarProfileSuccess(null, car_profile_id));
            })
            .catch(error => {console.log(error); dispatch(deleteCarProfileError(error.message)) });
    };
}

export const getCarRecommendationsBegin = () => ({ type: GET_CAR_RECOMMENDATIONS_BEGIN });
export const getCarRecommendationsSuccess = (result) => ({ type: GET_CAR_RECOMMENDATIONS_SUCCESS, payload: result });
export const getCarRecommendationsError = error => ({ type: GET_CAR_RECOMMENDATIONS_ERROR, payload: error });

export function getCarRecommendations() {
    return dispatch => {
        dispatch(getCarRecommendationsBegin());
        return fetch(getAPIURL(API_CAR_RECOMMENDATIONS_SUFFIX), {
            method: "GET",
            credentials: 'include'
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getCarRecommendationsSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getCarRecommendationsError(error.message)) });
    };
}

export const addCarRecommendationBegin = () => ({ type: ADD_CAR_RECOMMENDATION_BEGIN });
export const addCarRecommendationSuccess = (result) => ({ type: ADD_CAR_RECOMMENDATION_SUCCESS, payload: result });
export const addCarRecommendationError = error => ({ type: ADD_CAR_RECOMMENDATION_ERROR, payload: error });

export function addCarRecommendation(car_profile_id) {
    return dispatch => {
        dispatch(addCarRecommendationBegin());
        return fetch(getAPIURL(API_CAR_PROFILE_SUFFIX) + `${car_profile_id}/`, {
            method: "PATCH",
            body: new URLSearchParams({'hidden': false}),
            credentials: 'include',
            headers: {
                'X-CSRFToken': window.csrftoken
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(addCarRecommendationSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(addCarRecommendationError(error.message)) });
    };
}

export const patchCarProfileBegin = () => ({ type: PATCH_CAR_PROFILE_BEGIN });
export const patchCarProfileSuccess = (result) => ({ type: PATCH_CAR_PROFILE_SUCCESS, payload: result });
export const patchCarProfileError = error => ({ type: PATCH_CAR_PROFILE_ERROR, payload: error });

export function patchCarProfile(car_profile_id, body) {
    return dispatch => {
        dispatch(patchCarProfileBegin());
        return fetch(getAPIURL(API_CAR_PROFILE_SUFFIX) + `${car_profile_id}/`, {
            method: "PATCH",
            body: new URLSearchParams(body),
            credentials: 'include',
            headers: {
                'X-CSRFToken': window.csrftoken
            },
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(patchCarProfileSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(patchCarProfileError(error.message)) });
    };
}

export const getTCOSettingsBegin = () => ({ type: GET_TCO_SETTINGS_BEGIN });
export const getTCOSettingsSuccess = (result) => ({ type: GET_TCO_SETTINGS_SUCCESS, payload: result });
export const getTCOSettingsError = error => ({ type: GET_TCO_SETTINGS_ERROR, payload: error });

export function getTCOSettings() {
    return dispatch => {
        dispatch(getTCOSettingsBegin());
        return fetch(getAPIURL(API_TCO_SUFFIX), {
            method: "GET",
            credentials: 'include'
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getTCOSettingsSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getTCOSettingsError(error.message)) });
    };
}

export const patchTCOSettingsBegin = () => ({ type: PATCH_TCO_SETTINGS_BEGIN });
export const patchTCOSettingsSuccess = (result) => ({ type: PATCH_TCO_SETTINGS_SUCCESS, payload: result });
export const patchTCOSettingsError = error => ({ type: PATCH_TCO_SETTINGS_ERROR, payload: error });

export function patchTCOSettings(car_profile_id, body) {
    return dispatch => {
        dispatch(patchTCOSettingsBegin());
        return fetch(getAPIURL(API_TCO_SUFFIX) + `${car_profile_id}/`, {
            method: "PATCH",
            body: new URLSearchParams(body),
            credentials: 'include',
            headers: {
                'X-CSRFToken': window.csrftoken
            },
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(patchTCOSettingsSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(patchTCOSettingsError(error.message)) });
    };
}

export const getMakesBegin = () => ({ type: GET_MAKES_BEGIN });
export const getMakesSuccess = (result) => ({ type: GET_MAKES_SUCCESS, payload: result });
export const getMakesError = error => ({ type: GET_MAKES_ERROR, payload: error });

export function getMakes() {
    console.log("GET_MAKES", getAPIURL(API_CAR_MAKES_SUFFIX))
    return dispatch => {
        dispatch(getMakesBegin());
        return fetch(getAPIURL(API_CAR_MAKES_SUFFIX), {
            method: "GET",
            credentials: 'include',
            headers: {
                "Authorization": `Token ${window.localStorage.getItem("authenticated")}`
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getMakesSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getMakesError(error.message)) });
    };
}

export const getBaseModelsBegin = () => ({ type: GET_BASE_MODELS_BEGIN });
export const getBaseModelsSuccess = (result) => ({ type: GET_BASE_MODELS_SUCCESS, payload: result });
export const getBaseModelsError = error => ({ type: GET_BASE_MODELS_ERROR, payload: error });

export function getBaseModels(makeName) {
    return dispatch => {
        dispatch(getBaseModelsBegin());
        return fetch(getAPIURL(API_CAR_BASE_MODELS_SUFFIX) + `?make=${makeName}`, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Authorization": `Token ${window.localStorage.getItem("authenticated")}`
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getBaseModelsSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getBaseModelsError(error.message)) });
    };
}

export const addCarSearchFilter = (filterType, value, text) => ({type: ADD_CAR_SEARCH_FILTER, payload: {
    filterType: filterType,
    value: value,
    text: text
}});
export const removeCarSearchFilter = (value) => ({type: REMOVE_CAR_SEARCH_FILTER, payload: {value: value}});
export const setExpandedFilterPanel = (panel) => ({type: SET_EXPANDED_FILTER_PANEL, payload: panel})
export const setFilterModelTab = (index, make) => ({type: SET_FILTER_MODEL_TAB, payload: {index: index, make: make}})

export const getSearchBegin = () => ({ type: GET_SEARCH_BEGIN });
export const getSearchSuccess = (result) => ({ type: GET_SEARCH_SUCCESS, payload: result });
export const getSearchError = error => ({ type: GET_SEARCH_ERROR, payload: error });
export const clearSearch = () => ({type: CLEAR_SEARCH });

export function getSearch(query_string) {
    return dispatch => {
        dispatch(getSearchBegin());
        return fetch(getAPIURL(API_CAR_SUFFIX) + query_string, {
            method: "GET",
            credentials: 'include',
            headers: {
                "Authorization": `Token ${window.localStorage.getItem("authenticated")}`
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getSearchSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getSearchError(error.message)) });
    };
}



export const getBodyTypesBegin = () => ({ type: GET_BODY_TYPES_BEGIN });
export const getBodyTypesSuccess = (result) => ({ type: GET_BODY_TYPES_SUCCESS, payload: result });
export const getBodyTypesError = error => ({ type: GET_BODY_TYPES_ERROR, payload: error });

export function getBodyTypes() {
    return dispatch => {
        dispatch(getBodyTypesBegin());
        return fetch(getAPIURL(API_CAR_BODY_TYPES_SUFFIX), {
            method: "GET",
            credentials: 'include',
            headers: {
                "Authorization": `Token ${window.localStorage.getItem("authenticated")}`
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getBodyTypesSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getBodyTypesError(error.message)) });
    };
}


export const getPowertrainTypesBegin = () => ({ type: GET_POWERTRAIN_TYPES_BEGIN });
export const getPowertrainTypesSuccess = (result) => ({ type: GET_POWERTRAIN_TYPES_SUCCESS, payload: result });
export const getPowertrainTypesError = error => ({ type: GET_POWERTRAIN_TYPES_ERROR, payload: error });

export function getPowertrainTypes() {
    return dispatch => {
        dispatch(getPowertrainTypesBegin());
        return fetch(getAPIURL(API_CAR_POWERTRAIN_TYPES_SUFFIX), {
            method: "GET",
            credentials: 'include',
            headers: {
                "Authorization": `Token ${window.localStorage.getItem("authenticated")}`
            }
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getPowertrainTypesSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getPowertrainTypesError(error.message)) });
    };
}