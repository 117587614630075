import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {connect} from "react-redux";
import {RootStore, init, thunkAction, unboxThunk} from '../../redux';
import brandColors from "../../styles/colors/brandColors";
import {AttributesResponseInterface} from "../../helpData/getVehicleAttributes";
import {Attribute} from "../../store/vehicles/types";
import DialogModal from "../Modals/DialogModal";
import {StyledButton} from "../layout/GreenButton";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const storeEnhancer = connect(
    (state: RootStore) => ({
        ...state,
    }), {
        init,
        thunkAction: unboxThunk(thunkAction),
    }
);
const GreenCheckbox = withStyles({
  root: {
    color: brandColors.crispedge,
    '&$checked': {
      color: brandColors.crispedge,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

///////////////////////////////////////////////////////



type AppProps = {
    id_key: string,
    attributes: AttributesResponseInterface[],
    children?: string,
    onChange: any,
    dataTestId?: string
};
type FilterMenuState = {
    setAnchorEl: HTMLElement | any,
    attributes: any,
    error_msg: { title: string, msg: string, visible: boolean}
}

class FilterMenu extends React.PureComponent<AppProps, FilterMenuState> {

    constructor(props: AppProps) {
        super(props);

        this.handleDialodModalClose = this.handleDialodModalClose.bind(this);
        this.state = {
            setAnchorEl: null,
            attributes: null,
            error_msg: { title: 'Filters', msg: '', visible: false} };
    }

    componentDidMount() { }

    handleClick(event: any) {
        this.setState({setAnchorEl: event.currentTarget});
    }
    handleClose() {
        this.setState({setAnchorEl: null});
    }

    handleDialodModalClose(event: any){
        this.setState({error_msg: {title: this.state.error_msg.title, msg: '', visible: false}});
    }

    toggleAttribute(event: any, attribute: any) {
        attribute.selected = !attribute.selected;

        const selected_attributes = this.props.attributes.filter((item: Attribute) => item.selected);

        if(selected_attributes.length < 10) {
            this.setState({...this.state, [attribute.name]: attribute.selected});
        } else {
            attribute.selected = !attribute.selected;
            this.setState({error_msg: { title: this.state.error_msg.title, msg: 'Sorry, you could have only 10 custom filters', visible: true}});
        }

        this.props.onChange(attribute);
    }

    render() {

        return (
            <div key={this.props.id_key} data-test-id="FilterMenu">
                <StyledButton
                    data-test-id={this.props.dataTestId}
                    id={this.props.dataTestId}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={(event: any) => this.handleClick(event)}
                >
                    {this.props.children}
                </StyledButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={this.state.setAnchorEl}
                    keepMounted
                    open={Boolean(this.state.setAnchorEl)}
                    onClose={(event: any) => this.handleClose()}
                >
                    {this.props.attributes.map(attribute =>
                        <MenuItem
                            onChange={(event: any) => this.toggleAttribute(event, attribute)}
                            key={this.props.id_key + '_' + attribute.name}
                        >
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        data-test-id={'Filter_' + attribute.name}
                                        value={attribute.name}
                                        control={<GreenCheckbox checked={attribute.selected}/>}
                                        label={attribute.name}
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </MenuItem>
                    )}
                </StyledMenu>
                {
                    this.state.error_msg.visible === true ? (<DialogModal error_msg={this.state.error_msg} handleClose={this.handleDialodModalClose}/>) : null
                }
            </div>
        );
    }
}

export default storeEnhancer(FilterMenu);