import styled from '../../../utils/styled';

export const InformationItem = styled('div')`
    display: flex;
    height: 55px;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    margin-left: 15px;
    margin-right: 15px;
`;

interface ITextContainerProps {
    flex: number
    border?: boolean | undefined
}
export const TextContainer = styled('div')<ITextContainerProps>`
    ${props => props.flex && 'flex: ' + props.flex};
    height: 100%;
    ${props => props.border && 'border-left: 1px solid rgba(224, 224, 224, 1)'};
    display: flex;
    align-items: center;
`;

export const Title = styled('h3')`
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding-left: 20px;
`;

export const Description = styled(Title)`
    font-weight: 400;
`;

export const TableContainer = styled('div')`
    margin-top: 10px;
`;

export const TextMessage = styled('p')`
    color: rgba(0, 0, 0, 0.87);
    padding: 16px;
    font-size: 0.875rem;
    
    & > span {
        color: ${props => props.theme.colors.regular};
        text-decoration: underline;
        cursor: pointer;
    }
`;
