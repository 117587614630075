import _ from 'lodash';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import {withStyles} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import lightTheme from '../../styles/theme/light';
import TableCell from '@material-ui/core/TableCell';
import {TextMessage} from '../result_page_components/VehicleInformation/styled';
import {StyledTableCell, StylesTableBody, StylesTableHead, TableElement} from '../layout/TableBaseStyles';


export interface SimVehicleResult {
    model: string
    ev_score: number
    driving_days?: any[]
}

export interface VehicleResultView {
    vehicle_id: number
    telematics_id: string
    average_distance: number
    total_distance: number
    best?: SimVehicleResult
    worst?: SimVehicleResult
    ev_scores: SimVehicleResult[]
    description: string,
    driving_days?: any[],
    attributes: any
}

interface IResultTableProps {
    items: VehicleResultView[]
    selectedVehicle: any
    history: any
    handleChangeVehicle: (vehicle: VehicleResultView) => void
}

function ResultTable(props: IResultTableProps) {

    const {items, selectedVehicle, handleChangeVehicle, history} = props;
    console.log("items are")
    console.log(items)

    /* useEffect(() => {
        handleChangeVehicle(items[0])
    }, []) */

    const getRowAttribute = (attributes: any, type: string) => {
		for (let i in attributes) {
			if (attributes[i].name == type)
				return attributes[i].value
		}

		return null
    }
    
    const renderRows = () => {
        if(_.isEmpty(items)) {
            return (
                <TableRow>
                    <StyledTableCell colSpan={2}>
                        <TextMessage>
                            Vehicles was not selected in <span onClick={() => {history.push('/fleet-analysis/vehicles')}}>Filter Vehicles</span> tab
                        </TextMessage>
                    </StyledTableCell>
                </TableRow>
            );
        }
        return items.map((vehicle_result: VehicleResultView, index: number) => {
            return (
                <React.Fragment key={vehicle_result.vehicle_id}>
                    <StyledHeaderRow
                        selected={selectedVehicle && selectedVehicle.vehicle_id === vehicle_result.vehicle_id}
                        onClick={() => handleChangeVehicle(vehicle_result)}>
                        <TableCellInfo colSpan={1} >{vehicle_result.telematics_id}</TableCellInfo>
                        <TableCellInfo colSpan={1} >{getRowAttribute(vehicle_result.attributes, "Make")}</TableCellInfo>
                        <TableCellInfo colSpan={1} >{getRowAttribute(vehicle_result.attributes, "Model")}</TableCellInfo>
                        <TableCellInfo colSpan={1} >{getRowAttribute(vehicle_result.attributes, "Model Year")}</TableCellInfo>
                        {/*
                        <TableCellInfo colSpan={1} >{getRowAttribute(vehicle_result.attributes, "Department")}</TableCellInfo>
                        <TableCellInfo colSpan={1} >{getRowAttribute(vehicle_result.attributes, "MFB-Vehicle Type")}</TableCellInfo>
                        */}
                        {/*<TableCellInfo colSpan={1}>{vehicle_result.average_distance.toFixed(1) + ' mi'}</TableCellInfo>*/}
                        <TableCellInfo colSpan={1}>{vehicle_result.total_distance.toFixed(1) + ' mi'}</TableCellInfo>
                        {
                        //selectedVehicle && selectedVehicle.vehicle_id === vehicle_result.vehicle_id && <TableElement/>
                        }
                    </StyledHeaderRow>
                    {/*
                    <StyledFooterRow
                        selected={selectedVehicle && selectedVehicle.vehicle_id === vehicle_result.vehicle_id}
                        onClick={() => handleChangeVehicle(vehicle_result)}>
                        <TableCellBest colSpan={1}>
                            {vehicle_result.best && vehicle_result.best.model ? (`${(vehicle_result.best.ev_score * 100).toFixed(1)}% ${vehicle_result.best.model}`) : ' '}
                        </TableCellBest>
                        <TableCellWorst colSpan={1}>
                            {vehicle_result.worst && (`${(vehicle_result.worst.ev_score * 100).toFixed(1)}% ${vehicle_result.worst.model}`)}
                        </TableCellWorst>
                    </StyledFooterRow>
                    */}
                </React.Fragment>
            )
        })
    };

    return (
        <Table aria-labelledby="tableTitle" size={'medium'} style={{position: 'relative'}} aria-label="enhanced table">
            <StylesTableHead>
                <TableRow>
                    <StyledTableCell>Telematics id</StyledTableCell>
                    <StyledTableCell>Make</StyledTableCell>
                    <StyledTableCell>Model</StyledTableCell>
                    <StyledTableCell>Model Year</StyledTableCell>
                    {/*
                    <StyledTableCell>Department</StyledTableCell>
                    <StyledTableCell>Powertrain Type</StyledTableCell>
                    */}
                    {/*<StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Avg. daily dist.</StyledTableCell>*/}
                    <StyledTableCell>Total dist.</StyledTableCell>
                </TableRow>
            </StylesTableHead>
            <StylesTableBody>
                {renderRows()}
            </StylesTableBody>
        </Table>
    );
}

export default ResultTable;

const StyledHeaderRow = withStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
        position: 'relative',
        cursor: 'pointer',
    },
    selected: {
        backgroundColor: `${lightTheme.colors.regular}66 !important`,
    }
})(TableRow);

const StyledFooterRow = withStyles({
    root: {
        cursor: 'pointer'
    },
    selected: {
        backgroundColor: `${lightTheme.colors.regular}66 !important`,
    }
})(TableRow);

const TableCellBest = withStyles({
    root: {
        color: lightTheme.colors.text_green,
        paddingTop: 0,
    }
})(TableCell);

const TableCellWorst = withStyles({
    root: {
        color: lightTheme.colors.text_red,
        paddingTop: 0,
    }
})(TableCell);

const TableCellInfo = withStyles({
    root: {
        //paddingBottom: 6,
    }
})(TableCell);

