import {SortDirection} from './enums';

export function sort<T>(data: Array<T>, field: string, sortDirection: SortDirection) : Array<T> {
    // @ts-ignore
    return data.sort((item1: T, item2: T) => {
        if(sortDirection === SortDirection.ASC) {
            // @ts-ignore
            return item1[field] - item2[field];
        } else if(sortDirection === SortDirection.DESC) {
            // @ts-ignore
            return item2[field] - item1[field];
        }
        return data;
    });
}

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string) : any {
  return EMAIL_REGEXP.test(email);
}

export function formatMonthsToYearsOrMonths(nbMonths: number) {
    let months = nbMonths % 12;
    if (months > 0) { // display duration in months
        return nbMonths + " " + 'months';
    }
    else { // display duration in years
        let years = nbMonths / 12;
        if (years > 1) {
            return years + " " + 'years';
        }
        else {
            return years + " " + 'year';
        }
    }
}

export function formatDistance(data: any, decimals=0, short=null) {
    if (typeof data === 'object') {
        if (data !== null) {
            if (data.unit === "KILOMETERS") {
                return `${data.value.toFixed(decimals)} km`
            } else if (data.unit === "MILES") {
                if (short == null) {
                    return `${data.value.toFixed(decimals)} miles`
                } else {
                    return `${data.value.toFixed(decimals)} mi`
                }
            } else {
                if (data.value) {
                    return `${data.value.toFixed(decimals)} ${data.unit}`
                } else {
                    return data.value
                }
                
            }
        }
    }
    return data
}

export function formatNumberToCurrency(data: any, decimals=0) {
    if (data) {
        if (typeof data === 'object') {
            if (data.currency === "CAD") {
                var lang = "fr-CA"
            } else {
                var lang = "en-US"
            }
            //console.log("USING CURRENCY", lang, data.value, data.currency)
            var currencyFormatter = new Intl.NumberFormat(lang, {style: 'currency', currency: data.currency, minimumFractionDigits: 0, maximumFractionDigits: decimals, currencyDisplay: "symbol"});
            return currencyFormatter.format(data.value);
        } else {
            var currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: decimals});
            return currencyFormatter.format(data);
        }
    } else {
        return null
    }
    
}

export function formatNumberToQuantity(value: number) {
    var quantityFormatter = new Intl.NumberFormat('en-US');
    return quantityFormatter.format(value);
}

export function tooltipFormatNumberToCurrency(data: any, field: any) {
    if (typeof data === 'object') {
        if (data.currency === "CAD") {
            var lang = "en-CA"
        } else {
            var lang = "en-US"
        }
        var currencyFormatter = new Intl.NumberFormat(lang, {style: 'currency', currency: data.currency, minimumFractionDigits: 0, maximumFractionDigits: 0});
        return currencyFormatter.format(data.value)
    } else {
        var currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
        return currencyFormatter.format(data);
    }
}

export function getPowertrainTypeDisplayString(powertrain_type: string) {
    switch (powertrain_type) {
        case 'conventional':
            return 'Conventional'
        case 'HEV':
            return 'Hybrid (HEV)'
        case 'PHEV':
            return 'Plug-In Hybrid (PHEV)'
        case 'EV':
            return 'All Electric (EV)'
        default:
            return 'N/A';
    }
}