import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { fade, makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import { TransitionProps } from '@material-ui/core/transitions';

//DM: took this sample code from material-ui.com/components/tree-view
//Customized it after
interface IReportField {
    name: string,
    label: string,
    is_checked: boolean
}

interface IProps {
   fleetReportFieldsList: Array<IReportField>,
   yourFleetEstsFieldsList: Array<IReportField>,
   replaceIceEstsFieldsList: Array<IReportField>,
   evReplaceOptsFieldsList: Array<IReportField>
}

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      width: '100%'
    },
  }),
);


export default function CustomizedTreeView(props:IProps) {
    const classes = useStyles();
    let fleetReportFieldsListNum = 0;
    let yourFleetEstsFieldsListNum = 0;
    let replaceIceEstsFieldsListNum = 0;
    let evReplaceOptsFieldsListNum = 0;

    const {fleetReportFieldsList, 
        yourFleetEstsFieldsList, 
        replaceIceEstsFieldsList, 
        evReplaceOptsFieldsList, ...otherProps} = props;


    const listSectionReportFields = (inSectionCode:string) => {
        const items = [];

        let localFieldsList:Array<IReportField> = []
        if ("fleet_report" === inSectionCode) {
            localFieldsList = fleetReportFieldsList;
        } else if("your_fleet_estimates" === inSectionCode) {
            localFieldsList = yourFleetEstsFieldsList;
        } else if("replacement_ice_estimates" === inSectionCode) {
            localFieldsList = replaceIceEstsFieldsList;
        } else if("ev_replacement_options" === inSectionCode) {
            localFieldsList = evReplaceOptsFieldsList;
        }

        for(let i = 0; i < localFieldsList.length; i++) {
            if(("all_none" != localFieldsList[i].name) && (localFieldsList[i].is_checked)) {
                items.push(<StyledTreeItem 
                    key={localFieldsList[i].name}
                    nodeId={localFieldsList[i].name} 
                    label={localFieldsList[i].label}/>)
                }
        }
        return items;
    }

    const displaySectionCountLabel = (inSectionCode:string) => {
        let localFieldsList:Array<IReportField> = [];
        let count = 0;
        let labelPrefix = "No Label";
        if ("fleet_report" === inSectionCode) {
            localFieldsList = fleetReportFieldsList;
            labelPrefix = "Fleet Report (";
        } else if("your_fleet_estimates" === inSectionCode) {
            localFieldsList = yourFleetEstsFieldsList;
            labelPrefix = "Your Fleet etimates (";
        } else if("replacement_ice_estimates" === inSectionCode) {
            localFieldsList = replaceIceEstsFieldsList;
            labelPrefix = "Replacement ICE Estimates (";
        } else if("ev_replacement_options" === inSectionCode) {
            localFieldsList = evReplaceOptsFieldsList;
            labelPrefix = "EV Replacement Options (";
        }

        for(let i = 0; i < localFieldsList.length; i++) {
            if(localFieldsList[i].is_checked) {
                count++;
            }
        }
        //set the counts
        
        return labelPrefix + count + ")";
    }


        
  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['1','2','3','4','5']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
      <StyledTreeItem nodeId="1" label="Complete Report">
        <StyledTreeItem nodeId="2" label={displaySectionCountLabel("fleet_report")}>
            {listSectionReportFields("fleet_report")}
        </StyledTreeItem>
        <StyledTreeItem nodeId="3" label={displaySectionCountLabel("your_fleet_estimates")}>
        {listSectionReportFields("your_fleet_estimates")}
        </StyledTreeItem>
        <StyledTreeItem nodeId="4" label={displaySectionCountLabel("replacement_ice_estimates")}>
            {listSectionReportFields("replacement_ice_estimates")}
        </StyledTreeItem>
        <StyledTreeItem nodeId="5" label={displaySectionCountLabel("ev_replacement_options")}>
            {listSectionReportFields("ev_replacement_options")}
        </StyledTreeItem>
      </StyledTreeItem>
    </TreeView>
  );
}
