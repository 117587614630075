import * as React from "react";
import * as R from 'ramda';
import {connect} from "react-redux";
// Separate state props + dispatch props to their own interfaces.
// material ui styles
import styled from 'styled-components';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// components
import Page from "../../components/layout/Page";
import Container from "../../components/layout/Container";
import {FlexBox} from "../../components/layout/Card.tsx";
import {GreenButton} from "../../components/layout/GreenButton";
import DetailedExpansionPanel from '../../components/ChargingSites/SimpleTable';
// help data

// store
import {ApplicationState} from "../../store";
import {LocationsResponse, SimulationResponse} from "../../store/charging-page/types";
import {
    fetchLocaleRequest, fetchPostSimulationRequest, resultAreNotReady, setSelectedLocations,
    setSimulationUuid
} from "../../store/charging-page/actions";
import {fetchResults} from '../../store/results/actions';

import {GenerateStringUUIDWithOptions} from "../../helpData/generateStringUUUID";
import MapView from '../../components/result_page_components/Map/MapView';
import {Vehicle} from "../../helpData/get_vehicles_response_example";
import {SimVehicleSuggest} from "../../helpData/SimVehicles";
import LoadingModal from "../../components/Modals/LoadingModals";
import DialogInput from '../../components/Modals/DialogInput';
import {setAnalysisNameAction} from '../../store/analysis/actions';
import {getVehicles_query_Interface} from "../../helpData/getVehicleAttributes";
import {Attribute} from "../../store/vehicles/types";
import {group_vehicles_by_attribute, VehicleGroup} from "../select_replacement/group_vehicles_by_attribute";
import _ from "lodash";

////////////////////////////////////////////////////////////////////////

export const StyledGrid = styled(Box)(({theme}) => ({
    gridGap: '2vw',
    minHeight: '100vh',
    height: 'max-content',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '450px 1fr',
})) as typeof Box;

export const StyledPage = styled(Page)(({theme}) => ({
    minHeight: '100%',
    height: 'max-content',
})) as typeof Box;

/////////////////////////////////////////////////////////////////////////////////////////////

export interface PropsFromState {
    errors?: string,
    loading: boolean,
    lambdaProgress: number,
    simulation_uuid: string,
    setChargingStops: any,
    chargingStopsData: any,
    locations: LocationsResponse[],
    selectedLocations: LocationsResponse[],
    simulationResponse?: SimulationResponse | undefined,
    setChargingStopsData: any,
    current_query_for_saving: getVehicles_query_Interface,
    selected_vehicles: Vehicle[] | undefined,
    sim_vehicle_suggest: SimVehicleSuggest[] | undefined,
    analysisName: string
    vehicle_mapping: any
    setAnalysisNameAction: typeof setAnalysisNameAction
    history: any
    group_by_attribute: Attribute
    group_to_sim_vehicle_mapping: { [group_title: string]: Vehicle[]}
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
    fetchLocaleRequest: typeof fetchLocaleRequest
    fetchPostSimulationRequest: typeof fetchPostSimulationRequest
    setSelectedLocations: typeof setSelectedLocations
    setSimulationUuid: typeof setSimulationUuid
    resultAreNotReady: typeof resultAreNotReady
    fetchResults: typeof fetchResults
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromState & PropsFromDispatch

interface IState {
    locations: LocationsResponse[],
    selectedIDs: number[],
    centerCoords: {lng: number; lat: number;} | undefined,
    simulationResponse: SimulationResponse,
    chargingStopsData: [],
    simulation_algorithm: string,
    selectedLocationID: number,
    simulationStarted: boolean,
    submitPressed: boolean
}

export function vehicle_to_sim_vehicle_mapping(grouped_vehicles: VehicleGroup[], group_to_sim_vehicle_mapping: {[group_title: string]: Vehicle[]}) {

    const mapping: Map<string, number[]> = new Map();

    grouped_vehicles.forEach((group) => {

        group.vehicles.forEach((vehicle) => {

            const vehicle_id_str = vehicle.vehicle_id.toString();

            const vehicles_ids = mapping.get(vehicle_id_str) || [];

            const sim_vehicle_group = group_to_sim_vehicle_mapping[group.group_title] || [];

            vehicles_ids.push(...sim_vehicle_group.map((sim_vehicle) => sim_vehicle.vehicle_id));

            mapping.set(vehicle_id_str, vehicles_ids);
        });

    });

    return Array.from(mapping).reduce((obj: any, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});
}


class RunAnalysis extends React.PureComponent<AllProps, IState> {
    constructor(props: AllProps) {
        super(props);

        let _simulationResponse;
        let centerCoords;

        this.props.fetchLocaleRequest();

        let _selectedIDs = [] as any;

        const locations = JSON.parse(JSON.stringify(this.props.locations));

        if(this.props.selectedLocations && this.props.selectedLocations.length) {
            _selectedIDs = (JSON.parse(JSON.stringify(this.props.selectedLocations))
                .map((item: LocationsResponse) => item.id))
        }
        if(typeof this.props.simulationResponse !== 'undefined') {
            _simulationResponse = JSON.parse(JSON.stringify(this.props.simulationResponse));
        }
        if(locations.length) {
            centerCoords = {lng: locations[0].geo.lon, lat: locations[0].geo.lat};
        }

        let chargingStopsData = R.pathOr([], ['selectedLocations'], props)

        this.state = {
            simulationResponse: _simulationResponse || {},
            locations: locations || [],
            centerCoords: centerCoords,
            selectedIDs: _selectedIDs,
            simulation_algorithm: "MINIMUM_CHARGERS",
            chargingStopsData: R.pathOr([], ['selectedLocations'], props),
            selectedLocationID: -1,
            simulationStarted: false,
            submitPressed: false
        };

        //Run the simulation automatically
        //this.handelSubmit(chargingStopsData)

        this.handelSubmit = this.handelSubmit.bind(this);
        this.setLocationsData = this.setLocationsData.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
        this.setLocation = this.setLocation.bind(this);
    }

    componentDidUpdate(prevProps: AllProps) {
        let coords;

        if (this.props.lambdaProgress != prevProps.lambdaProgress) {
            this.setState({
                simulationStarted: true
            })
        }

        if (!_.isEqual(this.props.locations, prevProps.locations)) {
            const locations = JSON.parse(JSON.stringify(this.props.locations));

            if(locations && locations.length > 0) coords = {lng: locations[0].geo.lon, lat: locations[0].geo.lat};

            this.setState({
                locations: locations || [],
                centerCoords: coords
            });
        }
        if(typeof this.props.selectedLocations !== 'undefined' && !_.isEqual(this.props.selectedLocations, prevProps.selectedLocations)) {

            let _selected;

            if((JSON.parse(JSON.stringify(this.state.selectedIDs)))) {
                _selected = (JSON.parse(JSON.stringify(this.state.selectedIDs)));

            } else _selected = [];
            let __array = [] as any;

            (JSON.parse(JSON.stringify(this.props.selectedLocations)))
                .forEach((item: LocationsResponse) => {
                    __array.push(item.id)
                });

            __array.concat(_selected);

            console.log(__array);



            this.setState({
                selectedIDs: __array,
            });
        }
        if(typeof this.props.simulationResponse !== 'undefined' && !_.isEqual(this.props.simulationResponse, prevProps.simulationResponse)){
            const simulationResponse = JSON.parse(JSON.stringify(this.props.simulationResponse));
            this.setState({
                simulationResponse: simulationResponse || {}
            });
        }
    }


    setLocationsData(locations: LocationsResponse[]) {
        this.setState({
                locations: locations
            });
    }

    handelSubmit(data: any) {
        this.setState({
            submitPressed: true
        })

        this.props.resultAreNotReady();

        const uuid = GenerateStringUUIDWithOptions();

        let date_range = {startDate: '', endDate: ''};

        if (this.props.current_query_for_saving !== null) {
            date_range.startDate = this.props.current_query_for_saving.date_range.start_date;
            date_range.endDate = this.props.current_query_for_saving.date_range.end_date;
        }

        const grouped_vehicles = group_vehicles_by_attribute(
            this.props.group_by_attribute,
            this.props.selected_vehicles
        );

        const mapping: {[vehicle_id: string]: number[]} = vehicle_to_sim_vehicle_mapping(grouped_vehicles, this.props.group_to_sim_vehicle_mapping);

        var selected_simulation_vehicles_ids: number[] = [];

        Object.keys(mapping).forEach((k: string) => { selected_simulation_vehicles_ids = [...selected_simulation_vehicles_ids, ...mapping[k]] } );

        const runSimulationRequest = {
            "date_range": {
                "startDate": date_range.startDate,
                "endDate": date_range.endDate
            },
            "selected_vehicles_ids": Object.keys(mapping).map((k: string) => Number(k)),
            "selected_simulation_vehicles_ids": Array.from(new Set(selected_simulation_vehicles_ids)),
            "charging_stations_ids": this.state.selectedIDs,
            "simulation_algorithm": this.state.simulation_algorithm,
            "vehicle_mapping": mapping
        } as any;

        this.props.setSimulationUuid({simulation_uuid: uuid, body: runSimulationRequest});
        // this.props.fetchPostSimulationRequest({simulation_uuid: uuid, body: runSimulationRequest});
    }

    selectEvent(selectedIDs: number[]){
        let __selectedLocations = [] as any;

        if(selectedIDs.length) {
            this.props.locations.forEach((item: LocationsResponse) => {
                selectedIDs.forEach((s: number) => {
                    if (item.id === Number(s)) {

                        __selectedLocations.push(item)
                    }
                })
            });
        }

        this.props.setSelectedLocations(__selectedLocations);
        // this.setState({selectedIDs: selectedIDs})
    }

    handleSelectChange(event: any){
        const value = event.target.value.toString();
        this.setState({simulation_algorithm: value});
    }

    normalizeMapData(selectedLocations: LocationsResponse[], simulationResponse?: SimulationResponse) {
        return selectedLocations.map((sloc: LocationsResponse) => {
            let utilization = 'n/a';
            let l: LocationsResponse | undefined = undefined;
            if(simulationResponse) {
                l = simulationResponse.find((o: LocationsResponse) => o.id === sloc.id);
            }
            return {
                id: sloc.id,
                name: sloc.name,
                utilization: utilization,
                latitude: sloc.geo.lat,
                longitude: sloc.geo.lon,
                chargers: {
                    level_1: l ? l.chargers.level_1 : sloc.l1_charger_count,
                    level_2: l ? l.chargers.level_2 : sloc.l2_charger_count,
                    dc_fc: l ? l.chargers.dc_fc : sloc.dc_charger_count
                }
            }
        });
    }

    setLocation(id: number, lat: number, lng: number) {
        this.setState({
            selectedLocationID: id,
            centerCoords: {
                lat, lng
            }
        })
    }


    render() {
        const {loading, lambdaProgress} = this.props;
        const { simulationStarted } = this.state;
        //Redirect to home page if there is no analysis selected
        if (this.props.analysisName === '')
            this.props.history.push('/')

        console.log("simulation started")
        console.log(simulationStarted)
        if (simulationStarted && lambdaProgress == 100) {
            this.props.history.push('/fleet-analysis/result/')
        }

        console.log("progress" + lambdaProgress)

        return (
            <StyledPage>
                <Container style={{}}>
                                        
                                        
                                        
                                        <Paper>
                                            <GreenButton
                                                disabled={!this.props.selected_vehicles || this.props.selected_vehicles.length === 0}
                                                onClick={() => this.handelSubmit(this.state.chargingStopsData)}
                                                variant="contained"
                                                style={{margin: 30}}
                                            >
                                                Run Analysis
                                            </GreenButton>
                                            {
                                            simulationStarted && lambdaProgress != 0 ?
                                                <Typography variant="h4" style={{textAlign: 'center', padding: '30px'}}>
                                                    Running Analysis for "{this.props.analysisName}"
                                                </Typography>
                                            : simulationStarted && lambdaProgress == 0 && !loading ?
                                                <Typography variant="h4" style={{textAlign: 'center', padding: '30px'}}>
                                                    Analysis Successfully Ran
                                                </Typography>
                                            :
                                                null
                                            }
                                        </Paper>
                                        

                                        {/*
                                            loading || lambdaProgress > 0 ?
                                                <Paper>
                                                    <Typography variant="h4" style={{textAlign: 'center', padding: '30px'}}>
                                                        Running Analysis for "{this.props.analysisName}"
                                                    </Typography>
                                                </Paper>
                                            : loading == false ?
                                                <Paper>
                                                    <Typography variant="h4" style={{textAlign: 'center', padding: '30px'}}>
                                                        Analysis Successfully Ran
                                                    </Typography>
                                                </Paper>
                                            : null
                                        */}

                </Container>
                {
                    loading && (<LoadingModal open={loading} progress={lambdaProgress}/>)
                }
            </StyledPage>
        );
    }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
export const mapStateToProps = ({chargingPage, vehicle, homePage, resultsPage}: ApplicationState) => ({
    locations: chargingPage.locations,
    loading: chargingPage.loading || resultsPage.loading,
    lambdaProgress: resultsPage.lambdaProgress,
    selectedLocations: chargingPage.selectedLocations,
    // simulationResponse: chargingPage.simulationResponse,
    selected_vehicles: vehicle.selected_vehicles,
    current_query_for_saving: vehicle.current_query_for_saving,
    sim_vehicle_suggest: vehicle.sim_vehicle_suggest,
    analysisName: homePage.analysisName,
    simulationResponse: resultsPage.chargingSites,
    simulation_uuid: chargingPage.simulation_uuid,
    vehicle_mapping: vehicle.vehicle_mapping,
    group_by_attribute: vehicle.group_by_attribute,
    group_to_sim_vehicle_mapping: vehicle.group_to_sim_vehicle_mapping
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
    fetchLocaleRequest,
    fetchPostSimulationRequest,
    setSelectedLocations,
    setAnalysisNameAction,
    setSimulationUuid,
    resultAreNotReady,
    fetchResults
};

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RunAnalysis);
